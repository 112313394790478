import { FieldArray, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import useDebounce from "hooks/useDebounce";
import { useGetInvoiceItems } from "hooks/invoices";
import { InputWithAutocomplete } from "ui-lib/components/input";
import FormikInput from "components/form/FormikInput";
import { LyteButton } from "components/common/buttons";
import Color from "utils/colours";
import FormikTexteditor from "components/form/FormikTexteditor";

import InvoiceTotalCard from "./InvoiceTotalCard";
import { IconContainer, ItemRows } from "./styles";

const InvoiceDetailTable = ({ setCanNext }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [isSearching, setIsSearching] = useState(false);
  const [invoiceItemSearchKey, setInvoiceItemSearchKey] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  // Debounced search
  const debouncedItemSearch = useDebounce(invoiceItemSearchKey);

  const {
    data: { data: { data: invoiceItems = [] } = {} } = {},
    refetch: handleInvoiceItemsSearch,
  } = useGetInvoiceItems({
    searchKey: debouncedItemSearch,
    options: {
      enabled: !!debouncedItemSearch,
      refetchOnWindowFocus: false,
      refethOnMount: false,
      onSettled: () => setIsSearching(false),
    },
  });

  const onFocusHandler = useCallback((e) => {
    if (e.target.value.trim() === "") {
      return;
    }

    if (e.target.name === "itemName") {
      setInvoiceItemSearchKey(e.target.value);
      setIsSearching(true);
      handleInvoiceItemsSearch();
    }
  });

  const onItemChangeHandler = useCallback((value) => {
    setInvoiceItemSearchKey(value);
  });

  useEffect(() => {
    if (debouncedItemSearch) {
      handleInvoiceItemsSearch();
    }

    if (invoiceItemSearchKey) {
      setIsSearching(true);
    }
  }, [debouncedItemSearch, invoiceItemSearchKey]);

  return (
    <Row>
      <Col>
        <h5>Items</h5>
      </Col>
      <Col xs={12}>
        <FieldArray name="invoiceItems">
          {(arrayHelpers) => (
            <>
              {values?.invoiceItems?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ItemRows key={index}>
                  <Col lg={2}>
                    <InputWithAutocomplete
                      async
                      name={`invoiceItems[${index}].itemName`}
                      label="Item"
                      suggestions={invoiceItems}
                      labelKey="name"
                      onInputChange={onItemChangeHandler}
                      isLoading={isSearching}
                      onSelected={(selected) => {
                        setFieldValue(`invoiceItems[${index}].itemName`, selected.name);
                        setFieldValue(`invoiceItems[${index}].description`, selected.description);
                        setFieldValue(`invoiceItems[${index}].rate`, selected.rate);
                        setFieldValue(`invoiceItems[${index}].quantity`, 1);
                        setForceUpdate(!forceUpdate);
                      }}
                      value={values.invoiceItems[index].itemName}
                      onFocus={onFocusHandler}
                      maxLength={50}
                      isRequired
                      className="mb-0"
                    />
                  </Col>
                  <Col lg={4}>
                    <FormikTexteditor
                      label="Item Details/Description"
                      name={`invoiceItems[${index}].description`}
                      forceUpdate={forceUpdate}
                    />
                  </Col>
                  <Col xs={6} lg={1}>
                    <FormikInput
                      label="Quantity"
                      name={`invoiceItems[${index}].quantity`}
                      type="tel"
                      min={0}
                      max={999}
                      className="mb-0"
                    />
                  </Col>
                  <Col xs={6} lg={2}>
                    <FormikInput
                      label="Price"
                      name={`invoiceItems[${index}].rate`}
                      type="number"
                      step="1"
                      className="mb-0"
                    />
                  </Col>
                  <Col xs={10} lg={2}>
                    <FormikInput
                      label="Amount"
                      name={`invoiceItems[${index}].amount`}
                      type="number"
                      disabled
                      readonly
                      value={values.invoiceItems[index].quantity * values.invoiceItems[index].rate}
                      className="mb-0"
                    />
                  </Col>
                  <Col xs={2} lg={1}>
                    {values?.invoiceItems.length > 1 && (
                      <IconContainer onClick={() => arrayHelpers.remove(index)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </IconContainer>
                    )}
                  </Col>
                </ItemRows>
              ))}
              <div className="d-flex flex-column flex-lg-row justify-content-between mt-4">
                <LyteButton
                  size="sm"
                  style={{ height: "fit-content", marginBottom: "16px" }}
                  onClick={() =>
                    arrayHelpers.push({ itemName: "", description: "", quantity: 1, rate: 0 })
                  }
                >
                  <FontAwesomeIcon icon={faPlus} color={Color.neutral0} />
                  Add new row
                </LyteButton>
                <InvoiceTotalCard
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setCanNext={setCanNext}
                />
              </div>
            </>
          )}
        </FieldArray>
      </Col>
    </Row>
  );
};

export default InvoiceDetailTable;
