import InvoiceRejected from "views/public/invoice/InvoiceRejected";
import InvoiceReject from "views/public/invoice/InvoiceReject";
import InvoiceAccept from "views/public/invoice/InvoiceAccept";
import InvoiceAccepted from "views/public/invoice/InvoiceAccepted";
import MockInvoiceAccept from "views/public/invoice/MockInvoiceAccept";
import MockInvoiceReject from "views/public/invoice/MockInvoiceReject";

export const invoiceAcceptRejectRoutes = [
  {
    path: "/invoice-reject/:identifier",
    exact: true,
    component: InvoiceReject,
  },
  {
    path: "/invoice/rejected/:identifier",
    exact: true,
    component: InvoiceRejected,
  },
  {
    path: "/invoice-accept/:identifier",
    exact: true,
    component: InvoiceAccept,
  },
  {
    path: "/invoice/accepted/:identifier",
    exact: true,
    component: InvoiceAccepted,
  },
  {
    path: "/invoice-accept-mock",
    exact: true,
    component: MockInvoiceAccept,
  },
  {
    path: "/invoice-reject-mock/:identifier",
    exact: true,
    component: MockInvoiceReject,
  },
];
