import styled from "styled-components";

export const DividerStyled = styled.div`
  width: 100%;
  height: ${(props) => (props?.height ? props.height : "1px")};
  background-color: ${(props) => props.theme.color.dividerColor};

  ${(props) =>
    props.type === "horizontal" &&
    `
    height: 100%;
    width: ${(props) => (props?.width ? props.width : "1px")};
  `}
`;
