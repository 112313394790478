import { memo } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import LytePayLogo from "images/svg/lytepay-logo.svg";
import Loader from "components/common/Loader";
import InvoiceTemplate from "components/common/InvoiceTemplate";
import useApi from "hooks/useApi";

const InvoiceAccept = () => {
  const { identifier } = useParams();
  const { replace } = useHistory();
  const { Public } = useApi();

  const { data: invoice } = useQuery(
    "invoiceAccept",
    async () => {
      const { data } = await Public.get(`public/invoices/${identifier}`);
      return data;
    },
    {
      onSettled: ({ status }) => {
        if (status === "accepted") {
          replace(`/invoice/accepted/${identifier}`);
        }

        if (status === "rejected") {
          replace(`/invoice/rejected/${identifier}`);
        }
      },
    }
  );

  const {
    mutate: accept,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    async () => {
      const res = await Public.post(`public/invoices/${identifier}/accept`, {
        invoiceRef: identifier,
      });
      return res;
    },
    {
      mutationKey: "acceptInvoice",
      onError: (err) => toast.error(`Error accepting invoice`),
      onSuccess: () => {
        replace(`/invoice/accepted/${identifier}`);
      },
    }
  );

  if (!invoice) return <Loader />;

  if (invoice.status !== "pending") {
    return (
      <div className="container">
        <div className="bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img
              src={LytePayLogo}
              className="image mb-5 col-4 col-md-3 mx-auto"
              alt="lytepaylogo"
            />
            <h6>This invoice is no longer available to be accepted. Please close this page.</h6>
          </div>
        </div>
      </div>
    );
  }

  const ButtonText = memo(() => {
    if (isLoading) {
      return (
        <div className="d-flex align-items-center">
          <Spinner animation="border" size="sm" className="mr-2" /> Accepting...
        </div>
      );
    }
    if (isError) {
      return "Error. Try again.";
    }
    return <span>Accept {`${invoice.firstName}'s` || null} Invoice</span>;
  }, [isLoading, isError]);

  return (
    <div className="invoice-reject py-5">
      <div className="container">
        <div className="bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img src={LytePayLogo} className="image mb-5 col-4 col-md-3 mx-auto" alt="" />
            <h6>
              You have accepted the invoice from {invoice.fullName ? invoice.fullName : null}.{" "}
              <br />
              Please confirm your choice.
            </h6>
            <div className="clearfix">
              <div className="mx-auto">
                <button
                  type="button"
                  className="global-button"
                  disabled={isSuccess || isLoading}
                  onClick={() => accept()}
                >
                  <ButtonText />
                </button>
              </div>
            </div>
          </div>
        </div>
        <InvoiceTemplate invoice={invoice} disableLogoHint />
      </div>
    </div>
  );
};

export default InvoiceAccept;
