import { object, number, boolean } from "yup";

const WidgetSettingsSchema = ({ lockInPeriods, rewardsApy }) =>
  object({
    value: number()
      .required("This is a required field")
      .min(0, "Minimum Boost cannot be below 0")
      .max(100, "Maximum Boost cannot exceed 100"),
    autoReboost: boolean().oneOf([true, false]).required("This is a required field"),
    agreeReboost: boolean()
      .oneOf([true], "This is a required field")
      .required("This is a required field"),
    optInForTrialBoost: boolean().oneOf([true, false]),
    duration: number().required("This is a required field").oneOf(lockInPeriods, "Invalid reward"),
    rewards: number().required("This is a required field").oneOf(rewardsApy, "Invalid reward"),
  });

export default WidgetSettingsSchema;
