import { Image } from "react-bootstrap";

import lyteWink from "images/icons/lyte-wink-blue.gif";

const Loader = () => (
  <div className="bg-wink-white mx-auto">
    <div>
      <Image className="d-block" src={lyteWink} />
      <div className="color-blue-link text-center">
        <strong>Loading...</strong>
      </div>
    </div>
  </div>
);

export default Loader;
