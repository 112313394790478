import styled from "styled-components";

import Color from "utils/colours";

export const StyledField = styled.div`
  margin-bottom: 24px;
  position: relative;
  .form-control {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
  .checkbox-container {
    display: flex;
    padding: 10px 25px;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 48px;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid ${Color.dark200};
    background-color: ${Color.neutral0};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: none;
    margin-bottom: 16px;
    .checkbox-content-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 0;
      gap: 16px;
      position: relative;
      .tick {
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: ${Color.neutral0};
        border: 2px solid ${Color.dark200};
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .check-icon {
          display: none;
          color: ${Color.neutral0};
          width: 12px;
        }
      }
      .checkbox-content-label {
        margin: 0;
        font-size: 19px;
      }
    }
    &.checked {
      border: 2px solid ${Color.navi500};
      background-color: ${Color.navi50};
      .checkbox-content-container {
        .tick {
          background-color: ${Color.navi500};
          .check-icon {
            display: block;
          }
        }
      }
    }
  }

  .description-text {
    color: ${Color.dark700};
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 6px;
    line-height: 1.1;
  }

  .error-text {
    color: ${Color.empathy500};
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 6px;
    line-height: 1.1;
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  color: ${Color.dark700};
  margin-bottom: 6px;
  font-weight: 600;
`;
