import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { APPROVED } from "components/dashboard/bank-account-details/constants";
import IllustInvoiceSent from "images/illust/illust-invoice-sent.png";
import { useUserData } from "hooks/useUserData";

const SubmittedContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  max-width: 600px;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Heading = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
`;

const KycSubmitted = () => (
  <SubmittedContainer>
    <div className="container">
      <div className="row justify-content-center">
        <div className="p-0 text-center">
          <Heading>
            <strong>Your account is being reviewed!</strong>
          </Heading>

          <Image src={IllustInvoiceSent} className="image my-4 mx-auto" />

          <h5>
            It may take up to 2 business days for it to be approved and you will receive an email
            once your account is verified.
          </h5>

          <div className="mt-5">
            <Link to="/" className="color-blue-link">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  </SubmittedContainer>
);

export default KycSubmitted;
