import styled from "styled-components";

import Color from "utils/colours";

export const RangeContainer = styled.div`
  &.disabled {
    opacity: 0.5;
  }
`;

export const Track = styled.div`
  height: 8px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Thumb = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.thumbColor};
  border-radius: 50%;
  border: none;
  outline: none;
`;

export const TrackingText = styled.div`
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: -24px;
  color: ${(props) => props.theme.color.textDarkContrast};
  text-align: center;
  border-radius: 8px;
  background-color: transparent;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;
