import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import { useFormikContext } from "formik";

import { LyteButton } from "components/common/buttons";
import { useContents } from "hooks/contents";
import { allCountries } from "utils/commonHelpers";
import FormikDropdown from "components/form/FormikDropdown";
import FormikInput from "components/form/FormikInput";
import Progressbar from "components/common/Progressbar";
import { StyledUploadProfile } from "views/public/profile/upload/styled";
import { useSaveBankAccount } from "hooks/useBankAccount";

const BankAccountUpdateForm = () => {
  const { values, errors } = useFormikContext();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileImage, setUploadedFileImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const { data } = useContents();
  const { mutate: mutateSaveBankAccount, isLoading } = useSaveBankAccount();

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileSizeLimit = 30 * 1024 * 1024; // 20MB
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (file?.size > fileSizeLimit) {
      setErrorMessage("File size exceeds the limit (30MB).");
      return;
    }

    if (!acceptedFileTypes.includes(file?.type)) {
      setErrorMessage("Invalid file type. Only PNG, JPG, and JPEG are allowed.");
      return;
    }

    setErrorMessage("");
    setUploadedFile(file);
    setUploadedFileImage(URL.createObjectURL(file));
    setUploadProgress(0);
  };

  const handleSaveDetails = (skip) => {
    const formData = new FormData();
    if (skip) {
      formData.append("bankStatement", uploadedFile);
    }
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    mutateSaveBankAccount(formData, {
      onSuccess: () => {},
    });
  };

  const isDisabled =
    "countryCode" in errors ||
    "bankId" in errors ||
    "currency" in errors ||
    "accountHolderName" in errors ||
    "accountNumber" in errors;
  return (
    <>
      <p>You will receive all LytePay payments directly into your bank account.</p>
      <p>
        We kindly request that you email <a href="mailto:support@lytepay.co">support@lytepay.co</a>{" "}
        for further assistance in the event that you are unable to locate your bank in the system
      </p>
      <div className="bg-white pt-2 pb-4">
        <FormikDropdown
          label="Bank Location"
          name="countryCode"
          lists={allCountries.map((country) => ({
            name: country.name,
            id: country.iso2.toUpperCase(),
          }))}
          disabled
        />
        <FormikDropdown
          label="Account Currency"
          name="currency"
          lists={[{ name: "SGD", id: "SGD" }]}
          disabled
        />
        <FormikDropdown
          label="Bank Name"
          name="bankId"
          disabled={isLoading}
          lists={data?.banks
            .map((bank) => ({ name: bank.bankName, id: bank.id }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
        <FormikInput label="Account Holder Name" name="accountHolderName" disabled={isLoading} />
        <FormikInput label="Account Number" name="accountNumber" disabled={isLoading} />
        <p>
          As a Financial Institution, we need to verify that your bank account belongs to you.
          Please upload a recent bank statement (or at least the header) showing your:
          <ul className="font-weight-bold">
            <li>Full Name</li>
            <li>Bank Name</li>
            <li>Bank Account Number</li>
            <li>Date of Statement</li>
          </ul>
        </p>
        <p>
          Note: <i>Bank Balance is not required</i>
        </p>
        <div className="bg-white px-5 py-4">
          <StyledUploadProfile>
            <h3 className="text-center mb-5">Upload your Bank Statement</h3>
            <Dropzone
              accept={{
                "image/jpeg": [".jpeg", ".png"],
              }}
              disabled={isLoading}
              onDrop={handleDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <StyledUploadProfile.Dropzone
                  {...getRootProps()}
                  className={isLoading ? "text-muted" : ""}
                >
                  <input {...getInputProps()} />
                  {uploadedFileImage ? (
                    <div className="d-flex align-items-center flex-column justify-content-center w-100 p-3">
                      <StyledUploadProfile.Image
                        src={uploadedFileImage}
                        alt="Uploaded"
                        className="img-fluid pb-2"
                      />
                      {uploadProgress > 0 && (
                        <Progressbar
                          animated={uploadProgress !== 100}
                          variant={uploadProgress === 100 ? "success" : "info"}
                          now={uploadProgress}
                          label={`${uploadProgress}%`}
                          min={0}
                          max={100}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center text-center p-3">
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        style={{ width: "80px", height: "80px" }}
                      />
                      <p className="pt-2">Drag and drop a file here or click to select a file</p>
                    </div>
                  )}
                </StyledUploadProfile.Dropzone>
              )}
            </Dropzone>
            {errorMessage && (
              <p className="mt-2" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
          </StyledUploadProfile>
        </div>
        <LyteButton
          className="mx-auto"
          size="sm"
          disabled={isLoading || isDisabled}
          onClick={handleSaveDetails}
        >
          Save Bank Details
        </LyteButton>
      </div>
    </>
  );
};

export default BankAccountUpdateForm;
