import { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { Formik } from "formik";

import { useUserDataSingleUse } from "hooks/useUserData";
import FormikDropdown from "components/form/FormikDropdown";
import FormikInput from "components/form/FormikInput";
import FormikUpload from "components/form/FormikUpload";
import Loader from "components/common/Loader";
import { PENDING_VERIFICATION } from "components/kyc/constants";
import { isFeatureEnabled } from "utils/commonHelpers";
import IllustKycBill from "images/illust/illust-kyc-bill.png";
import IllustKycFrontImage from "images/illust/illust-kyc-front-img.png";
import IllustKycBackImage from "images/illust/illust-kyc-back-img.png";
import IllustKycSelfie from "images/illust/illust-kyc-selfie.png";

import { useSubmitManualVerification } from "./service";
import { manualKycValidationSchema } from "./ManualKyc.schema";

const ManualKyc = () => {
  const manualKycEnabled = isFeatureEnabled("MANUAL");
  const { replace } = useHistory();

  const { data: userData } = useUserDataSingleUse({
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });

  const { mutate: mutateSubmittedManualVerification, isLoading: isSubmitting } =
    useSubmitManualVerification();

  useEffect(() => {
    if (userData) {
      if (userData?.kycStatus === PENDING_VERIFICATION) {
        replace("/kyc");
      } else if (userData?.isKycApproved) {
        replace("/");
      }
    }
  }, [replace, userData]);

  const confirmHandler = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });
    mutateSubmittedManualVerification(formData, {
      onSuccess: () => {
        setTimeout(() => {
          replace("/kyc");
        }, 500);
      },
    });
  };

  if (!manualKycEnabled) {
    return <Redirect to="/kyc" />;
  }

  if (!userData) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{
        firstName: userData.firstName,
        lastName: userData.lastName,
        gender: userData.gender,
        dateOfBirth: userData.dateOfBirth,
        citizenship: userData.citizenship,
        addressLine1: userData.addressLine1,
        addressLine2: userData.addressLine2,
        addressCountry: userData.countryCode,
        addressCity: "",
        addressState: "",
        postalCode: userData.postalCode,
        kycDocumentNumber: userData.kycDocumentNumber,
        kycDocumentType: "Passport",
        frontSideImage: null,
        backSideImage: null,
        faceImage: null,
        billImage: null,
      }}
      validationSchema={manualKycValidationSchema}
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={confirmHandler}
    >
      {({ setFieldValue, handleChange, values, isValid, submitForm }) => (
        <Container className="py-5">
          <Row className="justify-content-center gap-4 gap-md-0">
            <Col xs={12} md={6}>
              <h3 className="text-center">
                <em>
                  <strong>Manual </strong>
                </em>
                Verification
              </h3>
              <p className="text-center mb-8">
                To proceed further, we kindly request you to prepare the form of identification
                (E.G. NRIC, passport, driver's license) documents and a bill sent to your
                residential address for proof of address for verification.
              </p>
              <Card>
                <Card.Body className="pb-4">
                  <Card.Text>
                    <Row className="gy-3">
                      <Col xs={12} className="form-group mb-0">
                        <h5 className="mb-0">Personal Information</h5>
                        <small className="text-muted">
                          Please update your personal information in the fields below.
                        </small>
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="First Name"
                          name="firstName"
                          placeholder="Enter your first name"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="Last Name"
                          name="lastName"
                          placeholder="Enter your last name"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="Date of Birth"
                          name="dateOfBirth"
                          type="date"
                          placeholder="Enter your date of birth"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikDropdown
                          label="Gender"
                          name="gender"
                          lists={[
                            { name: "Male", id: "M" },
                            { name: "Female", id: "F" },
                          ]}
                          placeholder="Please select gender"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="ID Number"
                          name="kycDocumentNumber"
                          placeholder="Enter your ID number"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikDropdown
                          label="Citizenship"
                          name="citizenship"
                          lists={[
                            { name: "Indonesia", id: "ID" },
                            { name: "Singapore", id: "SG" },
                          ]}
                          placeholder="Please select citizenship"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col xs={12} className="form-group mt-4 mb-0">
                        <h5 className="mb-0">Proof of Identity</h5>
                        <small className="text-muted">
                          Please select your identity and upload your supporting documents below.
                        </small>
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikDropdown
                          label="Identity Document Type"
                          name="kycDocumentType"
                          lists={[
                            { name: "Singapore Pink IC", id: "Singapore Pink IC" },
                            { name: "Singapore Blue IC", id: "Singapore Blue IC" },
                            { name: "Work Pass", id: "Work Pass" },
                            { name: "Passport", id: "Passport" },
                          ]}
                          placeholder="Please select type"
                          className="mb-0"
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "Passport") {
                              setFieldValue("backSideImage", null);
                            }
                          }}
                          isRequired
                        />
                      </Col>
                      <Col xs={12}>
                        <FormikUpload
                          label="Front of ID"
                          name="frontSideImage"
                          description="Please upload your front of ID with clearly visible and complete image."
                          className="mb-3"
                          defaultPreviewImage={IllustKycFrontImage}
                          isRequired
                        />
                      </Col>
                      {values.kycDocumentType !== "Passport" && (
                        <Col xs={12}>
                          <FormikUpload
                            label="Back of ID"
                            name="backSideImage"
                            description="Please upload your back of ID with name and address clearly visible."
                            className="mb-3"
                            defaultPreviewImage={IllustKycBackImage}
                            isRequired
                          />
                        </Col>
                      )}
                      <Col xs={12}>
                        <FormikUpload
                          label="Selfie"
                          name="faceImage"
                          description="Please upload your selfie with face clearly visible."
                          className="mb-3"
                          defaultPreviewImage={IllustKycSelfie}
                          isRequired
                        />
                      </Col>
                      <Col xs={12} className="form-group mt-4 mb-0">
                        <h5 className="mb-0">Registered Address</h5>
                        <small className="text-muted">
                          Please update your address information in the fields below.
                        </small>
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="Address Line 1"
                          name="addressLine1"
                          placeholder="Enter your address line 1"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="Address Line 2"
                          name="addressLine2"
                          placeholder="Enter your address line 2"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikDropdown
                          label="Country"
                          name="addressCountry"
                          lists={[
                            { name: "Indonesia", id: "ID" },
                            { name: "Singapore", id: "SG" },
                          ]}
                          placeholder="Please select country"
                          className="mb-0"
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "SG") {
                              setFieldValue("addressCity", "");
                              setFieldValue("addressState", "");
                            }
                          }}
                          isRequired
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <FormikInput
                          label="Postal Code"
                          name="postalCode"
                          placeholder="Enter your postal code"
                          className="mb-0"
                          isRequired
                        />
                      </Col>
                      {values.addressCountry !== "SG" && (
                        <>
                          <Col md={6} xs={12}>
                            <FormikInput
                              label="City"
                              name="addressCity"
                              placeholder="Enter your city"
                              className="mb-0"
                              isRequired
                            />
                          </Col>
                          <Col md={6} xs={12}>
                            <FormikInput
                              label="State"
                              name="addressState"
                              placeholder="Enter your state"
                              className="mb-0"
                              isRequired
                            />
                          </Col>
                        </>
                      )}
                      <Col xs={12} className="form-group mt-4 mb-0">
                        <h5 className="mb-0">Proof of Address</h5>
                        <small className="text-muted">
                          Please upload your supporting documents below.
                        </small>
                      </Col>
                      <Col xs={12}>
                        <FormikUpload
                          label="Submit a photo of a bill sent to your residential address."
                          name="billImage"
                          description="Letter must be within 3 months. Address and name clearly visible."
                          defaultPreviewImage={IllustKycBill}
                          isRequired
                        />
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
              <div className="my-4 d-flex">
                <Button
                  variant="primary"
                  className="rounded-pill px-4 d-flex mx-auto"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Confirm Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
};

export default ManualKyc;
