import Profile from "views/public/profile";
import UpdateProfile from "views/public/profile/update";
import UploadProfile from "views/public/profile/upload";

export const profile = [
  {
    path: "/profile/:id",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile/:id/edit",
    exact: true,
    component: UpdateProfile,
  },
  {
    path: "/upload-profile/:id",
    exact: true,
    component: UploadProfile,
  },
];
