import { useFormikContext } from "formik";

import { LyteButton } from "components/common/buttons";
import { useContents } from "hooks/contents";
import { allCountries } from "utils/commonHelpers";
import FormikDropdown from "components/form/FormikDropdown";
import FormikInput from "components/form/FormikInput";

const BankDetailsForm = ({ setStepUpdateBank }) => {
  const { errors } = useFormikContext();

  const { data } = useContents();

  const isDisabled =
    "BankLocation" in errors ||
    "BankName" in errors ||
    "AccountCurrency" in errors ||
    "AccountHolderName" in errors ||
    "AccountNumber" in errors;

  const handleSaveDetails = () => {
    setStepUpdateBank(3);
  };

  return (
    <>
      <h2 className="font-weight-bold">Bank Details</h2>
      <p className="mt-2">You will receive all LytePay payments directly into your bank account.</p>
      <p>
        We kindly request that you email <a href="mailto:support@lytepay.co">support@lytepay.co</a>{" "}
        for further assistance in the event that you are unable to locate your bank in the system
      </p>
      <form className="bg-white px-5 py-4">
        <FormikDropdown
          label="Bank Location"
          name="CountryCode"
          lists={allCountries.map((country) => ({
            name: country.name,
            id: country.iso2.toUpperCase(),
          }))}
          disabled
        />
        <FormikDropdown
          label="Account Currency"
          name="Currency"
          lists={[{ name: "SGD", id: "SGD" }]}
          disabled
        />
        <FormikDropdown
          label="Bank Name"
          name="BankId"
          lists={data?.banks
            .map((bank) => ({ name: bank.bankName, id: bank.id }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
        <FormikInput label="Account Holder Name" name="AccountHolderName" />
        <FormikInput label="Account Number" name="AccountNumber" />
        <LyteButton className="mx-auto" size="sm" disabled={isDisabled} onClick={handleSaveDetails}>
          Save Bank Details
        </LyteButton>
        <LyteButton
          className="mx-auto mt-2"
          variant="ghost"
          size="sm"
          onClick={() => setStepUpdateBank(1)}
        >
          cancel
        </LyteButton>
      </form>
    </>
  );
};

export default BankDetailsForm;
