/* eslint-disable react/no-array-index-key */
import { Card, Col, Row } from "react-bootstrap";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { only } from "styled-breakpoints";

import { CarouselContainer } from "./styled";

const MultiCarousel = ({ items = [] }) => {
  const isSm = useBreakpoint(only("sm"));

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: items.length > 2 ? 3 : items.length,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: items.length > 1 ? 2 : items.length,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1140, min: 576 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <CarouselContainer className="mb-5 pb-3" responsive={responsive} arrows={false} showDots>
      {items.map((item, index) => (
        <Card key={`${item.title}-${index}`}>
          <Card.Body>
            <Row className="align-items-center gx-5">
              <Col>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p className="fw-bolder mb-0">{item?.title}</p>
                  <div className="star-container">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="mb-0">{item?.description}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </CarouselContainer>
  );
};

export default MultiCarousel;
