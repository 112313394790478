/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Field, useFormikContext } from "formik";
import { Form } from "react-bootstrap";

const FieldCheck = ({ id, name, label, ...rest }) => {
  const { values } = useFormikContext();
  return (
    <Field as={Form.Check} checked={values[name]} id={id} name={name} label={label} {...rest} />
  );
};

export default FieldCheck;
