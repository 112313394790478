import * as yup from "yup";

const FreelancerInfoCollectionSchema = yup.object().shape({
  roles: yup
    .array()
    .min(1, "You must select at least one role")
    .required("You must select at least one role"),
  invoicesSentPerMonth: yup
    .string()
    .oneOf(["0-5", "6-10", "11-25", "25+"])
    .required("You must select how many invoices do you send every month"),
  freelancerStatus: yup
    .string()
    .oneOf(["full-time", "part-time", "still-exploring"])
    .required("You must select freelancer status"),
});

export { FreelancerInfoCollectionSchema };
