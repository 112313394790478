import { useGetCashoutSummary } from "hooks/cashout";
import TableBase from "ui-lib/components/table-base";

import { columns } from "./cashout/CashoutSummary.columns";

const CashoutSummary = () => {
  const { data } = useGetCashoutSummary();
  return (
    <div className="container">
      <div className="pt-4 p-md-5">
        <h3>Cashout Summary</h3>
        <TableBase data={data} columns={columns} pagination />
      </div>
    </div>
  );
};

export default CashoutSummary;
