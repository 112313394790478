import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useGetProfile = (id, extraParams) => {
  const { Public } = useApi();
  return useQuery(
    ["PublicProfile", id],
    async () => {
      const { data } = await Public({
        method: "get",
        url: `lyte-assistant/user-profiles/${id}`,
      });
      return data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...extraParams,
    }
  );
};

export const useGetProfilePicture = (id, extraParams) => {
  const { Public } = useApi();
  return useQuery(
    ["PublicProfilePicture", id],
    async () => {
      const { data } = await Public({
        method: "get",
        url: `lyte-assistant/user-profiles/profile-image/${id}`,
        responseType: "blob",
      });
      return data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...extraParams,
    }
  );
};

export const useSaveProfile = (extraParams) => {
  const { Public } = useApi();
  return useMutation(
    async (payload) => {
      const promise = await Public({
        method: "post",
        url: `lyte-assistant/user-profiles`,
        data: payload,
      });
      toast.promise(promise, {
        loading: "Saving profile...",
        success: "Successfully saved profile",
        error: ({ message }) => `Error when saving profile`,
      });
      const { data } = await promise;
      return data;
    },
    {
      ...extraParams,
    }
  );
};

export const useGetOpenGraphData = (extraParams) => {
  const { Public } = useApi();
  return useMutation(
    async (payload) => {
      const { data } = await Public({
        method: "post",
        url: `public/open-graph`,
        data: payload,
      });
      return data;
    },
    {
      ...extraParams,
    }
  );
};
