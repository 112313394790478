import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { useTheme } from "styled-components";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";

import { LyteButton } from "components/common/buttons";

import { useSearchAndFilter } from "./TableBase.hooks";
import { CUSTOM_STYLES } from "./constants";

const TableBase = ({
  data,
  pagination,
  columns,
  paginationComponentOptions,
  search,
  customStyles,
  responsive,
  onRowClicked,
  className,
  paginationServer,
  onChangeRowsPerPage,
  onChangePage,
  paginationTotalRows,
  paginationRowsPerPageOptions,
}) => {
  const { searchComponent, filterComponent, onClearFilters, results } = useSearchAndFilter({
    data,
    searchColumns: search?.searchColumns,
    placeholder: search?.placeholder,
    filterOptions: search?.filterOptions,
    filterColumns: search?.filterColumns,
  });
  const theme = useTheme();

  return (
    <>
      {!!search && (
        <Formik>
          <Row className="no-gutters gap-2 mb-3">
            <Col xs={12} md={4}>
              {search.searchComponent || searchComponent}
            </Col>
            {search.filterOptions && filterComponent && (
              <Col xs={12} md={3}>
                {filterComponent}
              </Col>
            )}
            <Col xs={12} md={2} className="d-flex align-items-center ml-3">
              <LyteButton
                id="table-base-clear-filter"
                variant="ghost"
                size="sm"
                onClick={onClearFilters}
              >
                Clear
              </LyteButton>
            </Col>
          </Row>
        </Formik>
      )}
      <DataTable
        customStyles={customStyles || CUSTOM_STYLES(theme)}
        columns={columns}
        data={results}
        pagination={pagination}
        paginationComponentOptions={paginationComponentOptions}
        onRowClicked={onRowClicked}
        responsive={responsive}
        className={className}
        paginationServer={paginationServer}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        paginationTotalRows={paginationTotalRows}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      />
    </>
  );
};

TableBase.defaultProps = {
  data: [],
  pagination: false,
  customStyles: undefined,
  paginationComponentOptions: {},
  search: null,
  responsive: true,
  onRowClicked: () => {},
  className: "",
  paginationServer: false,
  onChangeRowsPerPage: () => {},
  onChangePage: () => {},
  paginationTotalRows: 0,
  paginationRowsPerPageOptions: [5, 10, 25, 30, 50, 100],
};

TableBase.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginationComponentOptions: PropTypes.shape({}),
  search: PropTypes.shape({
    searchColumns: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({})),
    filterColumns: PropTypes.arrayOf(PropTypes.string),
    searchComponent: PropTypes.node,
  }),
  customStyles: PropTypes.shape({}),
  responsive: PropTypes.bool,
  onRowClicked: PropTypes.func,
  className: PropTypes.string,
  paginationServer: PropTypes.bool,
  onChangeRowsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  paginationTotalRows: PropTypes.number,
  paginationRowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

export default TableBase;
