/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Card, Col, Container, Form, Nav, Navbar, Row, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Formik, Field, ErrorMessage, FieldArray, useFormikContext } from "formik";

import Loading from "ui-lib/components/loading/Loading";

import Otp from "../component/otp";
import {
  useGetProfile,
  useGetProfilePicture,
  useSaveProfile,
  useGetOpenGraphData,
} from "../profile.service";
import ProfilePreview from "../component/preview";

import { UpdateProfileSchema } from "./schema";
import { PreviewContainer, UpdateContainer } from "./styled";
import {
  EducationFormFields,
  ExperiencesFormFields,
  FormFields,
  TestimonialsFormFields,
  ServicesFormFields,
  ListingsFormFields,
} from "./formFields";

const ToggleSwitch = ({ form, activeLabel, field }) => {
  const { setFieldValue } = form;
  const { name, value } = field;
  const handleChange = (newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <Form.Check
      type="switch"
      id={name}
      label={activeLabel}
      checked={value}
      onChange={() => handleChange(!value)}
    />
  );
};

const UpdateProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [cookies] = useCookies([]);
  const [visibility, setVisibility] = useState({
    educations: true,
    experiences: true,
    page: true,
    testimonials: true,
    transactions: true,
  });
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [updatedData, setUpdateData] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const { data: dataProfile } = useGetProfile(id, {
    onSuccess: (response) => {
      setVisibility({ ...response?.visibility });
    },
  });
  const { data: dataProfilePicture } = useGetProfilePicture(id);
  const { mutate: mutateSaveProfile, isLoading: isLoadingSaveProfile } = useSaveProfile();
  const { mutate: mutateGetOpenGraphData, isLoading: isLoadingOpenGraph } = useGetOpenGraphData();

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeUnload = (event) => {
      if (cookies?.editProfileAuth && !updatedData) {
        event.preventDefault();
        event.returnValue = "";
        return "Changes you made may not be saved. Are you sure you want to refresh?";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onHandleUpdateProfile = (values, preview = false) => {
    const submitValue = {
      identifier: id,
      visibility: { ...visibility },
      profileImageUrl: dataProfile?.profileImageUrl,
      ...values,
      educations: values.educations?.map((edu) => ({
        ...edu,
        startDate: dayjs(edu.startDate).toISOString(),
        endDate: dayjs(edu.endDate).toISOString(),
      })),
      experiences: values.experiences?.map((exp) => ({
        ...exp,
        startDate: dayjs(exp.startDate).toISOString(),
        endDate: dayjs(exp.endDate).toISOString(),
      })),
      testimonials: values.testimonials?.map((testi) => ({
        ...testi,
        date: dayjs(testi.date).toISOString(),
      })),
      subjectTaught: dataProfile?.subjectTaught,
      agencyName: dataProfile?.agencyName,
      agencyCode: dataProfile?.agencyCode,
      registrationNumber: dataProfile?.registrationNumber,
      transactions: dataProfile?.transactions,
      rebate: dataProfile?.rebate,
      rebateDuration: dataProfile?.rebateDuration,
      createdDateTime: dataProfile?.createdDateTime,
      updatedDateTime: dayjs(new Date()).toISOString(),
    };

    if (preview) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setPreviewData(submitValue);
      return setPreview(true);
    }
    mutateSaveProfile(submitValue, {
      onSuccess: () => {
        setUpdateData(true);
        setShowToast(true);
      },
    });
    return submitValue;
  };

  const initialValues = {
    mobileNumber: dataProfile?.mobileNumber,
    name: dataProfile?.name,
    emailAddress: dataProfile?.emailAddress,
    description: dataProfile?.description,
    jobTitle: dataProfile?.jobTitle,
    educations:
      dataProfile?.educations?.map((edu) => ({
        name: edu?.name,
        certification: edu?.certification,
        fieldOfStudy: edu?.fieldOfStudy,
        level: edu?.level,
        description: edu?.description,
        startDate: dayjs(edu?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(edu?.endDate).format("YYYY-MM-DD"),
      })) || [],
    experiences:
      dataProfile?.experiences?.map((exp) => ({
        jobTitle: exp?.jobTitle,
        companyName: exp?.companyName,
        description: exp?.description,
        current: exp?.current,
        startDate: dayjs(exp?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(exp?.endDate).format("YYYY-MM-DD"),
      })) || [],
    testimonials:
      dataProfile?.testimonials?.map((testi) => ({
        name: testi?.name,
        message: testi?.message,
        location: testi?.location,
        date: dayjs(testi?.date).format("YYYY-MM-DD"),
      })) || [],
    services:
      dataProfile?.services?.map((srv) => ({
        title: srv.title,
        description: srv.description,
      })) || [],
    listings:
      dataProfile?.listings?.map((lst) => ({
        title: lst.title,
        description: lst.description,
        image: lst.image,
        url: lst.url,
        forSale: lst.forSale,
      })) || [],
  };

  const handleListingUrlBlur = (event, index, values) => {
    const submitValue = {
      url: event.target.value,
    };

    mutateGetOpenGraphData(submitValue, {
      onSuccess: (data) => {
        values.listings[index].title = data.title;
        values.listings[index].description = data.description;
        values.listings[index].image = data.image;
      },
    });
  };

  const handleFieldGeneration = (field, index, parent, values) => {
    const name = parent ? `${parent}.${index}.${field.key}` : field.key;
    switch (field.type) {
      case "toggle": {
        return (
          <Field
            name={name}
            render={(props) => (
              <ToggleSwitch
                {...props}
                index={index}
                parent={parent}
                activeLabel={field.activeLabel}
              />
            )}
          />
        );
      }
      case "dropdown":
        return (
          <div className="dropdown-container">
            <Field
              name={name}
              id={name}
              as="select"
              className="form-control"
              disabled={field.disabled}
            >
              <option value="">Select an option</option>
              {field?.children?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.label}
                </option>
              ))}
            </Field>
            <FontAwesomeIcon className="icon" icon={faChevronDown} />
          </div>
        );
      case "listingUrl":
        return (
          <Field
            type={field.type}
            id={name}
            name={name}
            as="input"
            className="form-control"
            disabled={isLoadingOpenGraph}
            onBlur={(event) => handleListingUrlBlur(event, index, values)}
          />
        );
      default:
        return (
          <Field
            type={field.type}
            id={name}
            name={name}
            as={field.type === "textarea" ? "textarea" : "input"}
            className="form-control"
            disabled={
              name === `${parent}.${index}.endDate`
                ? values?.[parent]?.[index]?.current
                : field.disabled
            }
          />
        );
    }
  };

  const handleColGeneration = (field, index, parent = null, values = {}) => {
    const name = parent ? `${parent}.${index}.${field.key}` : field.key;
    return (
      <Col key={name} className="pb-4" md={parent ? 12 : 6}>
        <label htmlFor={name}>{field.label}</label>
        {handleFieldGeneration(field, index, parent, values)}
        <ErrorMessage name={name} component="div" className="text-danger" />
      </Col>
    );
  };

  const handleChangeVisibility = (title) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [title]: !prevVisibility[title],
    }));
  };

  return (
    <>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{ position: "fixed", right: 0 }}
      >
        <Toast.Header>Success</Toast.Header>
        <Toast.Body>You have successfully update your profile</Toast.Body>
      </Toast>
      {cookies?.editProfileAuth ? (
        !preview ? (
          <UpdateContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={UpdateProfileSchema}
              enableReinitialize
            >
              {({ values, isValid }) => (
                <form>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>Account Settings</h4>
                    </Col>
                    {FormFields.map((field) => handleColGeneration(field))}
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>Services</h4>
                    </Col>
                    <FieldArray name="services">
                      {({ push, remove }) => (
                        <>
                          {values?.services?.map((_, index) => (
                            <Col className="mb-3 border-bottom border-primary" md={6}>
                              <FontAwesomeIcon
                                className="trash-icon"
                                icon={faTrash}
                                onClick={() => remove(index)}
                              />
                              <Row>
                                {ServicesFormFields.map((field) =>
                                  handleColGeneration(field, index, "services")
                                )}
                              </Row>
                            </Col>
                          ))}
                          <Col>
                            <Button
                              className="w-100 my-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  title: "",
                                  description: "",
                                })
                              }
                            >
                              Add Service
                            </Button>
                          </Col>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>Educations</h4>
                      <Form.Check
                        type="switch"
                        id="visibility-education"
                        label="Toggle visibility of the content"
                        checked={visibility.educations}
                        onChange={() => handleChangeVisibility("educations")}
                      />
                    </Col>
                    <FieldArray name="educations">
                      {({ push, remove }) => (
                        <>
                          {values?.educations?.map((_, index) => (
                            <Col className="mb-3 border-bottom border-primary" md={6}>
                              {values.educations.length > 1 && (
                                <FontAwesomeIcon
                                  className="trash-icon"
                                  icon={faTrash}
                                  onClick={() => remove(index)}
                                />
                              )}
                              <Row>
                                {EducationFormFields.map((field) =>
                                  handleColGeneration(field, index, "educations")
                                )}
                              </Row>
                            </Col>
                          ))}
                          <Col>
                            <Button
                              className="w-100 my-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  name: "",
                                  certification: "",
                                  fieldOfStudy: "",
                                  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  endDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  level: "",
                                  description: "",
                                })
                              }
                            >
                              Add Education
                            </Button>
                          </Col>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>experiences</h4>
                      <Form.Check
                        type="switch"
                        id="visibility-experiences"
                        label="Toggle visibility of the content"
                        checked={visibility.experiences}
                        onChange={() => handleChangeVisibility("experiences")}
                      />
                    </Col>
                    <FieldArray name="experiences">
                      {({ push, remove }) => (
                        <>
                          {values?.experiences?.map((_, index) => (
                            <Col className="mb-3 border-bottom border-primary" md={6}>
                              {values.experiences.length > 1 && (
                                <FontAwesomeIcon
                                  className="trash-icon"
                                  icon={faTrash}
                                  onClick={() => remove(index)}
                                />
                              )}
                              <Row>
                                {ExperiencesFormFields.map((field) =>
                                  handleColGeneration(field, index, "experiences", values)
                                )}
                              </Row>
                            </Col>
                          ))}
                          <Col>
                            <Button
                              className="w-100 my-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  jobTitle: "",
                                  certification: "",
                                  companyName: "",
                                  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  endDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  current: false,
                                  description: "",
                                })
                              }
                            >
                              Add Experience
                            </Button>
                          </Col>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>testimonials</h4>
                      <Form.Check
                        type="switch"
                        id="visibility-testimonials"
                        label="Toggle visibility of the content"
                        checked={visibility.testimonials}
                        onChange={() => handleChangeVisibility("testimonials")}
                      />
                    </Col>
                    <FieldArray name="testimonials">
                      {({ push, remove }) => (
                        <>
                          {values?.testimonials?.map((_, index) => (
                            <Col className="mb-3 border-bottom border-primary" md={6}>
                              {values.testimonials.length > 1 && (
                                <FontAwesomeIcon
                                  className="trash-icon"
                                  icon={faTrash}
                                  onClick={() => remove(index)}
                                />
                              )}
                              <Row>
                                {TestimonialsFormFields.map((field) =>
                                  handleColGeneration(field, index, "testimonials", values)
                                )}
                              </Row>
                            </Col>
                          ))}
                          <Col>
                            <Button
                              className="w-100 my-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  jobTitle: "",
                                  certification: "",
                                  companyName: "",
                                  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  endDate: dayjs(new Date()).format("YYYY-MM-DD"),
                                  current: false,
                                  description: "",
                                })
                              }
                            >
                              Add Testimonial
                            </Button>
                          </Col>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex align-items-center gap-2 header-title">
                      <h4>Listings</h4>
                    </Col>
                    <FieldArray name="listings">
                      {({ push, remove }) => (
                        <>
                          {values?.listings?.map((_, index) => (
                            <Col className="mb-3 border-bottom border-primary" md={6}>
                              <FontAwesomeIcon
                                className="trash-icon"
                                icon={faTrash}
                                onClick={() => remove(index)}
                              />
                              <Row>
                                {ListingsFormFields.map((field) =>
                                  handleColGeneration(field, index, "listings", values)
                                )}
                              </Row>
                            </Col>
                          ))}
                          <Col>
                            <Button
                              className="w-100 my-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  title: "",
                                  description: "",
                                  image: "",
                                  url: "",
                                  forSale: true,
                                })
                              }
                            >
                              Add Listing
                            </Button>
                          </Col>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <Container className="container-footer-update">
                    <Row className="row-footer-update">
                      <Col md={{ span: 3, offset: 3 }}>
                        <Button
                          variant="tertiary"
                          className="w-100"
                          onClick={() => {
                            onHandleUpdateProfile(values, true);
                          }}
                          disabled={isLoadingSaveProfile}
                        >
                          Preview
                        </Button>
                      </Col>
                      <Col md={3}>
                        <Button
                          className="w-100"
                          disabled={!isValid || isLoadingSaveProfile}
                          onClick={() => {
                            onHandleUpdateProfile(values);
                          }}
                        >
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </form>
              )}
            </Formik>
            {isLoadingSaveProfile && (
              <Loading
                style={{
                  backdropFilter: "blur(2px)",
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 100000,
                }}
              />
            )}
          </UpdateContainer>
        ) : (
          <PreviewContainer>
            <ProfilePreview data={previewData} imageData={dataProfilePicture} />
            <Container className="container-footer-update">
              <Row className="row-footer-update d-flex w-100 justify-content-center">
                <Col md={3}>
                  <Button
                    variant="tertiary"
                    className="w-100"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setPreview(false);
                    }}
                  >
                    Back to editing
                  </Button>
                </Col>
              </Row>
            </Container>
          </PreviewContainer>
        )
      ) : (
        <Otp phoneNumber={dataProfile?.mobileNumber} />
      )}
    </>
  );
};

export default UpdateProfile;
