import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";

import { StyledField, StyledLabel, StyledPasswordIcon } from "./styles";

const FormikInput = ({
  label,
  type,
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  description,
  ...restProps
}) => {
  const [field, meta] = useField(name);
  const [hide, setHide] = useState(true);

  const inputType = type === "password" ? (hide ? "password" : "input") : type;

  return (
    <StyledField className={`field-container ${className}`}>
      {label && <StyledLabel htmlFor={name}>{`${label} ${isRequired ? "*" : ""}`}</StyledLabel>}
      <Field
        type={inputType}
        id={name}
        name={name}
        className="form-control"
        disabled={disabled}
        placeholder={placeholder}
        {...restProps}
      />
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && <ErrorMessage name={name} component="div" className="error-text" />}
      {type === "password" && (
        <StyledPasswordIcon onClick={() => setHide(!hide)}>
          {hide ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
        </StyledPasswordIcon>
      )}
    </StyledField>
  );
};

FormikInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikInput.defaultProps = {
  label: "",
  className: "",
  type: "input",
  placeholder: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
};

export default FormikInput;
