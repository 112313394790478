import styled from "styled-components";
import PropTypes from "prop-types";

const Badge = styled.div`
  font-size: 1.25rem;
  border-radius: 25px;
  color: #002682;
  border: 2px solid #002682;
  background-color: #ffffff;
  width: fit-content;
  min-width: 150px;
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-align: center;
  ${({ upperCase }) =>
    upperCase &&
    `
  text-transform: uppercase;
  `}
`;

const StatusBadge = ({ title, upperCase, className }) => (
  <Badge upperCase={upperCase} className={className}>
    {title?.replace("_", " ").replace("-", " ")}
  </Badge>
);

export default StatusBadge;

StatusBadge.defaultProps = {
  upperCase: false,
  className: "",
};

StatusBadge.propTypes = {
  upperCase: PropTypes.bool,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
