import { useState } from "react";
import { Card, Row, Col, Button, Image, Modal as RBModal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import PropTypes from "prop-types";

import mrLyte from "images/illust/illust-flyman.png";
import lyteWink from "images/icons/lyte-wink-blue.gif";

import TnCContent from "./TnC.constant";

const Modal = styled(RBModal)`
  @media only screen and (min-width: 768px) {
    .modal-dialog {
      width: 700px;
      max-width: none;
    }
  }
`;
const ModalBody = styled(RBModal.Body)`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const ReferralButton = styled.span`
  border: 1px solid ${(props) => props.theme.color.borderBlue};
`;

const ReferralCard = ({ user }) => {
  const clipboardText = `Join me on LytePay. I’ve been using it to keep track of invoices, payments and even get rewarded up to 6% on each invoice! Click this link: ${process.env.REACT_APP_ORIGIN_URI}/?referral=${user.referralCode} to sign up now.`;

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const ModalTnC = (
    <Modal show={showModal} onHide={handleClose} centered backdrop="static">
      <Modal.Header className="border-bottom-0 pb-0" closeButton>
        <Modal.Title>
          Terms and Conditions for LytePay for Freelancers Referral Promotion
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        <TnCContent />
      </ModalBody>
      <Modal.Footer className="d-flex justify-content-between border-top-0 w-100">
        <Button variant="primary" className="px-5 py-2 w-100" onClick={handleClose}>
          I Understand
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Card className="py-2 px-4" style={{ borderRadius: "10px", position: "relative" }}>
      <Row>
        <Col sm={7} className="p-3">
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <h4 className="m-0">Refer and Earn</h4>
          </div>
          <div>
            <p>
              Get $10 for every friend that signs up and uses LytePay to collect their first invoice
              with your referral code.
            </p>
            <div id="dashboard-referral-utilities">
              <ReferralButton className="p-3 px-4 rounded-left align-middle">
                <strong>{user.referralCode}</strong>
              </ReferralButton>
              <Button
                className="global-button py-3 share-button"
                style={{
                  borderRadius: "0",
                  border: "1px solid #3363ff",
                }}
                onClick={async () => {
                  if ("clipboard" in navigator) {
                    await navigator.clipboard.writeText(clipboardText);
                    toast("Copied!", {
                      icon: () => <Image height="20px" className="d-block" src={lyteWink} />,
                    });
                  } else {
                    document.execCommand("copy", true, clipboardText);
                  }
                }}
              >
                <strong>
                  <FontAwesomeIcon icon={faCopy} />
                </strong>
              </Button>
              <Button
                className="global-button py-3 share-button"
                style={{
                  borderRadius: "0 5px 5px 0",
                  border: "1px solid #00c342",
                  backgroundColor: "#00c342",
                }}
                onClick={async () => {
                  window.open(`https://wa.me/?text=${clipboardText}`, `_blank`);
                }}
              >
                <strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />{" "}
                  </svg>
                </strong>
              </Button>
            </div>

            <p className="mt-2 mb-0">
              For more information please look at our
              <Button className="button-link pl-1" onClick={() => setShowModal(true)}>
                Terms & Conditions
              </Button>
              .
            </p>
            <ToastContainer />
          </div>
        </Col>
        <Col sm={5} className="d-none d-sm-block m-auto">
          <img src={mrLyte} alt="" className="w-100 img-fluid" />
        </Col>
      </Row>
      {ModalTnC}
    </Card>
  );
};

ReferralCard.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default ReferralCard;
