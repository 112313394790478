import styled from "styled-components";

export const FloatingHelpContainer = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
`;

FloatingHelpContainer.Trigger = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.borderPrimary};
  background: ${(props) => props.theme.color.backgroundPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.borderPrimary};
`;

FloatingHelpContainer.HiddenBox = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 16px;
  background: ${(props) => props.theme.color.backgroundPrimary};
  display: none;
  box-shadow: 0px 4px 32px ${(props) => props.theme.color.backgroundSecondary};
  transform: translateY(100%);
  transition: all 0.3s;

  ${(props) =>
    props.isVisible &&
    `
    display: block;
    opacity: 1;
    transform: translateY(calc(-48px - 24px));
  `}
`;
FloatingHelpContainer.HiddenBox.IconContainer = styled.div`
  background: ${(props) => props.theme.color.backgroundPrimary};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  color: ${(props) => props.theme.color.invoiceBorderLine};
  p {
    margin: 0;
    text-wrap: nowrap;
    color: ${(props) => props.theme.color.textSecondary} !important;
  }
  &:hover {
    background: ${(props) => props.theme.color.backgroundSecondary};
    color: ${(props) => props.theme.color.textPrimary};
  }
`;
