import { useContext } from "react";
import { AccordionContext, Container, NavLink, useAccordionToggle } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Icon, StyledAccordion, StyledCard, Styledli, Styledul } from "./styled";
import BoostFaq from "./BoostFaq";
import InvoiceFaq from "./InvoiceFaq";
import NewBoostFaq from "./NewBoostFaq";

const ContextAwareToggle = ({ eventKey, callback, item }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <StyledAccordion.Toggle
      className={`d-flex align-items-center justify-content-between ${
        isCurrentEventKey && "active"
      }`}
      type="button"
      onClick={decoratedOnClick}
    >
      {item.header}
      <StyledAccordion.IconContainer>
        <Icon icon={faPlus} className="icon" />
      </StyledAccordion.IconContainer>
    </StyledAccordion.Toggle>
  );
};

const Faq = () => {
  const url = window.location.pathname;
  return (
    <Container className="py-4">
      <div>
        <h1 className="pt-4 mb-2">FAQ</h1>
        <Styledul>
          <Styledli>
            <NavLink href={`${url}#boost`}>Boost</NavLink>
          </Styledli>
          <Styledli>
            <NavLink href={`${url}#invoice`}>Invoicing</NavLink>
          </Styledli>
        </Styledul>
      </div>
      <div id="boost" className="mb-3">
        <h1 className="pt-4 mb-4">Boost</h1>
        <StyledAccordion>
          {BoostFaq.map((item, index) => {
            const eventKey = `boost-${+index + 0}`;
            return (
              <StyledCard key={eventKey}>
                <StyledCard.Header>
                  <ContextAwareToggle eventKey={eventKey} item={item} />
                </StyledCard.Header>
                <StyledAccordion.Collapse eventKey={eventKey}>
                  <StyledCard.Body>{item.description}</StyledCard.Body>
                </StyledAccordion.Collapse>
              </StyledCard>
            );
          })}
        </StyledAccordion>
      </div>
      <div id="invoice">
        <h1 className="pt-4 my-4">Invoicing</h1>
        <StyledAccordion>
          {InvoiceFaq.map((item, index) => {
            const eventKey = `invoice-${+index + 0}`;
            return (
              <StyledCard key={eventKey}>
                <StyledCard.Header>
                  <ContextAwareToggle eventKey={eventKey} item={item} />
                </StyledCard.Header>
                <StyledAccordion.Collapse eventKey={eventKey}>
                  <StyledCard.Body>{item.description}</StyledCard.Body>
                </StyledAccordion.Collapse>
              </StyledCard>
            );
          })}
        </StyledAccordion>
      </div>
      <div className="my-5 text-center">
        If you have any other questions on the Boost rates updates, please contact{" "}
        <a href="mailto:support@lytepay.co">support@lytepay.co</a> or directly to Winston at{" "}
        <a href="mailto:winston.henvio@lyte.co">winston.henvio@lyte.co</a> and we will get back to
        you as soon as possible
      </div>
    </Container>
  );
};

export default Faq;
