/* eslint-disable arrow-body-style */

import toast from "react-hot-toast";
import { Card, Container, Image } from "react-bootstrap";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { formatCurrency } from "utils/commonHelpers";
import { usePostAcceptInvoice } from "hooks/invoices";
import { LyteButton } from "components/common/buttons";

const QuickPayInvoiceSuccess = () => {
  const { state } = useLocation();

  const { push } = useHistory();
  const { mutate } = usePostAcceptInvoice({
    onSuccess: () => push(`/invoice/${state?.data?.contextObjects[0].invoiceReference}`),
  });

  if (!state) {
    return <Redirect to="/" />;
  }

  const amountDisplay = formatCurrency(state?.data?.contextObjects[0].amount);

  return (
    <Container className="p-md-5 ">
      <Card className="mt-5">
        <Card.Body>
          <div className="w-100 text-center mx-auto">
            <h2>
              <strong>QRPay QR Code</strong>
            </h2>
            <h3>Invoice No. {state?.data?.contextObjects[0].invoiceNumber}</h3>
            <div className="w-100">
              <Image src={process.env.REACT_APP_PAY_NOW_QR} alt="qr-code" />
            </div>
            <h5>
              <p className="text-danger">
                Indicate Reference: {state?.data?.contextObjects[0].invoiceReference}
                <FontAwesomeIcon
                  className="mx-2 btn p-1"
                  icon={faCopy}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(state?.data?.contextObjects[0].invoiceReference)
                      .then(() => toast.success("Copied!"))
                  }
                />
              </p>
            </h5>

            <h2 className="text-primary">{amountDisplay}</h2>
            <LyteButton
              className="my-5"
              onClick={() => mutate(state?.data?.contextObjects[0].invoiceReference)}
            >
              Mark as Accepted
            </LyteButton>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QuickPayInvoiceSuccess;
