import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";

import { StyledField, StyledLabel } from "./styles";

const FormikCheckbox = ({
  label,
  list,
  name,
  className,
  isRequired,
  description,
  ...restProps
}) => {
  const [field, meta] = useField(name);

  return (
    <StyledField className={`field-container ${className}`}>
      {label && <StyledLabel>{`${label} ${isRequired ? "*" : ""}`}</StyledLabel>}
      {list.map((item) => (
        <label
          htmlFor={`${item?.value}-${name}`}
          className={`checkbox-container ${meta.value.includes(item.value) ? "checked" : ""}`}
        >
          <Field
            type="checkbox"
            id={`${item?.value}-${name}`}
            name={name}
            value={item?.value}
            disabled={item?.disabled}
            className={`form-control ${item?.className}`}
            checked={meta.value.includes(item.value)}
            {...restProps}
          />
          <div className="checkbox-content-container">
            <div className="tick">
              <FontAwesomeIcon className="check-icon" icon={faCheck} />
            </div>
            <p className="checkbox-content-label">{item?.label}</p>
          </div>
        </label>
      ))}
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && <ErrorMessage name={name} component="div" className="error-text" />}
    </StyledField>
  );
};

FormikCheckbox.propTypes = {
  label: PropTypes.string,
  list: PropTypes.shape().isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikCheckbox.defaultProps = {
  label: "",
  className: "",
  description: "",
  isRequired: false,
};

export default FormikCheckbox;
