import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { LyteButton } from "components/common/buttons";
import { fixedNumber, formatCurrency } from "utils/commonHelpers";
import CardSummary from "components/common/card-summary";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";

const SmallText = styled.p`
  font-size: 13px;
  margin: 0 !important;
  font-weight: 600;
`;

const SmallTextGrey = styled.p`
  font-size: 13px;
  margin: 0 !important;
  color: ${({ theme }) => theme.color.invoiceBorderLine};
`;

const CardSummaryDivider = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.dividerPrimary};
  margin: 8px 0;
`;

const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.color.textDarkContrast};
  &:hover {
    color: ${(props) => props.theme.color.textLinkHover};
  }
`;

const AdvanceCard = ({ filteredAdvanceList }) => {
  const { push } = useHistory();

  const handleAdvanceCashout = (item) => {
    push(`advance/${item?.advanceReferenceNumber}`);
  };

  return (
    filteredAdvanceList?.length > 0 && (
      <Col className="py-4" md={12} lg={{ span: 6, offset: 3 }}>
        <h5>{`${filteredAdvanceList?.length || 0} Invoice(s) Available to Advance`}</h5>
        {filteredAdvanceList?.map((item) => (
          <CardSummary>
            <Row className="no-gutters">
              <Col xs={5} className="d-flex flex-column justify-content-center">
                <ButtonStyled
                  variant="btn btn-block text-left "
                  size="sm"
                  className="mb-2 p-0 font-weight-bold cursor-pointer border-none"
                  onClick={() => push(`invoice/${item?.invoiceReference}`)}
                >
                  {item?.invoiceNumber}
                </ButtonStyled>
                <SmallText>{item?.companyName}</SmallText>
              </Col>
              <Col xs={7}>
                <div className="d-flex flex-column align-items-end justify-content-center pl-2">
                  <p className="font-weight-bold mb-2">
                    {formatCurrency(item?.eligibleAdvanceAmount)}
                  </p>
                  <SmallTextGrey className="text-right">
                    {item?.advanceType === "sponsored"
                      ? `Fee Paid by Agency`
                      : `${fixedNumber(item?.advanceFeeRate * 100) || 0}%${
                          item?.advanceFeeAddOn
                            ? ` + ${formatCurrency(fixedNumber(item?.advanceFeeAddOn))}`
                            : ""
                        } Fee Applies`}
                  </SmallTextGrey>
                </div>
              </Col>
            </Row>

            <CardSummaryDivider />

            <Row className="no-gutters">
              <Col xs={5} className="d-flex flex-column gap-1 flex-wrap">
                <SmallTextGrey className="m-0">
                  Due: {dayjs(item?.advanceAvailableDate).add(7, "day").format("DD/MM/YYYY")}
                </SmallTextGrey>
                <div style={{ width: "fit-content" }}>
                  <InvoiceStatuses status={item?.advanceStatus} pill />
                </div>
              </Col>
              <Col xs={7} className="d-flex align-items-end justify-content-end gap-3 pl-2">
                <div>
                  <LyteButton size="sm" onClick={() => handleAdvanceCashout(item)}>
                    Advance
                  </LyteButton>
                </div>
              </Col>
            </Row>
          </CardSummary>
        ))}
      </Col>
    )
  );
};

export default AdvanceCard;
