import moment from "moment";
import PropTypes from "prop-types";

import { formatCurrency } from "utils/commonHelpers";

const CellBoostAmount = ({ boostAmount, boostStartingDate }) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">{formatCurrency(boostAmount)}</span>
    <span className="text-muted text-nowrap">
      {moment(new Date(boostStartingDate)).format("DD MMM YYYY")}
    </span>
  </div>
);

CellBoostAmount.propTypes = {
  boostAmount: PropTypes.number.isRequired,
  boostStartingDate: PropTypes.string.isRequired,
};

export default CellBoostAmount;
