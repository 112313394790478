import styled from "styled-components";

export const OtpContainer = styled.div`
  height: 100%;

  .container {
    height: inherit;
    .row {
      margin: auto;
      height: inherit;
    }
  }
`;

OtpContainer.Box = styled.div`
  width: 100%;
  border: 2px solid #3363ff;
  border-radius: 15px;
  padding: 1.5rem 2rem;
`;

OtpContainer.Input = styled.input`
  width: 100%;
  background-color: transparent;
  border: 2px solid transparent;
  border-bottom-color: #3363ff;
  font-size: 24px;
  text-align: center;
  outline: none !important;

  &:active,
  &:hover,
  &:visited,
  &:focus-visible {
    border: 2px solid transparent;
    border-bottom-color: #3363ff;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
