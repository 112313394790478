import * as yup from "yup";

export const UpdateProfileSchema = yup.object().shape({
  name: yup.string().required("This is a required field"),
  mobileNumber: yup.string().required("This is a required field"),
  emailAddress: yup.string().email("Invalid email address").required("This is a required field"),
  description: yup.string().required("This is a required field"),
  jobTitle: yup.string().required("This is a required field"),
  educations: yup.array().of(
    yup.object().shape({
      name: yup.string().required("This is a required field"),
      certification: yup.string().required("This is a required field"),
      fieldOfStudy: yup.string().required("This is a required field"),
      startDate: yup
        .date()
        .required("This is a required field")
        .test("startDate", "Start date must be before end date", function (value) {
          const { endDate } = this.parent;
          return endDate ? value <= endDate : true;
        }),
      endDate: yup
        .date()
        .required("This is a required field")
        .test("endDate", "End date must be after start date", function (value) {
          const { startDate } = this.parent;
          return startDate ? value >= startDate : true;
        }),
      level: yup.string().required("This is a required field"),
      description: yup.string().required("This is a required field"),
    })
  ),
  experiences: yup.array().of(
    yup.object().shape({
      jobTitle: yup.string().required("This is a required field"),
      companyName: yup.string().required("This is a required field"),
      startDate: yup
        .date()
        .required("This is a required field")
        .test("startDate", "Start date must be before end date", function (value) {
          const { endDate } = this.parent;
          return endDate ? value <= endDate : true;
        }),
      endDate: yup.date().when("current", {
        is: false,
        then: yup
          .date()
          .required("This is a required field")
          .test("endDate", "End date must be after start date", function (value) {
            const { startDate } = this.parent;
            return startDate ? value >= startDate : true;
          }),
        otherwise: yup.date().nullable(),
      }),
      current: yup.boolean(),
      description: yup.string().required("This is a required field"),
    })
  ),
  testimonials: yup.array().of(
    yup.object().shape({
      name: yup.string().required("This is a required field"),
      message: yup.string().required("This is a required field"),
      location: yup.string().required("This is a required field"),
      date: yup.date().required("This is a required field"),
    })
  ),
});
