/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import { Form } from "react-bootstrap";
import { ErrorMessage, Field, useFormikContext } from "formik";
import styled from "styled-components";

import ErrorMessageSmall from "./ErrorMessageSmall";

const FieldStyled = styled(Field)`
  &:disabled {
    color: ${(props) => props.theme.color.textDarkContrast};
  }
`;

const FieldInput = ({ id, label, type, name, isRequired, ...rest }) => {
  const { touched, errors } = useFormikContext();
  return (
    <>
      <Form.Label htmlFor={id}>
        {label}
        {isRequired && "*"}
      </Form.Label>
      <FieldStyled
        as={Form.Control}
        type={type || "text"}
        name={name}
        id={id}
        isValid={touched[name] && !errors[name]}
        isInvalid={touched[name] && errors[name]}
        {...rest}
      />
      <ErrorMessage name={name} component={ErrorMessageSmall} />
    </>
  );
};

export default FieldInput;
