import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { Badge, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

import lyteLogo from "images/svg/lytepay-logo.svg";
import cashoutIcon from "images/icons/icn-cashout.png";
import { useGetAdvanceList } from "hooks/advance";
import Color from "utils/colours";
import { useGlobalState } from "providers/GlobalProvider";
import DashboardMenuIcon from "images/svg/menu/dashboard-menu-icon.svg";
import AdvanceMenuIcon from "images/svg/menu/advance-menu-icon.svg";
import BoostMenuIcon from "images/svg/menu/boost-menu-icon.svg";
import InvoiceMenuIcon from "images/svg/menu/invoice-menu-icon.svg";
import CashoutMenuIcon from "images/svg/menu/cashout-menu-icon.svg";
import FaqMenuIcon from "images/svg/menu/faq-menu-icon.svg";
import LogoutMenuIcon from "images/svg/menu/logout-menu-icon.svg";
import { useUserDataState } from "providers/UserDataProvider";

const ToggleContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  p {
    margin: 0;
    transition: none;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    margin: 0;
  }

  /* Customize the toggle input */
  .toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* Customize the slider (circle) */
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.color.toggleBackground};
    border-radius: 20px;
    transition: 0.4s;
  }

  /* Style the circle when the toggle is ON */
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: ${(props) => props.theme.color.toggleBullet};
    border-radius: 50%;
    transition: 0.4s;
  }

  /* Apply the round shape to the slider */
  .round {
    border-radius: 20px;
  }

  /* Style the circle when the toggle is OFF */
  .toggle-input:checked + .toggle-slider {
    background-color: ${(props) => props.theme.color.buttonBackgroundPrimary};
  }

  /* Move the circle to the right when the toggle is ON */
  .toggle-input:checked + .toggle-slider:before {
    transform: translateX(20px);
  }
`;

const CustomSidebarContent = styled(SidebarContent)`
  .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    line-height: 40px;
    font-size: 14px;
  }
  .pro-menu {
    .pro-menu-item {
      &:first-of-type {
        border-bottom: 1px solid ${(props) => props.theme.color.borderDefault};
      }
      &.pro-sub-menu {
        .pro-menu-item {
          border: none;
        }
      }
    }
  }

  .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: transparent;
    .pro-inner-item {
      &::before {
        display: none !important;
      }
    }
    ul {
      padding: 0 !important;
    }
  }
`;

const CustomSidebarFooter = styled(SidebarFooter)`
  border-top: 1px solid ${(props) => props.theme.color.borderDefault} !important;
`;

const Navi = ({ hidden, setSelectedTheme }) => {
  /* ------------------------ */
  /*         HOOKS            */
  /* ------------------------ */

  const { logout, isAuthenticated } = useAuth0();
  const [isChecked, setIsChecked] = useState(localStorage.getItem("theme") === "dark");

  const { userData } = useUserDataState();

  const { selectedSubMenuId, setSelectedSubMenuId, toggleSidebar, setToggleSidebar } =
    useGlobalState();

  const { data: AdvanceListData } = useGetAdvanceList();
  const toggleSubmenu = (submenu) => {
    if (submenu === selectedSubMenuId) {
      setSelectedSubMenuId(null);
    } else {
      setSelectedSubMenuId(submenu);
    }
  };

  const handleMenuAdvance = () => {
    if (AdvanceListData?.length > 0) {
      return (
        <SubMenu
          open={selectedSubMenuId === "Advance"}
          onOpenChange={() => toggleSubmenu("Advance")}
          title={
            <div className="d-flex align-items-center gap-2 navi-each" style={{ fontWeight: 600 }}>
              <Image src={AdvanceMenuIcon} />
              Advance
            </div>
          }
          label="Advance"
        >
          <MenuItem onClick={() => setToggleSidebar(false)}>
            <Link to="/advancesummary" className="navi-each">
              Advances Summary
            </Link>
          </MenuItem>
        </SubMenu>
      );
    }
    return false;
  };

  const handleToggle = (event) => {
    setIsChecked(!isChecked);
    const choosenTheme = event.target.checked ? "dark" : "light";
    setSelectedTheme(choosenTheme);
    localStorage.setItem("theme", choosenTheme);
  };

  return (
    <ProSidebar
      toggled={toggleSidebar}
      breakPoint="md"
      onToggle={(value) => setToggleSidebar(!value)}
      hidden={hidden}
    >
      <CustomSidebarContent>
        <Menu iconShape="square">
          <Image src={lyteLogo} className="navi-logo py-3" alt="/" />
          {userData &&
            userData?.kycStatus &&
            (userData?.isKycApproved || !userData?.registrationCompletedAt) && (
              <>
                <MenuItem onClick={() => setToggleSidebar(false)} className="navi-each">
                  <Link
                    to="/"
                    className="navi-each d-flex align-items-center gap-2"
                    style={{ fontWeight: 600 }}
                  >
                    <Image src={DashboardMenuIcon} />
                    Home
                  </Link>
                </MenuItem>

                <SubMenu
                  id="menu-invoice"
                  open={selectedSubMenuId === "Invoice"}
                  onOpenChange={() => toggleSubmenu("Invoice")}
                  title={
                    <div
                      className="d-flex align-items-center gap-2 navi-each"
                      style={{ fontWeight: 600 }}
                    >
                      <Image src={InvoiceMenuIcon} />
                      Invoice
                    </div>
                  }
                  label="Invoice"
                >
                  <MenuItem onClick={() => setToggleSidebar(false)}>
                    <Link to="/invoice/create/1" className="navi-each">
                      Create Invoice
                    </Link>
                  </MenuItem>
                  <MenuItem id="menu-invoice-summary" onClick={() => setToggleSidebar(false)}>
                    <Link to="/invoice/listing" className="navi-each">
                      Invoice Summary
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => setToggleSidebar(false)}>
                    <Link to="/quickpay/create" className="navi-each">
                      QRPay
                    </Link>
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  open={selectedSubMenuId === "Boost"}
                  onOpenChange={() => toggleSubmenu("Boost")}
                  title={
                    <div
                      className="d-flex align-items-center gap-2 navi-each"
                      style={{ fontWeight: 600 }}
                    >
                      <Image src={BoostMenuIcon} />
                      Boost
                    </div>
                  }
                  label="Boost"
                >
                  <MenuItem onClick={() => setToggleSidebar(false)}>
                    <Link to="/boost/settings" className="navi-each">
                      Boost Settings
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => setToggleSidebar(false)}>
                    <Link to="/boost/summary" className="navi-each">
                      Boost Summary
                    </Link>
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  open={selectedSubMenuId === "Cashout"}
                  onOpenChange={() => toggleSubmenu("Cashout")}
                  title={
                    <div
                      className="d-flex align-items-center gap-2 navi-each"
                      style={{ fontWeight: 600 }}
                    >
                      <Image src={CashoutMenuIcon} />
                      Cashout
                    </div>
                  }
                  label="Cashout"
                >
                  <MenuItem onClick={() => setToggleSidebar(false)}>
                    <Link to="/cashoutsummary" className="navi-each">
                      Cashout Summary
                    </Link>
                  </MenuItem>
                </SubMenu>
                {handleMenuAdvance()}
              </>
            )}
        </Menu>
        {userData && (userData?.isKycApproved || !userData?.registrationCompletedAt) && (
          <Menu className="d-block d-md-none">
            <MenuItem onClick={() => setToggleSidebar(false)} className="navi-each">
              <Link to="/boost/settings" className="navi-each">
                <Button
                  variant="outline-primary"
                  className="py-2 mr-4 w-100"
                  style={{ fontWeight: 600 }}
                >
                  Boost Settings
                </Button>
              </Link>
            </MenuItem>
            <MenuItem onClick={() => setToggleSidebar(false)} className="navi-each">
              <Link to="/boost/summary" className="navi-each">
                <Button className="py-2 mr-4 w-100">
                  <Image src={cashoutIcon} alt="" className="mr-2" />
                  Cashout
                </Button>
              </Link>
            </MenuItem>
          </Menu>
        )}
      </CustomSidebarContent>
      <CustomSidebarFooter>
        <ToggleContainer>
          <p>Dark mode</p>
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleToggle}
              className="toggle-input"
            />
            <span className="toggle-slider round" />
          </label>
        </ToggleContainer>
        <Menu>
          <MenuItem>
            {isAuthenticated && (
              <Button
                variant="btn btn-block text-left"
                size="sm"
                className="px-0 d-flex align-items-center gap-2 navi-each"
                onClick={() => logout()}
              >
                <Image src={LogoutMenuIcon} />
                Log out
              </Button>
            )}
          </MenuItem>
        </Menu>
      </CustomSidebarFooter>
    </ProSidebar>
  );
};

export default Navi;
