import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useUpdateUserData, useUserData } from "hooks/useUserData";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const location = useLocation();
  const { user: userAuth0 } = useAuth0();
  const params = new URLSearchParams(location.search);
  const [updateUserSuccess, setUpdateUserSuccess] = useState(false);

  const [userData, setUserData] = useState(null);

  const { mutate: mutateUpdateUser } = useUpdateUserData({
    onSuccess: (data) => {
      if (data?.success) setUpdateUserSuccess(true);
      setUpdateUserSuccess(false);
    },
    enabled: !!userAuth0 && !updateUserSuccess,
  });

  useEffect(() => {
    if (userAuth0?.email && userAuth0?.sub) {
      mutateUpdateUser({
        email: userAuth0.email,
        identifier: userAuth0.sub,
        campaignSource: params.get("source") || "",
        referrerCode: params.get("referral") || "",
      });
    }
  }, [userAuth0]);

  const { refetch } = useUserData({
    onSuccess: (data) => {
      setUserData(data);
    },
    enabled: updateUserSuccess && !!userAuth0,
  });

  const value = useMemo(() => ({ userData, setUserData, refetch }), [userData]);

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
};

export const useUserDataState = () => useContext(UserDataContext);
