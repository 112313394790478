import styled from "styled-components";

import Color from "utils/colours";

export const StyledLink = styled.a`
  font-size: inherit;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  color: ${Color.navi500};
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  &.secondary {
    text-decoration: underline;
  }
  &:hover {
    color: ${Color.navi500};
    text-decoration: none;
  }
`;
