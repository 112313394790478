import { Link } from "react-router-dom";

const Progressbar = ({ menuItems, pageNumber, url }) => (
  <div id="kyc-progress-bar">
    <div className="steps-wrap">
      {menuItems.map((each, idx) => {
        const progressNo = idx + 1;
        return progressNo <= pageNumber ? (
          <Link
            key={`${each}-${url}`}
            to={`${url}/${progressNo}`}
            className={`steps text-center ${progressNo <= pageNumber && "active"}`}
          >
            <div className="dot" />
            <p>{each}</p>
          </Link>
        ) : (
          <div className="step text-center">
            <div className="dot" />
            <p>{each}</p>
          </div>
        );
      })}
    </div>
  </div>
);

export default Progressbar;

Progressbar.defaultProps = {
  menuItems: ["Lyte", "Pay2", "Progress", "Bar"],
};
