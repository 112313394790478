/* eslint-disable no-unused-expressions */
import { useReducer } from "react";

const netural = "neutral";
const loading = "loading";
const success = "success";
const error = "error";

const initialButtonState = {
  buttonState: netural,
  isDisabled: false,
  isLoading: false,
};

const buttonReducer = (state, { action }) => {
  switch (action) {
    case netural:
      return {
        ...state,
        isLoading: false,
        isDisabled: false,
        buttonState: netural,
      };
    case loading:
      return {
        ...state,
        isLoading: true,
        isDisabled: true,
        buttonState: loading,
      };
    case success:
      return {
        ...state,
        isLoading: false,
        isDisabled: false,
        buttonState: success,
      };
    case error:
      return {
        ...state,
        isLoading: false,
        isDisabled: false,
        buttonState: error,
      };
    default:
      return state;
  }
};

const LyteBtnAction = ({
  children,
  variant,
  className,
  onClick,
  disableSuccess,
  onSuccess,
  successMessage,
  loadingMessage,
  onError,
  errorMessage,
  returnToDefault,
  returnToDefaultTimeout,
  disabled,
  ...props
}) => {
  const [state, dispatch] = useReducer(buttonReducer, initialButtonState);

  const { buttonState, isDisabled, isLoading } = state;

  const variantClasses =
    variant === "white"
      ? "global-button outline m3-auto"
      : variant === "link"
      ? "btn color-blue-link mr-4"
      : "global-button";

  return (
    <button
      type="button"
      disabled={isDisabled || isLoading || buttonState === success || disabled}
      className={`${variantClasses} ${className}`}
      onClick={async () => {
        try {
          dispatch({ action: loading });
          await onClick();
          !disableSuccess && dispatch({ action: success });
          onSuccess && (await onSuccess());
          returnToDefault &&
            setTimeout(() => dispatch({ action: netural }), returnToDefaultTimeout);
        } catch (e) {
          dispatch({ action: error });
          onError && (await onError());
          returnToDefault &&
            setTimeout(() => dispatch({ action: netural }), returnToDefaultTimeout);
        }
      }}
      {...props}
    >
      {buttonState === error
        ? errorMessage
        : buttonState === success
        ? successMessage
        : buttonState === loading
        ? loadingMessage
        : children}
    </button>
  );
};

export default LyteBtnAction;

LyteBtnAction.defaultProps = {
  returnToDefaultTimeout: 2000,
  successMessage: "Success.",
  errorMessage: "Error.",
  loadingMessage: "Loading...",
  className: "",
  disableSuccess: false,
};
