import { Formik, Field } from "formik";

import lyteWink from "images/icons/lyte-wink-blue.gif";
import { useUserData } from "hooks/useUserData";
import { useUpdateUserProfile, useGetUserLogo } from "hooks/user";
import { allCountries } from "utils/commonHelpers";
import Loader from "components/common/Loader";
import FileUpload from "components/common/FileUpload";
import { BankAccountDetails } from "components/dashboard";
import { LyteButton } from "components/common/buttons";

import { userProfileValidationSchema } from "./UserProfile.schema";

const UserProfile = () => {
  const { data: user } = useUserData();

  const lyteIdIdentifier = user?.lyteIdIdentifier;

  const { data: logo } = useGetUserLogo();

  const { isLoading: updateUserIsLoading, mutateAsync: updateUser } = useUpdateUserProfile();

  const initialValues = userProfileValidationSchema.cast({ ...user }, { stripUnknown: true });

  if (!user) {
    return <Loader />;
  }
  return !user && !logo ? (
    <Loader />
  ) : (
    <>
      <section className="invoice py-5">
        <div className="container">
          <h5>
            <strong>Logo</strong>
          </h5>
          <div className="py-2">
            <div className="bg-white py-4 px-3">
              <FileUpload
                id="user-profile-logo"
                userSub={lyteIdIdentifier}
                renderLogo={(file) =>
                  user.logoUploadsPath ? (
                    !logo ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <img height="32" className="d-block" src={lyteWink} alt="loading..." />
                      </div>
                    ) : (
                      <img
                        src={
                          file.upload ? URL.createObjectURL(file.upload) : URL.createObjectURL(logo)
                        }
                        alt="user-logo"
                        height="150px"
                        width="100%"
                        id="img-logo"
                      />
                    )
                  ) : (
                    <em className="text-secondary py-4">You have yet to upload a Logo.</em>
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>
      <Formik
        initialValues={initialValues}
        validationSchema={userProfileValidationSchema}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={(values) =>
          updateUser({
            lyteIdIdentifier,
            ...values,
          })
        }
      >
        {({ values, errors, isValid, submitForm }) => (
          <section className="invoice py-0">
            <div className="container">
              <h5>
                <strong>Profile</strong>
              </h5>
              <div className="invoice-reject py-2">
                <div className="bg-white py-5">
                  <div className="col-md-8">
                    <h6>Please update your profile in the fields below.</h6>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="firstName">First Name *</label>
                        <Field
                          id="firstName"
                          name="firstName"
                          type="text"
                          className="form-control"
                        />
                        <small className="text-danger">{errors.firstName}</small>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lastName">Last Name *</label>
                        <Field id="lastName" name="lastName" type="text" className="form-control" />
                        <small className="text-danger">{errors.lastName}</small>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="email">Email *</label>
                        <Field id="email" name="email" type="email" className="form-control" />
                        <small className="text-danger">{errors.email}</small>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className=" d-block" htmlFor="mobileNumber">
                          Mobile Number *
                        </label>
                        <select
                          id="mobileCountryCode"
                          className="form-number-select form-control"
                          value={values.mobileCountryCode}
                        >
                          <option value="SG" label="+65" />
                        </select>
                        <Field
                          id="mobileNumber"
                          name="mobileNumber"
                          type="number"
                          className="form-number-text form-control "
                          value={values.mobileNumber}
                        />
                        <small className="text-danger">{errors.mobileNumber}</small>
                      </div>
                    </div>

                    <hr />

                    <h6 className="mt-4 mb-3">Registered Address</h6>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="addressLine1">Address Line 1</label>
                        <Field
                          id="addressLine1"
                          name="addressLine1"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="addressLine2">Address Line 2</label>
                        <Field
                          id="addressLine2"
                          name="addressLine2"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="countryCode">Country</label>
                        <select
                          id="countryCode"
                          className="form-control"
                          name="countryCode"
                          value={values.countryCode}
                        >
                          {allCountries.map((item) => (
                            <option
                              key={item.iso2}
                              value={item.iso2.toUpperCase()}
                              label={item.name}
                            />
                          ))}
                        </select>
                        <small className="text-danger">{errors.countryCode}</small>
                      </div>
                      <div className="form-group col-md-8">
                        <label htmlFor="postalCode">Postal Code</label>
                        <Field
                          id="postalCode"
                          name="postalCode"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="clearfix mt-4">
                      <div className="mx-auto">
                        <LyteButton
                          size="sm"
                          disabled={!isValid || updateUserIsLoading}
                          onClick={() => submitForm()}
                        >
                          Update
                        </LyteButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Formik>
      <section className="invoice py-5">
        <div className="container">
          <BankAccountDetails />
        </div>
      </section>
    </>
  );
};

export default UserProfile;
