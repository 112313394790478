import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { fixedNumber, formatCurrency } from "utils/commonHelpers";
import TableBase from "ui-lib/components/table-base";
import { useGetAdvanceList } from "hooks/advance";
import Color from "utils/colours";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";

const columns = [
  {
    name: "No",
    selector: (row) => row.advanceId,
    width: "50px",
  },
  {
    name: "Invoice Number",
    selector: (row) => row.invoiceNumber,
    width: "150px",
    cell: (row) => (
      <Button
        variant="btn btn-block btn-link text-left "
        size="sm"
        className="p-0 pr-2 color-blue-link cursor-pointer font-weight-bold"
        onClick={() =>
          window.open(`${window.location.origin}/invoice/${row.invoiceReference}`, "_blank")
        }
        style={{
          color: Color.navi300,
          width: "max-content",
          outline: "none",
          boxShadow: "none",
          border: "none",
        }}
      >
        {row.invoiceNumber}
      </Button>
    ),
  },
  {
    name: "Reference Number",
    selector: (row) => row.advanceReferenceNumber,
    sortable: true,
    width: "180px",
  },
  {
    name: "Eligible Amount",
    selector: (row) => row.eligibleAdvanceAmount,
    sortable: true,
    cell: (row) => formatCurrency(row.eligibleAdvanceAmount),
    width: "160px",
  },
  {
    name: "Fee",
    selector: (row) => row.advanceFeeRate,
    cell: (row) => (
      <div>
        {row?.advanceType.toLowerCase() === "sponsored" ? (
          <div className="d-flex flex-column">
            <p className="m-0">Fee Paid by Agency</p>
            <span>{`(${fixedNumber(row.advanceFeeRate * 100)}%)`}</span>
          </div>
        ) : (
          <p className="m-0">{`${fixedNumber(row.advanceFeeRate * 100)}%`}</p>
        )}
      </div>
    ),
    width: "100px",
  },
  {
    name: "Sponsored",
    selector: (row) => row.advanceType,
    cell: (row) => {
      if (row?.advanceType.toLowerCase() === "sponsored")
        return (
          <div className="w-100 text-center">
            <FontAwesomeIcon color="green" icon={faCheckCircle} />
          </div>
        );
      return (
        <div className="w-100 text-center">
          <FontAwesomeIcon color="red" icon={faTimesCircle} />
        </div>
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.advanceStatus,
    sortable: true,
    cell: (row) => <InvoiceStatuses status={row?.advanceStatus} pill />,
    center: true,
  },
  {
    name: "Created on",
    selector: (row) => row.advanceAvailableDate,
    sortable: true,
    width: "140px",
    cell: (row) =>
      row.advanceAvailableDate ? dayjs(row.advanceAvailableDate).format("DD MMM YYYY HH:MM") : "-",
  },
];

const AdvanceSummary = () => {
  const { data } = useGetAdvanceList();

  return (
    <div className="container">
      <div className="pt-4 p-md-5">
        <h3>Advances Summary</h3>
        <TableBase data={data} columns={columns} pagination />
      </div>
    </div>
  );
};

export default AdvanceSummary;
