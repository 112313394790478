import { Col, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import IllustHooray from "images/illust/illust-hooray.png";
import { LyteButton } from "components/common/buttons";

const RegistrationFinishContainer = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const RegistrationFinish = () => {
  const { replace } = useHistory();

  return (
    <RegistrationFinishContainer className="container">
      <Row className="row justify-content-center">
        <Col xs={10} md={8} lg={6} className="text-center">
          <h3 className="font-weight-bold">Welcome To Lyte!</h3>

          <Image src={IllustHooray} className="image my-4 col-10 mx-auto" />

          <p>
            Your registration was successful. Just a few more steps to have you all set up! Login
            and proceed with your identity and bank account verification. Once verified, you will be
            able to use our invoicing tools to manage your billing easily and efficiently.
          </p>

          <div className="mt-3 text-center d-flex justify-content-center">
            <LyteButton onClick={() => replace("/")}>Login</LyteButton>
          </div>
        </Col>
      </Row>
    </RegistrationFinishContainer>
  );
};

export default RegistrationFinish;
