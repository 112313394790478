import { Button, Modal } from "react-bootstrap";

import { LyteBtn } from "ui-lib/components/buttons";

import InvoiceFlowCarousel from "./InvoiceFlowCarousel";

const InvoiceFlow = ({ show, setShow }) => {
  const handleClose = () => setShow(false);

  const onChangeHandler = () => {
    localStorage.setItem("lytepay2onboardinginvoiceflow", false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className="border-bottom-0" closeButton />
      <Modal.Body>
        <InvoiceFlowCarousel />
      </Modal.Body>
      <Modal.Footer className="flex-column-reverse flex-md-row align-items-center d-flex justify-content-between border-top-0 mt-2 mt-md-0">
        <LyteBtn className="m-2" variant="link" type="button" onClick={onChangeHandler}>
          Skip
        </LyteBtn>
        <Button variant="primary" className="px-5 py-2" onClick={handleClose}>
          Create invoice now
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceFlow;
