import { useMutation } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useInitiateMyInfoVerification = (options) => {
  const { Private } = useApi();
  return useMutation(
    async () => {
      const { data } = await Private({
        method: "post",
        url: "/users/kyc/myinfo/initiate",
      });
      return data;
    },
    { ...options }
  );
};

export const useAuthorizeMyInfoVerification = (options) => {
  const { Private } = useApi();
  return useMutation(
    async (payload) => {
      const promise = Private({
        method: "post",
        url: "/users/kyc/myinfo/authorize",
        data: payload,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};

export const useSubmittedMyInfoVerification = (options) => {
  const { Private } = useApi();
  return useMutation(
    async () => {
      const promise = Private({
        method: "post",
        url: "/users/kyc/myinfo/submit",
      });
      toast.promise(promise, {
        loading: "Submitting KYC",
        success: `KyC successfully submitted`,
        error: `Error submitting KYC`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};
