import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { LyteButton } from "components/common/buttons";

import { CANCELLED, DRAFT, REJECTED } from "./InvoiceSummary.constants";

const InvoiceDetailsButtons = ({ invoice, state }) => {
  const { push } = useHistory();
  const getButtonVariant = (param) => {
    if (state === param) return "primary";
    return "secondary";
  };

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="mt-3 d-flex justify-content-start">
        <LyteButton
          variant={getButtonVariant("details")}
          className="mr-1"
          onClick={() => push(`/invoice/${invoice.invoiceReference}`)}
        >
          Invoice Details
        </LyteButton>
        {invoice.cashouts.length > 0 && (
          <LyteButton
            variant={getButtonVariant("cashout")}
            onClick={() => push(`/invoice/${invoice.invoiceReference}/cashout`)}
          >
            Cashout
          </LyteButton>
        )}
      </div>
      {invoice?.pdfLink &&
        invoice?.pdfLink !== "" &&
        invoice.status !== REJECTED &&
        invoice.status !== DRAFT &&
        invoice.status !== CANCELLED && (
          <LyteButton
            variant="secondary"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_URL}/public/files/${invoice.pdfLink.replace(
                  "invoices",
                  "invoicePdf"
                )}`,
                "_blank"
              )
            }
          >
            <FontAwesomeIcon icon={faDownload} />
            Download PDF
          </LyteButton>
        )}
    </div>
  );
};

export default InvoiceDetailsButtons;
