import { useQuery } from "react-query";
import qs from "qs";
import { useLocation } from "react-router-dom";

import useApi from "./useApi";

export const useInvoicesData = (query = {}) => {
  const { search } = useLocation();
  const { Private } = useApi();

  // checks if there are any query params in the url if there are then it will
  // merged to the passed query object
  const currentQuery = qs.parse(search, { ignoreQueryPrefix: true });
  const mergedQuery = { ...currentQuery, ...query };
  const queryParams = qs.stringify(mergedQuery);

  return useQuery(
    ["userInvoices", queryParams],
    async () => {
      const { data } = await Private.get(`invoices/v3/user/?${queryParams}`);
      return data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useRecentInvoicesData = () => {
  const { Private } = useApi();
  return useQuery(
    "userRecentInvoices",
    async () => {
      const { data } = await Private.get(`invoices/user/dashboard`);
      return data.reverse();
    },
    {
      keepPreviousData: true,
    }
  );
};
