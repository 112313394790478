import { down } from "styled-breakpoints";
import styled from "styled-components";

export const TooltipBox = styled.div`
  box-sizing: border-box;
  font-family: inherit;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  color: ${(props) => props.theme.color.textSecondary};
  overflow: hidden;
  max-width: 420px;
  min-width: 290px;
  border-radius: 8px;
  padding: 16px 24px;
  border: none;
  ${down("md")} {
    max-width: 320px;
    min-width: 200px;
  }
`;

export const ProgressBullet = styled.div`
  margin: 0 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? props.theme.color.toggleBulletActive : props.theme.color.toggleBackground};
  cursor: pointer;
`;
