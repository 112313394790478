import { up } from "styled-breakpoints";
import styled from "styled-components";

const Color = {
  lyteWhite: "#ffffff",
  lyteDark: "#18191b",
  lyteBlue: "#3363ff",
  lyteDarkBlue: "#001866",
  lyteGreen: "#61BFA8",
  lytePurple: "#713DF5",
  lytePink: "#ED6393",
  lyteDark100: "#F0F0F0",
  lyteDark200: "#B5BEC7",
};

export const ProfileSection = styled.section`
  padding: 20px 10px;
  background-color: ${Color.lyteWhite};
  ${up("lg")} {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
  }
`;

ProfileSection.Row = styled.div`
  ${up("lg")} {
    display: flex;
    width: 100%;
    gap: 20px;
  }
`;

ProfileSection.LogoText = styled.div`
  font-size: 36px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  background-image: url(${(props) => props.maskImage});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: cover;
  background-position: center center;
`;

ProfileSection.Header = styled.div`
  .logo-container {
    height: 34px;
    margin-bottom: 48px;
  }
  .profile-picture {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
  }
  .emma-container {
    margin-top: 85px;
    text-align: center;
    background-color: ${Color.lyteDark100};
    border-radius: 16px;
    position: relative;
    padding: 32px 20px 16px;
    .emma-picture-container {
      position: absolute;
      left: 50%;
      top: -28px;
      transform: translateX(-50%);
      background-color: ${Color.lyteDark200};
      border-radius: 50%;
      width: 48px;
      height: 48px;
      overflow: hidden;
      svg {
        width: 100%;
        height: 100%;
        padding-top: 8px;
      }
    }

    .btn {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      font-weight: 600;
      color: ${Color.lytewhite};
      background-color: ${Color.lyteBlue};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 35px;
      &-tertiary {
        color: ${Color.lyteDark};
        background-color: ${Color.lyteWhite};
        padding: 8px 16px;
        width: auto;
        border-radius: 24px;
        font-size: 13px;
      }
    }
  }

  .pills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .pills {
      background-color: ${Color.lyteWhite};
      border: 1px solid ${Color.lyteDark};
      border-radius: 45px;
      padding: 8px 24px;
    }
  }

  ${up("lg")} {
    width: 480px;
    padding: 30px;
    background-color: ${Color.lyteDark100};
    .emma-container {
      margin-top: 20px;
      background-color: ${Color.lyteWhite};
      .btn {
        &-tertiary {
          background-color: ${Color.lyteDark100};
        }
      }
    }
  }
`;

ProfileSection.Content = styled.div`
  .sliders {
    width: calc(100% + 33px);
    max-width: none;
    left: -20px;
    overflow-x: auto;
    position: relative;
  }
  .sliders-container {
    display: flex;
    gap: 18px;
    padding: 6px 26px;
    overflow-x: auto;
    .slide {
      min-width: 180px;
      /* max-height: 240px; */
      background-color: ${Color.lyteWhite};
      border: 1px solid ${Color.lyteDark};
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 2px;
    }
  }

  .social-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid ${Color.lyteDark};
    border-radius: 8px;
    padding: 18px 20px;
    margin-bottom: 20px;
  }

  .listing-container {
    width: 100%;
    display: flex;
    gap: 16px;
    border: 1px solid ${Color.lyteDark};
    border-radius: 8px;
    padding: 15px 12px;
    margin-bottom: 20px;
    .image-container {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      overflow: hidden;
      text-align: center;
      .img {
        width: 100%;
      }
    }
    .btn {
      border-radius: 4px;
      backdrop-filter: blur(2px);
      background-color: ${Color.lyteBlue};
      padding: 3.5px 8px;
      margin-bottom: 8px;
    }
  }
  ${up("lg")} {
    width: calc(100% - 480px);
    padding: 30px 5vw;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;
    .listing-container {
      width: calc(100% - 30px);
      margin-left: 15px;
    }
    .slider-container {
      padding-left: 15px;
    }
  }
`;

ProfileSection.Footer = styled.div`
  .emma-trigger-button {
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center center;
    border-radius: 32px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 20px auto;
    border-color: transparent;
  }
`;
