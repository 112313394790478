import {
  faChevronDown,
  faQuestionCircle,
  faTrash,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { useUpdateInvoiceBoost } from "hooks/boost";
import { useContents } from "hooks/contents";
import LyteBtn from "ui-lib/components/buttons/LyteBtn";
import Range from "ui-lib/components/range";
import { useDeleteInvoice } from "hooks/invoices";
import { useUserData } from "hooks/useUserData";
import Color from "utils/colours";
import { formatCurrency } from "utils/commonHelpers";
import FormikCheckbox from "components/form/FormikCheckbox";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";
import { LyteButton } from "components/common/buttons";

const BoostHeading = () => <div>Boost Settings</div>;

const PopoverStyled = styled(Popover)`
  width: 300px;
  max-width: none;
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  .arrow {
    display: none;
  }
`;
const BoostSettingContainer = styled.div``;

BoostSettingContainer.SelectContainer = styled.div`
  width: 100%;
  position: relative;
  .icon-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: ${Color.dark300};
  }
`;
BoostSettingContainer.FormSwitch = styled.div`
  label {
    display: flex;
    align-items: center;
  }
  &:not(:last-child) {
    padding-bottom: 12px;
  }
  input {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  input:checked + label .switch::before {
    transform: translateX(20px);
  }
  input:checked + label .switch {
    background-color: ${Color.navi600};
  }
`;

BoostSettingContainer.LabelSwitch = styled.label`
  width: 100%;
  gap: 8px;
  .icon {
    color: ${Color.navi500};
  }
  .switch {
    width: 44px;
    height: 22px;
    border-radius: 12px;
    background-color: ${Color.dark300};
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 1px;
      background-color: ${Color.neutral0};
      border-radius: 50%;
      transition: 0.4s;
    }
  }
`;

const DeleteButton = styled.div`
  border: 2px solid ${(props) => props.theme.color.borderAlertDestructive};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: ${(props) => props.theme.color.borderAlertDestructive};
  }
`;

const validationSchema = yup.object({
  invoiceId: yup.number().required(),
  boostPercentage: yup.number().required().min(0).max(1),
  boostDuration: yup.number().required().default(15),
  boostReturn: yup.number().required().default(0.07),
  autoReboost: yup.boolean(),
  optInForTrialBoost: yup.boolean(),
  agreeReboost: yup.boolean().oneOf([true], "This is a required field."),
});

const DEFAULT_BOOST_RETURN = 0.05;
const DEFAULT_BOOST_DURATION = 90;

const BoostSettings = ({ row }) => {
  const [opened, setOpened] = useState(false);
  const [invoiceBoostValue, setInvoiceBoostValue] = useState(
    Number((row.boostPercentage * 100).toFixed(2))
  );
  const open = () => setOpened(true);
  const close = () => setOpened(false);
  const { mutate: updateInvoiceBoost, isLoading: isUpdating } = useUpdateInvoiceBoost(
    {
      onSettled: () => {
        close();
      },
    },
    row.invoiceNumber
  );

  const { data: contents } = useContents();
  const { data: user } = useUserData();

  useEffect(() => {
    if (opened) {
      setInvoiceBoostValue(Number((row.boostPercentage * 100).toFixed(2)));
    }
  }, [opened]);

  const DURATION_REWARDS_OPTIONS = contents?.boostDurations?.map(
    (e, i) => `${e.key} Days at ${contents.boostRewards[i].key}% Yearly Rewards`
  );

  const handleCheckBoostAmount = (values) => {
    if (
      row?.validateMinAmount &&
      +row.amount * +values.boostPercentage < contents?.minimumValues?.minimumBoostValue
    )
      return true;
    return false;
  };

  return (
    <div>
      <div>
        <span>{row.boostPercentage * 100}%, &nbsp;</span>
        <span>
          {row.boostDuration} days / {row.boostReturn * 100} %
        </span>
      </div>
      {row.status !== "paid" &&
        row.status !== "draft" &&
        row.status !== "cancelled" &&
        row.status !== "rejected" && (
          <LyteButton size="sm" variant="ghost" className="m-0 p-0" onClick={open}>
            Update
          </LyteButton>
        )}
      <Modal centered show={opened} onHide={close}>
        <Formik
          enableReinitialize
          initialValues={validationSchema.cast(
            {
              invoiceId: row.id,
              boostReturn: row.boostReturn || DEFAULT_BOOST_RETURN,
              boostDuration: row.boostDuration || DEFAULT_BOOST_DURATION,
              boostPercentage: row.boostPercentage,
              autoReboost: row.autoReboost,
              optInForTrialBoost: row?.optInForTrialBoost || false,
              agreeReboost: row?.agreeReboost || false,
            },
            { stripUnknown: true }
          )}
          validationSchema={validationSchema}
          onSubmit={(values) => updateInvoiceBoost(values)}
          validateOnMount
        >
          {({ values, setFieldValue, handleSubmit, isValid }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="mb-0">Invoice {row.invoiceNumber} Boost Settings</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <Form>
                  <Form.Group className="my-2 w-100">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h6>Current Boost</h6>
                      <h6>{`${formatCurrency(
                        (+row.amount * +values.boostPercentage).toFixed(2)
                      )}`}</h6>
                    </div>
                    <Range
                      value={+invoiceBoostValue}
                      onChange={(range) => {
                        setInvoiceBoostValue(range[0].toFixed(0));
                        setFieldValue("boostPercentage", range[0] / 100);
                      }}
                      renderPopoverText={(value) => value}
                      className="w-100"
                      step={1}
                      min={0}
                      max={100}
                      suffix="%"
                    />
                  </Form.Group>
                  {handleCheckBoostAmount(values) && (
                    <Alert className="mt-5 mb-0" variant="danger">
                      {`Minimum boost amount is ${formatCurrency(
                        contents?.minimumValues?.minimumBoostValue
                      )}. Please adjust the boost percentage to meet the minimum amount. Your current boost amount is ${formatCurrency(
                        (+row.amount * +values.boostPercentage).toFixed(2)
                      )}.`}
                    </Alert>
                  )}
                  <Form.Group className="my-2 pb-4">
                    <h6 className="mt-4 pt-2">Boost duration and rate:</h6>
                    <BoostSettingContainer.SelectContainer>
                      <Form.Control
                        as="select"
                        id="boostDuration"
                        className="form-control"
                        onChange={(e) => {
                          const selectedIndex = e.target.value;
                          setFieldValue(
                            "boostDuration",
                            +contents?.boostDurations[selectedIndex].value
                          );
                          setFieldValue(
                            "boostReturn",
                            +contents?.boostRewards[selectedIndex].value
                          );
                        }}
                        value={
                          values.boostDuration &&
                          contents?.boostDurations?.findIndex(
                            (item) => +item.value === values.boostDuration
                          )
                        }
                      >
                        <option value="default" disabled>
                          --Select duration and rate--
                        </option>
                        {DURATION_REWARDS_OPTIONS.map((item, index) => (
                          <option key={item} value={index} label={item} />
                        ))}
                      </Form.Control>
                      <div className="icon-container">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </BoostSettingContainer.SelectContainer>
                  </Form.Group>

                  {(user?.numberOfTrialBoostRemaining > 0 || row?.usedTrialBoost) && (
                    <div className="d-flex">
                      <OverlayTrigger
                        placement="top-start"
                        delay={{ show: 100, hide: 200 }}
                        overlay={
                          <PopoverStyled>
                            <Popover.Content>
                              By choosing this option, you are using your one-time trial of Boost
                              for 45 days(4% p.a) to your next collected payment. Should you choose
                              to reboost, it will follow the current default Boost setting after 45
                              days.
                            </Popover.Content>
                          </PopoverStyled>
                        }
                      >
                        <BoostSettingContainer.FormSwitch className="form-group">
                          <Field
                            id="optInForTrialBoost"
                            name="optInForTrialBoost"
                            type="checkbox"
                            className="mt-1"
                          />
                          <BoostSettingContainer.LabelSwitch
                            className="justify-content-between"
                            htmlFor="optInForTrialBoost"
                          >
                            <div className="d-flex align-items-center gap-2 w-75">
                              <p className="m-0">
                                Apply a one-time trial of Boost for 45 Days at 4% p.a to my next
                                paid invoice.
                                <FontAwesomeIcon className="icon ml-2" icon={faQuestionCircle} />
                              </p>
                            </div>
                            <div className="switch" />
                          </BoostSettingContainer.LabelSwitch>
                        </BoostSettingContainer.FormSwitch>
                      </OverlayTrigger>
                    </div>
                  )}

                  <OverlayTrigger
                    placement="top-start"
                    delay={{ show: 100, hide: 200 }}
                    overlay={
                      <PopoverStyled>
                        <Popover.Content>
                          By selecting this option, I intend to reBoost my invoices upon their
                          maturity date. I acknowledge that any early withdrawal will deactivate
                          this setting, and I will be required to reauthorize Auto ReBoost every 6
                          months.
                        </Popover.Content>
                      </PopoverStyled>
                    }
                  >
                    <BoostSettingContainer.FormSwitch>
                      <Field id="autoReboost" name="autoReboost" type="checkbox" />
                      <BoostSettingContainer.LabelSwitch
                        className="justify-content-between"
                        htmlFor="autoReboost"
                      >
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Auto-reBoost</p>
                          <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
                        </div>
                        <div className="switch" />
                      </BoostSettingContainer.LabelSwitch>
                    </BoostSettingContainer.FormSwitch>
                  </OverlayTrigger>

                  <FormikCheckbox className="m-0" name="agreeReboost">
                    <span>
                      By electing to re-Boost this invoice and/or Rewards, I agree to assign all my
                      rights and benefits under this invoice and/or Rewards to Lytepay Pte Ltd and
                      in return earn further Rewards.
                    </span>
                  </FormikCheckbox>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  block
                  size="lg"
                  onClick={() => handleCheckBoostAmount(values) || handleSubmit()}
                  disabled={isUpdating || !isValid || handleCheckBoostAmount(values)}
                >
                  Update
                </Button>
                <div className="text-center mt-1 w-100">
                  <small className="text-muted ">
                    Update only applies on selected invoice {row.invoiceNumber}
                  </small>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

const InvoiceActions = ({ row }) => {
  const [opened, setOpened] = useState(false);
  const open = () => setOpened(true);
  const close = () => setOpened(false);
  const { mutate: deleteInvoice, isLoading: isUpdating } = useDeleteInvoice(
    {
      onSettled: () => {
        close();
      },
    },
    row.invoiceNumber
  );
  return (
    <div className="w-100">
      {(row.status === "draft" || row.status === "cancelled") && (
        <DeleteButton>
          <LyteButton variant="ghost" size="sm" onClick={open}>
            <FontAwesomeIcon icon={faTrash} />
          </LyteButton>
        </DeleteButton>
      )}
      <Modal centered show={opened} onHide={close}>
        <Modal.Header closeButton onHide={close}>
          <Modal.Title>Invoice {row.invoiceNumber}</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <p>
              Are you sure you want to delete invoice <strong>{row.invoiceNumber}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <LyteBtn
              variant="white"
              className="red"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </LyteBtn>
            <LyteBtn
              className="btn-sm"
              onClick={() => deleteInvoice(row.invoiceReference)}
              disabled={isUpdating}
            >
              Yes, I confirm
            </LyteBtn>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

BoostSettings.propTypes = {
  row: PropTypes.shape({
    invoiceNumber: PropTypes.string,
    boostPercentage: PropTypes.number,
    boostReturn: PropTypes.number,
    boostDuration: PropTypes.number,
    autoReboost: PropTypes.bool,
    status: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export const columns = [
  {
    name: "Invoice",
    selector: (row) => row.invoiceNumber,
    sortable: true,
    wrap: true,
    cell: (row) => (
      <Link className="color-blue-link" to={`/invoice/${row.invoiceReference}`}>
        {row.invoiceNumber}
      </Link>
    ),
    minWidth: "100px",
  },
  {
    name: "Client Name",
    selector: (row) => row.clientName,
    sortable: true,
    wrap: true,
    minWidth: "150px",
  },
  {
    name: "Amount",
    selector: (row) => row.description,
    wrap: true,
    cell: (row) => (
      <ul className="list-unstyled m-0">
        <li className="fw-700">{formatCurrency(row.amount)}</li>
        <li>{dayjs(row.issuedDateTime).format("DD MMM YYYY")}</li>
      </ul>
    ),
    minWidth: "150px",
  },
  {
    name: "Boost",
    selector: (row) => row.boostAmount,
    wrap: true,
    cell: (row) => (
      <div>
        <p
          className={clsx({
            "m-0": true,
            "fw-800": row.boostStatus === "available" || row.boostStatus === "current",
            "text-success": row.boostStatus === "available",
          })}
        >
          {formatCurrency(row.boostAmount)}
        </p>
      </div>
    ),
  },
  {
    name: "Payment Due",
    selector: (row) => row.paymentDueDate,
    wrap: true,
    cell: (row) => (
      <>
        <span>{dayjs(row.paymentDueDate).format("DD MMM YYYY")}</span>
        {row.isOverdue && (
          <FontAwesomeIcon className="ml-1 text-danger" icon={faClock} title="Overdue" />
        )}
      </>
    ),
    minWidth: "140px",
  },
  {
    name: <BoostHeading />,
    selector: (row) => row.boostPercentage,
    wrap: true,
    width: "200px",
    cell: (row) => <BoostSettings row={row} />,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    wrap: true,
    width: "150px",
    cell: (row) => <InvoiceStatuses status={row?.status || row?.boostStatus} pill />,
    center: true,
  },
  {
    name: "",
    selector: (row) => row.status,
    wrap: true,
    width: "70px",
    cell: (row) => <InvoiceActions row={row} />,
  },
];
