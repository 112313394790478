export const ADVANCE_CASHOUT_VALUES = [
  {
    label: "100%",
    value: 100,
  },
];

export const ADVANCE_STATUS = {
  available: "Advance Available",
  cashout_pending: "Cashout Pending",
  completed: "Completed",
  expired: "Expired",
};
