import { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import toast from "react-hot-toast";

import { useUserDataSingleUse } from "hooks/useUserData";
import Loader from "components/common/Loader";
import { PENDING_SUBMISSION, PENDING_VERIFICATION } from "components/kyc/constants";
import { isFeatureEnabled } from "utils/commonHelpers";

import { useInitiateMyInfoVerification } from "./service";

const MyInfoKyc = () => {
  const myInfoEnabled = isFeatureEnabled("MYINFO");
  const { replace } = useHistory();

  const { data: userData } = useUserDataSingleUse({
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });
  const { mutate: mutateInitiateMyInfoVerificationData } = useInitiateMyInfoVerification();

  useEffect(() => {
    if (userData) {
      if (userData?.kycStatus === PENDING_VERIFICATION) {
        replace("/kyc");
      } else if (userData?.isKycApproved) {
        replace("/");
      } else if (!userData?.kycStatus || userData?.kycStatus === PENDING_SUBMISSION) {
        mutateInitiateMyInfoVerificationData(
          {},
          {
            onSuccess: (url) => {
              if (url) {
                window.location.href = url;
              } else {
                toast.error("Error when authorizing MyInfo KYC");
                replace("/kyc");
              }
            },
            onError: () => {
              toast.error("Error when authorizing MyInfo KYC");
              replace("/kyc");
            },
            enabled: !userData?.kycStatus || userData?.kycStatus === PENDING_SUBMISSION,
            refetchOnWindowFocus: false,
          }
        );
      }
    }
  }, [replace, userData]);

  if (!myInfoEnabled) {
    return <Redirect to="/kyc" />;
  }

  return <Loader />;
};

export default MyInfoKyc;
