/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from "react";
import { Link, Switch, Route, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import { Formik } from "formik";
import { Image } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import * as Yup from "yup";
import styled from "styled-components";
import { down } from "styled-breakpoints";

import rejectImage from "images/illust/illust-reject.png";
import lyteWink from "images/icons/lyte-wink-blue.gif";
import {
  useGetInvoiceNumberPrefill,
  useGetPublicInvoiceByRef,
  useSendDraftInvoice,
  usePostSubmitInvoice,
} from "hooks/invoices";
import { useUserData } from "hooks/useUserData";
import { useGetBoostSettings } from "hooks/boost";
import InvoiceFlow from "components/onboarding/invoiceflow/InvoiceFlow";
import { LyteButton } from "components/common/buttons";

import { InvoiceWizardSchema } from "./Invoice.schema";
import InvoiceSummaryForm from "./InvoiceSummaryForm"; // page 4
import { AMOUNT, WIZARD_MODES, EDIT, CREATE, DRAFT, INVOICE_TYPES } from "./constants";
import InvoicePaymentForm from "./InvoicePaymentForm"; // page 3
import InvoiceClientForm from "./InvoiceClientForm"; // page 1
import InvoiceDetailForm from "./InvoiceDetailForm"; // page 2
import InvoiceWizardControl from "./InvoiceWizardControl";

Yup.setLocale({
  mixed: {
    required: "This is a required field",
  },
});

const CardStyled = styled.div`
  border-radius: 8px;
  padding: 48px 92px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};

  ${down("lg")} {
    padding: 16px;
  }
`;

const InvoiceWizard = () => {
  // Get the step, wizardMode (edit or create) and the invoice ref from the URL
  // These are used to determine which page to show whether edit or create
  const {
    params: { step, wizardMode, invoiceRef },
  } = useRouteMatch();

  if (!!invoiceRef && wizardMode === WIZARD_MODES[CREATE]) {
    return <Redirect to="/invoice/create/1" />;
  }

  if (!invoiceRef && wizardMode === WIZARD_MODES[EDIT]) {
    return <Redirect to="/" />;
  }

  /* ------------------------ */
  /*         HOOKS            */
  /* ------------------------ */

  const [showModal, setShowModal] = useState(true);
  const [canNext, setCanNext] = useState(true);

  const { isAuthenticated } = useAuth0();

  const { data: user } = useUserData();
  const { data: invoiceNumberPrefill } = useGetInvoiceNumberPrefill();
  const { data: boostSettings } = useGetBoostSettings();

  const { data: { data: publicInvoice } = {}, isLoading: isPublicInvoiceLoading } =
    useGetPublicInvoiceByRef(invoiceRef, {
      enabled: !!invoiceRef && wizardMode === "edit",
    });

  /* ------------------------------- */
  /* Use this hook to manipulate history, instead of <Link> */
  /* ------------------------------ */
  const history = useHistory();

  /* ------------------------------- */
  /* useEffect                       */
  /* ------------------------------ */

  useEffect(() => {
    const showInvoiceFlow = localStorage.getItem("lytepay2onboardinginvoiceflow") !== "false";
    setShowModal(showInvoiceFlow);
  }, []);

  /* ------------------------------- */

  const initialValues = {
    id: publicInvoice?.id || 0,
    clientName: publicInvoice?.invoiceClient.name || "",
    businessName: publicInvoice?.invoiceClient.businessName || "",
    email: publicInvoice?.invoiceClient.email || "",
    mobileCountryCode: publicInvoice?.invoiceClient.mobileCountryCode || "65",
    mobileNumber: publicInvoice?.invoiceClient.mobileNumber || "",
    addressLine1: publicInvoice?.invoiceClient.addressLine1 || "",
    addressLine2: publicInvoice?.invoiceClient.addressLine2 || "",
    country: publicInvoice?.invoiceClient.country || "Singapore",
    postalCode: publicInvoice?.invoiceClient.postalCode || "",
    invoiceNumber: publicInvoice?.invoiceNumber || invoiceNumberPrefill || "",
    projectTitle: publicInvoice?.projectTitle || "",
    issuedDateTime:
      moment(publicInvoice?.issuedDateTime).format("YYYY-MM-DD") ||
      moment(new Date()).format("YYYY-MM-DD"),
    paymentDueDays: publicInvoice?.paymentDue || 0,
    dueDateTime:
      moment(publicInvoice?.issuedDateTime)
        .add(publicInvoice?.paymentDue, "days")
        .format("YYYY-MM-DD") || moment(new Date()).add(0, "days").format("YYYY-MM-DD"),
    currency: publicInvoice?.currency || user?.currency || "SGD",
    invoiceItems: publicInvoice?.invoiceItems || [
      {
        itemName: "",
        description: "",
        quantity: 1,
        rate: 0,
      },
    ],
    showAddress: publicInvoice?.showAddress || false,
    agreeAuthClause: publicInvoice?.agreeAuthClause || false,
    agreeProhibitClause: publicInvoice?.agreeProhibitClause || false,
    agreeCollectionClause: publicInvoice?.agreeCollectionClause || false,
    sendReminderEmail:
      publicInvoice?.sendReminderEmail === undefined ? true : publicInvoice.sendReminderEmail,
    lateFeeRate: publicInvoice?.lateFeeRate * 100 || 0,
    invoiceType:
      publicInvoice?.invoiceType || (user?.isKycApproved ? "LyteCollect" : "SelfCollect"),
    // Do not show values below, embedded for passing onto api call
    businessContactPersonId: publicInvoice?.businessContactPersonId || null,
    businessRegistrationCode: publicInvoice?.businessRegistrationCode || null,
    invoiceId: publicInvoice?.id || "",
    ccToSelf: publicInvoice?.ccToSelf || false,
    discount: {
      type: publicInvoice?.discount.type ? publicInvoice?.discount.type.toLowerCase() : AMOUNT,
      value: publicInvoice?.discount.value,
      description: publicInvoice?.discount.description,
    },
    notes: publicInvoice?.notes || "",
    files: publicInvoice?.files || null,
    invoiceFiles: null,
    withholdingTax: {
      type: publicInvoice?.withholdingTax.type
        ? publicInvoice?.withholdingTax.type.toLowerCase()
        : AMOUNT,
      value: publicInvoice?.withholdingTax.value,
      description: publicInvoice?.withholdingTax.description,
    },
    vatValue: user?.country?.toLowerCase() === "id" ? publicInvoice?.vatValue || 11 : null,
  };

  const { mutateAsync: submitInvoice } = usePostSubmitInvoice({
    onSuccess: () => {
      if (boostSettings?.value === null || boostSettings?.value === 0) {
        history.replace("/invoice/create/success-set-boost");
      } else {
        history.replace("/invoice/create/submitted");
      }
    },
  });

  const { mutateAsync: submitDraftInvoice } = useSendDraftInvoice({
    onSuccess: () => {
      if (boostSettings?.value === null || boostSettings?.value === 0) {
        history.replace("/invoice/create/success-set-boost");
      } else {
        history.replace("/invoice/create/submitted");
      }
    },
  });

  const submitHandler = async (values, actions) => {
    if (publicInvoice?.id > 0) {
      const updatedValues = {
        ...values,
        invoiceId: publicInvoice?.id || 0,
        invoiceFiles: values?.invoiceFiles?.map((file) => file.id) || [],
        files: values?.invoiceFiles?.map((file) => file.id) || [],
      };

      await submitDraftInvoice(updatedValues).finally(() => {
        actions.setSubmitting(false);
      });
    } else {
      const updatedValues = {
        ...values,
        invoiceFiles: values?.invoiceFiles?.map((file) => file.id) || [],
      };
      await submitInvoice(updatedValues).finally(() => {
        actions.setSubmitting(false);
      });
    }
  };

  /* ------------------------ */
  /*         RETURN           */
  /* ------------------------ */

  if (
    (!user && !isAuthenticated) ||
    (wizardMode === WIZARD_MODES[EDIT] && isPublicInvoiceLoading)
  ) {
    return (
      <div className="bg-wink-white mx-auto">
        <div>
          <Image className="d-block" src={lyteWink} />
          <div className="color-blue-link text-center">
            <strong>Loading...</strong>
          </div>
        </div>
      </div>
    );
  }

  if (
    publicInvoice &&
    publicInvoice?.status &&
    publicInvoice?.status !== DRAFT &&
    wizardMode === WIZARD_MODES[EDIT]
  ) {
    return (
      <div className="container">
        <div className="col-md-8 mx-auto mt-5 text-center">
          <Image
            src={rejectImage}
            alt="reject-illustration"
            className="image mb-5 col-4 col-md-3 mx-auto"
          />{" "}
          <h6>This invoice is not available to edit</h6>
          <Link to="/invoice/listing">Go back</Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <InvoiceFlow show={showModal} setShow={setShowModal} />
      <Formik
        initialValues={InvoiceWizardSchema().cast(initialValues, { stripUnknown: true })}
        onSubmit={submitHandler}
        validateOnBlur
        validateOnChange
        validateOnMount
        enableReinitialize
        validationSchema={InvoiceWizardSchema({
          isMobileVerified: user?.isMobileVerified,
          isEmailVerified: user?.isEmailVerified,
          isKycApproved: user?.isKycApproved,
        })}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <section className="invoice pb-5">
            <div className="container">
              <div
                id="main"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="my-5 clearfix">
                  <div className="mb-4">
                    {+step === 1 && <h3>Invoice To</h3>}
                    {+step === 2 && <h3>Invoice Details</h3>}
                    {+step === 3 && <h3>Payment Terms</h3>}
                    {+step === 4 && <h3>Invoice Summary</h3>}
                  </div>

                  {/* --- Form (nested routing) --- */}
                  <CardStyled className="card">
                    <Switch>
                      <Route
                        path="/invoice/*/4"
                        render={() => <InvoiceSummaryForm values={values} user={user} />}
                      />
                      <Route
                        path="/invoice/*/3"
                        render={() => <InvoicePaymentForm user={user} />}
                      />
                      <Route
                        path="/invoice/*/2"
                        render={() => (
                          <InvoiceDetailForm
                            user={user}
                            values={publicInvoice}
                            setCanNext={setCanNext}
                          />
                        )}
                      />
                      <Route
                        path="/invoice/*/1"
                        render={() => <InvoiceClientForm touched={touched} errors={errors} />}
                      />
                    </Switch>
                  </CardStyled>

                  <div className="clearfix">
                    <InvoiceWizardControl
                      publicInvoice={publicInvoice}
                      canNext={canNext}
                      user={user}
                    />

                    {values?.invoiceType === INVOICE_TYPES.LYTE &&
                      !user?.hasApprovedBankAccount && (
                        <div
                          className="d-flex w-100 justify-content-end mt-3"
                          style={{ fontStyle: "italic" }}
                        >
                          {user?.bankAccount?.status === "pending" ? (
                            <div>
                              Your bank information needs to be verified before sending an invoice.
                            </div>
                          ) : (
                            <div className="d-flex flex-wrap">
                              {`Please submit in your bank details before sending an invoice. You can
                              click `}
                              <LyteButton
                                style={{ display: "contents" }}
                                className="mx-1"
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                  !user?.registrationCompletedAt
                                    ? window.open(
                                        `${process.env.REACT_APP_LP_BASE_URL}/account/bank`,
                                        "_blank"
                                      )
                                    : history.push("/bank-verification")
                                }
                              >
                                here
                              </LyteButton>
                              {` to update your bank account.`}
                            </div>
                          )}
                        </div>
                      )}
                    {(!user?.isMobileVerified ||
                      !user?.isEmailVerified ||
                      !user?.isKycApproved) && (
                      <div
                        className="col-md-6 text-right float-right mt-3"
                        style={{ fontSize: "13px" }}
                      >
                        <i>
                          You have yet to verify your identity/mobile/email, which are all required
                          to send an invoice through LytePay.
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Formik>
    </>
  );
};

export default InvoiceWizard;
