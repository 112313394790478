import { Accordion, Card, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)``;
StyledAccordion.Toggle = styled.button`
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: none;
  background-color: transparent;
  font-weight: 700;
  gap: 10px;
  text-align: left;
  &.active {
    .icon {
      transform: rotate(45deg);
    }
  }
`;
StyledAccordion.Collapse = styled(Accordion.Collapse)``;
StyledAccordion.IconContainer = styled.div``;

export const StyledCard = styled(Card)`
  &.card {
    background-color: #f2f5ff;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`;
StyledCard.Header = styled(Card.Header)`
  &.card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }
`;
StyledCard.Body = styled(Card.Body)``;

export const Icon = styled(FontAwesomeIcon)`
  transition: all 0.3s ease-in-out;
  color: #3363ff;
`;

export const StyledNav = styled(Nav)`
  border: 1px solid #3363ff;
  border-radius: 4px;
  .nav-link {
    color: #3363ff;
    font-size: 20px;
  }
`;

export const Styledul = styled.ul``;
export const Styledli = styled.li`
  position: relative;
  font-size: 16px;
  color: #3363ff;
  font-weight: 700;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3363ff;
  }
`;
