import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

import PdfIcon from "images/icons/pdf-icon.png";
import ExcelIcon from "images/icons/excel-icon.png";
import WordIcon from "images/icons/word-icon.png";
import FileIcon from "images/icons/files-icon.png";
import CsvIcon from "images/icons/csv-icon.png";
import TiffIcon from "images/icons/tiff-icon.png";
import ZipIcon from "images/icons/zip-icon.png";
import RarIcon from "images/icons/rar-icon.png";
import { LyteButton } from "components/common/buttons";
import { useUploadFile } from "hooks/useFile";
import useApi from "hooks/useApi";

import { Container, FilesContainer, UploaderDropzone } from "./styles";

const InvoiceDocumentUploader = ({ values, readOnly, setFieldValue }) => {
  const [uploadedFile, setUploadedFile] = useState([]);
  const { mutate: mutateUploadFile, isLoading } = useUploadFile();

  const { Private } = useApi();

  const handleFileTypeCheck = (file) => {
    if (
      file?.type === "application/vnd.ms-excel" ||
      file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file?.type === "xlsx"
    ) {
      return "excel";
    }
    if (file?.type === "text/csv" || file?.type === "csv") {
      return "csv";
    }
    if (
      file?.type === "image/tif" ||
      file?.type === "image/tiff" ||
      file?.type === "tif" ||
      file?.type === "tiff"
    ) {
      return "tiff";
    }
    if (file?.type === "application/zip" || file?.type === "zip") {
      return "zip";
    }
    if (file?.type === "application/x-rar" || file?.type === "rar") {
      return "rar";
    }
    if (
      file?.type === "application/msword" ||
      file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file?.type === "docx"
    ) {
      return "word";
    }
    if (file?.type === "application/pdf" || file?.type === "pdf") {
      return "pdf";
    }
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpeg" ||
      file?.type === "image/jpg" ||
      file?.type === "png" ||
      file?.type === "jpg" ||
      file?.type === "jpeg"
    ) {
      return URL.createObjectURL(file?.data || file);
    }
    return "file";
  };

  useEffect(() => {
    const fetchFiles = async () => {
      const arrayFiles = [];
      if (values?.files?.length > 0) {
        try {
          await Promise.all(
            values.files.map(async (file) => {
              const { data } = await Private.get(`files/pubId/${file.pubId}`, {
                responseType: "blob",
              });
              arrayFiles.push({
                id: file?.id,
                pubId: file?.pubId,
                file: { name: file?.name },
                fileImage: handleFileTypeCheck({ data, type: file?.extension }),
                showDelete: false,
              });
            })
          );
          setUploadedFile(arrayFiles);
          setFieldValue("invoiceFiles", arrayFiles);
        } catch (error) {
          console.error("Error fetching files:", error);
        }
      }
    };
    if (values?.files && !values?.invoiceFiles) {
      fetchFiles();
    } else {
      setUploadedFile(values?.invoiceFiles);
    }
  }, [values?.files]);

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileSizeLimit = 5 * 1024 * 1024; // 5MB
    const acceptedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/tif",
      "image/tiff",
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/zip",
      "application/x-rar",
    ];

    if (file?.size > fileSizeLimit) {
      toast.error("File size exceeds the limit (30MB).");
      return;
    }

    if (!acceptedFileTypes.includes(file?.type)) {
      toast.error(
        "Invalid file type. Only PDF, Word, ZIP, RAR, Excel, XLSX, CSV, DOC, and DOCX files are allowed."
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    await mutateUploadFile(formData, {
      onSuccess: ({ contextObjects }) => {
        const data = contextObjects[0];
        const objectFile = {
          id: data?.id,
          pubId: data?.pubId,
          file,
          fileImage: handleFileTypeCheck(file),
          showDelete: false,
        };
        setUploadedFile((prevFile) => (prevFile ? [...prevFile, objectFile] : [objectFile]));
        setFieldValue(
          "invoiceFiles",
          values.invoiceFiles ? [...values.invoiceFiles, objectFile] : [objectFile]
        );
      },
    });
  };

  const handleOpenClosePopover = (idx, show = false) => {
    setUploadedFile((prevFile) =>
      prevFile.map((file, index) => {
        if (idx === index) file.showDelete = show;
        return file;
      })
    );
  };

  const handleDeleteFile = (files, idx) => {
    setUploadedFile((prevFile) => prevFile.filter((_, index) => index !== idx));
    setFieldValue(
      "invoiceFiles",
      values.invoiceFiles.filter((_) => _.pubId !== files.pubId)
    );
  };

  const handleFileIcons = (fileType) => {
    let icon;
    switch (fileType) {
      case "pdf":
        icon = PdfIcon;
        break;
      case "excel":
        icon = ExcelIcon;
        break;
      case "csv":
        icon = CsvIcon;
        break;
      case "word":
        icon = WordIcon;
        break;
      case "tiff":
        icon = TiffIcon;
        break;
      case "zip":
        icon = ZipIcon;
        break;
      case "rar":
        icon = RarIcon;
        break;
      case "file":
        icon = FileIcon;
        break;
      default:
        break;
    }
    if (icon) return icon;
    return fileType;
  };

  return (
    <Container>
      <Dropzone onDrop={handleDrop} disabled={isLoading || readOnly} maxFiles={1}>
        {({ getRootProps, getInputProps }) => (
          <FilesContainer>
            {uploadedFile?.length > 0 &&
              uploadedFile?.map((files, index) => (
                <FilesContainer.Files key={files + Math.random() * 1000}>
                  {!readOnly && (
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      key={files?.fileImage}
                      placement="bottom"
                      show={files?.showDelete}
                      onToggle={(show) => handleOpenClosePopover(index, show)}
                      overlay={
                        <Popover id={`popover-positioned-${files?.fileImage}`}>
                          <Popover.Title as="h3">Delete File</Popover.Title>
                          <Popover.Content>
                            <p>Are you sure to delete this file?</p>
                            <div className="d-flex gap-2 justify-content-end">
                              <LyteButton
                                size="sm"
                                variant="desctructive"
                                onClick={() => handleOpenClosePopover(index)}
                              >
                                No
                              </LyteButton>
                              <LyteButton size="sm" onClick={() => handleDeleteFile(files, index)}>
                                Yes
                              </LyteButton>
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <FilesContainer.DeleteButton
                        className="delete-icon-container"
                        style={{ opacity: files.showDelete && "1" }}
                      >
                        <FontAwesomeIcon className="delete-icon" icon={faTrash} />
                      </FilesContainer.DeleteButton>
                    </OverlayTrigger>
                  )}

                  {files?.fileImage && (
                    <FilesContainer.Image
                      src={handleFileIcons(files?.fileImage)}
                      alt="Uploaded"
                      className="img-fluid p-1"
                    />
                  )}
                  {files?.file?.name && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={
                        <Popover>
                          <Popover.Content>
                            <p style={{ fontWeight: "bold" }} className="mb-0 p-2">
                              {files.file.name}
                            </p>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <FilesContainer.Name>
                        <p>{files.file.name}</p>
                      </FilesContainer.Name>
                    </OverlayTrigger>
                  )}
                </FilesContainer.Files>
              ))}
            {isLoading && (
              <FilesContainer.Files className="align-items-center">Loading...</FilesContainer.Files>
            )}
            {!readOnly && (
              <UploaderDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="drop-container">
                  <FontAwesomeIcon icon={faCloudUploadAlt} className="upload-icon" />
                  <p className="upload-text">Drop or click file here</p>
                </div>
              </UploaderDropzone>
            )}
          </FilesContainer>
        )}
      </Dropzone>
    </Container>
  );
};

export default InvoiceDocumentUploader;
