import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import img from "images/illust/errors/404.png";
import { LyteBtn } from "components/common/buttons";

const Error404 = () => (
  <div className="container text-center" style={{ marginTop: "15%" }}>
    <div className="mx-auto my-auto" style={{ width: "500px" }}>
      <h2 className="font-weight-bolder">Uh oh, Page not found.</h2>
      <Image src={img} alt="404" fluid width={400} />
      <p>
        Uh oh, we can't seem to find the page you're looking for. Try again later or contact us if
        the problem persists.
      </p>
      <NavLink to="/">
        <LyteBtn className="btn-lg bg-dark" style={{ width: "250px", padding: "0.75rem 0" }}>
          Back to Home
        </LyteBtn>
      </NavLink>
    </div>
  </div>
);

export default Error404;
