import { memo, useState } from "react";
import { Spinner, Form } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import LytePayLogo from "images/svg/lytepay-logo.svg";
import Loader from "components/common/Loader";
import InvoiceTemplate from "components/common/InvoiceTemplate";
import useApi from "hooks/useApi";

/* TODO: Uncomment when contents API is available for some public facing pages
    by mcometa - LP2-550

import { useContents } from "hooks/contents";

const RejectReasonSelect = ({ ...rest }) => {
  const { data: contents, isLoading } = useContents();
  return (
    <Form.Control as="select" disabled={isLoading} {...rest}>
      {isLoading && <option label="Loading..." value="" />}
      {contents?.rejectReasons?.map(({ key, value }) => (
        <option key={key} label={value} value={value} />
      ))}
    </Form.Control>
  );
};
*/

const InvoiceReject = () => {
  const { identifier } = useParams();
  const history = useHistory();
  const { Public } = useApi();

  const { data: invoice } = useQuery(
    "invoiceDetails",
    async () => {
      const { data } = await Public.get(`public/invoices/${identifier}`);
      return data;
    },
    {
      onSettled: ({ status }) => {
        if (status === "accepted") {
          history.replace(`/invoice/accepted/${identifier}`);
        }

        if (status === "rejected") {
          history.replace(`/invoice/rejected/${identifier}`);
        }
      },
      keepPreviousData: true,
    }
  );

  const [values, setValues] = useState({
    rejectReason: invoice?.rejectReason || "",
    otherReason: "none",
  });

  const {
    mutate: reject,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    async (e) => {
      e.preventDefault();
      document.querySelector("select[name=rejectReason]").reportValidity();
      if (!values.rejectReason) {
        throw Error("No reason selected");
      }
      const res = await Public.post(`public/invoices/${identifier}/reject`, {
        invoiceRef: identifier,
        ...values,
      });
      return res;
    },
    {
      mutationKey: "rejectInvoice",
      onError: (err) => toast.error(`Error rejecting invoice`),
      onSuccess: () => {
        history.replace(`/invoice/rejected/${identifier}`);
      },
    }
  );

  const rejectReasons = [
    ["The amount stated is not as discussed", "The amount stated is not as discussed"],
    ["The item listed is not as discussed", "The item listed is not as discussed"],
    ["I did not request this invoice", "I did not request this invoice"],
    ["Other (Please specify)", "Other"],
  ];

  const isButtonDisabled =
    !(invoice?.status === "pending") || isSuccess || isLoading || !values.rejectReason;

  if (!invoice) {
    return <Loader />;
  }

  if (invoice.status !== "pending") {
    return (
      <div className="container">
        <div className="bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img alt="" src={LytePayLogo} className="image mb-5 col-4 col-md-3 mx-auto" />
            <h6>This invoice is no longer available to be rejected. Please close this page.</h6>
          </div>
        </div>
      </div>
    );
  }

  const ButtonText = memo(() => {
    if (isLoading) {
      <div className="d-flex align-items-center">
        <Spinner animation="border" size="sm" className="mr-2" /> Rejecting...
      </div>;
    }

    if (isError) {
      return "Error. Try again.";
    }

    return <span>Reject {`${invoice.firstName}'s` || null} Invoice</span>;
  }, [isLoading, isError]);

  return (
    <div className="invoice-reject py-5">
      <div className="container">
        <div className="bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img alt="" src={LytePayLogo} className="image mb-5 col-4 col-md-3 mx-auto" />
            <h6>
              You have rejected the invoice from {invoice.fullName ? invoice.fullName : null}.{" "}
              <br />
              Please select the reason below.
            </h6>
            <Form className="my-4 col-lg-8 mx-auto">
              {/*
              TODO: Uncomment when contents API is available for some public facing pages
              by mcometa - LP2-550
              <RejectReasonSelect /> */}
              <Form.Control
                id="rejectReason"
                name="rejectReason"
                as="select"
                required
                disabled={!(invoice.status === "pending") && isSuccess && isLoading}
                value={values.rejectReason}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    rejectReason: e.target.value,
                  }))
                }
              >
                <option value="">Select Reason</option>
                {rejectReasons.map(([label, value]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              {values.rejectReason === "Other" && (
                <Form.Control
                  id="otherReason"
                  name="otherReason"
                  as="textarea"
                  onChange={(e) =>
                    setValues((prev) => ({
                      ...prev,
                      otherReason: e.target.value,
                    }))
                  }
                  cols="30"
                  rows="4"
                  className="mt-2"
                />
              )}
              <div className="mt-4 clearfix">
                <div className="mx-auto">
                  <button
                    className="global-button"
                    type="submit"
                    disabled={isButtonDisabled}
                    onClick={(e) => values.rejectReason && reject(e)}
                  >
                    <ButtonText />
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <InvoiceTemplate invoice={invoice} />
      </div>
    </div>
  );
};

export default InvoiceReject;
