import Kyc from "views/private/kyc";
import OnfidoKyc from "views/private/kyc/onfido/OnfidoKyc";
import OnfidoKycCallback from "views/private/kyc/onfido/OnfidoKycCallback";
import ManualKyc from "views/private/kyc/manual/ManualKyc";
import MyInfoKyc from "views/private/kyc/mi/MyInfoKyc";
import MyInfoKycCallback from "views/private/kyc/mi/MyInfoKycCallback";

export const kyc = [
  { path: "/kyc", exact: true, component: Kyc },
  { path: "/kyc/onfido", exact: true, component: OnfidoKyc },
  { path: "/kyc/onfido/callback", exact: true, component: OnfidoKycCallback },
  { path: "/kyc/manual", exact: true, component: ManualKyc },
  { path: "/kyc/mi", exact: true, component: MyInfoKyc },
  { path: "/kyc/mi/callback", exact: true, component: MyInfoKycCallback },
];
