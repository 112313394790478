import { useQuery } from "react-query";

import useApi from "./useApi";

export const useContents = () => {
  const { Private } = useApi();
  return useQuery(
    ["contents"],
    async () => {
      const { data } = await Private({ method: "get", url: "contents" });
      return data;
    },
    {
      keepPreviousData: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const useLockPeriods = () => {
  const { data: contents } = useContents();
  const res = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < contents?.boostDurations.length; i++) {
    res.push({
      value: {
        lockInPeriod: +contents.boostDurations[i].value,
        rewardAPY: +contents.boostRewards[i].value,
      },
      label: `${contents.boostDurations[i].value} Days at ${contents.boostRewards[i].key}% Yearly Rewards`,
    });
  }
  return res;
};
