import styled from "styled-components";

import Color from "utils/colours";

export const MobileNumberContainer = styled.div`
  position: relative;

  .custom-mobile-phone {
    input {
      padding-left: 74px;
    }
  }

  .country-code {
    font-size: 16px;
    position: absolute;
    left: 1px;
    top: 1px;
    display: flex;
    height: 46px;
    width: 60px;
    padding: 10px 14px;
    align-items: center;
    border: 1px solid transparent;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right-color: ${Color.dark200};
    background-color: ${Color.neutral0};
  }
`;
