import { useEffect, useState } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import toast from "react-hot-toast";

import { useUserDataSingleUse } from "hooks/useUserData";
import Loader from "components/common/Loader";
import { APPROVED, PENDING_SUBMISSION, PENDING_VERIFICATION } from "components/kyc/constants";
import { isFeatureEnabled } from "utils/commonHelpers";

import { definitions } from "./definitions";
import { useAuthorizeMyInfoVerification, useSubmittedMyInfoVerification } from "./service";

const flexRender = (accessor, data, defaultIfEmpty = "N/A") =>
  typeof accessor === "function" ? accessor(data) : data[accessor] || defaultIfEmpty;

const buildAddress = (address) => {
  const arr = [];

  if (address?.block?.value) {
    arr.push(`${address?.block?.value} ${address?.street?.value}`);
  }

  if (address?.floor?.value) {
    arr.push(`#${address?.floor?.value}-${address?.unit?.value} ${address?.building?.value ?? ""}`);
  }

  if (address?.postal?.value) {
    arr.push(`Singapore ${address?.postal?.value}`);
  }

  return arr.length > 0 ? arr.join("\n") : "-";
};

const MyInfoKycCallback = () => {
  const myInfoEnabled = isFeatureEnabled("MYINFO");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = decodeURIComponent(queryParams.get("code"));
  const { replace } = useHistory();
  const [data, setData] = useState();

  const { data: userData, refetch: refetchUserData } = useUserDataSingleUse({
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });
  const { mutate: mutateAuthorizeMyInfoVerification, isLoading: isAuthorizing } =
    useAuthorizeMyInfoVerification();
  const { mutate: mutateSubmittedMyInfoVerification, isLoading: isSubmitting } =
    useSubmittedMyInfoVerification();

  useEffect(() => {
    if (userData) {
      if (userData?.kycStatus === PENDING_SUBMISSION) {
        mutateAuthorizeMyInfoVerification(
          {
            code,
          },
          {
            onSuccess: (data) => {
              if (data) {
                toast.success("Successfully authorized");
                const personalData = {
                  uinfin: data?.uinfin?.value ?? "",
                  name: data?.name?.value ?? "",
                  hanyuPinyinName: data?.hanyuPinyinName?.value ?? "",
                  aliasName: data?.aliasName?.value ?? "",
                  hanyuPinyinAliasName: data?.hanyuPinyinAliasName?.value ?? "",
                  mariedName: data?.mariedName?.value ?? "",
                  sex: data?.sex?.code ?? "",
                  sexDescription: data?.sex?.desc,
                  race: data?.race?.desc ?? "",
                  dateOfBirth: data?.dateOfBirth?.value ?? "",
                  passExpiryDate: data?.passExpiryDate?.value ?? "",
                  passStatus: data?.passStatus?.value ?? "",
                  passType: data?.passType?.desc ?? "",
                  employmentSector: data?.employmentSector?.value ?? "",
                  nationality: data?.nationality?.code ?? "",
                  nationalityDescription: data?.nationality?.desc,
                  country: data?.address?.country?.code ?? "",
                  countryDescription: data?.address?.country?.desc,
                  postalCode: data?.address?.postal?.value ?? "",
                  address: buildAddress(data?.address),
                };
                setData(personalData);
              } else {
                toast.error("Failed to authorize, will be redirected to KYC selection");
                setTimeout(() => {
                  replace("/kyc");
                }, 1000);
              }
            },
          }
        );
      } else if (userData?.kycStatus === PENDING_VERIFICATION) {
        replace("/kyc");
      } else if (userData?.kycStatus === APPROVED) {
        replace("/dashboard");
      }
    }
  }, [code, mutateAuthorizeMyInfoVerification, refetchUserData, replace, userData]);

  const confirmHandler = () => {
    mutateSubmittedMyInfoVerification(
      {},
      {
        onSuccess: () => {
          setTimeout(() => {
            replace("/kyc");
          }, 500);
        },
      }
    );
  };

  if (!myInfoEnabled) {
    return <Redirect to="/kyc" />;
  }

  if (!data || isAuthorizing) {
    return <Loader />;
  }

  return (
    <Container className="py-5">
      <Row className="justify-content-center gap-4 gap-md-0">
        <Col xs={12} md={6}>
          <h3 className="text-center">
            <em>
              <strong>Singpass </strong>
            </em>
            Verification
          </h3>
          <p className="text-center mb-8">
            To proceed further, we kindly request you to confirm that all the details you've
            provided regarding your identity are valid.
          </p>
          <Card>
            <Card.Body>
              <Card.Text>
                <Row className="gy-3">
                  {definitions?.map((definition) => (
                    <Col key={definition} md={6} xs={12}>
                      <p className="mb-0 text-sm font-weight-bold">{definition?.header}</p>
                      <span>{flexRender(definition?.accessor, data, "-")}</span>
                    </Col>
                  ))}
                </Row>
              </Card.Text>
              <Button
                variant="primary"
                className="rounded-pill px-4 d-flex mx-auto"
                onClick={confirmHandler}
                disabled={isSubmitting}
              >
                Confirm Now
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MyInfoKycCallback;
