import { useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import Widget from "ui-lib/components/widget";
import { useUsersPromoCode } from "hooks/user";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";
import FormikInput from "components/form/FormikInput";
import { useUserDataState } from "providers/UserDataProvider";
import { LyteButton } from "components/common/buttons";

const PromoCodeSchema = yup.object().shape({
  code: yup.string().required("This is a required field"),
});

const PromoCodeCard = () => {
  const { userData: user, refetch } = useUserDataState();
  const { mutate: mutatePromoCode } = useUsersPromoCode();

  const handleSubmitPromoCode = useCallback((values) => {
    mutatePromoCode(values, {
      onSuccess: () => {
        refetch();
      },
    });
  }, []);

  return (
    <>
      <Widget
        title={
          <div className="d-flex justify-content-between mb-2">
            <span className="db-subheader">Refferal or Promotion Code</span>
            {user?.referrerCode2 && (
              <span className="d-flex align-items-center ">
                <span className="mr-3 small text-uppercase">{user?.referrerCode2}:</span>

                <InvoiceStatuses status="active" pill />
              </span>
            )}
          </div>
        }
        body={
          <Formik
            initialValues={{ code: "" }}
            validationSchema={PromoCodeSchema}
            enableReinitialize
            style={{ borderRadius: "10px" }}
          >
            {({ values, isValid }) => (
              <form>
                <div className="d-md-flex d-block gap-4">
                  <div className="mb-3 mb-md-0">
                    <FormikInput
                      className="m-0"
                      name="code"
                      placeholder="Enter Code here..."
                      type="text"
                    />
                  </div>
                  <LyteButton
                    type="button"
                    size="sm"
                    disabled={!isValid}
                    onClick={() => handleSubmitPromoCode(values)}
                  >
                    Use Promo
                  </LyteButton>
                </div>
              </form>
            )}
          </Formik>
        }
      />
      <div className="mb-3" />
    </>
  );
};

export default PromoCodeCard;
