import { LyteButton } from "components/common/buttons";

import { ProgressBullet, TooltipBox } from "./styles";

const TooltipTourGuide = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  totalSteps,
}) => {
  const handleButtonText = () => {
    if (isLastStep) return "Finish";
    if (index === 0) return "Start";
    return "Next";
  };

  return (
    <TooltipBox {...tooltipProps}>
      <div>
        {step.title && <h5>{step.title}</h5>}
        {step.content && <div>{step.content}</div>}
      </div>
      <div className="d-flex align-items-center justify-content-center mx-auto my-3">
        {totalSteps?.map((step, stepIndex) => (
          <ProgressBullet key={Math.random() * 1000} active={stepIndex === index} />
        ))}
      </div>
      <div className="d-flex align-items-center justify-content-between pt-1">
        {!isLastStep && (
          <LyteButton {...skipProps} variant="ghost" size="sm">
            Skip
          </LyteButton>
        )}
        <div className="d-flex align-items-center w-100 justify-content-end gap-1">
          {index > 0 && (
            <LyteButton {...backProps} variant="secondary" size="sm">
              Back
            </LyteButton>
          )}
          <LyteButton {...primaryProps} size="sm">
            {handleButtonText()}{" "}
          </LyteButton>
        </div>
      </div>
    </TooltipBox>
  );
};

export default TooltipTourGuide;
