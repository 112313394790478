import { useState } from "react";
import { Chart } from "react-google-charts";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "views/public/signup/components/Button";
import Color from "utils/colours";

import { ProfileContainer } from "./styles";

const ProfileCompletionModal = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const data = [
    ["Progress", "Remaining"],
    ["Progress", 3],
    ["Remaining", 7],
  ];

  const options = {
    pieHole: 0.8,
    pieSliceBorderColor: "transparent",
    pieSliceText: "none",
    legend: "none",
    backgroundColor: "transparent",
    pieStartAngle: 0,
    slices: [{ color: Color.neutral0 }, { color: Color.navi100 }],
    enableInteractivity: false,
  };

  return (
    <ProfileContainer>
      <button
        type="button"
        className="header"
        onClick={() => setIsContentVisible((prevValue) => !prevValue)}
      >
        <div className="header-left">
          <h5 className="font-weight-bold">Complete your profile</h5>
          <p className="m-0">Unlock all features when you complete your profile</p>
        </div>
        <div className="header-right">
          <Chart
            chartType="PieChart"
            data={data}
            className="custom-chart"
            width="120px"
            height="120px"
            options={options}
          />
          <p className="number-counter">30%</p>
        </div>
      </button>
      <div className={`content ${isContentVisible ? "visible" : ""}`}>
        <div className="progress-content">
          <div className="checkmark active">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div>
            <p>Create an account</p>
          </div>
        </div>
        <div className="progress-content">
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div>
            <p>Verify your Identity</p>
            <span>
              You will need to verify your identity before being able to conduct any transactions.
            </span>
          </div>
        </div>
        <div className="progress-content">
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div>
            <p>Add your bank account</p>
            <span>Link your bank account so that we know where to send all your cashouts.</span>
          </div>
        </div>
        <Button variant="link" type="button">
          Need help?
        </Button>
        <Button className="mt-3 w-100">Verify your identity</Button>
      </div>
    </ProfileContainer>
  );
};

export default ProfileCompletionModal;
