import { boost } from "./privateRoutes/boost";
import { cashout } from "./privateRoutes/cashout";
import { faq } from "./privateRoutes/faq";
import { invoice } from "./privateRoutes/invoice";
import { userProfile } from "./privateRoutes/userprofile";
import { kyc } from "./privateRoutes/kyc";
import { advance } from "./privateRoutes/advance";
import { bank } from "./privateRoutes/bank";
import { onboarding } from "./privateRoutes/onboarding";

export const privateRoutes = [
  ...boost,
  ...cashout,
  ...invoice,
  ...userProfile,
  ...faq,
  ...kyc,
  ...advance,
  ...bank,
  ...onboarding,
];
