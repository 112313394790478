import { useMutation } from "react-query";

import useApi from "hooks/useApi";

export const useRequestOtp = (extraParams) => {
  const { Public } = useApi();
  return useMutation(
    async (payload) => {
      const { data } = await Public({
        method: "post",
        url: `lyte-assistant/user-profiles/otp/request`,
        data: payload,
      });
      return data;
    },
    {
      ...extraParams,
    }
  );
};

export const useValidateOtp = (id, extraParams) => {
  const { Public } = useApi();
  return useMutation(
    async (payload) => {
      const { data } = await Public({
        method: "post",
        url: `lyte-assistant/user-profiles/otp/validate`,
        data: payload,
      });
      return data;
    },
    {
      ...extraParams,
    }
  );
};
