import { faChevronDown, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Image, OverlayTrigger, Popover } from "react-bootstrap";

import LyteBtn from "ui-lib/components/buttons/LyteBtn";
import Range from "ui-lib/components/range";
import { useGetBoostSettings, usePostNewBoostSettings } from "hooks/boost";
import { useContents, useLockPeriods } from "hooks/contents";
import Loader from "components/common/Loader";
import BoostIcon from "images/svg/boost-icon.svg";
import FormikCheckbox from "components/form/FormikCheckbox";
import Color from "utils/colours";
import { formatCurrency } from "utils/commonHelpers";

import { BoostSettingContainer, PopoverStyled } from "./styles";
import WidgetSettingsSchema from "./WidgetSettings.schema";

const WidgetSettings = ({ user }) => {
  const [lyteBoostInputValue, setLyteBoostInputValue] = useState(0);
  const [show, setShow] = useState(false);

  const { data: boostSettings } = useGetBoostSettings({
    onSuccess: (data) => setLyteBoostInputValue((+data.value * 100).toFixed(0) || 0),
  });

  const { mutate, isLoading, isSuccess } = usePostNewBoostSettings();

  const { data: contents } = useContents();

  const DURATION_REWARDS_OPTIONS = contents?.boostDurations.map(
    (e, i) => `${e.key} Days at ${contents.boostRewards[i].key}% Yearly Rewards`
  );

  const LOCK_IN_PERIOD_REWARDS_APY_OPTIONS = useLockPeriods();
  const rewardsApy = LOCK_IN_PERIOD_REWARDS_APY_OPTIONS.map((item) => +item.value.rewardAPY);
  const lockInPeriods = LOCK_IN_PERIOD_REWARDS_APY_OPTIONS.map((item) => +item.value.lockInPeriod);

  return !contents || !boostSettings ? (
    <Loader />
  ) : (
    <BoostSettingContainer>
      {!!boostSettings?.value && (
        <BoostSettingContainer.Banner>
          <Image src={BoostIcon} />
          <p>
            {`Current: Boosting ${lyteBoostInputValue}% of all invoices for
            ${
              DURATION_REWARDS_OPTIONS[
                contents?.boostDurations?.findIndex(
                  (item) => +item.value === boostSettings.duration
                )
              ]
            }`}
          </p>
        </BoostSettingContainer.Banner>
      )}
      <h4 className="font-weight-bold">Default Boost Settings</h4>
      <p>You can change this anytime before the invoice gets paid.</p>
      <BoostSettingContainer.Splitter />
      <Formik
        initialValues={{ ...boostSettings, value: boostSettings?.value || 0 }}
        onSubmit={mutate}
        enableReinitialize
        validationSchema={WidgetSettingsSchema({ lockInPeriods, rewardsApy })}
        validateOnMount
      >
        {({ setFieldValue, values, isValid }) => (
          <Form className="form-inline">
            <div className="mb-3 mt-4 w-100 boots-settings-boost-range">
              <div className="form-group">
                <OverlayTrigger
                  placement="top-start"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <PopoverStyled>
                      <Popover.Content>
                        {`The minimum boost amount is ${formatCurrency(
                          contents?.minimumValues?.minimumBoostValue
                        )}`}
                      </Popover.Content>
                    </PopoverStyled>
                  }
                >
                  <div className="d-flex">
                    <h6 className="mb-3">Amount I want to Boost:</h6>
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faQuestionCircle}
                      style={{ color: Color.navi500 }}
                    />
                  </div>
                </OverlayTrigger>
                <Range
                  value={lyteBoostInputValue}
                  onChange={(rangeValues) => {
                    // Range values are always percent value.
                    setLyteBoostInputValue(+rangeValues[0].toFixed(0));
                    // Convert to percentage value for backend payload use
                    setFieldValue("value", +rangeValues[0].toFixed(0) / 100);
                  }}
                  renderPopoverText={(value) => value}
                  onFinalChange={() => {
                    setShow(false);
                  }}
                  className="w-100"
                  step={1}
                  min={0}
                  max={100}
                  suffix="%"
                />
                <p className="small-text mt-5">
                  Any unBoosted amount will be transferred to your linked bank account.
                </p>
              </div>
            </div>
            <BoostSettingContainer.SelectContainer id="boots-settings-boost-duration">
              <h6>Boost duration and rate:</h6>
              <BoostSettingContainer.Select
                value={contents?.boostDurations?.findIndex(
                  (item) => +item.value === values.duration
                )}
                defaultValue="-1"
                id="boostDurationReward"
                className="form-control"
                onChange={(e) => {
                  const targetValue = e.target.value;
                  setTimeout(() => {
                    setFieldValue(
                      "duration",
                      +(contents?.boostDurations[targetValue].value || null)
                    );
                    setFieldValue("rewards", +(contents?.boostRewards[targetValue].value || null));
                  }, 0);
                }}
              >
                <option value="-1" disabled>
                  --Select duration and rate--
                </option>
                {DURATION_REWARDS_OPTIONS?.map((item, index) => (
                  <option value={index} key={item} label={item} />
                ))}
              </BoostSettingContainer.Select>
              <div className="icon-container">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </BoostSettingContainer.SelectContainer>

            <BoostSettingContainer.Splitter className="mt-3 mb-4" />
            <div className="w-100">
              {user?.numberOfTrialBoostRemaining > 0 && (
                <OverlayTrigger
                  placement="top-start"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <PopoverStyled>
                      <Popover.Content>
                        By choosing this option, you are using your one-time trial of Boost for 45
                        days(4% p.a) to your next collected payment. Should you choose to reboost,
                        it will follow the current default Boost setting after 45 days.
                      </Popover.Content>
                    </PopoverStyled>
                  }
                >
                  <BoostSettingContainer.FormSwitch className="form-group">
                    <Field
                      id="optInForTrialBoost"
                      name="optInForTrialBoost"
                      type="checkbox"
                      className="mt-1"
                    />
                    <BoostSettingContainer.LabelSwitch
                      className="justify-content-between"
                      htmlFor="optInForTrialBoost"
                      id="boots-settings-boost-trial"
                    >
                      <div className="d-flex align-items-center gap-2 w-75">
                        <p className="m-0">
                          Apply a one-time trial of Boost for 45 Days at 4% p.a to my next paid
                          invoice.
                          <FontAwesomeIcon className="icon ml-2" icon={faQuestionCircle} />
                        </p>
                      </div>
                      <div className="switch" />
                    </BoostSettingContainer.LabelSwitch>
                    <ErrorMessage name="optInForTrialBoost">
                      {(msg) => <small className="text-danger mt-1">{msg}</small>}
                    </ErrorMessage>
                  </BoostSettingContainer.FormSwitch>
                </OverlayTrigger>
              )}
              <OverlayTrigger
                placement="top-start"
                delay={{ show: 100, hide: 200 }}
                overlay={
                  <PopoverStyled>
                    <Popover.Content>
                      By selecting this option, I intend to reBoost my invoices upon their maturity
                      date. I acknowledge that any early withdrawal will deactivate this setting,
                      and I will be required to reauthorize Auto ReBoost every 6 months.
                    </Popover.Content>
                  </PopoverStyled>
                }
              >
                <BoostSettingContainer.FormSwitch className="form-group">
                  <Field id="autoReboost" name="autoReboost" type="checkbox" />
                  <BoostSettingContainer.LabelSwitch
                    id="boots-settings-auto-reboost"
                    className="justify-content-between"
                    htmlFor="autoReboost"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <p className="m-0">Auto-reBoost</p>
                      <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
                    </div>
                    <div className="switch" />
                  </BoostSettingContainer.LabelSwitch>
                  <ErrorMessage name="autoReboost">
                    {(msg) => <small className="text-danger mt-1">{msg}</small>}
                  </ErrorMessage>
                </BoostSettingContainer.FormSwitch>
              </OverlayTrigger>

              <FormikCheckbox name="agreeReboost">
                <span>
                  By electing to re-Boost my invoices and/or Rewards, I agree to assign all my
                  rights and benefits under the invoices and/or Rewards to Lytepay Pte Ltd and in
                  return earn further Rewards.
                </span>
              </FormikCheckbox>
            </div>
            <BoostSettingContainer.Splitter className="my-3" />
            <LyteBtn
              className="w-100 mb-3"
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
              success={isSuccess}
              style={{ fontWeight: 700 }}
            >
              Set as default
            </LyteBtn>
          </Form>
        )}
      </Formik>
    </BoostSettingContainer>
  );
};

export default WidgetSettings;
