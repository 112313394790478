import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";

import { StyledField, StyledLabel } from "./styles";

const FormikDropdown = ({
  label,
  children,
  lists = [],
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  isStacked,
  description,
  withoutDefaultOption,
  ...restProps
}) => {
  const [field, meta] = useField(name);

  return (
    <StyledField
      className={`field-container ${className} ${
        meta?.touched && meta?.error ? "form-invalid" : ""
      } ${meta?.value && meta?.touched && !meta?.error ? "form-success" : ""}`}
      isStacked={isStacked}
    >
      {label && <StyledLabel htmlFor={name}>{`${label} ${isRequired ? "*" : ""}`}</StyledLabel>}
      <div className="position-relative">
        <Field
          as="select"
          id={name}
          name={name}
          className="form-control select-control"
          data-cypress-id={`cypress-input-dropdown-${name}`}
          disabled={disabled}
          placeholder={placeholder}
          {...restProps}
        >
          {children ||
            (lists && lists?.length > 0 ? (
              <>
                {!withoutDefaultOption && (
                  <option disabled selected value="">
                    - Choose One -
                  </option>
                )}
                {lists?.map((list) => (
                  <option key={`${list?.id}-${list?.name}`} value={list?.id}>
                    {list?.name}
                  </option>
                ))}
              </>
            ) : (
              <option disabled>No Value</option>
            ))}
        </Field>
        <div className={`icon-container ${disabled && "disabled"}`}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && (
        <ErrorMessage
          data-cypress-id={`cypress-dropdown-${name}-error-message`}
          name={name}
          component="div"
          className="error-text"
        />
      )}
    </StyledField>
  );
};

FormikDropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  lists: PropTypes.shape(),
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  isStacked: PropTypes.bool,
  withoutDefaultOption: PropTypes.bool,
};

FormikDropdown.defaultProps = {
  label: "",
  children: null,
  lists: [],
  className: "",
  type: "input",
  placeholder: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
  isStacked: false,
  withoutDefaultOption: false,
};

export default FormikDropdown;
