import styled from "styled-components";

import Color from "utils/colours";

export const BoostCalculatorContainer = styled.div`
  padding: 24px;
  background-color: ${Color.integrity100};
  border-radius: 16px;
  width: 100%;
  color: ${Color.dark900};
  .small-text {
    font-size: 13px;
  }
`;

BoostCalculatorContainer.Input = styled.input`
  position: relative;
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  border: 1px solid ${(props) => props.theme.color.borderSecondary};
  border-radius: 8px;
  padding: 12px 16px 12px 48px;
  margin-bottom: 24px;
`;
BoostCalculatorContainer.InputContainer = styled.div`
  position: relative;
  &::after {
    content: "${(props) => props?.symbol || ""}";
    position: absolute;
    top: 48%;
    left: 14px;
    transform: translateY(-50%);
    z-index: 1000;
  }
`;

BoostCalculatorContainer.Splitter = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.borderSecondary};
`;

BoostCalculatorContainer.Select = styled.select`
  width: 100% !important;
  cursor: pointer;
  padding-right: 30px;
`;

BoostCalculatorContainer.SelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 50px;
  .icon-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: ${Color.dark300};
  }
`;

BoostCalculatorContainer.RewardContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${Color.integrity300};
`;
