/* eslint-disable import/no-extraneous-dependencies */
import "draft-js/dist/Draft.css";

import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import { ErrorMessage, useField } from "formik";
import { convertFromHTML, convertToHTML } from "draft-convert";
import createAutoListPlugin from "draft-js-autolist-plugin";
import createToolbarPlugin, { Separator } from "@draft-js-plugins/static-toolbar";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from "@draft-js-plugins/buttons";

import { StyledField, StyledLabel, StyledToolbar } from "./styles";

const autoListPlugin = createAutoListPlugin();

const FormikTexteditor = ({
  label,
  type,
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  description,
  forceUpdate,
  ...restProps
}) => {
  const [field, meta, helpers] = useField(name);
  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, autoListPlugin];
    return {
      plugins,
      Toolbar,
    };
  });

  const [editorState, setEditorState] = useState(
    meta?.value
      ? EditorState.createWithContent(convertFromHTML(meta.value))
      : EditorState.createEmpty()
  );
  const editorRef = useRef(null);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const html = convertToHTML(newEditorState.getCurrentContent());
    helpers.setValue(html);
  };

  const handleFormControlClick = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  useEffect(() => {
    const { value } = meta;
    if (value) setEditorState(EditorState.createWithContent(convertFromHTML(value)));
  }, [forceUpdate]);

  return (
    <StyledField onClick={handleFormControlClick}>
      {label && <StyledLabel htmlFor={name}>{`${label}${isRequired ? "*" : ""}`}</StyledLabel>}
      <div className="position-relative">
        <div className="form-control editor" data-cypress-id={`cypress-texteditor-${name}`}>
          <Editor
            editorKey={name}
            placeholder={placeholder}
            editorState={editorState}
            onChange={(state) => handleEditorChange(state)}
            plugins={plugins}
            // eslint-disable-next-line no-return-assign
            ref={(editor) => (editorRef.current = editor)}
            {...restProps}
          />
          <Toolbar>
            {(externalProps) => (
              <StyledToolbar toolbarTheme={externalProps?.theme}>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <Separator {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </StyledToolbar>
            )}
          </Toolbar>
        </div>
      </div>
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && (
        <ErrorMessage
          data-cypress-id={`cypress-texteditor-${name}-error-message`}
          name={name}
          component="div"
          className="error-text"
        />
      )}
    </StyledField>
  );
};

FormikTexteditor.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  description: PropTypes.string,
  forceUpdate: PropTypes.bool, // assuming forceUpdate is a function
};

FormikTexteditor.defaultProps = {
  placeholder: "",
  className: "",
  disabled: false,
  isRequired: false,
  description: "",
  forceUpdate: false,
};

export default FormikTexteditor;
