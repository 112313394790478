import * as Yup from "yup";

import { allCountries } from "utils/commonHelpers";

const MAX_FILE_SIZE = 2000000;
const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  document: ["pdf"],
};

const VALID_ISO2_COUNTRY_CODES = allCountries.map((item) => item.iso2.toUpperCase());

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1;
}

export const manualKycValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  dateOfBirth: Yup.string().required("Date of birth is required"),
  gender: Yup.mixed()
    .required("Please select gender")
    .oneOf(["M", "F"], "This is not a valid gender"),
  kycDocumentNumber: Yup.string().required("ID number is required"),
  citizenship: Yup.mixed()
    .required("Please select citizenship")
    .oneOf(["ID", "SG"], "This is not a valid citizenship"),
  kycDocumentType: Yup.mixed()
    .required("Please select document type")
    .oneOf(
      ["Singapore Pink IC", "Singapore Blue IC", "Work Pass", "Passport"],
      "This is not a valid document type"
    ),
  addressLine1: Yup.string()
    .required("Address line 1 is required")
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure(),
  addressLine2: Yup.string()
    .required("Address line 2 is required")
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure(),
  addressCountry: Yup.mixed()
    .required("Please select country")
    .oneOf(VALID_ISO2_COUNTRY_CODES, "This is not a valid country code"),
  addressCity: Yup.string()
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure()
    .when("addressCountry", {
      is: (val) => val !== "SG",
      then: Yup.string().required("Country is required"),
    }),
  addressState: Yup.string()
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure()
    .when("addressCountry", {
      is: (val) => val !== "SG",
      then: Yup.string().required("State is required"),
    }),
  postalCode: Yup.string()
    .required("Postal code is required")
    .when("country", {
      is: "Singapore",
      then: Yup.string().matches(/^[0-9]{6}$/g, "Invalid postal code"),
    }),
  frontSideImage: Yup.mixed()
    .required("Please upload a valid front side image")
    .test("is-valid-type", "Not a valid image type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 2MB",
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
  backSideImage: Yup.mixed().when("kycDocumentType", {
    is: (val) => val !== "Passport",
    then: Yup.mixed()
      .required("Please upload a valid back side image")
      .test("is-valid-type", "Not a valid image type", (value) =>
        isValidFileType(value && value.name.toLowerCase(), "image")
      )
      .test(
        "is-valid-size",
        "Max allowed size is 2MB",
        (value) => value && value.size <= MAX_FILE_SIZE
      ),
  }),
  faceImage: Yup.mixed()
    .required("Please upload your valid selfie image")
    .test("is-valid-type", "Not a valid image type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 2MB",
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
  billImage: Yup.mixed()
    .required("Please upload your valid bill image/document")
    .test(
      "is-valid-type",
      "Not a valid image/document type",
      (value) =>
        isValidFileType(value && value.name.toLowerCase(), "image") ||
        isValidFileType(value && value.name.toLowerCase(), "document")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 2MB",
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
});
