import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "./useApi";

export const useSaveBankAccount = (options) => {
  const { Private } = useApi();

  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "users/bankAccount",
        data: values,
      });
      toast.promise(promise, {
        loading: "Saving data",
        success: `Success saving bank account`,
        error: `Error when saving bank account`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};
