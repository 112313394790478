import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import img from "images/illust/errors/401.png";
import { LyteBtn } from "components/common/buttons";

const Error401 = () => (
  <div className="container text-center" style={{ marginTop: "15%" }}>
    <div className="mx-auto my-auto" style={{ width: "500px" }}>
      <h2 className="font-weight-bolder">Error. Page Unauthorised.</h2>
      <Image src={img} alt="401" fluid width={400} />
      <h5 className="my-4">
        You are not authorised to access this page. Please contact the administrator if problem
        continues.
      </h5>
      <NavLink to="/">
        <LyteBtn className="btn-lg bg-dark" style={{ width: "250px", padding: "0.75rem 0" }}>
          Back to Home
        </LyteBtn>
      </NavLink>
    </div>
  </div>
);

export default Error401;
