import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";

import SelectDiscountType from "components/form/SelectDiscountType";
import { LyteButton } from "components/common/buttons";
import { withCharacterCounter } from "ui-lib/components/input";
import { formatCurrency } from "utils/commonHelpers";
import Color from "utils/colours";
import { useOnClickOutside } from "hooks/useOnClickOutside";

import { AMOUNT, NOWITHHOLDINGTAX, PERCENTAGE } from "../constants";
import { TotalContainer } from "../InvoiceDetailTable/styles";

const FieldWithCharacterCounter = withCharacterCounter(Field);

const InvoiceWithholdingTax = ({
  amounts,
  setAmounts,
  readOnly,
  errors,
  values,
  setFieldValue,
}) => {
  const withholdingTaxValueRef = useRef();
  const popOverRef = useRef();
  const [show, setShow] = useState(false);

  useOnClickOutside(popOverRef, () => setShow(false));

  const onAddWithholdingTaxClick = (e) => {
    e.preventDefault();
    setFieldValue("withholdingTax", {
      type: values?.withholdingTax.type === NOWITHHOLDINGTAX ? AMOUNT : values?.withholdingTax.type,
      value: values?.withholdingTax.value,
      description: values?.withholdingTax.description,
    });

    setShow(!show);

    if (!show) {
      setTimeout(() => {
        withholdingTaxValueRef.current.focus();
      }, 200);
    }
  };

  const calculateAndSetAmounts = (isDelete = false) => {
    const withholdingTaxAmount = () => {
      switch (values?.withholdingTax?.type?.toLowerCase()) {
        case PERCENTAGE:
          return (
            ((amounts?.subtotal || 0) - (amounts?.discountAmount || 0)) *
            ((values?.withholdingTax?.value || 0) / 100)
          );

        default:
          return values?.withholdingTax?.value || 0;
      }
    };

    setAmounts((prevVal) => ({
      ...prevVal,
      withholdingTaxAmount: isDelete ? 0 : withholdingTaxAmount(),
    }));
  };

  const onApplyWithholdingTaxClick = () => {
    calculateAndSetAmounts();
    setShow(false);
  };

  const onRemoveWithholdingTaxClick = () => {
    setFieldValue("withholdingTax", {
      type: NOWITHHOLDINGTAX,
      value: "",
      description: "",
    });

    calculateAndSetAmounts(true);
    setShow(false);
  };

  useEffect(() => {
    calculateAndSetAmounts();
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return readOnly ? (
    values?.withholdingTax?.value ? (
      <div className="clearfix">
        <div className="d-flex flex-row justify-content-between align-items-start gap-2">
          <p className="d-flex flex-column m-0 text-left align-items-start">
            <small className="fw-600 m-0">
              Withholding Tax{" "}
              {values?.withholdingTax?.type?.toLowerCase() === PERCENTAGE &&
                `(${values?.withholdingTax?.value}%)`}
            </small>
            {values?.withholdingTax?.description && (
              <TotalContainer.Description className="font-italic">
                {values?.withholdingTax?.description}
              </TotalContainer.Description>
            )}
          </p>

          <h6 className="fw-600 m-0 text-nowrap" style={{ color: Color.integrity800 }}>
            (
            {`${values?.currency || ""} ${formatCurrency(
              amounts?.withholdingTaxAmount || values?.withholdingTaxAmount,
              false
            )}`}
            )
          </h6>
        </div>
      </div>
    ) : (
      <div />
    )
  ) : (
    <div className="clearfix">
      <OverlayTrigger
        show={show}
        trigger="click"
        placement={window.innerWidth < 768 ? "top" : "left"}
        overlay={
          <Popover id="popover-component">
            <Popover.Content ref={popOverRef}>
              <div className="form-group row mb-2">
                <label
                  htmlFor="withholdingTaxValue"
                  className="col-4 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  Withholding Tax
                </label>
                <div className="col-5 pl-0 pr-2">
                  <Field
                    id="withholdingTaxValue"
                    innerRef={withholdingTaxValueRef}
                    type="number"
                    name="withholdingTax.value"
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    min="0"
                  />
                </div>
                <div className="col-3 pl-0">
                  <Field name="withholdingTax.type">
                    {({ field }) => <SelectDiscountType {...field} style={{ fontSize: "14px" }} />}
                  </Field>
                </div>
              </div>
              <div className="form-group mb-2">
                <div className="offset-sm-4">
                  <ErrorMessage name="withholdingTax.value">
                    {(msg) => <small className="text-danger">{msg}</small>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="form-group mb-2">
                <FieldWithCharacterCounter
                  name="withholdingTax.description"
                  className="form-control"
                  placeholder="Description (optional)"
                  style={{ fontSize: "14px" }}
                  separator="/"
                  maxLength={100}
                />
              </div>
              <LyteButton
                className="w-100"
                size="sm"
                onClick={onApplyWithholdingTaxClick}
                disabled={!!errors?.withholdingTax?.value || !!errors?.withholdingTax?.description}
              >
                Apply Withholding Tax
              </LyteButton>
            </Popover.Content>
          </Popover>
        }
      >
        <div className="w-100">
          {amounts?.withholdingTaxAmount ? (
            <div className="d-flex flex-row justify-content-between align-items-start gap-2">
              <p className="d-flex flex-column m-0 text-left align-items-start">
                <LyteButton
                  variant="ghost"
                  size="sm"
                  className="text-left"
                  onClick={onAddWithholdingTaxClick}
                >
                  Withholding Tax
                  {values?.withholdingTax.type.toLowerCase() === PERCENTAGE &&
                    ` (${values?.withholdingTax.value}%)`}
                </LyteButton>
                {values?.withholdingTax?.description && (
                  <TotalContainer.Description className="font-italic">
                    {values?.withholdingTax.description}
                  </TotalContainer.Description>
                )}
              </p>

              <div className="d-flex align-items-center text-nowrap">
                <LyteButton
                  size="sm"
                  variant="ghost"
                  onClick={onRemoveWithholdingTaxClick}
                  title="Remove withholdingTax"
                  style={{ color: Color.empathy500 }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </LyteButton>
                (
                {`${values?.currency || ""} ${formatCurrency(
                  amounts?.withholdingTaxAmount,
                  false
                )}`}
                )
              </div>
            </div>
          ) : (
            <LyteButton variant="ghost" onClick={onAddWithholdingTaxClick}>
              Add Withholding Tax
            </LyteButton>
          )}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default InvoiceWithholdingTax;
