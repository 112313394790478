import { useState } from "react";
import { Button, Col, Image, Modal } from "react-bootstrap";
import { useFormikContext } from "formik";

import lytePayLogo from "images/svg/lytepay-logo.svg";
import { formatCurrency } from "utils/commonHelpers";

const AdvanceModal = ({ disabled, totalTransferred, remainingAmount, processingFeeToShow }) => {
  const [show, setShow] = useState(false);
  const { submitForm, values, isSubmitting, isValid } = useFormikContext();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className="w-100 py-3 my-3"
        onClick={handleShow}
        style={{ borderRadius: "10px" }}
        disabled={disabled}
      >
        ADVANCE
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        style={{ borderRadius: "1.3rem" }}
      >
        <Modal.Body className="p-5">
          <Image src={lytePayLogo} alt="" style={{ width: "150px" }} />
          <div>
            <Col className="d-flex justify-content-between my-2 p-0">
              <p className="pt-3">Confirm advance details</p>
            </Col>
            <Col className="d-flex justify-content-between my-2 p-0">
              <h5>Amount Advance</h5>
              <h5>{formatCurrency(values?.advanceAmount)}</h5>
            </Col>
            <Col className="d-flex justify-content-between border-bottom my-2 p-0">
              <h5>Processing Fee</h5>
              <h5>{processingFeeToShow}</h5>
            </Col>
            {values?.advanceFeeAddOn ? (
              <Col className="d-flex justify-content-between border-bottom my-2 p-0">
                <h5>Additional Fee</h5>
                <h5>{formatCurrency(values?.advanceFeeAddOn)}</h5>
              </Col>
            ) : (
              ""
            )}
            <Col className="d-flex justify-content-between my-2 p-0">
              <p>You will receive</p>
              <p>{formatCurrency(totalTransferred)}</p>
            </Col>
          </div>
          <div className="d-flex justify-content-center pt-4">
            <Button
              variant="link"
              className="text-danger py-3 px-4 w-50"
              onClick={() => setShow(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              className="py-3 px-4 w-50"
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdvanceModal;
