import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import useApi from "hooks/useApi";
import { formatBytes } from "utils/commonHelpers";

import { LyteBtn } from "./buttons";

export const ImageContainer = styled.div`
  display: grid;
  place-items: center;
  border: 2px dashed grey;
  padding: 10px;
  min-height: 150px;
  border-radius: 5px;
`;

const Image = styled.img`
  height: 150px;
  object-fit: contain;
  width: 100%;
`;

const UploadPreviewContainer = styled.div`
  display: grid;
  place-items: center;
  border: 2px dashed grey;
  padding: 10px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

const FileUpload = ({
  id,
  userSub,
  renderLogo,
  maxFileSizeInBytes,
  fileContentTypeAllowed,
  fileFormatAllowed,
}) => {
  const [file, setFile] = useState({ upload: "", preview: "" });
  const { Private } = useApi();

  const { mutate, isLoading, isSuccess } = useMutation(
    async () => {
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file.preview);
      formData.append("id", userSub);
      await Private({
        method: "post",
        url: "users/submitLogo",
        data: formData,
      });
    },
    {
      onSuccess: () => {
        setFile((p) => ({ upload: p.preview, preview: "" }));
      },
    }
  );

  const fileSizeExceed = (file.preview.size || 0) > maxFileSizeInBytes;
  const fileTypeWrong = !fileContentTypeAllowed.includes(file.preview.type);

  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-6 col-md-5 mb-4 mb-sm-0">
          <h6>
            <u>Preview Logo</u>
          </h6>
          <UploadPreviewContainer>
            {file.preview ? (
              <>
                <Image src={URL.createObjectURL(file.preview)} alt={`${id} upload-preview`} />
                <p
                  style={{
                    width: "300px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                  }}
                >
                  {file.preview.name}
                </p>
                <div className={`m-0 ${fileTypeWrong && "text-danger"}`}>
                  {fileTypeWrong && <FontAwesomeIcon icon={faExclamationTriangle} />}{" "}
                  {file.preview.type}
                  {fileTypeWrong && (
                    <p className="small text-info">{fileFormatAllowed.join(", ")} only</p>
                  )}
                </div>
                <div className={`m-0 ${fileSizeExceed && "text-danger"}`}>
                  {fileSizeExceed && <FontAwesomeIcon icon={faExclamationTriangle} />}{" "}
                  {formatBytes(file.preview.size)}
                  {fileSizeExceed && (
                    <p className="small text-info">
                      Max file size: {formatBytes(maxFileSizeInBytes)}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div className="text-center">
                <em className="text-secondary py-4">Logo here.</em>
                <p className="m-0">Max file size: {formatBytes(maxFileSizeInBytes)}</p>
                <p className="m-0">{fileFormatAllowed.join(", ")} only</p>
              </div>
            )}
          </UploadPreviewContainer>
          <div className="d-flex justify-content-between mt-3">
            <label htmlFor={id} className="global-button outline py-2 m-0">
              Choose File
            </label>
            <input
              id={id}
              name={id}
              hidden
              type="file"
              className="form-file"
              max={1000000}
              accept={fileFormatAllowed.join(", ")}
              onChange={(e) => {
                if (e.target.files.length === 0) {
                  // do nothing
                } else {
                  setFile((p) => ({
                    ...p,
                    preview: e.target.files[0],
                  }));
                }
              }}
            />

            {isSuccess && !file.preview ? (
              <div className="global-button green">Done!</div>
            ) : (
              <LyteBtn disabled={isLoading || fileSizeExceed || fileTypeWrong} onClick={mutate}>
                {isLoading ? "Uploading..." : "Upload"}
              </LyteBtn>
            )}
          </div>
        </div>

        <div className="col-sm-6 col-md-5">
          <h6>
            <u>Current Logo</u>
          </h6>
          <ImageContainer>{renderLogo && renderLogo(file)}</ImageContainer>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

FileUpload.defaultProps = {
  maxFileSizeInBytes: 1000000,
  fileContentTypeAllowed: ["image/png", "image/jpeg"],
  fileFormatAllowed: [".png", ".jpeg", ".jpg"],
};
