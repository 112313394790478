/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";

import { allCountries } from "utils/commonHelpers";

import {
  BUSINESSS_NAME_LIMIT,
  AMOUNT,
  PERCENTAGE,
  NODISCOUNT,
  NOWITHHOLDINGTAX,
} from "./constants";

export const InvoiceWizardSchema = (params) => {
  const { isMobileVerified, isEmailVerified, isKycApproved } = params || {};

  return Yup.object().shape({
    clientName: Yup.string()
      .required("Client Name is required")
      .min(2, "The characters minimum length is ${min}"),
    businessName: Yup.string().max(BUSINESSS_NAME_LIMIT, "The characters maximum length is ${max}"),
    email: Yup.string()
      .nullable()
      .test("required", "Email is required", function (value) {
        return value !== null && this.resolve(value) !== "";
      })
      .required("Email is required")
      .email("Invalid email address format"),
    mobileCountryCode: Yup.string()
      .matches(/^[0-9]*$/, "Invalid mobile country code")
      .when(["mobileNumber"], {
        is: (mobileNumber) => mobileNumber,
        then: Yup.string().required("Mobile Country Code is required"),
      }),
    mobileNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^[\d-]*$/, "Invalid Phone Number"),
    addressLine1: Yup.string().max(100, "The characters maximum length is ${max}").ensure(),
    addressLine2: Yup.string().max(100, "The characters maximum length is ${max}").ensure(),
    country: Yup.string().oneOf(
      allCountries.map((country) => country.name),
      "Selected country is not in the list of allow countries"
    ),
    postalCode: Yup.string().when("country", {
      is: "Singapore",
      then: Yup.string().matches(/^[0-9]{6}$/g, "Invalid postal code"),
    }),
    invoiceNumber: Yup.string()
      .required()
      .matches(
        /^[A-Za-z0-9_/-]*$/g,
        "Invalid invoice number. Only alphanumeric characters, underscores (_), dashes (-) and slashes (/) are allowed."
      )
      .max(30, "The characters maximum length is ${max}"),
    projectTitle: Yup.string().max(100, "The characters maximum length is ${max}"),
    issuedDateTime: Yup.string()
      .required()
      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "Invalid date format"),
    dueDateTime: Yup.string()
      .required()
      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "Invalid date format"),
    paymentDueDays: Yup.number()
      .min(0, "Value must between ${min} and 100")
      .max(100, "Value must between 0 and ${max}")
      .required(),
    currency: Yup.string().required().oneOf(["SGD", "MYR", "IDR"], "Not supported currency"),
    lateFeeRate: Yup.string().oneOf(["0", "2", "3", "4", "5", "6", "7", "8"], "Invalid late fee"),
    invoiceType: Yup.string().oneOf(["LyteCollect", "SelfCollect"], "Invalid Invoice Payment Type"),
    invoiceItems: Yup.array()
      .of(
        Yup.object().shape({
          itemName: Yup.string().required("Item is required"),
          description: Yup.string(),
          quantity: Yup.number()
            .required("Quantity is required")
            .min(1, "Quantity must at least 1"),
          rate: Yup.number()
            .required("Price is required")
            .min(0)
            .max(999999999, "Price must not exceed 99,999,999,999"),
          total: Yup.number(),
        })
      )
      .min(1, "Please insert at least one item"),
    agreeAuthClause:
      isMobileVerified && isEmailVerified && isKycApproved
        ? Yup.boolean().oneOf([true], "This is a required field.")
        : Yup.boolean(),
    agreeProhibitClause:
      isMobileVerified && isEmailVerified && isKycApproved
        ? Yup.boolean().oneOf([true], "This is a required field.")
        : Yup.boolean(),
    agreeCollectionClause:
      isMobileVerified && isEmailVerified && isKycApproved
        ? Yup.boolean().oneOf([true], "This is a required field.")
        : Yup.boolean(),
    discount: Yup.object().shape({
      type: Yup.string().oneOf([AMOUNT, PERCENTAGE, NODISCOUNT], "Invalid discount type"),
      value: Yup.number()
        .min(0, "Negative numbers are not allowed")
        .when("type", {
          is: (type) => type.toLowerCase() === PERCENTAGE,
          then: Yup.number().max(100, "You can only enter a maximum of 100 for % discount"),
        })
        .nullable(true),
      description: Yup.string().max(100).nullable(true),
    }),
    sendReminderEmail: Yup.boolean(),
    notes: Yup.string(),
    showAddress: Yup.boolean(),
    ccToSelf: Yup.boolean(),
    ccEmails: Yup.array().of(Yup.string().email("Invalid email address format")),
    businessContactPersonId: Yup.number().nullable(),
    businessRegistrationCode: Yup.string().nullable(),
    invoiceFiles: Yup.array().nullable(),
    files: Yup.array().nullable(),
    withholdingTax: Yup.object().shape({
      type: Yup.string().oneOf(
        [AMOUNT, PERCENTAGE, NOWITHHOLDINGTAX],
        "Invalid Withholding Tax type"
      ),
      value: Yup.number()
        .min(0, "Negative numbers are not allowed")
        .when("type", {
          is: (type) => type?.toLowerCase() === PERCENTAGE,
          then: Yup.number().max(100, "You can only enter a maximum of 100 for % Withholding Tax"),
        })
        .nullable(true),
      description: Yup.string().max(100).nullable(true),
    }),
    vatValue: Yup.number()
      .min(0, "Negative numbers are not allowed")
      .max(100, "You can only enter a maximum of 100 for % VAT")
      .nullable(true),
  });
};
