import PropTypes from "prop-types";

import { StyledButton } from "./styles";

const Button = ({ className, variant, children, prefix, suffix, isLoading, ...restProps }) => (
  <StyledButton className={`${variant} ${className}`} isLoading={isLoading} {...restProps}>
    {prefix && prefix}
    <span>{children}</span>
    {suffix && suffix}
  </StyledButton>
);

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  className: "",
  variant: "primary",
  children: "",
  isLoading: false,
  prefix: undefined,
  suffix: undefined,
};

export default Button;
