import { Container } from "react-bootstrap";
import { useParams, Switch, Route, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useEffect } from "react";

import Header from "../components/header";

import { SignupContainer } from "./styles";
import PersonalDetails from "./components/personal-details";
import MobileNumber from "./components/mobile-number";
import Otp from "./components/otp";
import LyteUsage from "./components/lyte-usage";
import FreelancerType from "./components/freelancer-type";
import AudienceSource from "./components/audience-source";
import { SignupSchema } from "./schema";

const OnboardingSteps = () => {
  const { step } = useParams();
  const { replace } = useHistory();
  const initialValues = {
    key: localStorage.getItem("signUpIdentifier"),
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    country: "",
    intention: [],
    intentionOthers: "",
    audienceSource: [],
    audienceSourceOthers: "",
    freelancerType: "",
    freelancerRoles: [],
    mobileNumber: "",
    referralCode: "",
  };

  useEffect(() => {
    const id = localStorage.getItem("signUpIdentifier");
    if (!id) replace("/signup");
  }, []);

  return (
    <SignupContainer>
      <Header currentStep={+step} />
      <Container className="content-container pb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          enableReinitialize
          validateOnMount
        >
          <Switch>
            <Route path="/signup/steps/1" render={() => <PersonalDetails />} />
            <Route path="/signup/steps/2" render={() => <MobileNumber />} />
            <Route path="/signup/steps/3" render={() => <Otp />} />
            <Route path="/signup/steps/4" render={() => <LyteUsage />} />
            <Route path="/signup/steps/5" render={() => <AudienceSource />} />
            <Route path="/signup/steps/6" render={() => <FreelancerType />} />
          </Switch>
        </Formik>
      </Container>
    </SignupContainer>
  );
};

export default OnboardingSteps;
