import styled from "styled-components";

import Color from "utils/colours";

export const ButtonStyled = styled.button`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  gap: 8px;
  text-align: center;
  transition: 0.3 all ease-in-out;
  font-weight: 700;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;

  // variant
  ${(props) => {
    const variant = {
      backgroundColor: "transparent",
      backgroundColorHover: "transparent",
      backgroundColorDisabled: "transparent",
      backgroundColorActive: "transparent",
      color: Color.neutral0,
      colorHover: Color.neutral0,
      colorDisabled: Color.neutral0,
      colorActive: Color.neutral0,
      borderColor: "transparent",
      borderColorHover: "transparent",
      borderColorDisabled: "transparent",
      borderColorActive: "transparent",
    };

    switch (props.variant) {
      case "primary":
        variant.backgroundColor = Color.navi500;
        variant.backgroundColorHover = Color.navi400;
        variant.backgroundColorDisabled = Color.dark200;
        variant.backgroundColorActive = Color.navi600;
        variant.color = Color.neutral0;
        variant.colorHover = Color.neutral0;
        variant.colorDisabled = Color.neutral0;
        variant.colorActive = Color.neutral0;
        variant.borderColor = Color.navi500;
        variant.borderColorHover = Color.navi400;
        variant.borderColorDisabled = Color.dark200;
        variant.borderColorActive = Color.navi600;
        break;
      case "secondary":
        variant.backgroundColor = Color.neutral0;
        variant.backgroundColorHover = Color.navi50;
        variant.backgroundColorDisabled = Color.neutral0;
        variant.backgroundColorActive = Color.navi100;
        variant.color = Color.navi500;
        variant.colorHover = Color.navi500;
        variant.colorDisabled = Color.dark200;
        variant.colorActive = Color.navi500;
        variant.borderColor = Color.navi500;
        variant.borderColorHover = Color.navi500;
        variant.borderColorDisabled = Color.dark200;
        variant.borderColorActive = Color.navi500;
        break;
      case "desctructive":
        variant.backgroundColor = Color.neutral0;
        variant.backgroundColorHover = Color.empathy50;
        variant.backgroundColorDisabled = Color.neutral0;
        variant.backgroundColorActive = Color.empathy100;
        variant.color = Color.empathy500;
        variant.colorHover = Color.empathy500;
        variant.colorDisabled = Color.dark200;
        variant.colorActive = Color.empathy500;
        variant.borderColor = Color.empathy500;
        variant.borderColorHover = Color.empathy500;
        variant.borderColorDisabled = Color.dark200;
        variant.borderColorActive = Color.empathy500;
        break;
      case "ghost":
        variant.color = props.theme.color.textLinkDefault;
        variant.colorHover = props.theme.color.textLinkHover;
        variant.colorDisabled = Color.dark200;
        variant.colorActive = props.theme.color.textLinkPressed;
        break;
      default:
        break;
    }
    return `
      background-color: ${variant.backgroundColor};
      color: ${variant.color};
      border-color: ${variant.borderColor};
      &:hover {
        background-color: ${variant.backgroundColorHover};
        color: ${variant.colorHover};
        border-color: ${variant.borderColorHover};
      }
      &:active {
        background-color: ${variant.backgroundColorActive};
        color: ${variant.colorActive};
        border-color: ${variant.borderColorActive};
      }
      &:disabled {
        background-color: ${variant.backgroundColorDisabled};
        color: ${variant.colorDisabled};
        border-color: ${variant.borderColorDisabled};
        cursor: not-allowed;
      }
    `;
  }}

  //size
  ${(props) => {
    const size = {
      minWidth: "auto",
      padding: 0,
      fontSize: "16px",
      iconSize: "24px",
    };

    switch (props.size) {
      case "md":
        size.minWidth = "130px";
        size.padding = "12px 24px";
        size.fontSize = "16px";
        size.iconSize = "24px";
        break;
      case "sm":
        size.minWidth = "67px";
        size.padding = "6px 16px";
        size.fontSize = "13px";
        size.iconSize = "16px";
        break;
      default:
        break;
    }
    return `
    min-width: ${size.minWidth};
    padding: ${size.padding};
    font-size: ${size.fontSize};
    .icon {
      font-size: ${size.iconSize};
      &.loading {
        animation: spin 1s linear infinite;
      }
    }
    `;
  }}

  //custom
  ${(props) => {
    if (props.variant === "ghost" || props.variant === "default") {
      return `
      min-width: auto;
      padding: 0;
        cursor: default;
      `;
    }
    return "";
  }}
`;
