import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import Joyride, { STATUS } from "react-joyride";

import { useUserData } from "hooks/useUserData";
import { LyteBoostTm } from "components/boost/boost-introduction";
import BoostNoKycWarning from "components/boost/no-kyc-warning";
import TooltipTourGuide from "components/dashboard/tour-guides/tooltip";
import IllustManinRocket from "images/illust/illust-man-in-rocket.png";
import { useLocalStorage } from "utils/useLocalStorage";
import { useTourGuideState } from "providers/TourGuideProvider";

import { PageTitle, PageDescription } from "./styles";
import WidgetCalculator from "./widget-calculator/WidgetCalculator";
import WidgetSettings from "./widget-settings";

const BoostSettings = () => {
  const { data: user } = useUserData();
  const [showTour] = useLocalStorage(
    "lytepay2onboardingboosttourflow",
    JSON.parse(localStorage.getItem("lytepay2onboardingboosttourflow")) || false
  );
  const { tutorialBoost, setTutorialBoost } = useTourGuideState();

  useEffect(() => {
    if (!showTour && user) setTutorialBoost((prevState) => ({ ...prevState, run: true }));
  }, [user, showTour]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTutorialBoost((prevState) => ({ ...prevState, run: false }));
      localStorage.setItem("lytepay2onboardingboosttourflow", true);
    }
  };

  const handleTooltipTourGuideView = (props) => (
    <TooltipTourGuide {...props} totalSteps={Array(tutorialBoost.steps.length).fill(0)} />
  );

  return (
    <div className="Dashboard">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={tutorialBoost.run}
        showProgress
        showSkipButton
        disableScrolling
        disableOverlayClose
        disableCloseOnEsc
        steps={tutorialBoost.steps}
        tooltipComponent={handleTooltipTourGuideView}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <div className="mt-md-5 my-4">
        <Container>
          {!user?.isKycApproved && <BoostNoKycWarning user={user} />}
          <PageTitle className="mt-4 mb-2">
            <LyteBoostTm /> Settings
          </PageTitle>
          <PageDescription className="mb-4">
            Boost rewards you for managing your income with us. You can set a default Boost amount
            to be applied to every invoice you send. Any amount not Boosted will be immediately
            transferred to your linked bank account.
          </PageDescription>
          <div className="py-4 row gap-4 gap-md-0">
            <div className="col-md-7">
              <WidgetSettings user={user} />
            </div>
            <div className="col-md-5 col-xl-4">
              <WidgetCalculator />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BoostSettings;
