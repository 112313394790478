import moment from "moment";
import PropTypes from "prop-types";

import { formatCurrency } from "utils/commonHelpers";

const CellRewardsAccumulated = ({ boostEndDate }) => (
  <div className="d-flex flex-column">
    <span className="text-muted text-nowrap">{moment(boostEndDate).format("DD MMM YYYY")}</span>
  </div>
);

CellRewardsAccumulated.propTypes = {
  boostEndDate: PropTypes.string.isRequired,
};

export default CellRewardsAccumulated;
