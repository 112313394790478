import * as yup from "yup";

export const cellUpdateModalSchema = yup.object({
  boostId: yup.number().required(),
  autoReboost: yup.boolean().required("This is a required field").default(true),
  boostPercentage: yup.number().required("This is a required field").default(1),
  boostDuration: yup.number().required("This is a required field").default(365),
  agreeReboost: yup
    .boolean()
    .oneOf([true], "This is a required field")
    .required("This is a required field"),
});
