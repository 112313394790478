import { useMutation } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useRegistration = (options) => {
  const { Public } = useApi();

  return useMutation(async (values) => {
    const promise = Public.post(`public/user/registration`, values);
    toast.promise(promise, {
      success: "Success registering your account.",
      loading: "Registering your account.",
      error: ({ response, message }) =>
        `Error registering account: ${response?.data?.messages?.[0] || message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};
