import { Badge } from "react-bootstrap";
import dayjs from "dayjs";

import { formatCurrency } from "utils/commonHelpers";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";

export const columns = [
  {
    name: "No",
    selector: (row) => row.id,
    cell: (row, index) => index + 1,
    width: "60px",
  },
  {
    name: "Cashout Ref",
    selector: (row) => row.referenceNumber,
    sortable: true,
    width: "180px",
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    cell: (row) => formatCurrency(row.amount),
    width: "150px",
  },
  {
    name: "Type",
    selector: (row) => row.cashoutType,
    cell: (row) => <span className="text-capitalize">{row.cashoutType}</span>,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => <InvoiceStatuses status={row?.status} pill />,
    width: "150px",
    center: true,
  },
  {
    name: "Created on",
    selector: (row) => row.createdDateTime,
    sortable: true,
    width: "200px",
    cell: (row) =>
      row.createdDateTime ? dayjs(row.createdDateTime).format("DD MMM YYYY HH:MM") : "-",
  },
  {
    name: "Paid on",
    selector: (row) => row.paidDateTime,
    sortable: true,
    width: "200px",
    cell: (row) => (row.paidDateTime ? dayjs(row.paidDateTime).format("DD MMM YYYY HH:MM") : "-"),
  },
];
