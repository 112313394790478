export const CUSTOM_STYLES = (theme) => ({
  table: {
    style: {
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
    },
  },
  tableWrapper: {
    style: {},
  },
  header: {
    style: {
      display: "none",
      backgroundColor: "transparent",
    },
  },
  subHeader: {
    style: {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
      backgroundColor: "transparent",
    },
  },
  headCells: {
    style: {
      padding: "0 12px",
      fontSize: "14px",
      fontWeight: "700",
      color: theme?.color?.textSecondary,
      backgroundColor: "transparent",
    },
    activeSortStyle: {
      color: theme?.color?.textSecondary,
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: theme?.color?.textSecondary,
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: theme?.color?.textSecondary,
      },
      "&:hover": {
        color: theme?.color?.textSecondary,
      },
    },
  },
  rows: {
    style: {
      "&:not(:last-of-type)": {
        border: "0",
      },
    },
  },
  cells: {
    style: {
      "&:not(:first-of-type)": {
        borderLeft: `2px solid ${theme?.color?.tableContentBackgroundPrimary}`,
      },
      padding: "8px 10px",
      fontSize: "14px",
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      verticalAlign: "top",
      minWidth: "0",
      color: theme?.color?.textSecondary,
    },
  },
  pagination: {
    style: {
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      color: theme?.color?.textSecondary,
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    pageButtonsStyle: {
      color: theme?.color?.textSecondary,
      fill: theme?.color?.textSecondary,
      "&:disabled": {
        color: theme?.color?.textSecondary,
        fill: theme?.color?.textSecondary,
      },
      "&:hover:not(:disabled)": {
        backgroundColor: theme?.color?.buttonBackgroundPrimaryHover,
      },
      "&:focus": {
        backgroundColor: theme?.color?.buttonBackgroundPrimaryHover,
      },
    },
  },
  noData: {
    style: {
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      color: theme?.color?.textSecondary,
      borderRadius: "8px",
    },
  },
});
