import { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from "views/public/signup/components/Button";
import FormikCheckbox from "views/public/signup/components/FormikCheckbox";
import FormikInput from "views/public/signup/components/FormikInput";

import { LYTE_AUDIENCE_SOURCE_TYPES } from "./constants";

const AudienceSource = ({ userData }) => {
  const { push, replace } = useHistory();
  const { values, errors } = useFormikContext();

  const isDisabled = "audienceSource" in errors || "audienceSourceOthers" in errors;

  const handleSubmit = () => {
    if (userData) {
      push(`/onboarding/freelancer-type`);
      return;
    }
    push(`/signup/steps/6`);
  };

  return (
    <div>
      <form>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h3 className="font-weight-bold">How did you hear about LytePay?</h3>
            <h6 className="pb-4">*You may choose more than 1 option</h6>
            <FormikCheckbox list={LYTE_AUDIENCE_SOURCE_TYPES} name="audienceSource" />
            {values.audienceSource.includes("others") && (
              <FormikInput label="Specify (Others)" name="audienceSourceOthers" />
            )}
            <Button
              type="button"
              className="w-100 mt-4 mb-3"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AudienceSource;
