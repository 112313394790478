import styled from "styled-components";

import Color from "utils/colours";

export const StyledField = styled.div`
  margin-bottom: 24px;
  position: relative;

  a {
    color: ${Color.navi500};
    font-weight: 700;
    text-decoration: none;
  }

  .checkbox-container {
    display: flex;
    align-items: start;
    gap: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    padding-left: 40px;
    .hidden {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark-container {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      top: 0;
      background-color: ${(props) => props.theme.color.formInputBackgroundColor};
      border: 2px solid ${(props) => props.theme.color.inputBorderDefault};
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${Color.integrity500};
        width: 20px;
        height: 20px;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
      }
    }
    input:checked ~ .checkmark-container .icon-container {
      opacity: 1;
    }
    .label-container {
      width: 100%;
    }
  }

  .description-text,
  .error-text {
    color: ${(props) => props.theme.color.textSubtleContrast};
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 4px;
  }

  &.form-invalid {
    .description-text,
    .error-text {
      color: ${(props) => props.theme.color.textAlertError};
    }
  }

  &.form-success {
    .description-text,
    .error-text {
      color: ${(props) => props.theme.color.textAlertSuccess};
    }
  }
`;
