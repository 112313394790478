import { Formik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { SignupSchema } from "./schema";
import Header from "./components/header";
import Button from "./components/Button";
import FormikInput from "./components/FormikInput";
import CustomLink from "./components/Link";
import { SignupContainer } from "./styles";
import { useRegistration } from "./service";

const SignupPage = () => {
  const { push, replace } = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };

  const { mutate: mutateRegister, isLoading: isLoadingRegister } = useRegistration();

  const handleSubmit = (values) => {
    mutateRegister(values, {
      onSuccess: (response) => {
        localStorage.setItem("signUpIdentifier", response?.contextObjects[0]);
        push(`/verify-email?email=${encodeURIComponent(values.email)}`);
      },
    });
  };

  return (
    <SignupContainer>
      <Header />
      <Container className="content-container">
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          enableReinitialize
          validateOnMount
        >
          {({ values, isValid }) => (
            <form>
              <Row>
                <Col xs={12} lg={{ span: 6, offset: 3 }}>
                  <h3 className="font-weight-bold pb-4">Let's get started!</h3>
                  <p className="mb-2">I am a</p>
                  <Button type="button" variant="tertiary" className="mb-4 px-3 py-1">
                    <span style={{ fontSize: "13px" }}>Freelancer</span>
                  </Button>
                  <FormikInput
                    label="Email"
                    name="email"
                    description="Please ensure the same email is registered with your agency"
                  />
                  <FormikInput label="Password" type="password" name="password" />
                  <Button
                    type="button"
                    className="w-100"
                    disabled={!isValid || isLoadingRegister}
                    onClick={() => handleSubmit(values)}
                  >
                    Create an account
                  </Button>
                  <div className="w-100 text-center mt-4">
                    Already have an account?{" "}
                    <CustomLink onClick={() => replace(process.env.REACT_APP_ORIGIN_URI)}>
                      Login Here!
                    </CustomLink>
                  </div>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Container>
    </SignupContainer>
  );
};

export default SignupPage;
