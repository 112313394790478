import { Col, Container, Row } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom/";

import Loader from "components/common/Loader";
import { useUserData } from "hooks/useUserData";
import { LyteButton } from "components/common/buttons";
import Error401 from "views/public/errors/Error401";
import BankIllustration from "images/illust/illust-bank-acc.png";

const BankVerification = () => {
  const { push, replace } = useHistory();
  const { data: user } = useUserData();

  if (!user) {
    return <Loader />;
  }

  if (!user.fromOnboarding) {
    return <Error401 />;
  }

  if (user.bankAccount) {
    return <Redirect to="/bank-account" />;
  }

  return (
    <Container className="mt-4">
      <Row>
        <Col className="text-center" md={{ offset: 3, span: 6 }}>
          <h2 className="font-weight-bold">Update Bank Details</h2>

          <img className="img-fluid" src={BankIllustration} alt="bank-illust" />

          <p>
            Consider updating your bank details for a smooth Cashout experience in the future. The
            bank account will be where your Cashouts go to
          </p>
          <div>
            <LyteButton className="mx-auto mb-3" onClick={() => push("/bank-verification/details")}>
              Update Bank Details
            </LyteButton>
            <LyteButton className="m-auto" variant="ghost" onClick={() => replace("/")}>
              Update Later
            </LyteButton>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BankVerification;
