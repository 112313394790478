import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { APPROVED, REJECTED, PENDING } from "./constants";

const OnboardingBankAccountDetails = ({ bankAccount }) => (
  <>
    {(!bankAccount || bankAccount?.status === PENDING) && (
      <div>
        <p>
          In order to send and receive money from an Invoice, we will need to have at least one bank
          account verified. Bank account verification takes 1 - 2 business days.
        </p>
      </div>
    )}

    {bankAccount?.status === REJECTED && (
      <div>
        {bankAccount?.rejectReason ? (
          <>
            <p>Your bank account has been rejected with reason: {bankAccount?.rejectReason}.</p>
            <p>Please delete the previous submission and submit again.</p>
          </>
        ) : (
          <p>
            Your bank account has been rejected. Please delete the previous submission and submit
            again.
          </p>
        )}
      </div>
    )}

    {bankAccount?.status === APPROVED && (
      <div className="">
        <p className="mb-0">You have successfully linked your bank account.</p>
      </div>
    )}
    <div>
      <Link to="/bank-account" className="global-button dotted">
        Update Bank Account
      </Link>
    </div>
  </>
);

OnboardingBankAccountDetails.defaultProps = {
  bankAccount: {},
};

OnboardingBankAccountDetails.propTypes = {
  bankAccount: PropTypes.shape({
    status: PropTypes.string,
    rejectReason: PropTypes.string,
  }),
};

export default OnboardingBankAccountDetails;
