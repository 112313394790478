import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faQuestion, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { faComment, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { useLocalStorage } from "utils/useLocalStorage";
import { useTourGuideState } from "providers/TourGuideProvider";

import { FloatingHelpContainer } from "./styles";

const FloatingHelp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { setTutorialBoost, setTutorialDashboard } = useTourGuideState();
  const location = useLocation();
  const { push } = useHistory();
  const boxRef = useRef(null);
  const boostPath = location.pathname.includes("/boost/settings");
  const dashboardPath = location.pathname === "/";

  const [boostTour, setBoostTour] = useLocalStorage(
    "lytepay2onboardingboosttourflow",
    JSON.parse(localStorage.getItem("lytepay2onboardingboosttourflow")) || true
  );
  const [dashboardTour, setDashboardTour] = useLocalStorage(
    "lytepay2onboardingdashboardtourflow",
    JSON.parse(localStorage.getItem("lytepay2onboardingdashboardtourflow")) || true
  );

  const handleCheckPathname = dashboardPath || boostPath;

  const handleResetTutorial = () => {
    if (handleCheckPathname) {
      if (dashboardPath) {
        setTutorialDashboard((prevState) => ({ ...prevState, run: true }));
        setDashboardTour(false);
      }
      if (boostPath) {
        setTutorialBoost((prevState) => ({ ...prevState, run: true }));
        setBoostTour(false);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  const handleContactUs = () => {
    window.location.href = `mailto:support@lytepay.co`;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <FloatingHelpContainer>
      <FloatingHelpContainer.Trigger ref={boxRef} onClick={() => setIsVisible(!isVisible)}>
        <FontAwesomeIcon icon={isVisible ? faTimes : faQuestion} />
      </FloatingHelpContainer.Trigger>
      {isVisible && (
        <FloatingHelpContainer.HiddenBox isVisible={isVisible}>
          <div className="p-2">
            {handleCheckPathname && (
              <FloatingHelpContainer.HiddenBox.IconContainer onClick={() => handleResetTutorial()}>
                <FontAwesomeIcon icon={faPlay} />
                <p>Show Tutorial</p>
              </FloatingHelpContainer.HiddenBox.IconContainer>
            )}
            <FloatingHelpContainer.HiddenBox.IconContainer onClick={() => push("/faq")}>
              <FontAwesomeIcon icon={faQuestionCircle} />
              <p>Help & FAQ</p>
            </FloatingHelpContainer.HiddenBox.IconContainer>
            <FloatingHelpContainer.HiddenBox.IconContainer onClick={() => handleContactUs()}>
              <FontAwesomeIcon icon={faComment} />
              <p>Contact Us</p>
            </FloatingHelpContainer.HiddenBox.IconContainer>
          </div>
        </FloatingHelpContainer.HiddenBox>
      )}
    </FloatingHelpContainer>
  );
};

export default FloatingHelp;
