import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import useApi from "./useApi";

export const useGetPublicInvoiceByRef = (invoiceRef, options) => {
  const { Public } = useApi();

  return useQuery(invoiceRef, async () => Public.get(`public/invoices/${invoiceRef}`), {
    ...options,
  });
};

export const useGetInvoiceNumberPrefill = () => {
  const { Private } = useApi();
  return useQuery(
    ["invoicePrefill"],
    async () => {
      const { data } = await Private.get("users/invoiceNumberPrefill");
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateDraftInvoice = () => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private({
      method: "post",
      url: "invoices/v2/draft",
      data: values,
    });

    toast.promise(promise, {
      loading: "Saving...",
      success: `Updated Draft Invoice ${values.invoiceNumber}`,
      error: ({ message, response }) => {
        if (response?.data?.inputValidationErrors.length > 0) {
          return (
            <div className="d-flex flex-column">
              <p className="mb-2">Please correct the following errors:</p>
              <ul className="m-0 pl-3">
                {response?.data?.inputValidationErrors.map((error) => (
                  <li key={error.value}>{error.value}</li>
                ))}
              </ul>
            </div>
          );
        }

        return `Error updating draft invoice: ${message}`;
      },
    });

    const { data } = await promise;
    return data;
  });
};

export const useSendDraftInvoice = () => {
  const { Private } = useApi();

  const { push } = useHistory();
  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "invoices/draft/submit",
        data: values,
      });
      toast.promise(promise, {
        loading: `Sending invoice...`,
        success: `Send Draft Invoice ${values.invoiceNumber}`,
        error: ({ message, response }) => {
          if (response?.data?.inputValidationErrors.length > 0) {
            return (
              <div className="d-flex flex-column">
                <p className="mb-2">Please correct the following errors:</p>
                <ul className="m-0 pl-3">
                  {response?.data?.inputValidationErrors.map((error) => (
                    <li key={error.value}>{error.value}</li>
                  ))}
                </ul>
              </div>
            );
          }

          return `Error sending invoice: ${message}`;
        },
      });
      const { data } = await promise;
      return data;
    },
    {
      onSuccess: () => {
        push(`/invoice/listing`);
      },
    }
  );
};

export const usePostDuplicateInvoice = (invoiceNumber) => {
  const { Private } = useApi();

  const { push } = useHistory();
  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "invoices/duplicate",
        data: values,
      });
      toast.promise(promise, {
        loading: `Duplicating...`,
        success: `Duplicated Invoice ${invoiceNumber}`,
        error: ({ message }) => `Error duplicating the invoice: ${message}`,
      });
      const { data } = await promise;
      return data;
    },
    {
      onSuccess: () => push("/invoice/listing"),
    }
  );
};

export const usePostSubmitInvoice = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("invoices", values);
    toast.promise(promise, {
      loading: "Sending invoice...",
      success: "Invoice Sent",
      error: ({ message, response }) => {
        if (response?.data?.inputValidationErrors.length > 0) {
          return (
            <div className="d-flex flex-column">
              <p className="mb-2">Please correct the following errors:</p>
              <ul className="m-0 pl-3">
                {response?.data?.inputValidationErrors.map((error) => (
                  <li key={error.value}>{error.value}</li>
                ))}
              </ul>
            </div>
          );
        }

        return `Error sending invoice: ${message}`;
      },
    });
    const { data } = await promise;

    return data;
  }, options);
};

export const usePostQuickInvoice = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    values.businessName = "";
    const promise = Private.post("invoices/quick", values);
    toast.promise(promise, {
      loading: "Creating quick invoice...",
      success: "Quick Invoice Created!",
      error: ({ message, response }) => {
        if (response?.data?.inputValidationErrors.length > 0) {
          return (
            <div className="d-flex flex-column">
              <p className="mb-2">Please correct the following errors:</p>
              <ul className="m-0 pl-3">
                {response?.data?.inputValidationErrors.map((error) => (
                  <li key={error.value}>{error.value}</li>
                ))}
              </ul>
            </div>
          );
        }

        return `Error creating quick invoice: ${message}`;
      },
    });

    const { data } = await promise;
    return data;
  }, options);
};

export const usePostDraftInvoice = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("invoices/v2/draft", values);
    toast.promise(promise, {
      loading: "Saving invoice...",
      success: "Draft invoice saved",
      error: ({ message }) => `Error sending invoice: ${message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const usePostTestSendInvoice = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("invoices/testsend", values);
    toast.promise(promise, {
      loading: "Sending test invoice...",
      success: "Test invoice sent",
      error: ({ message }) => `Error sending test invoice: ${message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const useGetClients = ({ searchKey = "", options = {} } = {}) => {
  const { Private } = useApi();

  const query = searchKey ? `?searchKey=${searchKey}` : "";

  return useQuery(
    ["clients", searchKey],
    async () => Private.get(`invoices/autocomplete/client${query}`),
    options
  );
};

export const useGetInvoiceItems = ({ searchKey = "", options = {} } = {}) => {
  const { Private } = useApi();

  const query = searchKey ? `?searchKey=${searchKey}` : "";

  return useQuery(
    ["clients", searchKey],
    async () => Private.get(`invoices/autocomplete/item${query}`),
    options
  );
};

export const usePostInvoiceStateUpdate = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("invoices/update", values);
    toast.promise(promise, {
      loading: "Updating invoice state",
      success: "Invoice status updated",
      error: ({ message }) => `Error updating invoice: ${message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const usePostAcceptInvoice = (options) => {
  const { Public } = useApi();
  return useMutation(async (invoiceRef) => {
    const promise = Public.post(`public/invoices/${invoiceRef}/accept`, {
      invoiceRef,
    });
    toast.promise(promise, {
      loading: "Accepting Invoice...",
      success: "Invoice successfully accepted",
      error: ({ message }) => `Error when accepted invoice: ${message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const useDeleteInvoice = (options) => {
  const { Private } = useApi();

  const queryClient = useQueryClient();
  return useMutation(async (invoiceRef) => {
    const promise = Private.delete(`invoices/${invoiceRef}`);
    toast.promise(promise, {
      loading: "Delete invoice",
      success: "Invoice status deleted",
      error: ({ message }) => `Error delete invoice: ${message}`,
    });
    const { data } = await promise;
    queryClient.invalidateQueries("userInvoices");
    return data;
  }, options);
};

export const useExportInvoice = (params, options) => {
  const { Private } = useApi();

  return useMutation(
    async (values) => {
      const promise = Private.post(`invoices/export?TypeFormat=${params}`, values);
      toast.promise(promise, {
        loading: "Exporting invoice",
        success: "Success export Invoice",
        error: ({ message }) => `Error export invoice: ${message}`,
      });
      const { data } = await promise;
      return data;
    },
    {
      ...options,
    }
  );
};
