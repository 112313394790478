import {
  ToggleButton as RBToggleButton,
  ToggleButtonGroup as RBToggleButtonGroup,
  Modal as RBModal,
} from "react-bootstrap";
import styled from "styled-components";

export const ToggleButtonGroup = styled(RBToggleButtonGroup)`
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ToggleButton = styled(RBToggleButton)`
  border-radius: 4px !important;
  flex: 0 0 auto !important;
  background: #fff;
  border-color: #3363ff;
  color: #3363ff;

  &:focus,
  &.focus {
    background-color: #fff;
    color: #3363ff;
  }
`;

export const Modal = styled(RBModal)`
  @media only screen and (min-width: 768px) {
    .modal-dialog {
      width: 700px;
      max-width: none;
    }
  }
`;
