export const DRAFT = "draft";
export const PENDING = "pending";
export const ACCEPTED = "accepted";
export const REJECTED = "rejected";
export const COMPLETED = "completed";
export const CANCELLED = "cancelled";
export const DELETED = "deleted";
export const PAID = "paid";
export const PARTIALLY_PAID = "partiallypaid";

export const PAYMENT_STATUS_UNPAID = "unpaid";
export const PAYMENT_STATUS_OVERDUE = "overdue";

export const SELECT_FILTER_OPTIONS = [
  {
    name: "All Status",
    value: "",
  },
  { name: "Pending", value: PENDING },
  { name: "Draft", value: DRAFT },

  { name: "Cancelled", value: CANCELLED },
  { name: "Accepted", value: ACCEPTED },
  { name: "Rejected", value: REJECTED },
  // { name: "Deleted", value: DELETED },
  { name: "Paid", value: PAID },
  // { name: "Completed", value: COMPLETED },
  { name: "Partially Paid", value: PARTIALLY_PAID },
];

export const SEARCH_OPTIONS = {
  searchColumns: ["projectTitle", "invoiceNumber"],
  placeholder: "Search for project name or invoice number...",
  filterOptions: SELECT_FILTER_OPTIONS,
  filterColumns: ["status"],
};

export const SELECT_PAYMENT_STATUS_OPTIONS = [
  { name: "All Invoices", value: "" },
  { name: "Unpaid Invoices", value: PAYMENT_STATUS_UNPAID },
  { name: "Overdue Invoices", value: PAYMENT_STATUS_OVERDUE },
];

export const LOCK_IN_PERIOD_REWARDS_APY_OPTIONS = [
  { value: { lockInPeriod: 15, rewardAPY: 0.07 }, label: "15 days / 7%" },
  { value: { lockInPeriod: 30, rewardAPY: 0.08 }, label: "30 days / 8%" },
  { value: { lockInPeriod: 90, rewardAPY: 0.09 }, label: "90 days / 9%" },
  { value: { lockInPeriod: 180, rewardAPY: 0.1 }, label: "180 days / 10%" },
  { value: { lockInPeriod: 365, rewardAPY: 0.11 }, label: "365 days / 11%" },
];

export const tableCustomStyles = {
  header: {
    style: {
      display: "none",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#122ECD",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
    },
  },
  headCells: {
    style: {
      padding: "0 15px",
      fontSize: "14px",
      fontWeight: "600",
      color: "white",
    },
    activeSortStyle: {
      color: "white",
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: "white",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "white",
      },
      "&:hover": {
        color: "white",
      },
    },
  },
  rows: {
    style: {
      "&:not(:last-of-type)": {
        border: "0",
      },
    },
  },
  cells: {
    style: {
      borderBottom: "5px solid #f8f8f8",
      padding: "1rem",
      fontSize: "14px",
      backgroundColor: "#ffffff",
      verticalAlign: "top",
      minWidth: "0",
    },
  },
};
