import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import img from "images/illust/errors/500.png";
import { LyteBtn } from "components/common/buttons";

const Fallback = ({ resetError }) => {
  const history = useHistory();
  return (
    <div className="container text-center" style={{ marginTop: "15%" }}>
      <div className="mx-auto my-auto" style={{ width: "500px" }}>
        <h2 className="font-weight-bolder">Oops! Something is not working.</h2>
        <Image src={img} alt="401" fluid width={400} />
        <h5 className="my-4">
          The server encountered an internal error or misconfiguration and was unable to complete
          your request. Try to refresh this page or feel free to contact us if the problem persists.
        </h5>
        <LyteBtn
          onClick={() => {
            resetError();
            history.replace("/");
          }}
          className="btn-lg bg-dark"
          style={{ width: "250px", padding: "0.75rem 0" }}
        >
          Go Back
        </LyteBtn>
      </div>
    </div>
  );
};

Fallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default Fallback;
