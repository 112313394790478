import InvoiceWizard from "components/invoice/InvoiceWizard";
import InvoiceCreateSuccessSetBoost from "components/invoice/invoice-create-success-set-boost";
import InvoiceSubmitted from "components/invoice/InvoiceSubmitted";
import InvoiceDetails from "views/private/invoice/InvoiceDetails";
import InvoiceSummary from "views/private/invoice/InvoiceSummary";
import InvoiceCashout from "views/private/invoice/InvoiceCashout";

export const invoice = [
  {
    path: "/invoice/create/success-set-boost",
    exact: true,
    component: InvoiceCreateSuccessSetBoost,
  },
  {
    path: "/invoice/listing",
    exact: true,
    component: InvoiceSummary,
  },
  {
    path: "/invoice/create/submitted",
    exact: true,
    component: InvoiceSubmitted,
  },
  {
    path: "/invoice/:identifier",
    exact: true,
    component: InvoiceDetails,
  },
  {
    path: "/invoice/:identifier/cashout",
    exact: true,
    component: InvoiceCashout,
  },
  {
    path: "/invoice/:wizardMode/:step/",
    exact: true,
    component: InvoiceWizard,
  },
  {
    path: "/invoice/:wizardMode/:step/:invoiceRef",
    exact: true,
    component: InvoiceWizard,
  },
];
