import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  /* ------------------------- Env variables ------------------------- */
  const { REACT_APP_DOMAIN: domain, REACT_APP_CLIENT_ID: clientId } = process.env;

  /* ---------------------- React router history ---------------------- */
  // # gives access to Auth0 to route
  const history = useHistory();

  /* -------------------- Redirect handler ---------------------------- */
  const onRedirectCallback = (appState) => {
    history.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      // useRefreshTokens
      // cacheLocation="memory"
      // skipRedirectCallback={window.location.pathname === "/invoice"}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
