import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "./useApi";

export const useGetCashoutSummary = () => {
  const { Private } = useApi();
  return useQuery("cashoutsummary", async () => {
    const { data } = await Private({ method: "get", url: "/cashouts" });
    return data;
  });
};

export const usePostCashout = (options) => {
  const { Private } = useApi();
  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "boosts/cashout",
        data: values,
      });

      toast.promise(promise, {
        loading: "Processing Cashout...",
        success: "Cashout successfully processed",
        error: ({ message }) => `Error when processing cashout: ${message}`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};

export const useGetCashoutInvoice = (invoiceReference) => {
  const { Private } = useApi();
  return useQuery(
    ["cashoutInvoice", invoiceReference],
    async () => {
      const { data } = await Private({
        method: "get",
        url: `invoices/summary/${invoiceReference}`,
      });
      return data;
    },
    {
      enabled: !!invoiceReference,
    }
  );
};
