import PropTypes from "prop-types";
import { Popover, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { LyteButton } from "components/common/buttons";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";

import { CURRENT } from "../constants";

const StyledPopover = styled(Popover)`
  border: 0ch;
  font-size: 14px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  .arrow {
    display: none;
  }
`;

const CellStatus = ({ status, boostStartingDate, invoiceReference }) => {
  const { push } = useHistory();

  const daysRemaining = moment().diff(moment(boostStartingDate), "days");
  return (
    <div className="d-flex flex-column">
      <OverlayTrigger
        trigger={status === CURRENT && "hover"}
        placement="top"
        overlay={
          <StyledPopover className="text-center">
            <Popover.Content>
              If you decided to cashout this invoice, you will lost the reward accumulated.
              <p className="text-primary m-0">
                <small>
                  Remaining days until available for cashout + total reward accumulated:{" "}
                  <strong>{daysRemaining}</strong> days.
                </small>
              </p>
            </Popover.Content>
          </StyledPopover>
        }
      >
        {status === CURRENT ? (
          <LyteButton onClick={() => push(`/cashout/${invoiceReference}`)} size="sm">
            Cashout
          </LyteButton>
        ) : (
          <InvoiceStatuses status={status} pill />
        )}
      </OverlayTrigger>
    </div>
  );
};

CellStatus.propTypes = {
  status: PropTypes.string.isRequired,
  boostStartingDate: PropTypes.string.isRequired,
  invoiceReference: PropTypes.string.isRequired,
};

export default CellStatus;
