import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import useApi from "./useApi";

export const useUpdateInvoiceBoost = (options, invoiceNumber) => {
  const { Private } = useApi();

  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      const { data } = await Private({
        method: "post",
        url: "invoices/boost/update",
        data: payload,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userInvoices");
        toast.success(`Updated boost settings for Invoice ${invoiceNumber}`);
      },
      onError: () => {
        toast.error(`Error updating boost settings for Invoice`);
      },
      ...options,
    }
  );
};

export const useUserBoostDashboard = () => {
  const { Private } = useApi();
  return useQuery("boostUserDashboard", async () => {
    const { data } = await Private({
      method: "get",
      url: "boosts/user/dashboard",
    });
    return data;
  });
};

export const useGetBoostSettings = (options) => {
  const { Private } = useApi();
  return useQuery(
    "boostSettings",
    async () => {
      const { data } = await Private.get("boosts/setting");
      return data;
    },
    { keepPreviousData: true, ...options }
  );
};

export const usePostNewBoostSettings = () => {
  const { Private } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) => {
      const { data } = Private.post("boosts/setting", payload);
      return data;
    },
    {
      onSuccess: (data, vars) => {
        toast.success(`Updated boost settings`);
        // Force remount using random value due to issue with stale initialValues
        // being used for a render cycle.
        // https://github.com/jaredpalmer/formik/issues/729
        queryClient.setQueryData("boostSettings", {
          ...vars,
          agreeReboost: false,
          randomValue: Math.random(),
        });
      },
      onError: () => {
        toast.error(`Error updating boost settings`);
      },
    }
  );
};
