import { createContext, useContext, useMemo, useState } from "react";

import IllustHandClap from "images/illust/illust-hand-clap.png";
import IllustManinRocket from "images/illust/illust-man-in-rocket.png";

const TourGuide = createContext();

export const TourGuideProvider = ({ children }) => {
  const [tutorialDashboard, setTutorialDashboard] = useState({
    run: false,
    stepIndex: 0,
    steps: [
      {
        content: (
          <div>
            <div className="text-center w-100">
              <h5>Welcome Aboard!</h5>
              <img src={IllustHandClap} className="img-fluid w-50 my-4" alt="tour-logo" />
            </div>
            <p>
              Here at LytePay, we are dedicated to empowering your freelance journey. Let’s get
              started!
            </p>
          </div>
        ),
        placement: "center",
        target: "body",
      },
      {
        target: "#cta-invoice-dashboard",
        content: (
          <div>
            <p>Click here to create and send your customized invoice in just seconds.</p>
          </div>
        ),
      },
      {
        target: "#menu-invoice-summary",
        content: (
          <div>
            <p>Track all your invoices so you never miss a payment from your clients. </p>
          </div>
        ),
      },
      {
        target: "#top-navigation-boost-settings-button",
        content: (
          <div>
            <p>
              Looking to supercharge your earnings? Set up your Boost settings and earn up to 6%
              rewards on your total invoice amount - for free!
            </p>
          </div>
        ),
      },
      {
        target: "#dashboard-referral-utilities",
        content: (
          <div>
            <p>
              Spread the word and earn! Invite friends to join LytePay with your code and reap the
              benefits together.
            </p>
          </div>
        ),
      },
    ],
  });
  const [tutorialBoost, setTutorialBoost] = useState({
    run: false,
    steps: [
      {
        content: (
          <div>
            <div className="text-center w-100">
              <h5>
                Welcome to <b>Boost!</b>
              </h5>
              <img src={IllustManinRocket} className="img-fluid w-50 my-4" alt="tour-logo" />
            </div>
            <p>
              Earn up to 6% rewards on your total invoice amount. Let’s make your money work for
              you!
            </p>
          </div>
        ),
        placement: "center",
        target: "body",
      },
      {
        target: "#boost-settings-calculator-layout",
        content: (
          <div>
            <p>
              Curious about your potential earnings? Use the <b>Boost Calculator</b> to visualize
              how Boosting impacts your income.
            </p>
          </div>
        ),
      },
      {
        target: ".boots-settings-boost-range",
        content: (
          <div>
            <p>
              Choose the amount you want to Boost on all your invoices. You can also customize your
              Boost per invoice in the <b>Invoice Summary</b> page{" "}
            </p>
          </div>
        ),
      },
      {
        target: "#boots-settings-boost-duration",
        content: (
          <div>
            <p>
              Set the <b>Duration and Rate</b> for your Boost. Customize it to suit your financial
              strategy.
            </p>
          </div>
        ),
      },
      {
        target: "#boots-settings-auto-reboost",
        content: (
          <div>
            <p>
              Set up <b>Auto ReBoost</b> to continue earning up to 6% rewards hassle-free. Reminder
              that Boost has no lock-in period which means you can take out your money from Boost
              whenever you like!
            </p>
          </div>
        ),
      },
      {
        target: "#boots-settings-boost-trial",
        content: (
          <div>
            <p>
              New to Boost? Try out your <b>1 time 4.5% p.a. Trial</b> for 45 days and see how Boost
              works!
            </p>
          </div>
        ),
      },
    ],
  });

  const value = useMemo(
    () => ({ tutorialDashboard, setTutorialDashboard, tutorialBoost, setTutorialBoost }),
    [tutorialBoost, tutorialDashboard]
  );

  return <TourGuide.Provider value={value}>{children}</TourGuide.Provider>;
};

export const useTourGuideState = () => useContext(TourGuide);
