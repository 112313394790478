import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { Image, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormikUpload = ({
  label,
  name,
  defaultPreviewImage,
  className,
  disabled,
  isRequired,
  description,
}) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const fileInputRef = useRef(null);
  const [inputMode, setInputMode] = useState("file");
  const defaultImage = value ? URL.createObjectURL(value) : defaultPreviewImage;

  const triggerInput = (mode) => {
    setInputMode(mode);
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    helpers.setValue(event.target.files[0]);
  };

  return (
    <div className={className}>
      {label && <h6>{`${label}${isRequired ? "*" : ""}`}</h6>}
      {defaultImage ? (
        <Image
          width={150}
          height={250}
          className="mx-auto w-auto mt-1 mb-3"
          src={defaultImage}
          alt="Upload Preview"
        />
      ) : (
        <div>No File Uploaded</div>
      )}
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && (
        <ErrorMessage
          data-cypress-id={`cypress-file-input-${name}-error-message`}
          name={name}
          component="div"
          className="text-danger small my-3"
        />
      )}
      <div className="d-flex">
        <input
          ref={fileInputRef}
          type="file"
          accept={inputMode === "camera" ? "image/*" : "*"}
          capture={inputMode === "camera" ? "environment" : undefined}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            className="border-right-0"
            onClick={() => triggerInput("file")}
            disabled={disabled}
          >
            Upload
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => triggerInput("camera")}
            disabled={disabled}
          >
            Camera
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

FormikUpload.propTypes = {
  label: PropTypes.string,
  defaultPreviewImage: PropTypes.instanceOf(File),
  className: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikUpload.defaultProps = {
  label: "",
  defaultPreviewImage: null,
  className: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
};

export default FormikUpload;
