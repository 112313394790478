import styled from "styled-components";

import lyteWinkBlue from "images/icons/lyte-wink-blue.gif";

export const PageTitle = styled.h4`
  font-weight: 700;
  text-align: left;
  span {
    color: ${(props) => props.theme.color.textSecondary};
    font-style: normal;
    font-weight: 700;
  }
`;

export const PageDescription = styled.div`
  color: ${(props) => props.theme.color.textSecondary};
  font-weight: 400;
  text-align: left;
`;

export const LyteWinkLoader = styled.div`
  background: url("${lyteWinkBlue}") center center no-repeat;
  background-size: 32px 32px;
  height: 32px;
`;
