import clsx from "clsx";
import { useQuery } from "react-query";

import useApi from "hooks/useApi";
import { formatCurrency, formatDDMMMYYYY } from "utils/commonHelpers";
import InvoiceNoKycWarning from "components/invoice/InvoiceNoKycWarning";
import { useGetUserLogo } from "hooks/user";

import Invoice from "./InvoiceTemplate.styled";
import Clause from "./clause/Clause";

const INVOICE_TYPE_SELF_COLLECT = "SelfCollect";

const FormTotalCon = ({ name, amount }) => (
  <div className="form-total-con ml-auto my-3" style={{ width: "300px" }}>
    <div className="d-flex justify-content-between" style={{ height: "auto" }}>
      <p className="m-0 font-weight-bold">{name}</p>
      <p className="m-0 currency">{amount}</p>
    </div>
  </div>
);

FormTotalCon.defaultProps = {
  name: "Prop-name-string",
  amount: "Prop-amount-any",
};

export const customStyles = {
  header: {
    style: {
      display: "none",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#122ECD",
      borderRadius: "5px",
    },
  },
  headCells: {
    style: {
      padding: "0 15px",
      fontSize: "14px",
      fontWeight: "400",
      color: "white",
    },
    activeSortStyle: {
      color: "white",
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: "white",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "white",
      },
      "&:hover": {
        color: "white",
      },
    },
  },
  rows: {
    style: {
      "&:not(:last-of-type)": {
        border: "0",
      },
    },
  },
  cells: {
    style: {
      "&:not(:first-of-type)": {
        borderLeft: "2px solid #f8f8f8",
      },
      padding: "8px 10px",
      fontSize: "14px",
      backgroundColor: "#ffffff",
      verticalAlign: "top",
      minWidth: "0",
    },
  },
};

export const columns = [
  {
    name: "Item",
    selector: (row) => row.itemName,
    sortable: true,
    width: "20%",
    maxWidth: "250px",
    wrap: true,
  },
  {
    name: "Description",
    width: "40%",
    selector: (row) => row.description,
    minWidth: "200px",
    wrap: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
    maxWidth: "90px",
    sortable: true,
    right: true,
  },
  {
    name: "Price",
    selector: (row) => row.rate,
    sortable: true,
    right: true,
    wrap: true,
    cell: (row) => <div className="currency">{formatCurrency(row.rate)}</div>,
  },
  {
    name: "Amount",
    selector: (row) => row.total,
    right: true,
    wrap: true,
    cell: (row) => <div className="currency">{formatCurrency(row.total)}</div>,
  },
];

export const columnsForLateFee = [
  {
    name: "Reference Number",
    selector: (row) => row.referenceNumber,
    width: "33%",
  },
  {
    name: "Late Fee Amount",
    selector: (row) => row.lateFeeAmount,
    width: "33%",
    cell: (row) => (
      <div className="currency">
        {formatCurrency(row.lateFeeAmount)}
        <span> ({row.status})</span>
      </div>
    ),
  },
  {
    name: "Incurred Date",
    cell: (row) => <div className="currency">{formatDDMMMYYYY(new Date(row.createdDateTime))}</div>,
  },
];

/* <InvoiceTemplate />
  |
  |   ========================================
  |   |  KycWarning                           |
  |   ========================================
  |
  |   <Invoice>
  |
  |------------------------------------------------|
  |               <Invoice.Header />
  |
  | Invoice No. =====                       Logo
  | Issued Date  ====                       ======
  | Project Title =====
  |------------------------------------------------|
  |               <Invoice.Subheader />
  |
  | Client Info                      Freelancer Info
  | ==========                       =============
  | ==========                       =============
  |------------------------------------------------|
  |               <Invoice.Table />
  |
  | Item Name   Description   Qty   Rate    Total
  | =======     =========     ==    ===     =====
  | =======     =========     ==    ===     =====
  | =======     =========     ==    ===     =====
  |
  |
  |                               Total =========
  |------------------------------------------------|
  |               <Invoice.Footer>
  | Payment Terms and Info
  | =======================
  | =======================
  |
  | Insert clause as children - <Clause.??? />
  |
  |               </Invoice.Footer>
  |
  |------------------------------------------------|
  |
  |     </Invoice>
  |


  */

const InvoiceTemplate = ({ invoice, user, disableLogoHint }) => {
  const { data: logo } = useGetUserLogo();

  return (
    <>
      {invoice.invoiceType === INVOICE_TYPE_SELF_COLLECT && !user?.isKycApproved && (
        <InvoiceNoKycWarning user={user} />
      )}
      <div
        className={clsx({
          "rounded-top-0":
            invoice.invoiceType === INVOICE_TYPE_SELF_COLLECT || !user?.isKycApproved,
        })}
      >
        <Invoice>
          <Invoice.Header {...invoice} logo={logo} disableLogoHint={disableLogoHint} />
          <Invoice.Subheader {...invoice} />
          <Invoice.Table invoice={invoice} />
          {invoice.notes && <Invoice.Notes notes={invoice.notes} />}
          <Invoice.Footer issuedDateTime={invoice.issuedDateTime} paymentDue={invoice.paymentDue}>
            {user?.isKycApproved && (
              <Clause.Assignment
                fullName={invoice.fullName}
                invoiceType={invoice?.invoiceType}
                user={user}
              />
            )}
            <Clause.LatePaymentClause lateFeeRate={invoice.lateFeeRate} />
          </Invoice.Footer>
        </Invoice>
      </div>
    </>
  );
};

export default InvoiceTemplate;
