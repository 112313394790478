import styled from "styled-components";
import { Popover } from "react-bootstrap";

import Color from "utils/colours";

export const BoostSettingContainer = styled.div`
  padding: 24px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  border-radius: 16px;
  width: 100%;
  color: ${(props) => props.theme.color.textSecondary};
  .small-text {
    font-size: 13px;
  }
`;

BoostSettingContainer.Banner = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  border: 1px solid ${(props) => props.theme.color.borderSecondary};
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 24px;

  img {
    width: 24px;
  }
  p {
    margin: 0;
    font-weight: 600;
    color: ${Color.dark700};
  }
`;

BoostSettingContainer.Splitter = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.borderSecondary};
`;

BoostSettingContainer.Select = styled.select`
  width: 100% !important;
  cursor: pointer;
  padding-right: 30px;
`;

BoostSettingContainer.SelectContainer = styled.div`
  width: 100%;
  position: relative;
  .icon-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: ${Color.dark300};
  }
`;

BoostSettingContainer.FormSwitch = styled.div`
  label {
    display: flex;
    align-items: center;
  }
  &:not(:last-child) {
    padding-bottom: 12px;
  }
  input {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  input:checked + label .switch::before {
    transform: translateX(20px);
  }
  input:checked + label .switch {
    background-color: ${Color.navi600};
  }
`;

BoostSettingContainer.LabelSwitch = styled.label`
  width: 100%;
  gap: 8px;
  .icon {
    color: ${Color.navi500};
  }
  .switch {
    width: 44px;
    height: 22px;
    border-radius: 12px;
    background-color: ${Color.dark300};
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 1px;
      background-color: ${Color.neutral0};
      border-radius: 50%;
      transition: 0.4s;
    }
  }
`;

export const PopoverStyled = styled(Popover)`
  width: 300px;
  max-width: none;
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  .arrow {
    display: none;
  }
`;
