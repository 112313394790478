import { useQuery } from "react-query";

import useApi from "./useApi";

export const useBoost = () => {
  const { Private } = useApi();

  return useQuery("boostData", async () => {
    const { data } = await Private.get("boosts");

    return data;
  });
};
