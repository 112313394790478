import PropTypes from "prop-types";

import { formatCurrency } from "utils/commonHelpers";

const CellRewardsUnlocked = ({ unlockedRewards, accumulatedRewards }) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">
      {formatCurrency(+unlockedRewards + +accumulatedRewards)}
    </span>
  </div>
);

CellRewardsUnlocked.propTypes = {
  unlockedRewards: PropTypes.number.isRequired,
};

export default CellRewardsUnlocked;
