import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { allCountries } from "utils/commonHelpers";
import { InputWithAutocomplete } from "ui-lib/components/input";
import { useGetClients } from "hooks/invoices";
import useDebounce from "hooks/useDebounce";
import { LyteButton } from "components/common/buttons";
import FormikInput from "components/form/FormikInput";
import FormikDropdown from "components/form/FormikDropdown";
import Color from "utils/colours";
import Divider from "components/common/divider";

const EmailBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  background-color: ${Color.navi50};
`;

const InvoiceClientForm = () => {
  const [clientNameSearchKey, setClientNameSearchKey] = useState("");
  const [emailSearchKey, setEmailSearchKey] = useState("");
  const [businessNameSearchKey, setBusinessNameSearchKey] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const { setFieldValue, values, validateForm, setTouched } = useFormikContext();

  // Debounced search
  const debouncedClientNameSearchKey = useDebounce(clientNameSearchKey);
  const debouncedEmailSearchKey = useDebounce(emailSearchKey);
  const debouncedBusinessNameSearchKey = useDebounce(businessNameSearchKey);

  const { data: { data: { data: clients = [] } = {} } = {}, refetch: handleClientNameSearch } =
    useGetClients({
      searchKey: debouncedClientNameSearchKey,
      options: {
        enabled: !!debouncedClientNameSearchKey,
        refetchOnWindowFocus: false,
        refethOnMount: false,
        onSettled: () => setIsSearching(false),
      },
    });

  const { data: { data: { data: emails = [] } = {} } = {}, refetch: handleEmailSearch } =
    useGetClients({
      searchKey: debouncedEmailSearchKey,
      options: {
        enabled: !!debouncedEmailSearchKey,
        refetchOnWindowFocus: false,
        refethOnMount: false,
        onSettled: () => setIsSearching(false),
      },
    });

  const {
    data: { data: { data: businessNames = [] } = {} } = {},
    refetch: handleBusinessNameSearch,
  } = useGetClients({
    searchKey: debouncedBusinessNameSearchKey,
    options: {
      enabled: debouncedBusinessNameSearchKey?.length > 2,
      refetchOnWindowFocus: false,
      refethOnMount: false,
      onSettled: () => {
        setIsSearching(false);
      },
    },
  });

  const onClientNameChangeHandler = useCallback((value) => {
    setClientNameSearchKey(value);
  });

  const onEmailChangeHandler = useCallback((value) => {
    setEmailSearchKey(value);
  });

  const onBusinessNameChangeHandler = useCallback((value) => {
    setBusinessNameSearchKey(value);
  });

  const onSelectedHandler = useCallback(async (selected) => {
    // poluate the rest of the fields with the selected client here

    setFieldValue("clientName", selected?.name || selected?.contactPerson);
    setFieldValue("email", selected?.email);
    setFieldValue("businessName", selected?.businessName);
    setFieldValue("businessRegistrationCode", selected?.businessRegistrationCode || null);
    setFieldValue("mobileCountryCode", selected?.mobileCountryCode || 65);
    setFieldValue("mobileNumber", selected?.mobileNumber || "-");
    setFieldValue("addressLine1", selected?.addressLine1);
    setFieldValue("addressLine2", selected?.addressLine2);
    setFieldValue("country", selected?.country || "Singapore");
    setFieldValue("postalCode", selected?.postalCode || "");
    setFieldValue("businessContactPersonId", selected?.contactPersonId || null);

    setTimeout(() => {
      const allFieldNames = Object.keys(values);
      const touchedFields = allFieldNames.reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {});
      setTouched(touchedFields);
      validateForm();
    }, 200);
  });

  const onFocusHandler = useCallback((e) => {
    if (e.target.value.trim() === "") {
      return;
    }

    if (e.target.name === "clientName") {
      setClientNameSearchKey(e.target.value);
      setIsSearching(true);
      handleClientNameSearch();
    }

    if (e.target.name === "email") {
      setClientNameSearchKey(e.target.value);
      setIsSearching(true);
      handleEmailSearch();
    }

    if (e.target.name === "businessName") {
      setBusinessNameSearchKey(e.target.value);
      setIsSearching(true);
      handleBusinessNameSearch();
    }
  });

  useEffect(() => {
    if (debouncedClientNameSearchKey) {
      handleClientNameSearch();
    }

    if (debouncedEmailSearchKey) {
      handleEmailSearch();
    }

    if (debouncedBusinessNameSearchKey && debouncedBusinessNameSearchKey.length > 2) {
      handleBusinessNameSearch();
    }

    if (
      clientNameSearchKey ||
      emailSearchKey ||
      (businessNameSearchKey && businessNameSearchKey?.length > 2)
    ) {
      setIsSearching(true);
    }
  }, [
    debouncedClientNameSearchKey,
    debouncedEmailSearchKey,
    debouncedBusinessNameSearchKey,
    clientNameSearchKey,
    businessNameSearchKey,
  ]);

  const [ccEmails, setCCEmails] = useState("");
  const [ccEmailChips, setCCEmailChips] = useState([]);
  const [ccError, setCCError] = useState(null);

  const handleChange = (e) => {
    setCCEmails(e.target.value);
    setCCError(null);
  };

  const isEmail = (email) => /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/.test(email);

  const isInList = (email) => ccEmailChips.includes(email);

  const isValid = (email) => {
    let error = null;

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (error) {
      setCCError(error);

      return false;
    }

    return true;
  };

  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      if (ccEmails && isValid(ccEmails)) {
        setCCEmailChips([...ccEmailChips, ccEmails]);
        setFieldValue("ccEmails", [...ccEmailChips, ccEmails]);
        setCCEmails("");
      }
    }
  };

  const handleDelete = (toBeRemoved) => {
    setCCEmailChips(ccEmailChips.filter((email) => email !== toBeRemoved));
  };

  return (
    <form id="invoiceClientForm">
      <div className="form-con clearfix">
        <Row>
          <Col lg={6}>
            <InputWithAutocomplete
              async
              name="businessName"
              label="Company/Business Name"
              suggestions={businessNames.map((name) => ({
                ...name,
                customLabel: `${name.businessName} - ${name.name}`,
              }))}
              labelKey="businessName"
              onInputChange={onBusinessNameChangeHandler}
              onSelected={onSelectedHandler}
              value={values.businessName}
              onFocus={onFocusHandler}
              suggestionLabel="customLabel"
            />
          </Col>
          <Col lg={6}>
            <InputWithAutocomplete
              async
              name="clientName"
              suggestions={clients}
              label="Client Name"
              labelKey="name"
              onInputChange={onClientNameChangeHandler}
              isLoading={isSearching}
              onSelected={onSelectedHandler}
              value={values.clientName}
              onFocus={onFocusHandler}
              isRequired
            />
          </Col>
          <Col lg={6}>
            <InputWithAutocomplete
              async
              name="email"
              suggestions={emails}
              label="Email"
              labelKey="email"
              onInputChange={onEmailChangeHandler}
              isLoading={isSearching}
              onSelected={onSelectedHandler}
              value={values.email}
              onFocus={onFocusHandler}
              isRequired
            />
          </Col>

          <Col lg={6} className="position-relative">
            <FormikDropdown name="mobileCountryCode" isRequired isStacked>
              <>
                {allCountries.map((country) => (
                  <option
                    key={country.iso2}
                    value={+country.dialCode}
                  >{`+${country.dialCode}`}</option>
                ))}
              </>
            </FormikDropdown>
            <FormikInput
              name="mobileNumber"
              label="Phone Number"
              isRequired
              type="text"
              style={{ paddingLeft: "100px" }}
            />
          </Col>
          <Col lg={6}>
            <FormikInput
              as="textarea"
              value={ccEmails}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Type email address and press enter"
              name="ccemails"
              label="CC Emails"
              description={ccError && <p className="mt-1 text-danger">{ccError}</p>}
            />
            <div className="d-flex flex-wrap gap-2">
              {ccEmailChips?.map((email) => (
                <EmailBadge key={email} data-cypress-id={`cypress-chips-ccemails-${email}`}>
                  <span>{email}</span>
                  <LyteButton
                    className="justify-content-center p-0"
                    style={{ minWidth: "auto", width: "20px", height: "20px" }}
                    type="button"
                    onClick={() => handleDelete(email)}
                  >
                    &times;
                  </LyteButton>
                </EmailBadge>
              ))}
            </div>
          </Col>
          <Col xs={12}>
            <Divider className="my-4" />
          </Col>
          <Col lg={6}>
            <FormikInput name="addressLine1" label="Address Line 1" as="textarea" />
          </Col>
          <Col lg={6}>
            <FormikInput name="addressLine2" label="Address Line 2" as="textarea" />
          </Col>
          <Col lg={6}>
            <FormikDropdown name="country" label="Country" isRequired>
              <>
                {allCountries.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </>
            </FormikDropdown>
          </Col>
          <Col lg={3}>
            <FormikInput name="postalCode" label="Postal Code" />
          </Col>
        </Row>
      </div>
      <div>
        <small>Fields with asterisk (*) are required</small>
      </div>
    </form>
  );
};

export default InvoiceClientForm;
