import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import Loader from "components/common/Loader";
import { useGetPublicInvoiceByRef } from "hooks/invoices";
import TableBase from "ui-lib/components/table-base";

import { columns } from "../cashout/CashoutSummary.columns";

import InvoiceDetailsButtons from "./InvoiceDetails.buttons";

const InvoiceCashout = () => {
  const { identifier } = useParams();
  const { data: { data: invoice } = {} } = useGetPublicInvoiceByRef(identifier);

  return !invoice ? (
    <Loader />
  ) : (
    <Container className="py-5">
      <InvoiceDetailsButtons invoice={invoice} state="cashout" />
      <div className="mb-3 d-flex flex-column mt-3">
        <h3>Cashout</h3>
      </div>
      <TableBase data={invoice.cashouts} columns={columns} pagination />
    </Container>
  );
};

export default InvoiceCashout;
