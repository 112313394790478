import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import useApi from "hooks/useApi";
import IllustInvoiceSent from "images/illust/illust-boost-first-time-experience.png";

import {
  BoostTm,
  BoostIntroductionContainer,
  Heading,
  SubText,
  Image,
} from "./BoostIntroduction.styles";

export const LyteBoostTm = ({ variant }) => (
  <BoostTm className={variant === "white" && "text-white"}>
    <span>Boost</span>
  </BoostTm>
);

const BoostIntroduction = () => {
  const { Private } = useApi();

  const { data: boostSettings } = useQuery("boostSettings", async () => {
    const { data } = await Private.get("boosts/setting");

    return data;
  });

  return (
    <BoostIntroductionContainer className="col">
      <Row className="justify-content-center">
        <Col md="8">
          <Heading>Hooray, your invoice has been sent!</Heading>

          {boostSettings?.value === null && (
            <Heading>
              Have you set up <LyteBoostTm />?
            </Heading>
          )}

          <Image src={IllustInvoiceSent} className="image my-5 col-md-6 col-8 mx-auto" />

          <SubText>
            Hooray, your invoice has been sent. LytePay rewards you for letting us manage your
            income. Set up your default Boost amount now to get rewarded seamlessly once your client
            pays.
          </SubText>

          <div className="text-center">
            <Link className="col-md-6 global-button py-3" to="/boost/settings">
              <strong>
                Set up <LyteBoostTm variant="white" />
              </strong>
            </Link>
          </div>
        </Col>
      </Row>
    </BoostIntroductionContainer>
  );
};

export default BoostIntroduction;
