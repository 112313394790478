import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { StyledField, StyledLabel } from "./styles";

const CustomDatePicker = ({ field, form, ...rest }) => {
  const range = (start, end, step = 1) => {
    const length = Math.floor((end - start) / step) + 1;
    return Array.from({ length }, (_, index) => start + index * step);
  };
  const years = range(1960, new Date().getFullYear());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <DatePicker
      className="form-control"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={new Date(date).getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[new Date(date).getMonth()]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={field.value}
      onChange={(date) => {
        form.setFieldValue(field.name, date);
      }}
      dateFormat="dd/MM/yyyy"
      fixedHeight
      placeholderText={rest.placeholder}
    />
  );
};

const FormikDatepicker = ({
  label,
  type,
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  description,
  ...restProps
}) => {
  const [field, meta] = useField(name);

  return (
    <StyledField className={`field-container ${className}`}>
      {label && <StyledLabel htmlFor={name}>{`${label} ${isRequired ? "*" : ""}`}</StyledLabel>}
      <Field
        component={CustomDatePicker}
        id={name}
        name={name}
        className="form-control date-control"
        disabled={disabled}
        placeholder={placeholder}
        {...restProps}
      />
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && <ErrorMessage name={name} component="div" className="error-text" />}
    </StyledField>
  );
};

FormikDatepicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikDatepicker.defaultProps = {
  label: "",
  className: "",
  type: "input",
  placeholder: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
};

export default FormikDatepicker;
