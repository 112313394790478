import { useCallback, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { formatDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";

import FormikInput from "components/form/FormikInput";
import FormikDatePicker from "components/form/FormikDatepicker";
import FormikDropdown from "components/form/FormikDropdown";
import { StyledField, StyledIcon, StyledLabel } from "components/form/FormikDatepicker/styles";
import { useContents } from "hooks/contents";
import FormikCheckbox from "components/form/FormikCheckbox";

import { DRAFT, INVOICE_TYPES } from "./constants";
import InvoiceDetailTable from "./InvoiceDetailTable";
import { ItemRowContainer } from "./InvoiceDetailTable/styles";
import InvoiceDocumentUploader from "./invoiceDocumentUploader";

const InvoiceDetailForm = ({ setCanNext, user, values: dataValues }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const { data: dataContent, isLoading } = useContents();

  const invoiceTypes = [
    {
      value: INVOICE_TYPES.LYTE,
      key: "Lyte Collect",
    },
    {
      value: INVOICE_TYPES.SELF,
      key: "Self Collect",
    },
  ];

  const big3mediaChecker = values?.businessRegistrationCode?.toString() === "201318353D";
  const cawChecker = values?.businessRegistrationCode?.toString() === "201222832R";

  useEffect(() => {
    if (big3mediaChecker) {
      const dueDateTime = moment(values.issuedDateTime, "YYYY-MM-DD")
        .add(45, "days")
        .format("YYYY-MM-DD");
      setFieldValue("dueDateTime", dueDateTime);
      const issued = moment(values.issuedDateTime, "YYYY-MM-DD");
      const due = moment(dueDateTime, "YYYY-MM-DD");
      const diff = due.diff(issued, "days");
      setFieldValue("paymentDueDays", diff);
      setFieldValue("invoiceType", INVOICE_TYPES.LYTE);
      setFieldValue("lateFeeRate", 0);
    }
    if (cawChecker) {
      const dueDateTime = moment(values.issuedDateTime, "YYYY-MM-DD")
        .add(30, "days")
        .format("YYYY-MM-DD");
      setFieldValue("dueDateTime", dueDateTime);
      const issued = moment(values.issuedDateTime, "YYYY-MM-DD");
      const due = moment(dueDateTime, "YYYY-MM-DD");
      const diff = due.diff(issued, "days");
      setFieldValue("paymentDueDays", diff);
      setFieldValue("invoiceType", INVOICE_TYPES.LYTE);
      setFieldValue("lateFeeRate", 0);
    }
  }, [dataValues, values, dataContent]);

  // To check whether the user inputted the email for business invoice manually or automatically
  const lateFeeCheck = useCallback(() => {
    const emailExist = dataContent?.businessContactPersons.some(
      (person) => person.email === values.email || person.alternateEmail === values.email
    );
    if (emailExist) return false;
    return true;
  }, [dataValues, values, dataContent]);

  const handleDueTimeOverlay = (children, classNames, props) => {
    const dayOverlay = [
      {
        value: 7,
        isDisabled: cawChecker,
      },
      {
        value: 14,
        isDisabled: cawChecker,
      },
      {
        value: 30,
        isDisabled: false,
      },
      {
        value: 60,
        isDisabled: false,
      },
    ];
    return (
      <div className={classNames.overlayWrapper} {...props}>
        <div
          className={classNames.overlay}
          style={{
            width: "270px",
          }}
        >
          {children}
          {/* Within Days Buttons */}
          {dayOverlay.map(
            (day, index) =>
              !day.isDisabled && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <span
                  role="button"
                  tabIndex={index}
                  className="col-6 btn btn-link bg-light"
                  onClick={() => {
                    setFieldValue("paymentDueDays", day.value);
                    const issued = moment(values.issuedDateTime, "YYYY-MM-DD");
                    const due = issued.add(day.value, "days").format("YYYY-MM-DD");
                    setFieldTouched("dueDateTime", true);
                    setFieldValue("dueDateTime", due);
                  }}
                >
                  {day.value} days
                </span>
              )
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <form id="invoiceDetailForm">
        <div className="form-con clearfix">
          <Row>
            <Col lg={4}>
              <FormikInput name="invoiceNumber" label="Invoice Number" isRequired type="text" />
            </Col>
            <Col lg={3}>
              <FormikDatePicker
                label="Date Issued"
                name="issuedDateTime"
                isRequired
                formatDate={(date) => formatDate(date, "DD MMM YYYY")}
                value={formatDate(values.issuedDateTime, "DD MMM YYYY")}
                placeholder={formatDate(values.issuedDateTime, "DD MMM YYYY")}
                onDayChange={(day) => {
                  setFieldValue("issuedDateTime", day.toISOString().slice(0, 10));
                  const issued = moment(values.issuedDateTime, "YYYY-MM-DD");
                  const due = moment(values.dueDateTime, "YYYY-MM-DD");
                  const diff = due.diff(issued, "days");
                  const newDue = () => {
                    if (big3mediaChecker) {
                      return moment(day, "YYYY-MM-DD").add(45, "days").format("YYYY-MM-DD");
                    }
                    if (cawChecker) {
                      return moment(day, "YYYY-MM-DD").add(30, "days").format("YYYY-MM-DD");
                    }
                    return moment(day, "YYYY-MM-DD")
                      .add(values.paymentDueDays, "days")
                      .format("YYYY-MM-DD");
                  };
                  setFieldValue("dueDateTime", newDue());
                  setFieldValue("paymentDueDays", diff);
                  setFieldTouched("issuedDateTime", true);
                }}
                dayPickerProps={
                  big3mediaChecker
                    ? {
                        disabledDays: {
                          from: moment().startOf("year").toDate(),
                          to: moment(new Date()).subtract(1, "days").toDate(),
                        },
                      }
                    : {}
                }
              />
            </Col>
            {!big3mediaChecker && (
              <Col lg={3}>
                <Field name="dueDateTime">
                  {({ meta, form }) => (
                    <StyledField
                      className={`field-container ${
                        meta?.touched && meta?.error ? "form-invalid" : ""
                      } ${meta?.value && meta?.touched && !meta?.error ? "form-success" : ""}`}
                    >
                      <StyledLabel htmlFor="dueDateTime">
                        Payment Due* &nbsp;
                        <span className="small text-muted">{`Within ${form.values.paymentDueDays} day(s)`}</span>
                      </StyledLabel>
                      <div className="position-relative">
                        <DayPickerInput
                          formatDate={(date) => formatDate(date, "DD MMM YYYY")}
                          value={formatDate(form.values.dueDateTime, "DD MMM YYYY")}
                          placeholder={formatDate(form.values.dueDateTime, "DD MMM YYYY")}
                          onDayChange={(day, modifiers, pickerMethods) => {
                            const issued = moment(form.values.issuedDateTime, "YYYY-MM-DD");
                            const due = moment(day, "YYYY-MM-DD");
                            const diff = due.diff(issued, "days");
                            form.setFieldValue("paymentDueDays", diff);
                            form.setFieldValue("dueDateTime", due.format("YYYY-MM-DD"));
                            setFieldTouched("dueDateTime", true);
                            pickerMethods.hideDayPicker();
                          }}
                          dayPickerProps={{
                            month: new Date(form.values.issuedDateTime),
                            fromMonth: new Date(form.values.issuedDateTime),
                            disabledDays: {
                              from: moment().startOf("year").toDate(),
                              to: cawChecker
                                ? moment(form.values.issuedDateTime).add(29, "days").toDate()
                                : moment(form.values.issuedDateTime).subtract(1, "days").toDate(),
                            },
                          }}
                          inputProps={{
                            className: "form-control",
                            onChange: (e) => {
                              const issued = moment(form.values.issuedDateTime, "YYYY-MM-DD");
                              const due = moment(e.target.value, "YYYY-MM-DD");
                              const diff = due.diff(issued, "days");
                              form.setFieldValue("paymentDueDays", diff);
                            },
                            disabled: big3mediaChecker,
                          }}
                          classNames={{
                            overlayWrapper: "DayPickerInput-OverlayWrapper",
                            overlay: "DayPickerInput-Overlay rounded-lg",
                          }}
                          overlayComponent={({ children, classNames, ...props }) =>
                            handleDueTimeOverlay(children, classNames, props)
                          }
                        />
                        <StyledIcon>
                          <FontAwesomeIcon icon={faCalendar} />
                        </StyledIcon>
                      </div>
                      {meta?.touched && (
                        <ErrorMessage
                          data-cypress-id="cypress-daypicker-dueDateTime-error-message"
                          name="dueDateTime"
                          component="div"
                          className="error-text"
                        />
                      )}
                    </StyledField>
                  )}
                </Field>
              </Col>
            )}
            <Col lg={2}>
              <FormikDropdown name="currency" label="Currency" isRequired>
                {dataContent?.currencies?.map(({ value, key }) => (
                  <option label={value} value={value} key={key}>
                    {value}
                  </option>
                ))}
              </FormikDropdown>
            </Col>
            <Col lg={7}>
              <FormikInput name="projectTitle" label="Project Title" type="text" />
            </Col>
            <Col lg={3}>
              <FormikDropdown
                name="invoiceType"
                label="Payment Type"
                isRequired
                disabled={cawChecker || big3mediaChecker}
              >
                {invoiceTypes?.map(({ value, key }) => (
                  <option label={key} value={value} key={key}>
                    {value}
                  </option>
                ))}
              </FormikDropdown>
            </Col>
            {user?.country?.toLowerCase() === "id" && (
              <Col lg={2}>
                <FormikInput name="vatValue" label="VAT(%)" type="number" />
              </Col>
            )}
            {lateFeeCheck() && (
              <Col lg={2}>
                <FormikDropdown
                  name="lateFeeRate"
                  label="Late Fee Rate"
                  disabled={!values.sendReminderEmail}
                >
                  {dataContent?.lateFees?.map(({ value, key }) => (
                    <option label={`${key}%`} value={value} key={key}>
                      {`${value}%`}
                    </option>
                  ))}
                </FormikDropdown>
              </Col>
            )}
            <Col xs={12}>
              <FormikCheckbox name="showAddress">Include my Home Address.</FormikCheckbox>
            </Col>
          </Row>
        </div>
      </form>

      {big3mediaChecker && (
        <ItemRowContainer className="no-gutters py-3">
          <Col xs={12}>
            <StyledLabel>Supporting Document(s)</StyledLabel>
            <InvoiceDocumentUploader values={values} setFieldValue={setFieldValue} />
          </Col>
        </ItemRowContainer>
      )}
      <ItemRowContainer>
        <InvoiceDetailTable setCanNext={setCanNext} />
      </ItemRowContainer>
    </>
  );
};

InvoiceDetailForm.propTypes = {
  setCanNext: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isKycApproved: PropTypes.bool.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoiceDetailForm;
