export const CURRENT = "current";
export const ABORTED = "aborted";
export const COMPLETED = "completed";

export const BOOST_STATUSES = [
  { id: CURRENT, value: CURRENT, name: "Ongoing" },
  { id: ABORTED, value: ABORTED, name: "Aborted" },
  { id: COMPLETED, value: COMPLETED, name: "Completed" },
];

export const FILTER_OPTIONS = [
  {
    id: "",
    name: "All Status",
    value: "",
  },
  ...BOOST_STATUSES,
];
