import PropTypes from "prop-types";
import { useField } from "formik";

// This HOC requires formik. Your Component should be a Formik component (ex: Field).

const withCharacterCounter = (Component) => {
  const SubComponent = ({
    separator,
    counterEl: CounterElement,
    counterElClassName,
    container: { el: Wrapper, className: wrapperClassName },
    allowExceed,
    ...props
  }) => {
    const { maxLength: limit } = props;
    const [field, meta] = useField(props);
    const { value: formikFieldValue = "" } = field;
    const combinedProps = { ...props, ...field, maxLength: allowExceed ? undefined : limit };

    return (
      <Wrapper className={wrapperClassName}>
        <Component {...combinedProps} />
        <div
          className={`d-flex mt-1 align-items-top ${
            meta.error ? "justify-content-between" : "justify-content-end"
          }`}
        >
          {meta.error && <small className="text-danger mr-1">{meta.error}</small>}
          <CounterElement
            className={`${counterElClassName} ${
              (formikFieldValue || "").length > limit && "text-danger"
            } text-right `}
            style={{ whiteSpace: "nowrap" }}
          >
            {(formikFieldValue || "").length} {separator} {limit}
          </CounterElement>
        </div>
      </Wrapper>
    );
  };

  SubComponent.defaultProps = {
    maxLength: 100,
    separator: "of",
    counterEl: "small",
    counterElClassName: "input-with-counter",
    container: {
      el: "div",
      className: "container-className",
    },
    allowExceed: false,
  };

  SubComponent.propTypes = {
    maxLength: PropTypes.number,
    separator: PropTypes.string,
    counterEl: PropTypes.string,
    counterElClassName: PropTypes.string,
    container: PropTypes.shape({
      el: PropTypes.string,
      className: PropTypes.string,
    }),
    allowExceed: PropTypes.bool,
  };

  return SubComponent;
};

export { withCharacterCounter };
