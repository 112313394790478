import clsx from "clsx";

const NoKycWarning = ({ className }) => (
  <div className={clsx(className, "alert alert-warning")}>
    <p className="font-weight-bold">You have not completed your identity verification</p>
    <p>
      <strong>Without verifying your identity</strong>, you will not be able to Collect with
      LytePay, which is necessary for taking part in our rewards program Boost.
    </p>
    <a
      href={`${process.env.REACT_APP_LP_BASE_URL}/kyc/illustration`}
      target="_blank"
      rel="noreferrer"
      className="global-button"
    >
      Verify Identity Now
    </a>
  </div>
);

export default NoKycWarning;
