import { Container } from "react-bootstrap";
import { useParams, Switch, Route, useHistory } from "react-router-dom";
import { Formik } from "formik";

import FreelancerType from "views/public/signup/onboarding-steps/components/freelancer-type";
import AudienceSource from "views/public/signup/onboarding-steps/components/audience-source";
import LyteUsage from "views/public/signup/onboarding-steps/components/lyte-usage";
import Header from "views/public/signup/components/header";
import { useUserDataState } from "providers/UserDataProvider";

import { OnboardingContainer } from "./styles";
import { OnboardingSchema } from "./schema";
import { useGetOnboardingKey } from "./service";

const OnboardingPage = () => {
  const { userData } = useUserDataState();
  const { step } = useParams();
  const { replace } = useHistory();

  const { data: key, isLoading } = useGetOnboardingKey({
    onSuccess: (data) => {
      if (!data) replace("/");
    },
  });

  const initialValues = {
    key,
    intention: [],
    intentionOthers: "",
    audienceSource: [],
    audienceSourceOthers: "",
    freelancerType: "",
    freelancerRoles: [],
    referralCode: "",
  };

  const handleStepCheck = () => {
    switch (step) {
      case "audience":
        return 5;
      case "freelancer-type":
        return 6;
      default:
        return 4;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <OnboardingContainer>
      <Container className="content-container pb-5">
        <Header currentStep={handleStepCheck()} userData={userData} />
        <Formik
          initialValues={initialValues}
          validationSchema={OnboardingSchema}
          enableReinitialize
          validateOnMount
        >
          <Switch>
            <Route path="/onboarding/lyte-usage" render={() => <LyteUsage userData={userData} />} />
            <Route
              path="/onboarding/audience"
              render={() => <AudienceSource userData={userData} />}
            />
            <Route
              path="/onboarding/freelancer-type"
              render={() => <FreelancerType userData={userData} />}
            />
          </Switch>
        </Formik>
      </Container>
    </OnboardingContainer>
  );
};

export default OnboardingPage;
