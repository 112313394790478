import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Toaster } from "react-hot-toast";
import { CookiesProvider } from "react-cookie";

import Auth0Provider from "./auth/Auth0Provider";
import App from "./App";
import "./main.scss";
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const queryClient = new QueryClient();

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: process.env.REACT_APP_SENTRY_ENV !== "local",
    }),
  ],
});

ReactDOM.render(
  <Router>
    <Auth0Provider>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <App />
          <Toaster
            toastOptions={{
              duration: 7000,
              position: "top-center",
            }}
          />
        </CookiesProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
