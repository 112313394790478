import { allCountries as countryList } from "country-telephone-data";
import currency from "currency.js";

export const allCountries = [
  {
    name: "Singapore",
    iso2: "sg",
    dialCode: "65",
    priority: 0,
    format: "+.. ....-....",
  },
  ...countryList.filter((country) => country.iso2 !== "sg"),
];

export const formatNumber = (number, minimumFractionDigits = 2) =>
  new Intl.NumberFormat("en-SG", {
    minimumFractionDigits,
  }).format(number);

export const formatCurrency = (number, withCurrencySymbol = true, customOptions = {}) => {
  const symbol = localStorage.getItem("userCurrency") || "SGD";
  const options = {
    symbol: withCurrencySymbol ? `${symbol}` : "",
    separator: ",",
    pattern: `! #`,
    precision: Math.max(2, Math.min(5, (number || 0).toString().split(".")[1]?.length || 0)),
  };
  return currency(number || 0, { ...options, ...customOptions }).format();
};

export const formatYYYYMMDD = (date) => {
  const dtFormat = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  });

  return dtFormat.format(date).split("/").reverse().join("-");
};

export const formatDDMMMYYYY = (date) => {
  const dtFormat = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  return dtFormat.format(date).split("/").reverse().join("-");
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  // Credit: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
};

export const fixedNumber = (value, number = 2) => {
  const formattedNumber = value.toFixed(number).replace(/\.?0+$/, "");
  return formattedNumber;
};

export const isFeatureEnabled = (key) => {
  const feature = process.env[`REACT_APP_FEATURE_${key}`];
  return feature === "true";
};
