import { Card, Row, Col, Image, OverlayTrigger, Popover } from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import BoostIcon from "images/svg/rocket-icon.svg";
import AdvanceIcon from "images/svg/advance-dashboard-icon.svg";
import InvoiceIcon from "images/svg/invoice-dashboard-icon.svg";
import OutstandingIcon from "images/svg/outstanding-payment-icon.svg";
import { useUserBoostDashboard } from "hooks/boost";
import { formatCurrency } from "utils/commonHelpers";
import Color from "utils/colours";

const PopoverStyled = styled(Popover)`
  width: 300px;
  max-width: none;
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;

  background-color: ${(props) => props.theme.color.cardBackground};

  .arrow {
    display: none;
  }
`;

const RowStyled = styled(Row)`
  .col-6 {
    &:first-of-type {
      border-right: 1px solid ${(props) => props.theme.color.borderDefault};
    }
    h5 {
      font-weight: 700;
      margin: 0;
    }
  }
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 8px;
  cursor: pointer;
  p {
    margin: 0;
  }
`;

CtaContainer.Icon = styled.div`
  padding: 16px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.color.borderBlue};
  background-color: ${(props) => props.theme.color.cardBackground};
`;

const LyteMoneyCard = ({ advanceListData }) => {
  const { push } = useHistory();

  const { data } = useUserBoostDashboard();

  return (
    <>
      <Card className="py-3 px-4 my-2" style={{ borderRadius: "10px", position: "relative" }}>
        <RowStyled>
          <Col xs={6} className="py-1 px-3 d-flex flex-column gap-3">
            <Image src={BoostIcon} width={24} />

            {!data ? (
              <p className="text-muted">Loading...</p>
            ) : (
              <div>
                <p className="m-0 d-flex align-items-center gap-2">
                  Currently Boosting
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <PopoverStyled>
                        <p className="m-0">
                          Total of ongoing Boost principal amount and accumulated rewards from
                          Boost.
                        </p>
                      </PopoverStyled>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color={Color.dark300} />
                  </OverlayTrigger>
                </p>
                <h5>{formatCurrency(data.lockedRewards)}</h5>
              </div>
            )}
          </Col>
          <Col xs={6} className="py-1 px-3 d-flex flex-column gap-3">
            <Image src={OutstandingIcon} width={24} />

            {!data ? (
              <p className="text-muted">Loading...</p>
            ) : (
              <div>
                <p className="m-0 d-flex align-items-center">Outstanding Payments</p>
                <h5>{formatCurrency(data.uncollectedInvoice)}</h5>
              </div>
            )}
          </Col>
        </RowStyled>
      </Card>
      <Row className="mt-4 justify-content-evenly">
        <Col xs={4}>
          <CtaContainer id="cta-invoice-dashboard" onClick={() => push("/invoice/create/1")}>
            <CtaContainer.Icon>
              <Image src={InvoiceIcon} className="img-fluid" />
            </CtaContainer.Icon>
            <p>Create Invoice</p>
          </CtaContainer>
        </Col>
        {advanceListData?.length > 0 && (
          <Col xs={4}>
            <CtaContainer onClick={() => push("/advancesummary")}>
              <CtaContainer.Icon>
                <Image src={AdvanceIcon} className="img-fluid" />
              </CtaContainer.Icon>
              <p>Advance</p>
            </CtaContainer>
          </Col>
        )}
        <Col xs={4}>
          <CtaContainer onClick={() => push("/boost/summary")}>
            <CtaContainer.Icon>
              <Image src={BoostIcon} className="img-fluid" />
            </CtaContainer.Icon>
            <p>Boost</p>
          </CtaContainer>
        </Col>
      </Row>
    </>
  );
};

export default LyteMoneyCard;
