import { Formik } from "formik";
import { useLocation, Redirect } from "react-router-dom";
import { useEffect } from "react";

import Loader from "components/common/Loader";
import { useUserData } from "hooks/useUserData";
import Error401 from "views/public/errors/Error401";

import { BankDetailSchema } from "./schema";
import BankAccountUpdateForm from "./BankAccountUpdateForm";

const BankAccountUpdate = () => {
  const { data: user } = useUserData();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!user) {
    return <Loader />;
  }

  if (!user.fromOnboarding) {
    return <Error401 />;
  }

  if (!user.bankAccount) {
    return <Redirect to="/bank-verification" />;
  }

  return (
    <section className="invoice py-5">
      <div className="container">
        <section className="invoice py-0">
          <div className="container">
            <h5>
              <strong>Update Bank Account</strong>
            </h5>
            <div className="invoice-reject py-2">
              <div className="bg-white py-3">
                <div className="col-md-12">
                  <Formik
                    initialValues={user.bankAccount}
                    validationSchema={BankDetailSchema}
                    enableReinitialize
                    validateOnChange
                    validateOnBlur
                    validateOnMount
                  >
                    <BankAccountUpdateForm />
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default BankAccountUpdate;
