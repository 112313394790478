export const profileType = (type) => {
  switch (type?.toLowerCase()) {
    case "tutor":
      return "Tuition Teacher";
    case "propertyagent":
      return "Property Agent";
    default:
      return "Creative Freelancer";
  }
};
