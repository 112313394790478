import { down } from "styled-breakpoints";
import styled from "styled-components";

import Color from "utils/colours";

export const SignupContainer = styled.div`
  font-family: "Inter";
  color: ${Color.dark700};
  min-height: 100%;
  background-color: ${Color.neutral0};
  position: relative;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: ${Color.dark900};
  }

  .content-container {
    min-height: 400px;
  }

  ${down("md")} {
    padding-top: 130px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${down("sm")} {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
