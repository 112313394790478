import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Container, Row, Button, Image } from "react-bootstrap";

import SingpassLogo from "images/svg/kyc/singpass-logo.svg";
import OnfidoLogo from "images/svg/kyc/onfido-logo.svg";
import ManualLogo from "images/svg/kyc/manual-logo.svg";
import { useUserDataSingleUse } from "hooks/useUserData";
import Loader from "components/common/Loader";
import KycSubmitted from "components/kyc/onfido-kyc-success";
import { PENDING_VERIFICATION } from "components/kyc/constants";
import { isFeatureEnabled } from "utils/commonHelpers";

const Kyc = () => {
  const myInfoEnabled = isFeatureEnabled("MYINFO");
  const manualKycEnabled = isFeatureEnabled("MANUAL");
  const { replace, push } = useHistory();

  const { data: userData, isLoading } = useUserDataSingleUse({
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (userData?.isKycApproved) {
      replace("/");
    }
  }, [replace, userData]);

  if (isLoading) {
    return <Loader />;
  }

  if (userData?.kycStatus === PENDING_VERIFICATION) {
    return <KycSubmitted />;
  }

  return (
    <Container className="py-5 text-center">
      <h3>
        <em>
          <strong>Identity </strong>
        </em>
        Verification
      </h3>
      <p className="mb-8">Please select how you would like to do your Identity Verification</p>
      <Row className="justify-content-center gap-4 gap-md-0">
        {myInfoEnabled && (
          <Col xs={12} md={4}>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Image
                    src={SingpassLogo}
                    alt="Singpass Logo"
                    className="w-50"
                    style={{ height: "50px" }}
                  />
                </Card.Title>
                <Card.Text>
                  <p>
                    Speed up eKYC processes for individual users easily with data from government
                    sources.
                  </p>
                </Card.Text>
                <Button
                  variant="primary"
                  className="rounded-pill px-4"
                  onClick={() => push("/kyc/mi")}
                >
                  Verify Now
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>
                <Image
                  src={OnfidoLogo}
                  alt="Onfido Logo"
                  className="w-50"
                  style={{ height: "50px" }}
                />
              </Card.Title>
              <Card.Text>
                <p>
                  Build trust from the start and beyond with a complete, AI-powered digital identity
                  solution.
                </p>
              </Card.Text>
              <Button
                variant="primary"
                className="rounded-pill px-4"
                onClick={() => push("/kyc/onfido")}
              >
                Verify Now
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {manualKycEnabled && (
          <Col xs={12} md={4}>
            <Card>
              <Card.Body>
                <Card.Title>
                  <Image
                    src={ManualLogo}
                    alt="Manual KYC Logo"
                    className="w-50"
                    style={{ height: "50px" }}
                  />
                </Card.Title>
                <Card.Text>
                  <p>
                    Manually fill up a form with your personal information and upload a photo of
                    your passport or ID card.
                  </p>
                </Card.Text>
                <Button
                  variant="primary"
                  className="rounded-pill px-4"
                  onClick={() => push("/kyc/manual")}
                >
                  Verify Now
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Kyc;
