import clsx from "clsx";

import { KYC_STATUSES, PENDING_SUBMISSION, PENDING_VERIFICATION } from "../constants";

const NoKycWarning = ({ className, kycStatus, children }) => (
  <div
    className={clsx(
      className,
      "alert",
      KYC_STATUSES[kycStatus]?.alertVariant || "alert-warning",
      "mb-4"
    )}
  >
    {children}
    {kycStatus !== PENDING_VERIFICATION && (
      <a
        href={
          kycStatus === PENDING_SUBMISSION
            ? `${process.env.REACT_APP_ORIGIN_URI}/kyc`
            : `${process.env.REACT_APP_LP_BASE_URL}/kyc/illustration`
        }
        target="_blank"
        rel="noreferrer"
        className="global-button"
      >
        Verify your identity now
      </a>
    )}
  </div>
);

export default NoKycWarning;
