import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from "prop-types";

import { ButtonStyled } from "./styles";

const LyteButton = ({
  isLoading = false,
  size = "md",
  variant = "primary",
  children,
  ...restProps
}) => (
  <ButtonStyled
    size={size}
    variant={variant}
    className={`${restProps?.classname || ""}`}
    disabled={restProps?.disabled || isLoading}
    {...restProps}
  >
    {isLoading && <FontAwesomeIcon className={`icon ${isLoading && "loading"}`} icon={faSpinner} />}
    {children}
  </ButtonStyled>
);

LyteButton.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["md", "sm"]),
  variant: PropTypes.oneOf(["primary", "secondary", "desctructive", "ghost", "default"]),
  children: PropTypes.node,
};

LyteButton.defaultProps = {
  isLoading: false,
  size: "md",
  variant: "primary",
  children: undefined,
};

export default LyteButton;
