import { Image } from "react-bootstrap";
import styled from "styled-components";

export const StyledUploadProfile = styled.div`
  font-size: 16px;
  color: #3363ff;
  font-weight: 500;
`;

StyledUploadProfile.Dropzone = styled.div`
  width: 100%;
  height: 250px;
  border: 2px dashed #4e4f58;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f1f4fd;

  .progress {
    width: 100%;
  }
`;

StyledUploadProfile.Image = styled(Image)`
  height: 100%;
  max-height: 200px;
`;
