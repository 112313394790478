import { useHistory, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import moment from "moment";

import Loader from "components/common/Loader";
import { LyteBtn } from "ui-lib/components/buttons";
import StatusBadge from "components/common/StatusBadge";
import Select, { InvoiceStatusOptions } from "components/common/forms/Select";
import { useUserData } from "hooks/useUserData";
import { usePostDuplicateInvoice, useGetPublicInvoiceByRef } from "hooks/invoices";
import InvoiceSummaryForm from "components/invoice/InvoiceSummaryForm";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";
import { LyteButton } from "components/common/buttons";

import InvoiceDetailsButtons from "./InvoiceDetails.buttons";
import { ACCEPTED, PARTIALLY_PAID, PENDING, REJECTED } from "./InvoiceSummary.constants";

const CardStyled = styled.div`
  border-radius: 8px;
  padding: 48px 92px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};

  ${down("lg")} {
    padding: 16px;
  }
`;

const InvoiceDetails = () => {
  const { identifier } = useParams();
  const { push } = useHistory();

  const { data: { data: invoice } = {}, refetch } = useGetPublicInvoiceByRef(identifier);

  const { data: user } = useUserData();

  const { mutate: duplicateHandler, isLoading } = usePostDuplicateInvoice(invoice?.invoiceNumber);

  const STATUS_EDITTABLE_INVOICES = [PENDING, ACCEPTED, REJECTED, PARTIALLY_PAID];
  const invoiceStatus = invoice?.status?.replace("_", " ").replace("-", " ");

  const handleUserReassignInvoice = {
    ...user,

    fullName: invoice?.fullName,
    email: invoice?.email,
    addressLine1: invoice?.addressLine1,
    addressLine2: invoice?.addressLine2,
    postalCode: invoice?.postalCode,
    mobileCountryCode: invoice?.mobileCountryCode,
    mobileNumber: invoice?.mobileNumber,
  };
  const handleInvoiceReassignInvoice = {
    ...invoice,
    dueDateTime: moment(invoice?.issuedDateTime).add(invoice?.paymentDue, "days"),
    clientName: invoice?.invoiceClient?.name,
    businessName: invoice?.invoiceClient?.businessName,
    addressLine1: invoice?.invoiceClient?.addressLine1,
    addressLine2: invoice?.invoiceClient?.addressLine2,
    country: invoice?.invoiceClient?.country,
    postalCode: invoice?.invoiceClient?.postalCode,
    mobileCountryCode: invoice?.invoiceClient?.mobileCountryCode,
    mobileNumber: invoice?.invoiceClient?.mobileNumber,
    email: invoice?.invoiceClient?.email,
  };

  return !user || !invoice ? (
    <Loader />
  ) : (
    <Container className="py-5">
      <InvoiceDetailsButtons invoice={invoice} state="details" />
      <div className="mb-3 d-flex flex-column align-items-center mt-3">
        <h3>
          {invoiceStatus === "draft" && "(DRAFT) "}Invoice {invoice.invoiceNumber}
        </h3>

        {STATUS_EDITTABLE_INVOICES.includes(invoiceStatus.replace(" ", "")) && (
          <div className="d-flex align-items-center" style={{ gap: 10 }}>
            <label htmlFor="invoiceStatus">Update Status:</label>
            <Select
              id="invoiceStatus"
              showSelect
              style={{ width: "180px", marginTop: 10 }}
              defaultOption={InvoiceStatusOptions[invoice.status]}
              invoiceReference={invoice.invoiceReference}
              invoiceNumber={invoice.invoiceNumber}
              invoiceStatus={invoice.status}
              refetch={refetch}
              totalAmountDue={invoice.totalAmountDue}
            />
          </div>
        )}

        <InvoiceStatuses status={invoice?.status} size="md" pills />
      </div>
      <CardStyled>
        <InvoiceSummaryForm
          user={handleUserReassignInvoice}
          values={handleInvoiceReassignInvoice}
          isDetail
        />
      </CardStyled>

      <div className="mt-3 gap-3 d-flex justify-content-end">
        <LyteButton variant="secondary" onClick={() => push("/invoice/listing")}>
          Back
        </LyteButton>
        {invoiceStatus === "draft" && (
          <>
            <LyteButton onClick={() => push(`/invoice/edit/1/${invoice.invoiceReference}`)}>
              Edit
            </LyteButton>
            <LyteButton disabled={isLoading} onClick={() => duplicateHandler({ id: invoice.id })}>
              Duplicate
            </LyteButton>
          </>
        )}
      </div>
    </Container>
  );
};

export default InvoiceDetails;
