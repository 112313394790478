import PropTypes from "prop-types";

import { PENDING_SUBMISSION, PENDING_VERIFICATION, APPROVED, REJECTED, DELETED } from "./constants";

const DefaultBankAccountDetails = ({ bankAccount }) => (
  <>
    {(!bankAccount ||
      bankAccount?.status === PENDING_SUBMISSION ||
      bankAccount?.status === DELETED) && (
      <div>
        <p>
          In order to send and receive money from an Invoice, we will need to have at least one bank
          account verified. Bank account verification takes 1 - 2 business days.
        </p>
        <a
          href={`${process.env.REACT_APP_LP_BASE_URL}/account/bank`}
          target="_blank"
          rel="noreferrer"
          className="global-button dotted"
        >
          + Add Bank Account
        </a>
      </div>
    )}

    {bankAccount?.status === REJECTED && (
      <div>
        {bankAccount?.rejectReason ? (
          <>
            <p>Your bank account has been rejected with reason: {bankAccount?.rejectReason}.</p>
            <p>Please delete the previous submission and submit again.</p>
          </>
        ) : (
          <p>
            Your bank account has been rejected. Please delete the previous submission and submit
            again.
          </p>
        )}
        <a
          href={`${process.env.REACT_APP_LP_BASE_URL}/account/bank`}
          target="_blank"
          rel="noreferrer"
          className="global-button dotted"
        >
          + Add Bank Account
        </a>
      </div>
    )}

    {bankAccount?.status === PENDING_VERIFICATION && (
      <div>
        <p>
          Your bank account has been submitted and will take 1-2 working days to be verified and
          approved.
        </p>
      </div>
    )}

    {bankAccount?.status === APPROVED && (
      <div className="">
        <p className="mb-0">You have successfully linked your bank account.</p>
      </div>
    )}
  </>
);

DefaultBankAccountDetails.defaultProps = {
  bankAccount: {},
};

DefaultBankAccountDetails.propTypes = {
  bankAccount: PropTypes.shape({
    rejectReason: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default DefaultBankAccountDetails;
