import { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import Button from "views/public/signup/components/Button";
import { HandleCountryCodeChecker } from "views/public/signup/onboarding-steps/utils";

import { OtpContainer, OtpInputContainer } from "./styles";
import { useRequestOtp, useValidateOtp } from "./service";

const Otp = () => {
  const { push, replace } = useHistory();
  const { values } = useFormikContext();

  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(90);
  const inputRefs = useRef([]);
  const focusedInputRef = useRef(null);

  const { mutate: mutateRequestOtp } = useRequestOtp();
  const { mutate: mutateValidateOtp } = useValidateOtp();

  useEffect(() => {
    mutateRequestOtp({
      key: values.key,
      mobileNumber: HandleCountryCodeChecker(values.country) + values.mobileNumber,
    });
  }, [values.mobileNumber]);

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[0].focus();
      // eslint-disable-next-line prefer-destructuring
      focusedInputRef.current = inputRefs.current[0];
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
      if (focusedInputRef.current && timer > 0) {
        const currentIndex = inputRefs.current.findIndex((input) => {
          if (input.id === focusedInputRef.current.id) {
            return input.id;
          }
          return 0;
        });
        inputRefs.current[currentIndex].focus();
      }
    }, 1000);
    if (timer === 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleInputFocus = (index) => {
    focusedInputRef.current = inputRefs.current[index];
  };

  const handleKeyUp = (e, index) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      setOtp((prevOTP) => {
        const updatedOTP = [...prevOTP];
        updatedOTP[index] = "";
        return updatedOTP;
      });
      if (index !== 0) {
        setTimeout(() => {
          inputRefs.current[index - 1].focus();
        }, 0);
      }
    } else if (e.keyCode >= 48 && e.keyCode <= 57) {
      const { key } = e;
      const numericValue = key.replace(/\D/g, "");
      setOtp((prevOTP) => {
        const updatedOTP = [...prevOTP];
        updatedOTP[index] = numericValue;
        return updatedOTP;
      });

      if (numericValue !== "" && index < 5) {
        setTimeout(() => {
          inputRefs.current[index + 1].focus();
        }, 0);
      } else if (numericValue === "") {
        setTimeout(() => {
          inputRefs.current[index].focus();
        }, 0);
      }
    }
  };

  const handleOtpChange = (e, index) => e.target.value;

  const handleResendClick = () => {
    mutateRequestOtp({
      key: values.key,
      mobileNumber: HandleCountryCodeChecker(values.country) + values.mobileNumber,
    });
    setTimer(90);
  };

  const handlePaste = (e, index) => {
    const pasteData = e.clipboardData.getData("text/plain");
    const pastedOtp = pasteData.slice(0, 6 - +index).split("");
    const updatedOtp = [...otp];
    let tempIndex = index;
    pastedOtp.forEach((digit) => {
      updatedOtp[tempIndex] = digit;
      tempIndex += 1;
    });
    setOtp(updatedOtp);
  };

  const splitNumber = (number) =>
    number.replace(
      /^(\+\d{1,2})(\d{4,})/,
      (_, countryCode, rest) => `${countryCode} ${rest.replace(/(\d{4})/g, "$1 ")}`
    );

  const handleDisableButton = () => {
    if (otp.some((value) => value === "")) return true;
    return false;
  };

  const handleSubmit = () => {
    mutateValidateOtp(
      {
        key: values.key,
        otp: otp.join(""),
      },
      {
        onSuccess: () => {
          push(`/signup/steps/4`);
        },
      }
    );
  };

  useEffect(() => {
    if (!values.mobileNumber) replace("/signup/steps/2");
  }, [values]);

  return (
    <OtpContainer>
      <Row>
        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <h3 className="font-weight-bold pb-2">
            Please enter the one-time password that we sent to
            <span className="phone-number">{` ${splitNumber(
              HandleCountryCodeChecker(values.country) + values.mobileNumber
            )}`}</span>
          </h3>
          <p className="d-flex align-items-center">
            Didn't receive a code?
            {timer > 0 ? (
              ` Resend OTP in ${Math.floor(timer / 60)
                .toString()
                .padStart(2, "0")}:${(timer % 60).toString().padStart(2, "0")}`
            ) : (
              <Button type="button" variant="link" className="ml-1" onClick={handleResendClick}>
                Resend OTP
              </Button>
            )}
          </p>
          <OtpInputContainer>
            {otp.map((digit, index) => (
              <div key={Math.random() * 1000}>
                <input
                  className="otp-input"
                  id={index}
                  type="tel"
                  maxLength="1"
                  value={digit || ""}
                  onChange={(e) => handleOtpChange(e, index)}
                  onClick={(e) => e.target.select()}
                  onPaste={(e) => handlePaste(e, index)}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                  onFocus={(e) => handleInputFocus(index)}
                  // eslint-disable-next-line no-return-assign
                  ref={(input) => (inputRefs.current[index] = input)}
                />
              </div>
            ))}
          </OtpInputContainer>
          <Button
            type="button"
            className="w-100 mt-4"
            disabled={handleDisableButton()}
            onClick={handleSubmit}
          >
            Verify
          </Button>
          <p className="mt-4 text-center d-flex align-items-center justify-content-center">
            Need help?
            <Button className="ml-1" type="button" variant="link">
              Contact us here
            </Button>
          </p>
        </Col>
      </Row>
    </OtpContainer>
  );
};

export default Otp;
