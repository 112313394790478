import { useEffect, useState } from "react";
import { Field, useField, useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Button from "views/public/signup/components/Button";
import FormikInput from "views/public/signup/components/FormikInput";

import { FREELANCER_TYPES } from "./constants";
import { RadioContainer, SelectContainer } from "./styles";
import { useGetUserRoles, useSubmitOnboarding } from "./service";

const FreelancerTypeSelect = ({ name }) => {
  const [field, meta] = useField(name);

  return (
    <RadioContainer>
      {FREELANCER_TYPES.map((type) => (
        <label
          htmlFor={`freelance-type-${type.value}-time`}
          className={`radio-container m-0 ${meta?.value === type.value ? "checked" : ""}`}
        >
          <Field
            type="radio"
            value={type.value}
            id={`freelance-type-${type.value}-time`}
            name={name}
          />
          <p>{type.label}</p>
        </label>
      ))}
    </RadioContainer>
  );
};

const FreelancerType = ({ userData }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { values, errors, setFieldValue } = useFormikContext();
  const { replace } = useHistory();

  const isDisabled =
    "freelancerType" in errors || "freelancerRoles" in errors || "referralCode" in errors;

  const { mutate: mutateSubmitOnboard } = useSubmitOnboarding();
  const { data: dataUserRoles } = useGetUserRoles();

  const handleSubmit = () => {
    mutateSubmitOnboard(
      {
        ...values,
        intention: values.intention.join(","),
        audienceSource: values.audienceSource.join(","),
        freelancerRoles: values.freelancerRoles.map((cat) => cat.roleId),
      },
      {
        onSuccess: () => {
          localStorage.removeItem("signUpIdentifier");
          replace("/signup/complete");
        },
      }
    );
  };

  const handleSelectChange = (selectedOption, setFieldValue) => {
    setSelectedOptions(selectedOption);
    setFieldValue("freelancerRoles", selectedOption);
  };

  const handleCancelOptions = (opt) => {
    setSelectedOptions((selected) =>
      selected.filter((selectedOpt) => selectedOpt.roleId !== opt.roleId)
    );
  };

  return (
    <div>
      <form>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h3 className="font-weight-bold pb-4">Lastly, what type of freelancer are you?</h3>
            <p>I am a...</p>
            <FreelancerTypeSelect name="freelancerType" />
            <SelectContainer>
              <div>
                <p className="mt-4 mb-1 font-weight-bold">
                  What do you freelance as? (Select all that apply)
                </p>
                <Select
                  isMulti
                  isSearchable
                  placeholder="Start typing to search..."
                  getOptionLabel={(option) => option.role}
                  getOptionValue={(option) => option.roleId}
                  escapeClearsValue={false}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  value={selectedOptions}
                  onChange={(select) => handleSelectChange(select, setFieldValue)}
                  options={dataUserRoles || []}
                  className="lyte-select-container"
                  classNamePrefix="lyte-select"
                />
              </div>
              <div className="d-flex align-items-center flex-wrap gap-2">
                {selectedOptions.map((opt) => (
                  <div className="label-options">
                    {opt.role}
                    <FontAwesomeIcon icon={faTimes} onClick={() => handleCancelOptions(opt)} />
                  </div>
                ))}
              </div>
            </SelectContainer>
            <FormikInput className="mt-4" label="Referral Code" name="referralCode" />
            <Button
              type="button"
              className="w-100 mt-4 mb-3"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Complete setup
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default FreelancerType;
