import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Row,
  Modal,
} from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import LyteLogo from "images/logo/Lyte_logo_blue.png";
import LyteLogoWhite from "images/svg/lyte-logo.svg";
import LyteHeaderProject1 from "images/svg/advance-icon.svg";
import LyteHeaderProject2 from "images/svg/invoice-icon.svg";
import LyteHeaderProject3 from "images/svg/boost-icon.svg";
import LyteIllust1 from "images/illust/illust-landing-1.png";
import LyteIllustLanding1 from "images/illust/illust-landing-profile.png";
import LyteIllustLanding2 from "images/illust/illust-woman-in-cloud.png";
import LyteIllustLanding3 from "images/illust/illust-landing-email.png";
import LyteIllustLanding4 from "images/illust/illust-landing-flying-free.png";
import LyteIllustLanding5 from "images/svg/Cashrewards-01.svg";
import LyteIllustBoost1 from "images/illust/Boost-How-01.png";
import LyteIllustBoost2 from "images/illust/Boost-How-02.png";
import LyteIllustBoost3 from "images/illust/Boost-How-03.png";
import LyteIllustBoost4 from "images/illust/Boost-How-04.png";

import {
  FirstSection,
  FooterContainer,
  HomepageContainer,
  SecondSection,
  StyledNavLink,
  ThirdSection,
} from "./styled";
import { homepageValidationSchema } from "./schema";

const Homepage = () => {
  const isSm = useBreakpoint(down("sm"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const BoostList = [
    {
      id: 1,
      image: LyteIllustBoost1,
      title: "Emma creates your online profile",
      content:
        "Your AI-Created online profile is a gateway for new leads and clients to view your portfolio and interact with your digital assistant.​",
      color: "blue",
    },
    {
      id: 2,
      image: LyteIllustBoost2,
      title: "Emma creates your online profile",
      content:
        "Emma contacts your existing and past clients to harvest testimonials and reviews, and automatically updates your profile page. Magic!​",
      color: "green",
    },
    {
      id: 3,
      image: LyteIllustBoost3,
      title: "Manage & Invoice your Clients​",
      content:
        "Activate Emma to automatically invoice your clients at every payment milestone. Emma will gently nudge your clients to make payment promptly.​​",
      color: "purple",
    },
    {
      id: 4,
      image: LyteIllustBoost4,
      title: "Obtain Referrals​",
      content:
        "Emma will reaches out to satisfied clients, cultivates referrals and help you with your new client onboarding.",
      color: "pink",
    },
  ];

  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const initialValues = homepageValidationSchema.cast(
    { jobType: "Tutor", mobileNumber: "" },
    { stripUnknown: true }
  );

  return (
    <HomepageContainer>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop
        keyboard={false}
        style={{ borderRadius: "1.4rem" }}
      >
        <Modal.Body className="p-5">
          <Formik
            initialValues={initialValues}
            validationSchema={homepageValidationSchema}
            enableReinitialize
            validateOnChange
            validateOnBlur
            validateOnMount
            onSubmit={(values) =>
              window.open(
                `https://wa.me/6588390489?text=Hi%20I'm%20a%20${
                  values.jobType === "Tutor" ? "Tutor" : "Property%20Agent"
                }`,
                "_self"
              )
            }
          >
            {({ values, errors, isValid, handleChange }) => (
              <Form className="container-fluid">
                <h6 className="text-center mb-3">
                  In order to access Emma today, please fill out the form below and proceed to your
                  AI Assistant.
                </h6>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="jobType">What type of freelancer are you?</label>
                    <select
                      id="jobType"
                      className="form-control w-100"
                      value={values.jobType}
                      onChange={handleChange}
                    >
                      <option value="Tutor" label="Tuition Tutor" />
                      <option value="PropertyAgent" label="Property Agent" />
                    </select>

                    <div
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "20px",
                        color: "#090909",
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label className=" d-block" htmlFor="mobileNumber">
                      What is your Phone Number
                    </label>
                    <div className="d-flex align-items-center gap-2">
                      +65
                      <Field
                        id="mobileNumber"
                        name="mobileNumber"
                        type="text"
                        className="form-number-text form-control w-100"
                        value={values.mobileNumber}
                        placeholder="Enter Mobile Number here"
                      />
                    </div>
                    <small className="text-danger">{errors.mobileNumber}</small>
                  </div>
                </div>
                <Button type="submit" className="w-100" disabled={!isValid}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Nav className="py-2">
        <Container className="header-container">
          <Row>
            <Navbar className="w-100" expand="md">
              <div className="d-flex align-items-center justify-content-between w-100">
                <Image src={LyteLogo} className="img-fluid logo-img" alt="Lyte" />
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              </div>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto pl-3 align-items-center text-nowrap">
                  <NavDropdown className="agents-dropdown" title="For Property Agents">
                    <NavDropdown.Item href="https://lyte.co/property-agents/era-singapore/">
                      <Image src="https://lyte.co/wp-content/uploads/2023/03/era-menu.png" />
                      <span className="pl-2">ERA</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://lyte.co/property-agents/huttons/">
                      <Image src="https://lyte.co/wp-content/uploads/2023/03/huttons-menu.png" />
                      <span className="pl-2">Huttons</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://lyte.co/property-agents/propnex">
                      <Image src="https://lyte.co/wp-content/uploads/2023/03/propnex-menu.png" />
                      <span className="pl-2">PropNex</span>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://lyte.co/property-agents/sri/">
                      <Image src="https://lyte.co/wp-content/uploads/2023/03/sri-menu.png" />
                      <span className="pl-2">SRI</span>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link className="selected" href="#">
                    For Freelancers
                  </Nav.Link>
                  <NavDropdown title="Products">
                    <NavDropdown.Item
                      className="d-flex align-items-center"
                      href="https://lyte.co/products/advance/"
                    >
                      <Image src={LyteHeaderProject1} />
                      <div className="pl-2">
                        Get paid in <br />
                        <b>Advance</b>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="d-flex align-items-center"
                      href="https://lyte.co/products/invoices/"
                    >
                      <Image src={LyteHeaderProject2} />
                      <div className="pl-2">
                        Send and receive <br />
                        <b>Invoice</b> on time
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="d-flex align-items-center"
                      href="https://lyte.co/property-agents/propnex"
                    >
                      <Image src={LyteHeaderProject3} />
                      <div className="pl-2">
                        <b>Boost</b>
                        <br />
                        for Freelancers
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="https://lyte.co/corporate/">Corporate</Nav.Link>
                  <Nav.Link href="https://web.lytepay.co/login">Log In</Nav.Link>
                  <Nav.Link className="register-button" href="https://web.lytepay.co/register">
                    Sign Up
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Row>
        </Container>
      </Nav>
      <Container>
        <FirstSection>
          <Row>
            <Col md={6} className="d-flex justify-content-end flex-column">
              <h1>Introducing Emma, Your AI-Powered Assistant for Freelancers</h1>
              <p>
                Emma is our AI-powered business assistant specifically for freelancers in mind. With
                this cutting-edge technology at your fingertips, experience a new level of
                efficiency and productivity so you can focus on what you do best.
              </p>
              <Button onClick={handleShow}>Gain Access to Emma today &nbsp; {">"}</Button>
            </Col>
            <Col md={6}>
              <Image src={LyteIllust1} className="img-fluid" />
            </Col>
          </Row>
        </FirstSection>
        <SecondSection>
          <Row className="my-5 align-items-center">
            <Col md={5}>
              <Image src={LyteIllustLanding1} className="img-fluid" />
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              <h2>Showcase yourself with an AI-Created Profile Page</h2>
              <p>
                Elevate your online presence and make a lasting impression with a personalized
                profile page streamlined by our AI Assistant Emma, within minutes. Highlight your
                skills, experiences and testimonials so you can stand out amongst the rest.
              </p>
            </Col>
          </Row>
          <Row className="my-5 align-items-center flex-row-reverse">
            <Col md={{ span: 5, offset: 1 }}>
              <Image src={LyteIllustLanding2} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h2>
                Generate leads while you sleep (capitalization only on the first word for
                consistency).
              </h2>
              <p>
                Our AI Assistant, Emma actively identifies opportunities, reaches out to clients for
                testimonials and cultivate referrals so you don’t have to. Ensure a steady stream of
                new leads and income. With Emma as your ally, wake up every morning to a growing
                network that can help open doors to new exciting projects.
              </p>
            </Col>
          </Row>
          <Row className="my-5 align-items-center">
            <Col md={5}>
              <Image src={LyteIllustLanding3} className="img-fluid" />
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              <h2>Automate and run your back-end admin tasks effortlessly.</h2>
              <p>
                Spend less time focusing on boring admin tasks and let our AI Assistant, Emma help
                manage your schedule, set up to do lists and automate tasks so you can focus on your
                core work.
              </p>
            </Col>
          </Row>
          <Row className="my-5 align-items-center flex-row-reverse">
            <Col md={{ span: 5, offset: 1 }}>
              <Image src={LyteIllustLanding4} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h2>Easy Invoicing, Prompt Payments.</h2>
              <p>
                Create, send and track customized invoices within seconds. Send automated reminders
                and collect payments via PayNow or Bank Transfers all in one platform - set things
                up for success for you and your clients.
              </p>
            </Col>
          </Row>
          <Row className="my-5 align-items-center">
            <Col md={5}>
              <Image src={LyteIllustLanding5} className="img-fluid" />
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              <h2>Supercharge your invoice with Boost.</h2>
              <p>
                You snooze, we Boost. Tailored for busy freelancers, supercharge your invoice amount
                and receive up to 5% cash reward when you Boost with LytePay. The longer you Boost,
                the more you gain.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button onClick={handleShow}>Enroll for Emma now</Button>
            </Col>
          </Row>
        </SecondSection>
        <ThirdSection>
          <h2 className="text-center pb-5">How does Emma work for you?</h2>
          <Container>
            {!isSm && (
              <Row>
                {BoostList.map((list, index) => (
                  <Col className="cols" key={list.id} xs={6}>
                    <div className={`content d-flex flex-column custom-box ${list.color}`}>
                      <Image src={list.image} className="img-fluid" />
                      <h3 className="pt-4 pb-2">
                        <span>{`0${index + 1}`}</span>
                        {` ${list.title}`}
                      </h3>
                      <p className="pb-4">{list.content}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
            {isSm && (
              <Carousel className="pb-3 " responsive={responsive} showDots arrows={false}>
                {BoostList.map((list, index) => (
                  <Col className="cols h-100" key={list.id}>
                    <div className={`content d-flex flex-column custom-box ${list.color}`}>
                      <Image src={list.image} className="img-fluid" />
                      <h3 className="pt-4 pb-2">
                        <span>{`0${index + 1}`}</span>
                        {` ${list.title}`}
                      </h3>
                      <p className="pb-4">{list.content}</p>
                    </div>
                  </Col>
                ))}
              </Carousel>
            )}
            <div className="w-100 my-5 flex-column d-flex align-items-center justify-content-center">
              <Button
                className="my-5"
                onClick={() => window.open("https://freelancer.lytepay.co/", "_blank")}
              >
                Get Started - It's Free
              </Button>
              <Button
                variant="secondary"
                className="mt-5"
                onClick={() =>
                  window.open("https://lyte.co/products/boost-for-freelancers/", "_blank")
                }
              >
                Boost FAQ
              </Button>
              <Button
                variant="secondary"
                className="mt-4"
                onClick={() =>
                  window.open("https://lyte.co/products/boost-for-freelancers/", "_blank")
                }
              >
                Invoicing FAQ
              </Button>
            </div>
          </Container>
        </ThirdSection>
      </Container>
      <FooterContainer fluid>
        <Container>
          <Row className="pt-5 footer-menu">
            <Col md={4}>
              <img src={LyteLogoWhite} title="Lyte Logo" alt="Lyte Logo" />
            </Col>
            <Col
              md={8}
              className="d-flex justify-content-end align-items-center pb-5 mt-4 flex-column flex-sm-row"
            >
              <span className="mr-sm-5">Follow us on our social</span>
              <div className="d-flex justify-content-center fs-2 gap-4 icon-container">
                <a className="icon" href="https://www.facebook.com/lytepay">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
                <a className="icon" href="https://www.instagram.com/lytepay.property/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a className="icon" href="https://www.linkedin.com/company/lyte-co/">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-menu-container">
                <h4 className="widget-title">Company</h4>
                <ul id="menu-footer-1" className="menu">
                  <li
                    id="menu-item-192"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-192"
                  >
                    <a href="https://lyte.co/corporate/">About Us</a>
                  </li>
                  <li
                    id="menu-item-2033"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2033"
                  >
                    <a href="https://lyte.co/category/articles/">Articles</a>
                  </li>
                  <li
                    id="menu-item-1742"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1742"
                  >
                    <a href="https://lyte.co/careers/">Careers</a>
                  </li>
                  <li
                    id="menu-item-1979"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1979"
                  >
                    <a href="https://lyte.co/policy-centre/">Policy Centre</a>
                  </li>
                  <li
                    id="menu-item-2001"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2001"
                  >
                    <a href="https://lyte.co/terms-of-use/">Terms of Use</a>
                  </li>
                  <li
                    id="menu-item-2005"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2005"
                  >
                    <a href="https://lyte.co/disclaimer/">Disclaimer</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-menu-container">
                <h4 className="widget-title">Lyte for</h4>
                <ul id="menu-footer-2" className="menu">
                  <li
                    id="menu-item-199"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-199"
                  >
                    <a rel="noopener" href="https://lyte.co/property-agents/">
                      Property Agents
                    </a>
                  </li>
                  <li
                    id="menu-item-202"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-202"
                  >
                    <a href="https://lyte.co/property-agents/era-singapore">ERA Agents</a>
                  </li>
                  <li
                    id="menu-item-203"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"
                  >
                    <a href="https://lyte.co/property-agents/huttons/">Huttons Agents</a>
                  </li>
                  <li
                    id="menu-item-830"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-830"
                  >
                    <a href="https://lyte.co/property-agents/sri/">SRI Agents</a>
                  </li>
                  <li
                    id="menu-item-831"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-831"
                  >
                    <a href="https://lyte.co/property-agents/propnex/">PropNex Agents</a>
                  </li>
                  <li
                    id="menu-item-748"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-383 current_page_item menu-item-748"
                  >
                    <a href="https://lyte.co/freelancers/" aria-current="page">
                      Freelancers
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-menu-container">
                <h4 className="widget-title">Solutions</h4>
                <ul id="menu-footer-3" className="menu">
                  <li
                    id="menu-item-749"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-749"
                  >
                    <a href="https://lyte.co/products/">All Products</a>
                  </li>
                  <li
                    id="menu-item-750"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-750"
                  >
                    <a href="https://lyte.co/products/invoices/">Invoice</a>
                  </li>
                  <li
                    id="menu-item-751"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-751"
                  >
                    <a href="https://lyte.co/products/advance/">Advance</a>
                  </li>
                  <li
                    id="menu-item-1630"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1630"
                  >
                    <a href="https://lyte.co/products/boost-for-freelancers/">
                      Boost for Freelancers
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-menu-container">
                <h4 className="widget-title">Contact</h4>
                <div className="textwidget">
                  <address className="m-0">
                    Lyte Ventures Pte Ltd
                    <br />
                    #05-01 JustCo International Plaza
                    <br />
                    10 Anson Road
                    <br />
                    Singapore 079903
                  </address>
                  <a href="mailto:support@lyte.co" alt="LytePay Support Email">
                    support@lyte.co
                  </a>
                  +65 6100 5983
                </div>
              </div>
            </Col>
            <Col className="pt-4 my-5">
              <div className="copyright-container">
                <div>
                  <p>
                    * The number of days to receive your commission may differ depending on the
                    agency you are registered with.
                  </p>
                  <p>
                    Lyte Ventures Pte Ltd is the holding company of the Lyte group of companies.
                    Lytepay Pte Ltd is a subsidiary of Lyte Ventures Pte Ltd. LytePay Pte Ltd is a
                    Major Payment Institution licensed by the Monetary Authority of Singapore (MAS)
                    under the Payment Services Act to provide (i) account issuance service; (ii)
                    domestic money transfer service; and (iii) cross-border money transfer service.
                    Please note that this does not mean you will be able to recover all the money
                    you paid to if LytePay’s business fails. Consumer Advisory: LytePay Pte Ltd is
                    not a moneylender and does not require the approval of the MAS or the Ministry
                    of Law to carry on business. Consumers (users) are advised to read the terms and
                    conditions carefully. If you wish to discuss data protection matters, please
                    contact our Data Protection Officer at dpo@lyteventures.com. Disclaimer: Lyte
                    Ventures Pte Ltd, its officers, employees, representatives or agents shall not
                    be held liable for any damages or injury resulting from your access to, or
                    inability to access this internet site, or from your reliance on any information
                    provided on this site or any accompanying literature or documentation. The
                    information and material provided on this site do not constitute an offer to
                    sell or the solicitation of an offer to buy, any currencies or securities and
                    must not be relied upon in connection with any payment decision.
                  </p>
                </div>
                <p className="copyright">© 2023 LytePay Pte Ltd. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </FooterContainer>
    </HomepageContainer>
  );
};

export default Homepage;
