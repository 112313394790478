export const LYTE_USAGE_TYPES = [
  {
    value: "invoice-creation-tracking",
    label: "Invoice Creation & Tracking",
    disabled: false,
    className: "",
  },
  {
    value: "payment-collection",
    label: "Payment Collection",
    disabled: false,
    className: "",
  },
  {
    value: "boost",
    label: "Boosting for Up to 6% Rewards",
    disabled: false,
    className: "",
  },
  {
    value: "others",
    label: "Others",
    disabled: false,
    className: "",
  },
];
