import { useEffect } from "react";
import { Image, Dropdown, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";

import cashoutIcon from "images/icons/icn-cashout.png";
import lyteLogo from "images/svg/lytepay-logo.svg";
import { useGlobalState } from "providers/GlobalProvider";
import { useUserDataState } from "providers/UserDataProvider";
import { LyteButton } from "components/common/buttons";

const TopNavi = ({ hidden }) => {
  const { logout } = useAuth0();
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { userData } = useUserDataState();

  useEffect(() => {
    const features = JSON.parse(process.env.REACT_APP_USER_FEATURES_LIST);
    if (userData?.userFeatures.includes(features?.lytechat) && window && window.mount) {
      window.mount("lytepay2");
    }
  }, [userData]);

  const isSm = useBreakpoint(down("sm"));

  useEffect(() => {
    switch (params.get("redirect")) {
      case "quickpay":
        push("/quickpay/create");
        break;
      case "boost-settings":
        push("/boost/settings");
        break;
      case "boost-summary":
        push("/boost/summary");
        break;
      default:
        break;
    }
  });

  const { setToggleSidebar } = useGlobalState();

  return (
    <section hidden={hidden}>
      <div className="tn-con row">
        {isSm && (
          <div className="col-3 p-0 d-flex align-items-center">
            <FontAwesomeIcon
              icon={faBars}
              className="d-block"
              style={{ cursor: "pointer" }}
              height={100}
              color="#3363ff"
              onClick={() => setToggleSidebar((prev) => !prev)}
            />
            <Image src={lyteLogo} className="tn-logo pl-2" alt="/" />
          </div>
        )}
        {userData && (userData?.isKycApproved || !userData?.registrationCompletedAt) && (
          <div className="col-9 col-md-12 d-flex justify-content-end align-items-center text-right p-0">
            {!isSm && (
              <div className="d-flex align-items-center gap-4 mr-4">
                <LyteButton
                  id="top-navigation-boost-settings-button"
                  variant="secondary"
                  onClick={() => push("/boost/settings")}
                >
                  Boost Settings
                </LyteButton>
                <LyteButton variant="primary" onClick={() => push("/boost/summary")}>
                  <Image src={cashoutIcon} alt="" />
                  <div className="ml-2">Cashout</div>
                </LyteButton>
              </div>
            )}
            {userData && (
              <Dropdown as="span">
                <Dropdown.Toggle as="span" variant="link" id="dropdown-basic">
                  <Image
                    src={`https://ui-avatars.com/api/?name=${
                      userData.fullName || "L+Y"
                    }&rounded=true&background=3363ff&bold=true&color=fff&size=40`}
                    alt="/"
                    width={isSm ? "30px" : "40px"}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0">
                  <Dropdown.Item as={Link} to="/userprofile">
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => logout()}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default TopNavi;
