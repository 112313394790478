const BoostFaq = [
  {
    header: "How does Boost work for Freelancers",
    description:
      "Boost is a rewards program by LytePay. As long as an invoice was sent and paid by your client via LytePay, you can Boost it. Enjoy rewards of up to 6% depending on your chosen commitment period.",
  },
  {
    header: "How is my reward from Boost calculated?",
    description:
      "The Boost reward of 4-6% is based on the amount you choose to Boost and the commitment period. It will then be pro-rated over the commitment period up to 365 days.",
  },
  {
    header: "Can I Boost the same invoice more than once?",
    description: (
      <>
        <p>
          Yes! Use our AutoBoost feature, and it will automatically reBoost for the same time period
          for up to 180 days.
        </p>
        <p>
          If you prefer to Boost it manually, you have 48 hours to decide if you’d like to reBoost
          the invoice.
        </p>
        <p>
          Rewards will be transferred to your bank account within 48 hours after the Boost period
          ends if no action has been taken.
        </p>
      </>
    ),
  },
  {
    header: "How safe is it to use Boost",
    description:
      "Your earned invoice amount is guaranteed and can be withdrawn at any time when you Boost it with zero risk. Lyte manages our own funds in parallel to the money put into Boost to generate returns that we then share with our users as rewards. Your money is not being actively managed.",
  },

  {
    header: "I load money on to LytePay?",
    description:
      "As we are a non-bank financial institutions, freelancers are not able to load money on to LytePay. You are only able to Boost invoices that have been paid and collected through LytePay.",
  },
  {
    header: "Is there a minimum Boost amount?",
    description: "There is a $50 minimum to Boost your invoice.",
  },
  {
    header: "If I choose to reBoost, will my Boost rewards be compounded?",
    description:
      "Yes. Every subsequent Boost period will include the rewards you earned from the previous Boost.",
  },
  {
    header: "Can I withdraw my money anytime?",
    description: (
      <>
        <p>
          Yes. Just remember that if you withdraw before the Boost period ends, your rewards are
          forfeited.{" "}
        </p>
        <p>
          {" "}
          For example, if you Boost $1,000 for 90 days, but choose to withdraw $800 after 60 days,
          you will not get any rewards. However, you can choose to Boost the remaining $200 for any
          period of your choice.
        </p>
      </>
    ),
  },
  {
    header: "How does the 45-Day 1-time Boost work?",
    description:
      "All existing and new users will have the option for a 1 time, 45-day Boost per account. This allows all users to try out our Boost function without committing to the 90-day commitment period. 45-day Boost are not eligible for reBoost.",
  },
  {
    header: "What are the fees for using Boost?",
    description:
      "Each early withdrawal from the Boost rewards program has a 0.25% service fee subject to a minimum of SGD $0.50. If you complete your Boost until the end of your chosen time period, there will be no fee.",
  },
  {
    header: "Is LytePay licensed by MAS?",
    description:
      "LytePay Pte Ltd is a Major Payment Institution licensed by the Monetary Authority of Singapore (MAS) under the Payment Services Act to provide (i) account issuance service; (ii) domestic money transfer service; and (iii) cross-border money transfer service. We are bound by the rules and regulations of the Republic of Singapore and Payment Services Act.",
  },
];

export default BoostFaq;
