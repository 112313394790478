import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";

import { StyledField, StyledLabel } from "./styles";

const FormikDropdown = ({
  label,
  children,
  lists,
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  description,
  ...restProps
}) => {
  const [field, meta] = useField(name);

  return (
    <StyledField className={`field-container ${className}`}>
      {label && <StyledLabel htmlFor={name}>{`${label} ${isRequired ? "*" : ""}`}</StyledLabel>}
      <Field
        as="select"
        id={name}
        name={name}
        className="form-control select-control"
        disabled={disabled}
        placeholder={placeholder}
        {...restProps}
      >
        {children || lists.length > 0 ? (
          <>
            <option disabled selected value="">
              - Choose One -
            </option>
            {lists?.map((list) => (
              <option key={`${list?.id}-${list?.name}`} value={list?.id}>
                {list?.name}
              </option>
            ))}
          </>
        ) : (
          <option disabled>No Value</option>
        )}
      </Field>
      <div className={`icon-container ${disabled && "disabled"}`}>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && <ErrorMessage name={name} component="div" className="error-text" />}
    </StyledField>
  );
};

FormikDropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  lists: PropTypes.shape(),
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikDropdown.defaultProps = {
  label: "",
  children: null,
  lists: [],
  className: "",
  type: "input",
  placeholder: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
};

export default FormikDropdown;
