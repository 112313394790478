import { useState } from "react";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { StyledField } from "./styles";

const FormikCheckbox = ({ name, className, disabled, children, ...restProps }) => {
  const [field, meta] = useField(name);

  return (
    <Field
      type="checkbox"
      name={name}
      data-cypress-id={`cypress-checkbox-${name}`}
      className="form-control"
      disabled={disabled}
      {...restProps}
    >
      {(fieldProps) => (
        <StyledField
          className={`field-container ${className} ${
            meta?.touched && meta?.error ? "form-invalid" : ""
          } ${meta?.value && meta?.touched && !meta?.error ? "form-success" : ""}`}
        >
          <label className="checkbox-container">
            <input
              name={name}
              className="hidden"
              type="checkbox"
              {...fieldProps.field}
              {...restProps}
            />
            <span className="checkmark-container">
              <span className="icon-container">
                <FontAwesomeIcon className="icon" icon={faCheck} />
              </span>
            </span>
            <div className="label-container">{children}</div>
          </label>
          {meta?.touched && (
            <ErrorMessage
              data-cypress-id={`cypress-checkbox-${name}-error-message`}
              name={name}
              component="div"
              className="error-text"
            />
          )}
        </StyledField>
      )}
    </Field>
  );
};

FormikCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

FormikCheckbox.defaultProps = {
  className: "",
  name: "",
  disabled: false,
  children: "",
};

export default FormikCheckbox;
