import { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from "views/public/signup/components/Button";
import FormikInput from "views/public/signup/components/FormikInput";
import { HandleCountryCodeChecker } from "views/public/signup/onboarding-steps/utils";

import { MobileNumberContainer } from "./styles";

const MobileNumber = () => {
  const { push, replace } = useHistory();
  const { values, errors } = useFormikContext();

  const isDisabled = "mobileNumber" in errors;

  const handleSubmit = () => {
    push(`/signup/steps/3`);
  };

  useEffect(() => {
    if (!values.firstName) replace("/signup/steps/1");
  }, [values]);

  return (
    <div>
      <form>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h3 className="font-weight-bold pb-2">Next, let's verify your mobile number</h3>
            <p className="pb-3">
              We'll send a one-time password (OTP) to your mobile number. This verification helps us
              keep your lyte account secure.
            </p>
            <MobileNumberContainer>
              <FormikInput className="custom-mobile-phone" name="mobileNumber" />
              <div className="country-code">{HandleCountryCodeChecker(values.country)}</div>
            </MobileNumberContainer>
            <Button type="button" className="w-100" disabled={isDisabled} onClick={handleSubmit}>
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default MobileNumber;
