import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { useTheme } from "styled-components";

import PRUP from "documents/LyteVentures-Prohibited_and_Restricted_Use_Policy.pdf";
import CtosPp from "documents/Lytepay-Collection_Terms_of_Service.pdf";
import TableBase from "ui-lib/components/table-base";
import { useGetUserLogo } from "hooks/user";
import { formatCurrency } from "utils/commonHelpers";
import FormikInput from "components/form/FormikInput";
import FormikCheckbox from "components/form/FormikCheckbox";
import { StyledLabel } from "components/form/FormikDatepicker/styles";

import InvoiceTotalCard from "../InvoiceDetailTable/InvoiceTotalCard";
import InvoiceNoKycWarning from "../InvoiceNoKycWarning";
import InvoiceDocumentUploader from "../invoiceDocumentUploader";

import { COLUMNS, CUSTOM_STYLES } from "./constants";
import {
  BankInformationContainer,
  ClientDetailsContainer,
  ItemRows,
  LogoWrapper,
  PaymentDetailsContainer,
  TextClauseDescription,
  TextClauseHeader,
  TextDescription,
} from "./styles";

const percentLateFee = (lateFeeRate) => lateFeeRate || "0";

const InvoiceSummaryForm = ({ values, user, setFieldValue, isDetail }) => {
  const { data: logo } = useGetUserLogo();
  const isMd = useBreakpoint(down("md"));

  const theme = useTheme();

  const handleBankPaymentInvoiceType = () => {
    if (values?.invoiceType === "LyteCollect")
      return (
        <>
          <Row>
            <Col xs={6}>
              <p className="title">Bank</p>
              <h6 className="m-0 font-weight-bold">DBS Bank Ltd</h6>
            </Col>
            <Col xs={6}>
              <p className="title">Account Holder</p>
              <h6 className="m-0 font-weight-bold">LytePay Pte Ltd</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <p className="title">Swift Code</p>
              <h6 className="m-0 font-weight-bold">DBSSSGSG</h6>
            </Col>
            <Col xs={6}>
              <p className="title">Account Number</p>
              <h6 className="m-0 font-weight-bold">072-5295-300</h6>
            </Col>
          </Row>
        </>
      );
    return (
      <>
        <Row>
          <Col xs={6}>
            <p className="title">Bank</p>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.bankName}</h6>
          </Col>
          <Col xs={6}>
            <p className="title">Account Holder</p>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.accountHolderName}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <p className="title">Account Number</p>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.accountNumber}</h6>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <form id="invoiceSummaryForm">
      <Row>
        <Col xs={8} lg={6}>
          <small>Invoice</small>
          <h4 className="font-weight-bold mb-2">{`# ${values.invoiceNumber}`}</h4>
          <p className="mb-0 font-weight-bold">{user?.fullName}</p>
          {user?.mobileNumber && (
            <p className="mb-0">{`+${user?.mobileCountryCode || "65"} ${user?.mobileNumber}`}</p>
          )}
          {user?.email && <p className="mb-0">{user.email}</p>}
          {values?.showAddress && (
            <>
              {user?.addressLine1 && <p className="mb-0">{user.addressLine1}</p>}
              {user?.addressLine2 && <p className="mb-0">{user.addressLine2}</p>}
            </>
          )}
        </Col>
        <Col xs={4} lg={6} className="d-flex justify-content-end">
          <LogoWrapper>
            <LogoWrapper.Logo
              src={
                logo?.size > 0 ? URL.createObjectURL(logo) : "https://ui-avatars.com/api/?size=100"
              }
              alt="invoice-logo"
            />
          </LogoWrapper>
        </Col>
      </Row>
      <ClientDetailsContainer>
        <Row>
          <Col lg={4}>
            <small>Client</small>
            <p className="mb-0 font-weight-bold">{`${values?.clientName || ""}${
              values.businessName ? `, ${values.businessName}` : ""
            }`}</p>
            {values?.mobileNumber && (
              <p className="mb-0">
                {values?.mobileNumber !== "-" && (
                  <span>{`${
                    values?.mobileCountryCode ? `+${values.mobileCountryCode} ` : ""
                  }`}</span>
                )}
                {values?.mobileNumber}
              </p>
            )}
            {values?.email && <p className="mb-0">{`${values?.email}`}</p>}
            <p className="mb-0">{`${values?.addressLine1 ? `${values.addressLine1} ` : ""}${
              values?.addressLine2 ? `${values.addressLine2} ` : ""
            }${values?.country ? `, ${values.country} ` : ""}${
              values?.postalCode ? ` ${values.postalCode} ` : ""
            }`}</p>
          </Col>
          {values?.projectTitle && (
            <Col lg={4}>
              <small>Project</small>
              <p className="mb-0 font-weight-bold">{`${values?.projectTitle || ""}`}</p>
            </Col>
          )}
          <Col lg={4}>
            <div className="d-flex gap-4">
              <div>
                <small>Date Issued</small>
                <p className="mb-0 font-weight-bold">
                  {dayjs(new Date(values?.issuedDateTime)).format("DD MMM YYYY")}
                </p>
              </div>
              <div>
                <small>Due Date</small>
                <p className="mb-0 font-weight-bold">
                  {dayjs(new Date(values?.dueDateTime)).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </ClientDetailsContainer>
      <div>
        <div>
          {isMd ? (
            <div>
              <h6 className="mt-4 font-weight-bold">Item</h6>
              {values.invoiceItems.map((item) => (
                <ItemRows className="mt-4">
                  <Col xs={7}>
                    <small>{item.itemName}</small>
                    <TextDescription dangerouslySetInnerHTML={{ __html: item.description }} />
                  </Col>
                  <Col xs={5}>
                    <div>
                      <small className="font-weight-bold">
                        {formatCurrency(item.quantity * item.rate)}
                      </small>
                    </div>
                    <div>
                      <small>
                        {item.quantity} x {formatCurrency(item.rate)}
                      </small>
                    </div>
                  </Col>
                </ItemRows>
              ))}
            </div>
          ) : (
            <TableBase
              columns={COLUMNS}
              data={values.invoiceItems}
              customStyles={CUSTOM_STYLES(theme)}
              responsive
            />
          )}
        </div>
        <div className="my-3 col-12 p-0">
          <div className="d-flex w-100 justify-content-end">
            <InvoiceTotalCard values={values} readOnly setFieldValue={setFieldValue} />
          </div>
          <div className="row justify-content-start mt-4">
            <Col lg={6}>
              {isDetail ? values?.notes : <FormikInput name="notes" label="Notes" as="textarea" />}
            </Col>
          </div>
        </div>
        {(values?.files?.length > 0 || values?.invoiceFiles?.length > 0) && (
          <Row>
            <Col xs={12}>
              <StyledLabel>Supporting Document(s)</StyledLabel>
              <InvoiceDocumentUploader values={values} readOnly setFieldValue={setFieldValue} />
            </Col>
          </Row>
        )}

        <div className="clearfix" />

        {user?.isKycApproved ? (
          <PaymentDetailsContainer>
            <PaymentDetailsContainer.Title>Payment Details</PaymentDetailsContainer.Title>
            <PaymentDetailsContainer.Clauses>
              <p className="font-weight-bold pt-3">ASSIGNMENT CLAUSE</p>
              <p>
                1.1 &nbsp; {user?.fullName} shall not delegate their obligations under this Contract
                to a third-party, without first receiving the Client’s written permission.
              </p>
              <p className="m-0">
                1.2 &nbsp; {user?.fullName} shall assign their rights and benefits under this
                Contract to Lyte Ventures Pte Ltd or any of its subsidiaries and amounts payable
                under this Contract shall be paid by either of the following:
              </p>
            </PaymentDetailsContainer.Clauses>
            <PaymentDetailsContainer.Clauses>
              <BankInformationContainer>{handleBankPaymentInvoiceType()}</BankInformationContainer>
            </PaymentDetailsContainer.Clauses>
            {values.invoiceType === "LyteCollect" && (
              <PaymentDetailsContainer.Clauses>
                <p className="title">PayNow QR Code</p>
                <div className="text-center mt-2">
                  <img
                    src={process.env.REACT_APP_PAY_NOW_QR}
                    className="img-fluid"
                    alt="PayNow QR Code"
                  />
                </div>
              </PaymentDetailsContainer.Clauses>
            )}
            <PaymentDetailsContainer.Clauses>
              <p>
                1.3 &nbsp; The Client may not assign its rights and delegate its obligations under
                this Contract without permission from {user?.fullName}.
              </p>
              {values.lateFeeRate ? (
                <>
                  <p className="title">LATE PAYMENT CLAUSE</p>
                  <p>
                    All payments and other charges which are not paid on the due date shall be
                    subject to a late payment fee of{" "}
                    {percentLateFee(!isDetail ? values.lateFeeRate : values.lateFeeRate * 100)}% per
                    month on the outstanding amount.
                  </p>
                </>
              ) : (
                ""
              )}
            </PaymentDetailsContainer.Clauses>
          </PaymentDetailsContainer>
        ) : (
          <InvoiceNoKycWarning user={user} />
        )}

        {user?.isKycApproved && !isDetail && (
          <div className="mt-4">
            <FormikCheckbox name="agreeProhibitClause">
              <TextClauseHeader>
                I agree to{" "}
                <a href={PRUP} target="_blank" rel="noreferrer">
                  Lyte's Prohibited and Restricted Use Policy
                </a>
              </TextClauseHeader>
              <TextClauseDescription>
                As LytePay is licensed by MAS as a Major Payment institution, please be assured that
                LytePay does not facilitate the transaction of any illegal or restricted product.
                Therefore, we require all our users to adhere to Lyte's Prohibited and Restricted
                Use of Policy.
              </TextClauseDescription>
            </FormikCheckbox>
            <FormikCheckbox name="agreeCollectionClause">
              <TextClauseHeader>
                I agree to{" "}
                <a href={CtosPp} target="_blank" rel="noreferrer">
                  Lyte's Collection Terms of Service and Privacy Policy
                </a>
              </TextClauseHeader>
            </FormikCheckbox>

            <FormikCheckbox name="ccToSelf">
              <TextClauseHeader>Send a copy to myself</TextClauseHeader>
            </FormikCheckbox>
          </div>
        )}
      </div>
    </form>
  );
};

InvoiceSummaryForm.propTypes = {
  values: PropTypes.shape({
    invoiceNumber: PropTypes.string,
    issuedDateTime: PropTypes.string,
    projectTitle: PropTypes.string,
    clientName: PropTypes.string,
    businessName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    mobileCountryCode: PropTypes.string,
    mobileNumber: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    invoiceItems: PropTypes.arrayOf(PropTypes.shape({})),
    lateFeeRate: PropTypes.number,
    showAddress: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func,
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    countryCode: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    mobileCountryCode: PropTypes.string,
    mobileNumber: PropTypes.string,
    isKycApproved: PropTypes.bool,
  }).isRequired,
  isDetail: PropTypes.bool,
};

InvoiceSummaryForm.defaultProps = {
  setFieldValue: () => {},
  isDetail: false,
};

export default InvoiceSummaryForm;
