import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { APPROVED } from "components/dashboard/bank-account-details/constants";
import IllustInvoiceSent from "images/illust/illust-invoice-sent.png";
import { useUserData } from "hooks/useUserData";

const InvoiceSubmittedContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 50px);
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
`;

const InvoiceSubmitted = () => {
  const { data: user } = useUserData();

  return (
    <InvoiceSubmittedContainer className="invoice-submitted">
      <div className="container">
        <div className="row justify-content-center">
          <div className="p-0 text-center">
            <Heading>
              <strong>Invoice is sent!</strong>
            </Heading>

            <Image src={IllustInvoiceSent} className="image my-4 col-md-6 col-8 mx-auto" />

            {user?.bankAccount?.status !== APPROVED && (
              <>
                <h5>
                  <strong>Have you verified your bank account details?</strong>
                </h5>
                <p>
                  Your invoice has been sent to your client, and we are able to collect for you. For
                  us to transfer payments to you, please link your bank account. Bank account
                  verification takes 1 - 2 business days.
                </p>
                <a
                  href={`${process.env.REACT_APP_LP_BASE_URL}/account/bank`}
                  target="_blank"
                  rel="noreferrer"
                  className="global-button"
                >
                  + Add Bank Account
                </a>
              </>
            )}

            <div className="mt-5">
              <Link to="/invoice/listing" className="color-blue-link">
                Back to Invoices
              </Link>
            </div>
          </div>
        </div>
      </div>
    </InvoiceSubmittedContainer>
  );
};

export default InvoiceSubmitted;
