import BoostIntroduction from "components/boost/boost-introduction";

import { InvoiceCreateSuccessSetBoostContainer } from "./InvoiceCreateSuccessSetBoost.styles";

const InvoiceCreateSuccessSetBoost = () => (
  <InvoiceCreateSuccessSetBoostContainer className="invoice-submitted">
    <div className="container">
      <div className="row justify-content-md-center">
        <BoostIntroduction />
      </div>
    </div>
  </InvoiceCreateSuccessSetBoostContainer>
);

export default InvoiceCreateSuccessSetBoost;
