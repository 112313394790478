import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { LyteBtn } from "components/common/buttons";
import img from "images/illust/illust-hooray.png";

const CashoutSuccess = () => (
  <div className="container text-center" style={{ marginTop: "15%" }}>
    <div className="mx-auto my-auto" style={{ width: "500px" }}>
      <h2 className="font-weight-bolder">Your money is on the way!</h2>
      <Image src={img} alt="401" fluid width={400} />
      <h5 className="my-4">
        You will receive the money in your linked bank account within 2 working days. Thank you for
        using LytePay!
      </h5>
      <NavLink to="/">
        <LyteBtn className="btn-lg bg-dark" style={{ width: "250px", padding: "0.75rem 0" }}>
          Back to Home
        </LyteBtn>
      </NavLink>
    </div>
  </div>
);

export default CashoutSuccess;
