import AdvanceSummary from "views/private/advance/AdvanceSummary";
import Advance from "views/private/advance/Advance";

export const advance = [
  {
    path: "/advancesummary",
    exact: true,
    component: AdvanceSummary,
  },
  { path: "/advance/:advanceReference", component: Advance, exact: true },
];
