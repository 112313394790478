import { useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import { onboardingFlowContent } from "./InvoiceFlow.constants";

const InvoiceFlowCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <Carousel
        style={{ height: "400px" }}
        className="text-center"
        activeIndex={index}
        onSelect={handleSelect}
        interval={null}
        indicators={false}
        prevIcon={
          <FontAwesomeIcon icon={faChevronLeft} color="black" style={{ marginRight: "50%" }} />
        }
        nextIcon={
          <FontAwesomeIcon icon={faChevronRight} color="black" style={{ marginLeft: "50%" }} />
        }
      >
        {onboardingFlowContent.map((e) => (
          <Carousel.Item className="px-5" key={e.id}>
            <div className="text-center">
              <Image src={e.image} height="200px" className="my-4" />
            </div>
            <h4 className="my-2">{e.title}</h4>
            <p>{e.description}</p>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="d-flex justify-content-center mt-4 mt-md-0">
        {onboardingFlowContent.map((e, idx) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            key={e.id}
            role="link"
            tabIndex={idx}
            onClick={() => setIndex(idx)}
            aria-labelledby={`step-${idx + 1}`}
            className={clsx([
              "mx-1",
              "rounded-circle",
              {
                "bg-light": idx !== index,
                "bg-primary": idx === index,
              },
            ])}
            style={{ height: "13px", width: "13px" }}
          />
        ))}
      </div>
    </>
  );
};

export default InvoiceFlowCarousel;
