import * as Yup from "yup";

import { allCountries } from "utils/commonHelpers";

const VALID_ISO2_COUNTRY_CODES = allCountries.map((item) => item.iso2.toUpperCase());

export const userProfileValidationSchema = Yup.object({
  firstName: Yup.string().default("").required("First name is required"),
  lastName: Yup.string().default("").required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email address is required")
    .default("")
    .nullable(),
  mobileCountryCode: Yup.string()
    .default("")
    .required("Mobile countr code is required")
    .oneOf(["SG"], "Invalid mobile country code")
    .nullable(true),
  mobileNumber: Yup.string()
    .default("")
    .required("Mobile number is required")
    .length(8, "Invalid mobile number")
    .nullable(),
  addressLine1: Yup.string()
    .default("")
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure()
    .nullable(),
  addressLine2: Yup.string()
    .default("")
    // eslint-disable-next-line no-template-curly-in-string
    .max(100, "The characters maximum length is ${max}")
    .ensure()
    .nullable(),
  countryCode: Yup.mixed()
    .oneOf(VALID_ISO2_COUNTRY_CODES, "This is not a valid country code")
    .nullable(true),
  postalCode: Yup.string()
    .when("country", {
      is: "Singapore",
      then: Yup.string().matches(/^[0-9]{6}$/g, "Invalid postal code"),
    })
    .nullable(),
});
