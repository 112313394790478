import { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Highlighter from "react-highlight-words";

import { useOnClickOutside } from "hooks/useOnClickOutside";
import FormikInput from "components/form/FormikInput";

const AutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Suggestions = styled(ListGroup)`
  position: absolute;
  top: 65px;
  background: #fff;
  width: 100%;
  z-index: 101;
  max-height: ${(props) => `${props.height}px` || "auto"};
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.08);

  .list-group-item {
    border-left: 0;
    border-right: 0;

    &.active-highlight {
      background-color: #ebebeb;
    }

    &:first-child {
      border-top: 0 !important;
    }
    &:last-child {
      border-bottom: 0 !important;
    }
  }
`;

const Highlight = ({ children }) => <strong>{children}</strong>;

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
};

const InputWithAutocomplete = ({
  name,
  suggestions,
  className,
  async,
  labelKey,
  noResultsText,
  onInputChange,
  height,
  asyncSearchingText,
  dropdownPlaceholder,
  isLoading,
  onSelected,
  onFocus,
  suggestionLabel,
  ...rest
}) => {
  const autocompleteRef = useRef();
  const [field, meta, helper] = useField(name);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([...suggestions]);
  const [activeSuggestion, setActiveSuggestion] = useState(null);
  useOnClickOutside(autocompleteRef, () => setShowSuggestions(false));

  const filteredSuggestionsCallback = async (input) => {
    // if async, do not filter the results just return the suggestions because
    // the suggestions are already filtered
    if (async) {
      return suggestions;
    }

    const filtered =
      input.trim() === ""
        ? []
        : await suggestions.filter((suggestion) => {
            if (typeof suggestion === "object") {
              return suggestion[labelKey].toLowerCase().indexOf(input.toLowerCase()) > -1;
            }

            return suggestion.toLowerCase().includes(input.toLowerCase());
          });
    return filtered;
  };

  const onChangeHandler = (e) => {
    setUserInput(e.target.value);
    helper.setValue(e.target.value);
    setShowSuggestions(true);

    filteredSuggestionsCallback(e.target.value).then((filtered) => {
      setFilteredSuggestions(filtered);
    });

    setActiveSuggestion(0);
    onInputChange(e.target.value, e);
  };

  const onListItemClickHandler = (e, index) => {
    setUserInput(e.currentTarget.innerText);
    helper.setValue(e.currentTarget.innerText);
    setActiveSuggestion(0);
    setShowSuggestions(false);
    onSelected(filteredSuggestions[index]);
  };

  const onFocusHandler = (e) => {
    setShowSuggestions(true);
    onFocus(e);
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (activeSuggestion < filteredSuggestions.length - 1) {
        setActiveSuggestion(activeSuggestion + 1);
      }

      return;
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (activeSuggestion > 0) {
        setActiveSuggestion(activeSuggestion - 1);
      }

      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (filteredSuggestions.length > 0) {
        setUserInput(filteredSuggestions[activeSuggestion][labelKey]);
        helper.setValue(filteredSuggestions[activeSuggestion][labelKey]);
        setActiveSuggestion(0);
        setShowSuggestions(false);
        onSelected(filteredSuggestions[activeSuggestion]);
      }
    }
  };

  useEffect(() => {
    if (async) {
      setFilteredSuggestions(suggestions);
    }
  }, [suggestions]);

  return (
    <AutocompleteWrapper ref={autocompleteRef}>
      <FormikInput
        {...field}
        autoComplete="off"
        id={name}
        name={name}
        onChange={(e) => {
          onChangeHandler(e);
          field.onChange(e);
        }}
        onBlur={(e) => {
          helper.setTouched(true);
          field.onBlur(e);
        }}
        onFocus={onFocusHandler}
        className={`${className}`}
        onKeyDown={onKeyDownHandler}
        type="text"
        {...rest}
      />
      {showSuggestions && (
        <Suggestions height={height}>
          {async && isLoading && <ListGroupItem>{asyncSearchingText}</ListGroupItem>}

          {async && userInput.trim() === "" && !isLoading && (
            <ListGroupItem>{dropdownPlaceholder}</ListGroupItem>
          )}

          {!isLoading && filteredSuggestions.length === 0 && userInput.trim() !== "" && (
            <ListGroupItem>{noResultsText}</ListGroupItem>
          )}

          {!isLoading &&
            userInput.trim() !== "" &&
            filteredSuggestions.map(
              (suggestion, index) =>
                suggestion?.[labelKey] && (
                  <ListGroupItem
                    className={activeSuggestion === index ? "active-highlight" : ""}
                    key={JSON.stringify(suggestion)}
                    as="button"
                    action
                    onClick={(e) => onListItemClickHandler(e, index)}
                  >
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      searchWords={[userInput]}
                      autoEscape
                      textToHighlight={
                        typeof suggestion === "object"
                          ? suggestion[suggestionLabel] || suggestion[labelKey]
                          : suggestion
                      }
                      highlightTag={Highlight}
                    />
                  </ListGroupItem>
                )
            )}
        </Suggestions>
      )}
    </AutocompleteWrapper>
  );
};

InputWithAutocomplete.defaultProps = {
  className: "",
  async: false,
  labelKey: null,
  height: undefined,
  noResultsText: "No results found...",
  asyncSearchingText: "Searching...",
  dropdownPlaceholder: "Start typing to search...",
  onInputChange: () => {},
  isLoading: false,
  onSelected: () => {},
  onFocus: () => {},
  suggestionLabel: undefined,
};

InputWithAutocomplete.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelKey: PropTypes.string,
  async: PropTypes.bool,
  noResultsText: PropTypes.string,
  onInputChange: PropTypes.func,
  height: PropTypes.number,
  asyncSearchingText: PropTypes.string,
  isLoading: PropTypes.bool,
  onSelected: PropTypes.func,
  dropdownPlaceholder: PropTypes.string,
  onFocus: PropTypes.func,
  suggestionLabel: PropTypes.string,
};

export default InputWithAutocomplete;
