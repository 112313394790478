import { useMutation } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useResendEmail = (options) => {
  const { Public } = useApi();
  return useMutation(async (values) => {
    const promise = Public.post(`public/user/email/resend`, values);
    toast.promise(promise, {
      success: "We have sended you another email verification.",
      loading: "Resending email.",
      error: ({ response, message }) =>
        `Error resending email: ${response?.data?.messages?.[0] || message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const useVerifyEmail = (options) => {
  const { Public } = useApi();
  return useMutation(async (values) => {
    const { data } = await Public.post(`public/user/email/validate`, values);
    return data;
  }, options);
};
