import styled from "styled-components";

import Color from "utils/colours";

export const StyledField = styled.div`
  margin-bottom: 24px;
  position: relative;
  .form-control {
    display: flex;
    height: 48px;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid ${Color.dark200};
    background-color: ${Color.neutral0};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    &:active,
    &:focus {
      border-color: ${Color.navi500};
      box-shadow: 0 0 4px ${Color.navi500};
    }
    &:-internal-autofill-selected {
      background-color: inherit;
    }
    &:disabled {
      border: none;
    }
  }

  .icon-container {
    position: absolute;
    top: 40px;
    right: 10px;
    cursor: pointer;
    background-color: inherit;
    &.disabled {
      display: none;
    }
  }

  .description-text {
    color: ${Color.dark700};
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 6px;
    line-height: 1.1;
  }

  .error-text {
    color: ${Color.empathy500};
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 6px;
    line-height: 1.1;
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  color: ${Color.dark700};
  margin-bottom: 6px;
  font-weight: 600;
`;
