import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";

import { useVerifyEmail } from "./service";

const VerifyRegistration = () => {
  const { replace } = useHistory();
  const { id } = useParams();

  const { mutate: mutateVerifyEmail } = useVerifyEmail();

  useEffect(() => {
    mutateVerifyEmail(
      {
        key: id,
      },
      {
        onSuccess: (response) => {
          window.location.replace("/signup/steps/1");
          localStorage.setItem("signUpIdentifier", response?.contextObjects[0]);
        },
        onError: () => {
          replace("/signup");
        },
      }
    );
  }, []);

  return <>Checking Verification...</>;
};

export default VerifyRegistration;
