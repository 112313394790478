import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "react-bootstrap";

import LytePayLogo from "images/svg/lytepay-logo.svg";
import InvoiceTemplate from "components/common/InvoiceTemplate";
import Loader from "components/common/Loader";

const WaterMark = styled.div`
  position: absolute;
  font-size: 7.5rem;
  opacity: 0.2;
  font-weight: 600;
  user-select: none;
  transform: rotate(315deg);
  top: 55%;
  left: 25%;
  z-index: 1000;
`;

const MockInvoiceAccept = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const queryString = params.get("invoiceQueryString");
  const invoice = JSON.parse(queryString);
  invoice.discount = {
    type: invoice.discountType,
    value: invoice.discountValue,
    description: invoice.discountDescription,
  };

  return !invoice ? (
    <Loader />
  ) : (
    <div className="invoice-reject py-5" style={{ position: "relative" }}>
      <WaterMark>Test Invoice</WaterMark>
      <div className="container">
        <Alert variant="warning" className="mb-5 h5 p-3">
          <p>Note:</p>
          <p>You are viewing a mockup of the invoice ACCEPTANCE page which your client will see.</p>
          <p>This page does not serve to be an actual invoice.</p>
        </Alert>
        <div className="form-bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img src={LytePayLogo} className="image mb-5 col-4 col-md-3 mx-auto" alt="" />
            <h6>
              You have accepted the invoice from {invoice.fullName ? invoice.fullName : null}.{" "}
              <br />
              Please confirm your choice.
            </h6>
            <div className="clearfix">
              <div className="mx-auto">
                <button type="button" className="global-button" disabled>
                  Accept {`${invoice.firstName}'s` || null} Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
        <InvoiceTemplate invoice={invoice} disableLogoHint isKycApproved />
      </div>
    </div>
  );
};

export default MockInvoiceAccept;
