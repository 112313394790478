import styled from "styled-components";

export const BoostIntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BoostTm = styled.span`
  color: #3363ff;
  font-style: italic;
  font-weight: 300;

  span {
    font-weight: 800;
  }
`;

export const Button = styled.a`
  font-weight: 600;
  padding: 14px 60px;
`;

export const SubText = styled.p`
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.8;
`;

export const Heading = styled.h3`
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 370px;
`;
