import { Badge } from "react-bootstrap";
import styled from "styled-components";

import Color from "utils/colours";

export const CustomBadge = styled(Badge)`
  width: auto;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 13px;
  ${(props) => {
    if (props?.size === "md") {
      return `
        font-size: 20px;
        padding: 8px;
        `;
    }
    return "";
  }}
  &.badge-pill {
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 13px;
  }
  &.badge-success {
    background-color: ${(props) => props.theme.color.badgeDoneBackground};
    color: ${(props) => props.theme.color.badgeDoneText};
  }
  &.badge-danger {
    background-color: ${(props) => props.theme.color.badgeAlertBackground};
    color: ${(props) => props.theme.color.badgeAlertText};
  }
  &.badge-info {
    background-color: ${(props) => props.theme.color.badgePendingBackground};
    color: ${(props) => props.theme.color.badgePendingText};
  }
  &.badge-light {
    background-color: ${(props) => props.theme.color.badgeGreyBackground};
    color: ${(props) => props.theme.color.badgeGreyText};
  }
  &.badge-primary {
    background-color: ${Color.navi50};
    color: ${Color.navi700};
  }
`;
