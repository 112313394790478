export const REJECTED = "rejected";
export const APPROVED = "approved";
export const PENDING_VERIFICATION = "pending_verification";
export const PENDING_SUBMISSION = "pending_submission";
export const PENDING = "pending";
export const DELETED = "deleted";

export const BADGE_VARIANTS = {
  [REJECTED]: {
    variant: "red",
    label: "Rejected",
  },
  [APPROVED]: {
    variant: "green",
    label: "Approved",
  },
  [PENDING_VERIFICATION]: {
    variant: "orange",
    label: "Pending Verification",
  },
  [PENDING_SUBMISSION]: {
    variant: "lightgrey",
    label: "Pending Submission",
  },
  [PENDING]: {
    variant: "lightgrey",
    label: "Pending Submission",
  },
  [DELETED]: {
    variant: "red",
    label: "Deleted",
  },
};
