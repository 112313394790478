import { Formik, useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from "views/public/signup/components/Button";
import FormikInput from "views/public/signup/components/FormikInput";
import FormikDatepicker from "views/public/signup/components/FormikDatepicker";
import FormikDropdown from "views/public/signup/components/FormikDropdown";

const PersonalDetails = () => {
  const { push } = useHistory();
  const { errors } = useFormikContext();

  const isDisabled =
    "firstName" in errors || "lastName" in errors || "dob" in errors || "country" in errors;

  const handleSubmit = () => {
    push(`/signup/steps/2`);
  };

  return (
    <div>
      <form>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h3 className="font-weight-bold pb-4">Let's get to know you better!</h3>
            <FormikInput label="Given name / First name (as per your passport)" name="firstName" />
            <FormikInput label="Surname / Last name (as per your passport)" name="lastName" />
            <FormikDatepicker
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              placeholder="DD/MM/YYYY"
            />
            <FormikDropdown
              label="Country"
              name="country"
              lists={[
                { name: "Singapore", id: "SG" },
                { name: "Indonesia", id: "ID" },
              ]}
            />
            <Button type="button" className="w-100" disabled={isDisabled} onClick={handleSubmit}>
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default PersonalDetails;
