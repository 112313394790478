import { createContext, useContext, useMemo, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [selectedSubMenuId, setSelectedSubMenuId] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState(null);

  const value = useMemo(
    () => ({ selectedSubMenuId, setSelectedSubMenuId, toggleSidebar, setToggleSidebar }),
    [selectedSubMenuId, toggleSidebar]
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalState = () => useContext(GlobalContext);
