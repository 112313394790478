import { Container, Form, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import qs from "qs";
import { Formik } from "formik";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useInvoicesData } from "hooks/useInvoicesData";
import TableBase from "ui-lib/components/table-base";
import useDebounce from "hooks/useDebounce";
import { LyteButton } from "components/common/buttons";
import FormikInput from "components/form/FormikInput";
import FormikDropdown from "components/form/FormikDropdown";
import { useExportInvoice } from "hooks/invoices";

import { columns } from "./InvoiceSummary.columns";
import { SELECT_FILTER_OPTIONS, SELECT_PAYMENT_STATUS_OPTIONS } from "./InvoiceSummary.constants";

const InvoiceSummary = () => {
  const history = useHistory();
  const { search } = useLocation();
  const currentQuery = qs.parse(search, { ignoreQueryPrefix: true });
  const [searchQuery, setSearchQuery] = useState({});

  const debouncedSearchQuery = useDebounce(searchQuery);
  const { data: response } = useInvoicesData(debouncedSearchQuery);

  const { mutate: mutateExportInvoice, isLoading: isLoadingExportInvoice } = useExportInvoice(
    "Excel",
    {
      onSuccess: (result) => {
        window.open(`${process.env.REACT_APP_API_URL}/public/files/exports/${result}`, "_blank");
      },
      enabled: response,
    }
  );

  useEffect(() => {
    if (Object.keys(debouncedSearchQuery).length) {
      // Sets the URL query params to the debounched search query
      // without reloading the page
      window.history.replaceState(
        null,
        null,
        Object.keys(debouncedSearchQuery).length ? `?${qs.stringify(debouncedSearchQuery)}` : ""
      );
    } else {
      window.history.pushState(null, null, "");
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    // Sets the search query to the current URL query params
    if (search) {
      setSearchQuery(currentQuery);
    }
  }, []);

  const handleResetSearch = () => {
    setSearchQuery({});
    history.push("/invoice/listing");
  };
  const handleChangePage = (page) => setSearchQuery({ ...searchQuery, page });
  const handleChangeRowsPerPage = (pageSize) =>
    setSearchQuery({ ...searchQuery, rowsPerPage: pageSize });
  const handleChangeSearchKey = (event) => {
    if (!event.target.value) {
      // removes the key from the search query and from the URL
      delete searchQuery.searchKey;
      setSearchQuery({
        ...searchQuery,
      });
    } else {
      setSearchQuery({
        ...searchQuery,
        searchKey: event.target.value,
      });
    }
  };
  const handleChangeStatus = (event) => {
    if (!event.target.value) {
      // removes the key from the search query and from the URL
      delete searchQuery.status;
      setSearchQuery({
        ...searchQuery,
      });
    } else {
      setSearchQuery({
        ...searchQuery,
        status: event.target.value,
      });
    }
  };
  const handleChangePaymentStatus = (event) => {
    if (!event.target.value) {
      // removes the key from the search query and from the URL
      delete searchQuery.paymentStatus;
      setSearchQuery({
        ...searchQuery,
      });
    } else {
      setSearchQuery({
        ...searchQuery,
        paymentStatus: event.target.value,
      });
    }
  };

  return (
    <Container className="py-5">
      <div className="d-flex gap-2 mb-3">
        <h3>Invoice Summary</h3>
        <LyteButton
          className="mb-2"
          size="sm"
          isLoading={isLoadingExportInvoice}
          disabled={isLoadingExportInvoice}
          onClick={() => mutateExportInvoice()}
        >
          <FontAwesomeIcon icon={faDownload} />
          Export as Excel
        </LyteButton>
      </div>
      <Row className="flex-md-row flex-column-reverse">
        <Col xs={12} md={9}>
          <Formik>
            {() => (
              <Row className="no-gutters gap-2 mb-3">
                <Col className="p-0" xs={12} md={6} lg={4}>
                  <FormikInput
                    value={searchQuery.searchKey || ""}
                    onChange={handleChangeSearchKey}
                    name="invoice-summary-searchbar"
                    placeholder="Search for invoice number, client name, business name or mobile number..."
                    className="mb-0"
                  />
                </Col>

                <Col className="p-0" xs={12} md={5} lg={2}>
                  <FormikDropdown
                    name="invoice-summary-filter-status"
                    value={searchQuery.status || ""}
                    onChange={handleChangeStatus}
                    lists={SELECT_FILTER_OPTIONS}
                    withoutDefaultOption
                    className="mb-0"
                  />
                </Col>

                <Col className="p-0" xs={12} md={6} lg={3}>
                  <FormikDropdown
                    name="invoice-summary-filter-payment-status"
                    value={searchQuery.paymentStatus || ""}
                    onChange={handleChangePaymentStatus}
                    lists={SELECT_PAYMENT_STATUS_OPTIONS}
                    withoutDefaultOption
                    className="mb-0"
                  />
                </Col>

                <Col className="p-0 d-flex align-items-center" xs={12} md={5} lg={2}>
                  <LyteButton
                    variant="ghost"
                    id="invoice-summary-filter-clear"
                    size="sm"
                    onClick={handleResetSearch}
                  >
                    Clear filters
                  </LyteButton>
                </Col>
              </Row>
            )}
          </Formik>
        </Col>
        <Col className="justify-content-end d-flex" xs={12} md={3}>
          <LyteButton
            size="sm"
            className="mb-3"
            onClick={() => history.push("/invoice/create/1")}
            style={{
              height: "fit-content",
            }}
          >
            Create invoice
          </LyteButton>
        </Col>
      </Row>
      <TableBase
        data={response?.data}
        columns={columns}
        pagination
        paginationServer
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationTotalRows={response?.total}
        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </Container>
  );
};

export default InvoiceSummary;
