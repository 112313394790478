import { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import dayjs from "dayjs";

import WhatsappIcon from "images/icons/whatsapp-icon.png";
import ProfilePicture from "images/illust/PropertyAgt.svg";
import { ReactComponent as EmmaIcon } from "images/svg/emma-icon.svg";
import { ReactComponent as EmmaButtonIcon } from "images/svg/emma-button-icon.svg";
import Gradient1 from "images/illust/gradient.png";

import MultiCarousel from "../carousel";

import { profileType } from "./constants";
import { ProfileSection } from "./styled";

const ProfilePreview = ({ data, imageData }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (imageData && imageData?.size !== 0) setImageUrl(URL.createObjectURL(imageData));
  }, [imageData]);

  return (
    <ProfileSection>
      <Container fluid>
        <ProfileSection.Row>
          <ProfileSection.Header>
            <div className="logo-container">
              <ProfileSection.LogoText className="logo-text" maskImage={Gradient1}>
                {data?.name?.split(" ")[0]}
              </ProfileSection.LogoText>
            </div>
            <Row>
              <Col
                className="align-items-center justify-content-center d-flex flex-column text-center"
                xs={12}
              >
                <div className="profile-picture mb-4">
                  <Image className="img-fluid w-100" src={imageUrl || ProfilePicture} alt="..." />
                </div>
                <h4 className="mt-3 mb-1 font-weight-bold">{`${data?.name} • ${profileType(
                  data?.profileType
                )}`}</h4>
                <p>{data?.description}</p>
              </Col>
            </Row>
            <div className="emma-container">
              <div className="emma-picture-container">
                <EmmaIcon />
              </div>
              <p>{`Hi! I'm Emma, ${data?.name} AI Assistant`}</p>
              <Button onClick={() => window.open("https://wa.me/6588390489", "_blank")}>
                <Image src={WhatsappIcon} />
                Ask us anything
              </Button>
              <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
                <Button variant="tertiary">Schedule a call</Button>
                <Button variant="tertiary">What are your rates?</Button>
              </div>
            </div>
            <Row className="px-1 py-4">
              <p>Skillset</p>

              <div className="pills-container">
                <div className="pills">Coding</div>
                <div className="pills">Hardware</div>
                <div className="pills">Software</div>
                <div className="pills">Open to suggestions</div>
                <div className="pills">Design</div>
              </div>
            </Row>
            <ProfileSection.Footer className="d-none d-md-block" bgImage={Gradient1}>
              <Button
                className="emma-trigger-button"
                onClick={() => window.open("https://wa.me/6588390489", "_blank")}
              >
                <EmmaButtonIcon />
                <p className="mb-0 font-weight-bold">Create profile with Emma</p>
              </Button>
            </ProfileSection.Footer>
          </ProfileSection.Header>
          <ProfileSection.Content>
            {data?.services?.length > 0 && (
              <Row className="px-1 py-4">
                <p>Services</p>
                <div className="sliders">
                  <div className="sliders-container">
                    {data?.services?.map((service, index) => (
                      <div className="slide">
                        <h6>{service.title}</h6>
                        <p>{service.description}</p>
                        <span>Enquire</span>
                      </div>
                    ))}
                  </div>
                </div>
              </Row>
            )}
            <Row>
              <p>Testimonials</p>
              <MultiCarousel
                items={data?.testimonials?.map((testimonial) => ({
                  title: testimonial.name,
                  time: dayjs(testimonial.date).format("DD MMM YYYY"),
                  description: testimonial.message,
                }))}
              />
            </Row>
            {data?.jobTitle === "PropertyAgent" && data?.listings?.length > 0 && (
              <Row>
                <p>My Listings</p>
                {data?.listings?.map((listing) => (
                  <div className="listing-container">
                    <div className="image-container">
                      <Image className="img-fluid" src={listing.image} alt={listing.title} />
                    </div>
                    <div>
                      {listing.forSale && <Button>FOR SALE</Button>}
                      <p className="mb-0 font-weight-bold">{listing.title}</p>
                      <p className="mb-0">{listing.description}</p>
                    </div>
                  </div>
                ))}
              </Row>
            )}
            <Row>
              <p>Links</p>
              <div className="w-100">
                <div className="social-container">
                  <FontAwesomeIcon icon={faFacebook} />
                  <p className="mb-0">Bruno Tutors</p>
                </div>
                <div className="social-container">
                  <FontAwesomeIcon icon={faInstagram} />
                  <p className="mb-0">@Bruno Tutors</p>
                </div>
              </div>
            </Row>
          </ProfileSection.Content>
          <ProfileSection.Footer className="d-md-none" bgImage={Gradient1}>
            <Button
              className="emma-trigger-button"
              onClick={() => window.open("https://wa.me/6588390489", "_blank")}
            >
              <EmmaButtonIcon />
              <p className="mb-0 font-weight-bold">Create profile with Emma</p>
            </Button>
          </ProfileSection.Footer>
        </ProfileSection.Row>
      </Container>
    </ProfileSection>
  );
};

export default ProfilePreview;
