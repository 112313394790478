import { up, down, between, only } from "styled-breakpoints";
import styled from "styled-components";
import { Badge, Container, Image, NavLink } from "react-bootstrap";

const Color = {
  lyteWhite: "#ffffff",
  lyteDark: "#18191b",
  lyteBlue: "#3363ff",
  lyteDarkBlue: "#001866",
  lyteGreen: "#61BFA8",
  lytePurple: "#713DF5",
  lytePink: "#ED6393",
};

export const UpdateContainer = styled(Container)`
  background-color: ${Color.lyteWhite};
  padding: 3rem 20px 8rem;
  h4 {
    margin: 0;
    text-transform: capitalize;
  }
  .header-title {
    border-bottom: 3px solid ${Color.lyteDark};
    padding: 10px;
    margin: 20px 0;
  }

  .dropdown-container {
    position: relative;
    height: 100%;
    .icon {
      position: absolute;
      bottom: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  .trash-icon {
    z-index: 100;
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    color: red;
  }

  .container-footer-update {
    z-index: 1000;
    position: fixed;
    background-color: ${Color.lyteWhite};
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 15px;
    .row {
      gap: 20px;
      .btn-tertiary {
        border: 1px solid ${Color.lyteBlue};
      }
    }
  }
`;

export const PreviewContainer = styled.div`
  .container-footer-update {
    z-index: 1000;
    position: fixed;
    background-color: #f1f4fd;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 15px;
    .row {
      gap: 20px;
      .btn-tertiary {
        border: 1px solid ${Color.lyteBlue};
        background-color: ${Color.lyteWhite};
      }
    }
  }
`;
