import { up, down, between, only } from "styled-breakpoints";
import styled from "styled-components";
import { Badge, Container, Image, NavLink } from "react-bootstrap";

const gradient = "linear-gradient(135deg, #3363ff 0%, #e21e80 100%)";
const Color = {
  lyteWhite: "#ffffff",
  lyteDark: "#18191b",
  lyteBlue: "#3363ff",
  lyteDarkBlue: "#001866",
  lyteGreen: "#61BFA8",
  lytePurple: "#713DF5",
  lytePink: "#ED6393",
};

export const StyledNavLink = styled(NavLink)`
  font-size: 20px;
  color: ${Color.blue};
  font-weight: 500;
`;

export const HomepageContainer = styled.div`
  background-color: ${Color.lyteWhite};
  color: ${Color.lyteDark};
  section {
    padding: 2rem 0 5rem 0;

    ${down("md")} {
      padding: 2rem 0;
    }

    .row {
      ${down("sm")} {
        gap: 20px;
      }
    }
  }
  .navbar-light .navbar-nav .nav-link {
    color: ${Color.lyteDark};
    font-size: 1rem;
    margin: 0 10px;

    ${only("md")} {
      font-size: 0.65rem;
    }
  }
  .dropdown .dropdown-toggle::after {
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .header-container {
    .row {
      margin: 0 -40px;
      ${only("md")} {
        margin: 0 -15px 0 -40px;
      }
      ${down("sm")} {
        margin: 0 -15px 0 -25px;
      }
      .logo-img {
        height: 60px;
        ${only("md")} {
          height: auto;
        }
      }

      .dropdown-menu {
        padding: 1.5rem;
        background: ${Color.lyteWhite};
        box-shadow: 0 4px 32px rgba(0, 0, 0, 0.2);
        border-radius: 1.5rem;
        .dropdown-item {
          width: 20rem;
          padding: 1rem 1.5rem;
        }
      }
      .agents-dropdown {
        .dropdown-menu {
          .dropdown-item {
            padding: 0 1.5rem;
            img {
              width: 60px;
            }
          }
        }
      }
      .selected {
        padding-right: 0;
        padding-left: 0;
        border-bottom: 2px solid ${Color.lyteBlue};
        font-weight: 700;
      }
      .register-button {
        display: block;
        padding: 1rem 2rem;
        background: ${Color.lyteBlue};
        border-radius: 2rem;
        border: 2px solid ${Color.lyteBlue};
        color: #fff;
        min-width: 7.625rem;
        text-align: center;
        transition: 0.3s;
        font-weight: bold;

        ${only("md")} {
          padding: 0.5rem 1rem;
          border-radius: 1rem;
        }
      }
    }
  }
  .btn {
    border-radius: 2rem;
    font-weight: 700;
    font-size: 1.188rem;
    line-height: 1.75rem;
    padding: 1rem 2rem;
    transition: 0.3s;
    max-width: fit-content;
    text-align: left;
    &.btn-primary {
      border: 2px solid ${Color.lyteBlue};
      background: ${Color.lyteBlue};
      color: ${Color.lyteWhite};
      &:hover,
      &:active {
        background-color: ${Color.lyteWhite};
        border-color: ${Color.lyteBlue};
        color: ${Color.lyteBlue};
      }
    }
    &.btn-secondary {
      border: 2px solid ${Color.lyteBlue};
      background: ${Color.lyteWhite};
      color: ${Color.lyteDark};
      &:hover,
      &:active {
        background-color: ${Color.lyteBlue};
        border-color: ${Color.lyteBlue};
        color: ${Color.lyteWhite};
      }
    }

    ${down("md")} {
      padding: 1rem 1.5rem;
      font-size: 1rem;
      margin: auto;
    }
  }

  ${only("md")} {
    .header-container {
      .navbar-nav {
        .nav-link {
          &a {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
`;

export const FirstSection = styled.section`
  h1 {
    font-size: 2.8rem;
    color: ${Color.lyteDarkBlue};
    margin-bottom: 1.5rem;
    font-weight: 800;
    ${down("md")} {
      font-size: 2.4rem;
    }
  }
  .btn {
    &.btn-primary {
      padding: 1rem 5rem 1rem 2rem;
    }
  }
  p {
    font-size: 1rem;
    margin-bottom: 2.5rem !important;
  }

  ${down("md")} {
    text-align: center;
  }
`;

export const SecondSection = styled.section`
  h2 {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    ${down("md")} {
      font-size: 2rem;
    }
  }
  p {
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }

  ${down("md")} {
    text-align: center;
  }
`;

export const ThirdSection = styled.section`
  .cols {
    padding: 1rem 0.75rem;
    .custom-box {
      height: 100%;
      padding: 1.75rem 3rem;
      border: 0.4rem solid #fff;
      border-radius: 5.5rem;
      &.blue {
        border-color: ${Color.lyteBlue};
        span {
          color: ${Color.lyteBlue};
        }
      }
      &.green {
        border-color: ${Color.lyteGreen};
        span {
          color: ${Color.lyteGreen};
        }
      }
      &.purple {
        border-color: ${Color.lytePurple};
        span {
          color: ${Color.lytePurple};
        }
      }
      &.pink {
        border-color: ${Color.lytePink};
        span {
          color: ${Color.lytePink};
        }
      }
    }
  }
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    ${down("md")} {
      font-size: 2rem;
    }
  }
  h3 {
    font-weight: 800;
  }
  p {
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }

  .react-multi-carousel-dot-list {
  }

  ${down("md")} {
    text-align: center;
  }
`;

export const FooterContainer = styled(Container)`
  background-color: ${Color.lyteBlue};
  color: ${Color.lyteWhite};
  .icon {
    fill: #ffffff;
    color: #ffffff;
  }
  .footer-menu {
    .widget-title {
      color: #ccd8ff;
      font-weight: 700;
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }
    &-container {
      .menu {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 0 0 0.5rem;
          a {
            color: #fff;
            font-weight: 400;
            font-size: 0.8rem;
          }
        }
      }
      a {
        color: #fff;
      }
    }
    ${down("sm")} {
      text-align: center;
      .icon-container {
        padding-top: 1rem;
        gap: 2.5rem !important;
        .icon {
          transform: scale(1.5);
        }
      }
      .footer-menu-container {
        padding-bottom: 2rem;
      }
      .copyright-container {
        text-align: left;
      }
    }
  }
`;
