export const FormFields = [
  {
    key: "mobileNumber",
    label: "Mobile Number",
    type: "text",
    disabled: true,
    children: [],
  },
  {
    key: "emailAddress",
    label: "Email Address",
    type: "text",
    disabled: true,
    children: [],
  },
  {
    key: "name",
    label: "Name",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "jobTitle",
    label: "Job Title",
    type: "dropdown",
    disabled: true,
    children: [
      {
        id: "Tutor",
        label: "Tuition Tutor",
      },
      {
        id: "PropertyAgent",
        label: "Property Agent",
      },
    ],
  },
  {
    key: "description",
    label: "Description",
    type: "textarea",
    disabled: false,
    children: [],
  },
];

export const EducationFormFields = [
  {
    key: "name",
    label: "Name",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "certification",
    label: "Certification",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "level",
    label: "Level",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "fieldOfStudy",
    label: "Field of Study",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "description",
    label: "Description",
    type: "textarea",
    disabled: false,
    children: [],
  },
  {
    key: "startDate",
    label: "Start Date",
    type: "date",
    disabled: false,
    children: [],
  },
  {
    key: "endDate",
    label: "End Date",
    type: "date",
    disabled: false,
    children: [],
  },
];

export const ExperiencesFormFields = [
  {
    key: "jobTitle",
    label: "Job Title",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "companyName",
    label: "Company Name",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "description",
    label: "Description",
    type: "textarea",
    disabled: false,
    children: [],
  },
  {
    key: "startDate",
    label: "Start Date",
    type: "date",
    disabled: false,
    children: [],
  },
  {
    key: "current",
    label: "Current",
    activeLabel: "I am still working here",
    type: "toggle",
    disabled: false,
    children: [],
  },
  {
    key: "endDate",
    label: "End Date",
    type: "date",
    disabled: false,
    children: [],
  },
];

export const TestimonialsFormFields = [
  {
    key: "name",
    label: "Name",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "message",
    label: "Message",
    type: "textarea",
    disabled: false,
    children: [],
  },
  {
    key: "location",
    label: "Location",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "date",
    label: "Date",
    type: "date",
    disabled: false,
    children: [],
  },
];

export const ServicesFormFields = [
  {
    key: "title",
    label: "Title",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "description",
    label: "Description",
    type: "textarea",
    disabled: false,
    children: [],
  },
];

export const ListingsFormFields = [
  {
    key: "url",
    label: "Url",
    type: "listingUrl",
    disabled: false,
    children: [],
  },
  {
    key: "title",
    label: "Title",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "description",
    label: "Description",
    type: "textarea",
    disabled: false,
    children: [],
  },
  {
    key: "image",
    label: "Image",
    type: "text",
    disabled: false,
    children: [],
  },
  {
    key: "forSale",
    label: "For Sale",
    activeLabel: "For Sale",
    type: "toggle",
    disabled: false,
    children: [],
  },
];
