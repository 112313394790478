import styled from "styled-components";

export const AnnoucementStyled = styled.div`
  margin: 0;
  p {
    margin: 0;
  }
  a {
    color: #0d6efd;
  }
`;
