export const EDIT = "edit";
export const CREATE = "create";
export const WIZARD_MODES = {
  [EDIT]: "edit",
  [CREATE]: "create",
};

export const AMOUNT = "amount";
export const PERCENTAGE = "percentage";
export const NODISCOUNT = "nodiscount";
export const NOWITHHOLDINGTAX = "nowithholdingtax";

export const DISCOUNT_OPTIONS = [
  {
    label: "$",
    value: AMOUNT,
  },
  {
    label: "%",
    value: PERCENTAGE,
  },
];

export const DRAFT = "draft";
export const PENDING = "pending";
export const CANCELLED = "cancelled";
export const ACCEPTED = "accepted";
export const REJECTED = "rejected";
export const DELETED = "deleted";
export const PAID = "paid";
export const PAID_AND_WAIVE = "paidAndWaive";
export const PARTIALLY_PAID = "partially_paid";

export const SELECT_FILTER_OPTIONS = [
  {
    label: "All Status",
    value: "",
    disabled: true,
  },
  { label: "Pending", value: PENDING },
  { label: "Draft", value: DRAFT },

  { label: "Cancelled", value: CANCELLED },
  { label: "Accepted", value: ACCEPTED },
  { label: "Rejected", value: REJECTED },
  { label: "Deleted", value: DELETED },
  { label: "Paid", value: PAID },
  { label: "Paid and Wave", value: PAID_AND_WAIVE },
];

export const BUSINESSS_NAME_LIMIT = 100;

export const INVOICE_TYPES = {
  LYTE: "LyteCollect",
  SELF: "SelfCollect",
};
