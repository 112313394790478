import Color from "./colours";

export const lightColorTheme = {
  // color token

  // text token
  textDarkContrast: Color.dark900,
  textSubtleContrast: Color.dark400,
  textLinkDefault: Color.navi500,
  textLinkHover: Color.navi400,
  textLinkPressed: Color.navi600,
  textAlertError: Color.empathy600,
  textAlertSuccess: Color.integrity600,
  textReverseContrast: Color.neutral0,

  // border token
  borderDefault: Color.dark100,
  borderBlue: Color.navi500,
  borderAlertDestructive: Color.empathy500,

  // surface token
  bodyBackground: Color.dark50,
  cardBackground: Color.neutral0,

  // button token
  buttonPrimaryDefault: Color.navi500,
  buttonPrimaryHover: Color.navi400,
  buttonPrimaryPressed: Color.navi600,
  buttonAllDisabled: Color.dark200,
  buttonSecondaryDefault: Color.neutral0,
  buttonSecondaryHover: Color.navi50,
  buttonSecondaryPressed: Color.navi100,
  buttonAlertHover: Color.empathy50,
  buttonAlertPressed: Color.empathy100,
  buttonSuccess: Color.integrity500,

  // icon token
  iconReverseContrast: Color.neutral0,
  iconDisabled: Color.dark400,
  iconAlert: Color.empathy600,
  iconDefault: Color.navi500,
  iconSuccess: Color.integrity500,

  // input token
  inputBorderDefault: Color.dark100,
  inputBorderActive: Color.navi200,
  inputBorderAlert: Color.empathy300,
  inputBorderDisabled: Color.dark50,
  inputBorderSuccess: Color.integrity400,

  // table token
  cellSelected: Color.navi50,

  // badge token
  badgeDoneBackground: Color.integrity50,
  badgeDoneText: Color.integrity700,
  badgePendingBackground: Color.foresight100,
  badgePendingText: Color.foresight800,
  badgeAlertBackground: Color.empathy50,
  badgeAlertText: Color.empathy600,
  badgeGreyBackground: Color.dark100,
  badgeGreyText: Color.dark800,

  invoiceSummaryTableDivider: Color.dark600,

  // color default

  textPrimary: Color.navi500,
  textSecondary: Color.neutral100,
  textSmallUppercase: Color.dark600,
  linkPrimary: Color.navi500,
  linkPrimaryHover: Color.navi500,
  bodyBackgroundPrimary: Color.dark50,
  backgroundPrimary: Color.neutral0,
  backgroundSecondary: Color.dark50,
  buttonBackgroundPrimary: Color.navi500,
  buttonBackgroundPrimaryHover: Color.neutral0,
  borderPrimary: Color.navi500,
  borderSecondary: Color.dark400,
  dividerPrimary: Color.dark100,
  tableHeaderBackgroundPrimary: Color.neutral0,
  tableContentBackgroundPrimary: Color.neutral0,
  invoiceBorderLine: Color.dark300,
  toggleBackground: Color.neutral100,
  toggleBullet: Color.neutral0,
  toggleBulletActive: Color.navi500,
  backgroundBannerCard: Color.dark50,

  dividerColor: Color.dark100,

  // input form
  formInputBackgroundColor: Color.neutral0,
  formInputBackgroundColorHover: Color.neutral0,
  formInputBackgroundColorActive: Color.neutral0,
  formInputBackgroundColorError: Color.neutral0,
  formInputBackgroundColorSuccess: Color.neutral0,
  formInputBackgroundColorDisabled: Color.dark50,
  formInputBorderColor: Color.dark100,
  formInputBorderColorHover: Color.navi200,
  formInputBorderColorActive: Color.navi200,
  formInputBorderColorError: Color.empathy300,
  formInputBorderColorSuccess: Color.integrity400,
  formInputBorderColorDisabled: "transparent",
  formInputTextColor: Color.dark800,
  formInputTextColorHover: Color.dark800,
  formInputTextColorActive: Color.dark800,
  formInputTextColorError: Color.dark800,
  formInputTextColorSuccess: Color.dark800,
  formInputTextColorDisabled: Color.dark300,
  formInputHintColor: Color.dark700,
  formInputHintColorHover: Color.dark700,
  formInputHintColorActive: Color.dark700,
  formInputHintColorError: Color.empathy500,
  formInputHintColorSuccess: Color.integrity700,
  formInputHintColorDisabled: Color.dark700,
  formInputBoxShadowColor: "transparent",
  formInputBoxShadowColorHover: Color.navi50,
  formInputBoxShadowColorActive: Color.navi50,
  formInputBoxShadowColorError: Color.empathy50,
  formInputBoxShadowColorSuccess: Color.integrity50,
  formInputBoxShadowColorDisabled: "transparent",
  formInputPlaceholderColor: Color.dark300,

  // invoice creation
  tableHeaderTextColor: Color.dark600,
  tableContentTextColor: Color.dark600,
  invoicePaymentDetails: Color.navi50,
  invoicePaymentDetailsText: Color.dark800,
};

export const darkColorTheme = {
  // color token

  // text token
  textDarkContrast: Color.neutral0,
  textSubtleContrast: Color.dark300,
  textLinkDefault: Color.navi300,
  textLinkHover: Color.navi400,
  textLinkPressed: Color.navi500,
  textAlertError: Color.empathy600,
  textAlertSuccess: Color.integrity600,
  textReverseContrast: Color.neutral0,

  // border token
  borderDefault: Color.dark600,
  borderBlue: Color.navi300,
  borderAlertDestructive: Color.empathy700,

  // background token
  bodyBackground: Color.dark950,
  cardBackground: Color.dark900,

  // button token
  buttonPrimaryDefault: Color.navi500,
  buttonPrimaryHover: Color.navi400,
  buttonPrimaryPressed: Color.navi600,
  buttonAllDisabled: Color.dark700,
  buttonSecondaryDefault: Color.dark900,
  buttonSecondaryHover: Color.dark800,
  buttonSecondaryPressed: Color.dark900,
  buttonAlertHover: Color.dark800,
  buttonAlertPressed: Color.dark900,
  buttonSuccess: Color.integrity500,

  // icon token
  iconReverseContrast: Color.neutral0,
  iconDisabled: Color.dark400,
  iconAlert: Color.empathy600,
  iconDefault: Color.navi500,
  iconSuccess: Color.integrity500,

  // input token
  inputBorderDefault: Color.dark600,
  inputBorderActive: Color.navi600,
  inputBorderAlert: Color.empathy600,
  inputBorderDisabled: Color.dark800,
  inputBorderSuccess: Color.integrity500,

  // table token
  cellSelected: Color.navi900,

  // badge token
  badgeDoneBackground: Color.integrity700,
  badgeDoneText: Color.integrity50,
  badgePendingBackground: Color.foresight900,
  badgePendingText: Color.foresight300,
  badgeAlertBackground: Color.empathy700,
  badgeAlertText: Color.empathy50,
  badgeGreyBackground: Color.dark600,
  badgeGreyText: Color.neutral0,
  invoiceSummaryTableDivider: Color.neutral0,

  // color default

  textPrimary: Color.navi500,
  textSecondary: Color.neutral0,
  textSmallUppercase: Color.dark50,
  linkPrimary: Color.navi500,
  linkPrimaryHover: Color.navi500,
  bodyBackgroundPrimary: Color.dark800,
  backgroundPrimary: Color.neutral100,
  backgroundSecondary: Color.dark50,
  buttonBackgroundPrimary: Color.navi500,
  buttonBackgroundPrimaryHover: Color.neutral0,
  borderPrimary: Color.navi500,
  borderSecondary: Color.dark400,
  dividerPrimary: Color.dark800,
  tableHeaderBackgroundPrimary: Color.neutral100,
  tableContentBackgroundPrimary: Color.neutral100,
  invoiceBorderLine: Color.neutral0,
  toggleBackground: Color.neutral0,
  toggleBullet: Color.neutral100,
  toggleBulletActive: Color.navi500,
  backgroundBannerCard: Color.dark900,

  dividerColor: Color.dark100,

  // input form
  formInputBackgroundColor: Color.dark900,
  formInputBackgroundColorHover: Color.dark900,
  formInputBackgroundColorActive: Color.dark900,
  formInputBackgroundColorError: Color.dark900,
  formInputBackgroundColorSuccess: Color.dark900,
  formInputBackgroundColorDisabled: Color.dark800,
  formInputBorderColor: Color.dark100,
  formInputBorderColorHover: Color.navi200,
  formInputBorderColorActive: Color.navi200,
  formInputBorderColorError: Color.empathy300,
  formInputBorderColorSuccess: Color.integrity400,
  formInputBorderColorDisabled: "transparent",
  formInputTextColor: Color.dark800,
  formInputTextColorHover: Color.dark800,
  formInputTextColorActive: Color.dark800,
  formInputTextColorError: Color.dark800,
  formInputTextColorSuccess: Color.dark800,
  formInputTextColorDisabled: Color.dark300,
  formInputHintColor: Color.dark700,
  formInputHintColorHover: Color.dark700,
  formInputHintColorActive: Color.dark700,
  formInputHintColorError: Color.empathy500,
  formInputHintColorSuccess: Color.integrity700,
  formInputHintColorDisabled: Color.dark700,
  formInputBoxShadowColor: "transparent",
  formInputBoxShadowColorHover: Color.navi50,
  formInputBoxShadowColorActive: Color.navi50,
  formInputBoxShadowColorError: Color.empathy50,
  formInputBoxShadowColorSuccess: Color.integrity50,
  formInputBoxShadowColorDisabled: "transparent",
  formInputPlaceholderColor: Color.dark300,

  // invoice creation
  tableHeaderTextColor: Color.dark600,
  tableContentTextColor: Color.dark600,
  invoicePaymentDetails: Color.dark800,
  invoicePaymentDetailsText: Color.neutral0,
};

export const darkColor = {
  neutral0: "#272f38",
  neutral100: "#ffffff",
  navi900: "#EBEFFF",
  navi800: "#C0CFFF",
  navi700: "#A1B7FF",
  navi600: "#7696FF",
  navi500: "#5C82FF",
  navi400: "#3363ff",
  navi300: "#2E5AE8",
  navi200: "#2446B5",
  navi100: "#1C368C",
  navi50: "#152A6B",
  dark900: "#F4F5F6",
  dark800: "#CDD3D9",
  dark700: "#B5BEC7",
  dark600: "#93A0AD",
  dark500: "#7E8D9D",
  dark400: "#5E7185",
  dark300: "#566779",
  dark200: "#43505E",
  dark100: "#343E49",
  dark50: "#272F38",
  empathy50: "#FCEBF1",
  empathy100: "#F5C1D4",
  empathy200: "#F1A3BF",
  empathy300: "#EA79A1",
  empathy400: "#E65F8F",
  empathy500: "#E03773",
  empathy600: "#CC3269",
  empathy700: "#9F2752",
  empathy800: "#7B1E3F",
  empathy900: "#5E1730",
  foresight50: "#FEF8E6",
  foresight100: "#FBEAB1",
  foresight200: "#F9DF8B",
  foresight300: "#F6D156",
  foresight400: "#F5C835",
  foresight500: "#F2BA03",
  foresight600: "#DCA903",
  foresight700: "#AC8402",
  foresight800: "#856602",
  foresight900: "#664E01",
  integrity50: "#EDF8F5",
  integrity100: "#C8E8E0",
  integrity200: "#AEDDD1",
  integrity300: "#89CDBC",
  integrity400: "#72C4AF",
  integrity500: "#4FB59B",
  integrity600: "#48A58D",
  integrity700: "#38816E",
  integrity800: "#2B6455",
  integrity900: "#214C41",
};

export const lightColor = {
  neutral0: "#ffffff",
  neutral100: "#272f38",
  navi50: "#EBEFFF",
  navi100: "#C0CFFF",
  navi200: "#A1B7FF",
  navi300: "#7696FF",
  navi400: "#5C82FF",
  navi500: "#3363ff",
  navi600: "#2E5AE8",
  navi700: "#2446B5",
  navi800: "#1C368C",
  navi900: "#152A6B",
  dark50: "#F4F5F6",
  dark100: "#CDD3D9",
  dark200: "#B5BEC7",
  dark300: "#93A0AD",
  dark400: "#7E8D9D",
  dark500: "#5E7185",
  dark600: "#566779",
  dark700: "#43505E",
  dark800: "#343E49",
  dark900: "#272F38",
  empathy50: "#FCEBF1",
  empathy100: "#F5C1D4",
  empathy200: "#F1A3BF",
  empathy300: "#EA79A1",
  empathy400: "#E65F8F",
  empathy500: "#E03773",
  empathy600: "#CC3269",
  empathy700: "#9F2752",
  empathy800: "#7B1E3F",
  empathy900: "#5E1730",
  foresight50: "#FEF8E6",
  foresight100: "#FBEAB1",
  foresight200: "#F9DF8B",
  foresight300: "#F6D156",
  foresight400: "#F5C835",
  foresight500: "#F2BA03",
  foresight600: "#DCA903",
  foresight700: "#AC8402",
  foresight800: "#856602",
  foresight900: "#664E01",
  integrity50: "#EDF8F5",
  integrity100: "#C8E8E0",
  integrity200: "#AEDDD1",
  integrity300: "#89CDBC",
  integrity400: "#72C4AF",
  integrity500: "#4FB59B",
  integrity600: "#48A58D",
  integrity700: "#38816E",
  integrity800: "#2B6455",
  integrity900: "#214C41",
};
