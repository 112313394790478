import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { useRecentInvoicesData } from "hooks/useInvoicesData";
import Color from "utils/colours";
import { formatCurrency } from "utils/commonHelpers";
import InvoiceStatuses from "components/invoice/InvoiceStatuses";
import CardSummary from "components/common/card-summary";
import { LyteButton } from "components/common/buttons";

const SmallTextGrey = styled.p`
  font-size: 13px;
  margin: 0 !important;
  color: ${({ theme }) => theme.color.invoiceBorderLine};
`;

const CardSummaryDivider = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.dividerPrimary};
  margin: 8px 0;
`;

const InvoiceSummary = () => {
  const { data: invoices } = useRecentInvoicesData();
  const { push } = useHistory();

  const filteredInvoices = !invoices
    ? []
    : invoices
        .filter((invoice) => invoice.status !== "draft" && invoice.status !== "cancelled")
        .sort((a, b) => b.id - a.id)
        .slice(0, 5);

  return (
    <Card style={{ background: "none" }}>
      <div className="d-flex justify-content-between">
        <h6 className="font-weight-bold text-uppercase">Invoice Summary</h6>

        <Link to="/invoice/listing">
          <p className="font-weight-bold " style={{ color: Color.navi400 }}>
            View All
          </p>
        </Link>
      </div>
      {
        filteredInvoices.length === 0 ? (
          <Card className="p-3 mb-1">There are no outstanding invoices</Card>
        ) : (
          filteredInvoices?.map((item) => (
            <CardSummary key={Math.random() * 1000}>
              <Row className="no-gutters">
                <Col xs={4} className="d-flex flex-column justify-content-center ">
                  <p className="mb-2 font-weight-bold text-capitalize">{item?.name}</p>
                  <p className="m-0 text-capitalize">{item?.invoiceNumber}</p>
                </Col>
                <Col xs={8}>
                  <div className="d-flex flex-column align-items-end justify-content-center pl-2">
                    <p className="font-weight-bold m-0">
                      {`${item?.currency || ""} ${formatCurrency(item?.amount, false)}`}
                    </p>
                  </div>
                </Col>
              </Row>

              <CardSummaryDivider />

              <Row className="no-gutters">
                <Col xs={7} className="d-flex flex-column gap-1 flex-wrap ">
                  <SmallTextGrey className="m-0">
                    Due: {dayjs(item?.paymentDueDate).format("DD/MM/YYYY")}
                    {item.isOverdue && (
                      <FontAwesomeIcon
                        className="ml-1 text-danger"
                        icon={faClock}
                        title="Overdue"
                      />
                    )}
                  </SmallTextGrey>
                  <div style={{ width: "fit-content" }}>
                    {item?.boostStatus ? (
                      <InvoiceStatuses
                        status={item?.boostStatus}
                        pill
                      >{`${item.boostStatus} boost`}</InvoiceStatuses>
                    ) : (
                      <InvoiceStatuses status={item?.status} pill />
                    )}
                  </div>
                </Col>
                <Col xs={5} className="d-flex align-items-end justify-content-end gap-3 pl-2">
                  <div>
                    <LyteButton
                      variant="ghost"
                      size="sm"
                      onClick={() => push(`/invoice/${item?.invoiceReference}`)}
                    >
                      View Detail
                    </LyteButton>
                  </div>
                </Col>
              </Row>
            </CardSummary>
          ))
        )
        // <TableBase
        //   data={invoices}
        //   columns={columns}
        //   onRowClicked={(row) => push(`/invoice/${row.invoiceReference}`)}
        //   responsive
        // />
      }
    </Card>
  );
};

export default InvoiceSummary;
