import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUserDataSingleUse } from "hooks/useUserData";
import KycSubmitted from "components/kyc/onfido-kyc-success";
import Loader from "components/common/Loader";
import { PENDING_SUBMISSION } from "components/kyc/constants";

import { useSubmittedOnfidoVerification } from "./service";

const OnfidoKycCallback = () => {
  const { replace } = useHistory();
  const { data, isLoading: isUserLoading } = useUserDataSingleUse();
  const { mutate: mutateSubmittedOnfidoVerificationData, isLoading: isLoadingSubmit } =
    useSubmittedOnfidoVerification();

  useEffect(() => {
    if (data) {
      if (data?.isKycApproved) {
        replace("/dashboard");
      } else if (!data?.kycStatus || data?.kycStatus === PENDING_SUBMISSION) {
        mutateSubmittedOnfidoVerificationData();
      } else {
        replace("/kyc");
      }
    }
  }, [data]);

  return isUserLoading || isLoadingSubmit ? <Loader /> : <KycSubmitted />;
};

export default OnfidoKycCallback;
