import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";

import Loading from "ui-lib/components/loading/Loading";

import { OtpContainer } from "./styled";
import { useRequestOtp, useValidateOtp } from "./service";

const Otp = ({ phoneNumber }) => {
  const { id } = useParams();
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [countdown, setCountdown] = useState(300);
  const [cookies, setCookie] = useCookies([]);

  const { mutate: mutateRequest } = useRequestOtp();
  const { mutate: mutateValidate, isLoading: isLoadingValidateOtp } = useValidateOtp();

  useEffect(() => {
    if (!cookies?.editProfileAuth) mutateRequest({ identifier: id });
    else setCountdown(0);
  }, []);

  const handleOTPChange = (event) => {
    const { value } = event.target;
    setOTP(value);

    const isValidOTP = /^\d{6}$/.test(value);
    const isNumeric = /^\d+$/.test(value);
    if (value.length === 6 && isValidOTP && isNumeric) {
      mutateValidate(
        { identifier: id, otp: value },
        {
          onSuccess: (response) => {
            const exprationDate = new Date(response.contextObjects[0].expiredDateTime);
            if (response.success) {
              setCookie("editProfileAuth", response.contextObjects[0].authorizationKey, {
                expires: exprationDate,
              });
              window.location.reload();
            } else {
              setError(response?.messages?.[0] || "Invalid OTP");
            }
          },
        }
      );
    } else if (!isNumeric) {
      setError("Invalid OTP");
    } else {
      setError("");
    }
  };

  const handleResendClick = () => {
    mutateRequest({ identifier: id });
    setOTP("");
    setCountdown(300);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  return (
    <OtpContainer>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6}>
            <OtpContainer.Box>
              {isLoadingValidateOtp && (
                <Loading
                  style={{
                    backdropFilter: "blur(2px)",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
              <h6>
                In order to update your profile page, we need an identity confirmation from you. We
                have send a SMS to your phone number at +{phoneNumber}. Please fill the verification
                code below to continue.
              </h6>
              <div className="py-3 px-4 w-100">
                <OtpContainer.Input
                  type="text"
                  value={otp}
                  onChange={handleOTPChange}
                  maxLength={6}
                  placeholder="Enter OTP"
                  disabled={isLoadingValidateOtp || countdown <= 0}
                />
              </div>
              {error && (
                <p className="text-center w-100" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              <p className="d-flex align-items-center justify-content-center">
                {countdown > 0 ? (
                  `Resend OTP in ${countdown} seconds`
                ) : (
                  <Button onClick={handleResendClick}>Resend OTP</Button>
                )}
              </p>
            </OtpContainer.Box>
          </Col>
        </Row>
      </Container>
    </OtpContainer>
  );
};

export default Otp;
