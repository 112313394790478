import clsx from "clsx";
import PropTypes from "prop-types";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import currency from "currency.js";

import { formatCurrency } from "utils/commonHelpers";
import { useUserData } from "hooks/useUserData";
import Loading from "ui-lib/components/loading";

import AdvanceModal from "./AdvanceModal";

const Breakdown = ({ invoiceBalance, values, isSponsored }) => {
  const { data: user } = useUserData();
  const { advanceAmount, advanceFee: processingFee } = values;
  const remainingAmount = currency(invoiceBalance).subtract(advanceAmount).value;
  const canCashout = user?.hasApprovedBankAccount;
  const totalTransferred = () => {
    if (isSponsored) {
      return currency(advanceAmount);
    }
    if (values?.advanceFeeAddOn) {
      return currency(advanceAmount).subtract(+processingFee + +values.advanceFeeAddOn).value;
    }
    return currency(advanceAmount).subtract(processingFee).value;
  };
  const processingFeeToShow = isSponsored ? "Paid by the agency" : formatCurrency(processingFee);

  const breakdownItems = [
    {
      key: "advanceAmount",
      label: "Advance Amount",
      displayValue: formatCurrency(advanceAmount || 0),
    },
    {
      key: "processingFee",
      label: "Processing Fee*",
      displayValue: processingFeeToShow,
    },
  ];

  return !user ? (
    <Loading />
  ) : (
    <>
      <p className="fw-600 text-uppercase my-2">Advance Breakdown</p>
      <Card>
        <Card.Body>
          <ListGroup>
            {breakdownItems.map(({ key, label, displayValue }) => (
              <ListGroup.Item key={key} className="p-2 border-0">
                <Row>
                  <Col xs={6}>{label}</Col>
                  <Col
                    xs={6}
                    className={clsx({
                      "text-right": true,
                      "text-success": key === "discount",
                      "fw-600": key === "discount",
                    })}
                  >
                    {displayValue}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
            {values?.advanceFeeAddOn ? (
              <ListGroup.Item className="p-2 border-0">
                <Row>
                  <Col xs={6}>Additional Fee*</Col>
                  <Col
                    xs={6}
                    className={clsx({
                      "text-right": true,
                    })}
                  >
                    {formatCurrency(values?.advanceFeeAddOn)}
                  </Col>
                </Row>
              </ListGroup.Item>
            ) : (
              ""
            )}
            <ListGroup.Item
              className="p-2 fw-800"
              style={{
                borderTop: "1px solid #000",
                borderRight: 0,
                borderLeft: 0,
                borderBottom: 0,
                borderRadius: 0,
              }}
            >
              <Row>
                <Col xs={6}>Total transferred</Col>
                <Col xs={6} className="text-right">
                  {formatCurrency(totalTransferred())}
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          <div>
            <AdvanceModal
              disabled={!canCashout}
              totalTransferred={totalTransferred()}
              processingFeeToShow={processingFeeToShow}
              remainingAmount={remainingAmount}
            />
            <p className="text-muted small text-center">
              {!user.hasApprovedBankAccount && (
                <p className="text-danger">You need an approved bank account to advance.</p>
              )}
              Please review all details above before clicking to advance. By cashing out, you agree
              to our Terms & Conditions
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Breakdown;

Breakdown.defaultProps = {
  advanceAmount: 0,
};

Breakdown.propTypes = { advanceAmount: PropTypes.number };
