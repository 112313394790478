import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-query";

import RejectIllustration from "images/illust/illust-reject.png";
import Loader from "components/common/Loader";
import useApi from "hooks/useApi";

const InvoiceAccepted = () => {
  const { identifier } = useParams();
  const history = useHistory();
  const { Public } = useApi();

  const { data: invoice } = useQuery(
    "invoiceDetails",
    async () => {
      const { data } = await Public.get(`public/invoices/${identifier}`);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onSettled: ({ status }) => {
        if (status === "rejected") {
          history.replace(`/invoice/rejected/${identifier}`);
        }
        if (status === "pending") {
          history.replace(`/invoice-accept/${identifier}`);
        }
      },
    }
  );

  return !invoice ? (
    <Loader />
  ) : (
    <div className="invoice-submitted py-5">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-8 p-0 text-center">
            <img alt="" src={RejectIllustration} className="image mb-4 col-4 mx-auto" />
            <h5>
              <strong>You have accepted {`${invoice.fullName}'s` || null} invoice.</strong>
            </h5>
            <p>Please close this page.</p>
            <div className="mt-3">
              <a
                href={process.env.REACT_APP_LP_BASE_URL}
                target="_blank"
                rel="noreferrer"
                className="global-button"
              >
                Learn more about LytePay
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAccepted;
