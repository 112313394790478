import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, Form } from "react-bootstrap";

import { LyteBtn } from "ui-lib/components/buttons/";
import useApi from "hooks/useApi";
import { useContents } from "hooks/contents";
import { formatCurrency } from "utils/commonHelpers";

const InvoiceStatus = {
  Draft: "draft",
  Pending: "pending",
  Accepted: "accepted",
  Rejected: "rejected",
  Cancelled: "cancelled",
  Deleted: "deleted",
  Paid: "paid",
  PaidAndWaive: "paidAndWaive",
  PartiallyPaid: "partially_paid",
  Viewed: "viewed",
};

export const InvoiceStatusOptions = {
  draft: { key: "draft", label: "Draft", value: "draft" },
  pending: { key: "pending", label: "Pending", value: "pending" },
  accepted: { key: "accepted", label: "Accepted", value: "accepted" },
  rejected: { key: "rejected", label: "Rejected", value: "rejected" },
  cancelled: { key: "cancelled", label: "Cancelled", value: "cancelled" },
  deleted: { key: "deleted", label: "Deleted", value: "deleted" },
  paid: { key: "paid", label: "Paid", value: "paid" },
  paidAndWaive: {
    key: "paidAndWaive",
    label: "Paid & Waive Fees",
    value: "paidAndWaive",
  },
  partially_paid: {
    key: "partially_paid",
    label: "Partially Paid",
    value: "partially_paid",
  },
};

const Select = ({
  defaultOption,
  disableDefaultOption,
  style,
  className,
  invoiceReference,
  invoiceNumber,
  refetch,
  invoiceStatus,
  totalAmountDue,
}) => {
  const { Private } = useApi();

  const [selectedStatus, setSelectedStatus] = useState("");
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const availableOptions = useCallback((currentStatus) => {
    switch (currentStatus) {
      case InvoiceStatus.Accepted:
        return [InvoiceStatusOptions.paid, InvoiceStatusOptions.cancelled];
      case InvoiceStatus.Rejected:
        return [InvoiceStatusOptions.cancelled];
      case InvoiceStatus.PartiallyPaid:
        return [InvoiceStatusOptions.paid];
      case InvoiceStatus.Pending:
      case InvoiceStatus.Viewed:
        return [InvoiceStatusOptions.paid, InvoiceStatusOptions.cancelled];
      default:
        return [];
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  });

  // TODO: Use ReactQuery useMutation and show the toast notifications
  const submitStatusChangeHandler = useCallback(async () => {
    const result = await Private({
      method: "post",
      url: "invoices/update",
      data: {
        invoiceReference,
        status: selectedStatus,
        statusChangeReason: reason,
        statusChangeOtherReason: otherReason,
      },
    });
    setSubmit(true);
    // result is boolean
    setSuccess(result);
    closeModal();
    // eslint-disable-next-line no-unused-expressions
    refetch && (await refetch());
  }, [closeModal, selectedStatus, reason, otherReason, invoiceReference, Private, refetch]);

  const resetSelectedStatus = useCallback(() => {
    setSelectedStatus(defaultOption.label);
  }, [defaultOption.label]);

  const { data } = useContents();

  return (
    <div style={{ display: "block" }}>
      <form>
        <select
          className={`form-control form-control-sm mx-auto ${className}`}
          style={style}
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            setIsOpen(true);
          }}
          disabled={
            defaultOption.key === InvoiceStatus.Draft ||
            defaultOption.key === InvoiceStatus.Paid ||
            defaultOption.key === InvoiceStatus.Cancelled ||
            defaultOption.key === InvoiceStatus.Paid
          }
        >
          <option value="" disabled>
            Select status
          </option>
          {defaultOption && (
            <option
              className="text-capitalize"
              defaultValue
              {...defaultOption}
              disabled={disableDefaultOption}
              hidden
            />
          )}
          {availableOptions(defaultOption.key).map(({ key, label, value }) => (
            <option key={key} value={value} label={label} />
          ))}
        </select>
      </form>
      <p>
        {success === true ? (
          <p className="text-success">
            <FontAwesomeIcon icon={faCheckCircle} /> Status changed
          </p>
        ) : (
          success === false && (
            <p className="text-danger">
              <FontAwesomeIcon icon={faTimesCircle} /> Server Error
            </p>
          )
        )}
      </p>
      <Modal show={isOpen} onHide={closeModal} centered backdrop="static">
        <Modal.Header closeButton onHide={closeModal}>
          <Modal.Title>Invoice {invoiceNumber}</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {invoiceStatus === InvoiceStatus.PartiallyPaid ? (
              <p>
                Are you sure you want to change the status of invoice
                <strong> {invoiceNumber} </strong>
                from {invoiceStatus.replace("_", " ")} to{" "}
                <strong>{selectedStatus.toUpperCase()}</strong>? The remaining amount due of{" "}
                <strong>{formatCurrency(totalAmountDue)}</strong> will be waived.
              </p>
            ) : (
              <p>
                Are you sure you want to change the status of invoice
                <strong> {invoiceNumber} </strong> from {invoiceStatus.replace("_", " ")} to{" "}
                <strong>{selectedStatus.toUpperCase()}</strong>?
              </p>
            )}
            <div className="my-4">
              <Form.Control
                id="paidReasons"
                name="paidReasons"
                as="select"
                required
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select Reason</option>
                {selectedStatus === "paid"
                  ? data?.paidReasons.map(({ key, value }) => (
                      <option key={value} value={value}>
                        {key}
                      </option>
                    ))
                  : data?.cancelledReasons.map(({ key, value }) => (
                      <option key={value} value={value}>
                        {key}
                      </option>
                    ))}
              </Form.Control>
              {reason === "Other (Please specify)" && (
                <Form.Control
                  id="otherReason"
                  name="otherReason"
                  as="textarea"
                  onChange={(e) => setOtherReason(e.target.value)}
                  cols="30"
                  rows="4"
                  className="mt-2"
                />
              )}
            </div>
            <p className="text-small">
              <strong>NOTE:</strong> This action cannot be undone. Invoices paid by yourself are not
              eligible for Boost rewards.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <LyteBtn
              variant="white"
              className="red"
              onClick={() => {
                resetSelectedStatus();
                closeModal();
              }}
            >
              Cancel
            </LyteBtn>
            <LyteBtn
              className="btn-sm"
              disabled={submit}
              onClick={async () => {
                setSubmit(true);
                await submitStatusChangeHandler();
              }}
            >
              Yes, I confirm
            </LyteBtn>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Select;

Select.defaultProps = {
  options: [
    {
      key: 1,
      value: "default option 1",
      label: "default option 1",
    },
    {
      key: 2,
      value: "default option 2",
      label: "default option 3",
    },
  ],
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
};
