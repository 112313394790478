import NoKycWarning from "components/kyc/no-kyc-warning/NoKycWarning";
import {
  APPROVED,
  DRAFT,
  PENDING_SUBMISSION,
  PENDING_VERIFICATION,
  REJECTED,
} from "components/kyc/constants";

const KycStatus = ({ user }) =>
  !user || user?.isKycApproved || user?.kycStatus === APPROVED ? null : (
    <NoKycWarning kycStatus={user?.kycStatus}>
      {(!user?.kycStatus ||
        user?.kycStatus === DRAFT ||
        user?.kycStatus === PENDING_SUBMISSION) && (
        <>
          <p className="font-weight-bold">You have not completed your identity verification</p>
          <p>
            To enjoy all of LytePay's services, you will need to verify your identity. Complete it
            now.
          </p>
        </>
      )}

      {user?.kycStatus === PENDING_VERIFICATION && (
        <>
          <p className="font-weight-bold">Your identity is being verified.</p>
          <p>
            The process usually takes a few minutes. For follow-ups please contact{" "}
            <span className="text-blue">support@lytepay.co</span>.
          </p>
        </>
      )}

      {user?.kycStatus === REJECTED && (
        <>
          <p className="font-weight-bold">
            Your identity documents has been rejected. Please try again.
          </p>
          <p>
            To enjoy all of LytePay's services, you will need to verify your identity. Complete it
            now.
          </p>
        </>
      )}
    </NoKycWarning>
  );

export default KycStatus;
