import PropTypes from "prop-types";

import { StyledLink } from "./styles";

const CustomLink = ({ className, variant, children, ...restProps }) => (
  <StyledLink className={`${variant} ${className}`} {...restProps}>
    {children}
  </StyledLink>
);

CustomLink.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

CustomLink.defaultProps = {
  className: "",
  variant: "primary",
  children: "",
};

export default CustomLink;
