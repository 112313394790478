import { Image } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const UploaderDropzone = styled.div`
  .drop-container {
    width: 100px;
    height: 100px;
    border: 2px dashed ${(props) => props.theme.color.borderDefault};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: ${(props) => props.theme.color.bodyBackground};
    &:hover {
      background-color: ${(props) => props.theme.color.cardBackground};
    }
  }

  .upload-icon {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.color.iconDefault};
  }

  .upload-text {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 10px;
  }

  .progress {
    width: 100%;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

FilesContainer.Files = styled.div`
  position: relative;
  border: 2px solid ${(props) => props.theme.color.borderDefault};
  border-radius: 5px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background-color: ${(props) => props.theme.color.bodyBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 12px;
  &:hover,
  &:active {
    .delete-icon-container {
      opacity: 1;
    }
  }
`;

FilesContainer.Name = styled.div`
  white-space: nowrap;
  display: flex;
  width: 100%;
  cursor: help;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    line-height: 1;
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

FilesContainer.Image = styled(Image)`
  height: 100%;
  max-height: 55px;
`;

FilesContainer.DeleteButton = styled.div`
  transition: all 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  color: ${(props) => props.theme.color.iconAlert};
  background-color: ${(props) => props.theme.color.buttonAlertPressed};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
`;
