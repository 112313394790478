import { useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import Color from "utils/colours";
import LyteLogo from "images/logo/lytepay-logo.png";

import { HeaderContainer, StyledImage, StyledProgress } from "./styles";

const Header = ({ currentStep, userData }) => {
  const isSm = useBreakpoint(down("sm"));
  const steps = 6;
  const arraySteps = Array.from({ length: steps }, (_, index) => index + 1);
  const history = useHistory();

  const handleCheckDefaultStep = () => {
    if (userData && currentStep > 4) return true;
    if (!userData && currentStep > 1) return true;
    return false;
  };

  const handleBackStep = useCallback(() => {
    if (handleCheckDefaultStep()) history.goBack();
  }, [currentStep, userData]);

  return (
    <HeaderContainer fluid>
      <Row>
        <Col xs={12}>
          {!currentStep ? <StyledImage src={LyteLogo} /> : !isSm && <StyledImage src={LyteLogo} />}
        </Col>

        {currentStep && (
          <Col xs={12}>
            <Container>
              <Row className="justify-content-center no-gutters">
                <Col xs={12} lg={6}>
                  <StyledProgress steps={steps}>
                    <div className="progress-bar-container">
                      {arraySteps.map((step) => (
                        <div
                          className={`each-progress ${step <= (currentStep || 0) && "active"}`}
                        />
                      ))}
                    </div>
                  </StyledProgress>
                  {handleCheckDefaultStep() && (
                    <div className="d-flex align-items-center pt-2">
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        onClick={() => handleBackStep()}
                        color={Color.dark900}
                        style={{ cursor: "pointer" }}
                      />
                      <p className="m-0 ml-2 font-weight-bold">Back</p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </HeaderContainer>
  );
};

export default Header;
