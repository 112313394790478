import { formatDate } from "react-day-picker/moment";

export const definitions = [
  { accessor: "uinfin", header: "UINFIN" },
  { accessor: "name", header: "Name" },
  { accessor: "hanyuPinyinName", header: "Hanyu Pinyin Name" },
  { accessor: "aliasName", header: "Alias Name" },
  { accessor: "hanyuPinyinAliasName", header: "Hanyu Pinyin Alias" },
  { accessor: "mariedName", header: "Maried Name" },
  { accessor: "sexDescription", header: "Sex" },
  { accessor: "race", header: "Race" },
  {
    accessor: (data) => (data?.dateOfBirth ? formatDate(data?.dateOfBirth, "DD MMM YYYY") : "-"),
    header: "Date of Birth",
  },
  {
    accessor: (data) =>
      data?.passExpiryDate ? formatDate(data?.passExpiryDate, "DD MMM YYYY") : "-",
    header: "Pass Expiry Date",
  },
  { accessor: "passStatus", header: "Pass Status" },
  { accessor: "passType", header: "Pass Type" },
  { accessor: "employmentSector", header: "Employment Sector" },
  { accessor: "nationalityDescription", header: "Nationality" },
  { accessor: "countryDescription", header: "Country" },
  { accessor: "postalCode", header: "Postal Code" },
  {
    accessor: (data) =>
      data?.address?.split("\n").map((item) => (
        <>
          {item}
          <br />
        </>
      )),
    header: "Address",
  },
];
