import * as yup from "yup";

export const SignupSchema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("This is a required field"),
  password: yup
    .string()
    .required("This is a required field")
    .min(8, "Password must be more than 8 characters")
    .max(20, "Password must be less than 20 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must be alphanumeric and contain at least one uppercase letter and one symbol"
    ),
});
