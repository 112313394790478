import toast from "react-hot-toast";
import { useMutation } from "react-query";

import useApi from "../useApi";

export const useUpdateNextBoostSettings = (opts) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("boosts/update/setting", values);
    toast.promise(promise, {
      loading: "Updating next boost settings...",
      success: "Next boost settings updated!",
      error: "Error updating boost settings",
    });
    const { data } = await promise;
    return data;
  }, opts);
};
