import { down } from "styled-breakpoints";
import styled from "styled-components";

import LyteFooterImage from "images/illust/illust-signup-footer.png";
import Color from "utils/colours";

export const SignupContainer = styled.div`
  font-family: "Inter";
  color: ${Color.dark700};
  min-height: 100%;
  background-color: ${Color.neutral0};
  position: relative;
  padding-top: 110px;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    background-image: url(${LyteFooterImage});
    background-size: cover;
    background-position: bottom center;
    background-repeat: repeat-x;
    position: absolute;
    width: 100%;
    height: 200px;
    bottom: 0;
    left: 0;
  }

  h3 {
    color: ${Color.dark900};
  }

  .content-container {
    min-height: 400px;
    background-color: rgba(255, 255, 255, 0.85);
    position: relative;
    padding-bottom: 20px;
  }

  ${down("md")} {
    padding-top: 84px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
