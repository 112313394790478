import { useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";

import useApi from "hooks/useApi";

import { StyledUploadProfile } from "./styled";

const UploadProfile = () => {
  const { id } = useParams();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { Public } = useApi();

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileSizeLimit = 20 * 1024 * 1024; // 20MB
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (file?.size > fileSizeLimit) {
      setErrorMessage("File size exceeds the limit (20MB).");
      return;
    }

    if (!acceptedFileTypes.includes(file?.type)) {
      setErrorMessage("Invalid file type. Only PNG, JPG, and JPEG are allowed.");
      return;
    }

    setErrorMessage("");
    setUploadedFile(URL.createObjectURL(file));
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("identifier", id);
    Public({
      headers: {
        common: {
          Authorization: process.env.REACT_APP_PUBLIC_PROFILE_TOKEN,
        },
      },
      method: "post",
      url: "lyte-assistant/user-profiles/profile-image",
      data: formData,
      onUploadProgress: (progressEvent) => {
        let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        if (progress === 100) progress = 99;
        setUploadProgress(progress);
      },
    })
      .then((response) => {
        setUploadProgress(100);
        setSuccessMessage(
          "Great! We have received your profile pic. You may close this window and back to your chat with Emma."
        );
      })
      .catch(() => {
        setErrorMessage("There's a problem with uploading your file, please try again later.");
        setTimeout(() => {
          setUploadedFile(null);
          setUploadProgress(0);
        }, 500);
      });
  };

  return (
    <StyledUploadProfile>
      <Container className="p-5">
        <Row className="align-items-center justify-content-center">
          <Col xs={12} md={8}>
            <h3 className="text-center mb-5">Upload your Profile Picture</h3>
            <Dropzone
              accept={{
                "image/jpeg": [".jpeg", ".png"],
              }}
              onDrop={handleDrop}
              disabled={successMessage}
            >
              {({ getRootProps, getInputProps }) => (
                <StyledUploadProfile.Dropzone {...getRootProps()}>
                  <input {...getInputProps()} />
                  {uploadedFile ? (
                    <div className="d-flex align-items-center flex-column justify-content-center w-100 p-3">
                      <StyledUploadProfile.Image
                        src={uploadedFile}
                        alt="Uploaded"
                        className="img-fluid pb-2"
                      />
                      {uploadProgress > 0 && (
                        <ProgressBar
                          animated={uploadProgress !== 100}
                          variant={uploadProgress === 100 ? "success" : "info"}
                          now={uploadProgress}
                          label={`${uploadProgress}%`}
                          min={0}
                          max={100}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center text-center p-3">
                      <FontAwesomeIcon
                        icon={faCloudUploadAlt}
                        style={{ width: "80px", height: "80px" }}
                      />
                      <p className="pt-2">Drag and drop a file here or click to select a file</p>
                    </div>
                  )}
                </StyledUploadProfile.Dropzone>
              )}
            </Dropzone>
            {successMessage && (
              <p className="mt-2" style={{ color: "green" }}>
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="mt-2" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}

            <p className="font-italic font-weight-light mt-2">
              *Please keep in mind, in order to get a good quality of your profile picture, we
              recommend that you upload a potrait picture with 3:4 size and use a file of type png .
            </p>
          </Col>
        </Row>
      </Container>
    </StyledUploadProfile>
  );
};

export default UploadProfile;
