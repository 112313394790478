import { useEffect, useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { useHistory } from "react-router-dom";

import { useGetAnnoucements } from "hooks/annoucement";
import Loader from "components/common/Loader";
import {
  LyteMoneyCard,
  ReferralCard,
  InvoiceSummary,
  KycStatus,
  ProfileCompletionModal,
  PromoCodeCard,
} from "components/dashboard";
import { useGetUserRoles } from "hooks/user";
import FreelancerInfoCollectionModal from "components/onboarding/freelancer-info-collection/FreelancerInfoCollection";
import AdvanceCard from "components/dashboard/AdvanceCard";
import { useGetAdvanceList } from "hooks/advance";
import { LyteButton } from "components/common/buttons";
import TooltipTourGuide from "components/dashboard/tour-guides/tooltip";
import { useGlobalState } from "providers/GlobalProvider";
import { useTourGuideState } from "providers/TourGuideProvider";
import { useUserDataState } from "providers/UserDataProvider";

import { AnnoucementStyled } from "./Dashboard.styles";

const Dashboard = () => {
  const { userData: user } = useUserDataState();
  const [showModal, setShowModal] = useState(false);
  const { data: userRoles } = useGetUserRoles();
  const { data: annoucements } = useGetAnnoucements();
  const { data: AdvanceListData } = useGetAdvanceList();
  const features = JSON.parse(process.env.REACT_APP_USER_FEATURES_LIST);
  const { setSelectedSubMenuId } = useGlobalState();
  const { setToggleSidebar } = useGlobalState();
  const downMd = useBreakpoint(down("md"));
  const { tutorialDashboard, setTutorialDashboard } = useTourGuideState();
  const { push } = useHistory();

  const filteredAdvanceList = AdvanceListData?.filter((item) => item.advanceStatus === "available");

  useEffect(() => {
    if (userRoles?.length) {
      const areAllRolesInactive = userRoles?.every((item) => item.active === false);
      setShowModal(areAllRolesInactive);
    }
  }, [userRoles]);

  useEffect(() => {
    const showTour = JSON.parse(localStorage.getItem("lytepay2onboardingdashboardtourflow"));
    setTimeout(() => {
      if (!showTour && user && !showModal)
        setTutorialDashboard((prevState) => ({ ...prevState, run: true }));
    }, 500);
  }, [user, showModal]);

  const handleJoyrideCallback = (data) => {
    const { status, index, action, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setTutorialDashboard((prevState) => ({ ...prevState, run: false }));
      localStorage.setItem("lytepay2onboardingdashboardtourflow", true);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 1) {
        setSelectedSubMenuId("Invoice");
        if (downMd && action === "next") {
          setToggleSidebar(true);
          setTutorialDashboard((prevState) => ({
            ...prevState,
            stepIndex: nextStepIndex,
            run: false,
          }));
          setTimeout(() => {
            setTutorialDashboard((prevState) => ({ ...prevState, run: true }));
          }, 300);
        } else {
          setTutorialDashboard((prevState) => ({ ...prevState, stepIndex: nextStepIndex }));
        }
      } else if (index === 2) {
        if (downMd) {
          setToggleSidebar(false);
        }
        setTutorialDashboard((prevState) => ({ ...prevState, stepIndex: nextStepIndex }));
      } else if (index === 3) {
        if (downMd && action === "prev") {
          setToggleSidebar(true);
          setTutorialDashboard((prevState) => ({
            ...prevState,
            stepIndex: nextStepIndex,
            run: false,
          }));
          setTimeout(() => {
            setTutorialDashboard((prevState) => ({ ...prevState, run: true }));
          }, 300);
        } else {
          setTutorialDashboard((prevState) => ({ ...prevState, stepIndex: nextStepIndex }));
        }
      } else {
        setTutorialDashboard((prevState) => ({ ...prevState, stepIndex: nextStepIndex }));
      }
    }
  };

  const handleTooltipTourGuideView = (props) => (
    <TooltipTourGuide {...props} totalSteps={Array(tutorialDashboard.steps.length).fill(0)} />
  );

  if (!user) {
    return <Loader />;
  }

  return (
    <Container className="py-5">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={tutorialDashboard.run}
        showProgress
        showSkipButton
        disableOverlayClose
        disableCloseOnEsc
        stepIndex={tutorialDashboard.stepIndex}
        steps={tutorialDashboard.steps}
        tooltipComponent={handleTooltipTourGuideView}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {annoucements && (
        <Alert
          variant={clsx({
            danger: annoucements.type === "maintenance",
            success: annoucements.type === "promotion",
            primary: true,
          })}
          className="p-4 mb-4"
        >
          <h6>
            <FontAwesomeIcon icon={faBullhorn} className="mr-3" />
            {annoucements.title}
          </h6>
          <AnnoucementStyled
            dangerouslySetInnerHTML={{
              __html: annoucements?.description,
            }}
          />
        </Alert>
      )}

      {!user?.hasApprovedBankAccount && (
        <Alert variant="warning" className="p-4 mb-4">
          {user?.bankAccount?.status === "pending" ? (
            <>
              <h6>
                <FontAwesomeIcon icon={faBullhorn} className="mr-3" />
                Your bank information is being verified
              </h6>
              <AnnoucementStyled className="d-flex flex-wrap">
                The process may take up to 1-2 business days. Please reach out to
                <a className="px-1" href="mailto:support@lytepay.co">
                  support@lytepay.co
                </a>
                should you have any questions.
              </AnnoucementStyled>
            </>
          ) : (
            <>
              <h6>
                <FontAwesomeIcon icon={faBullhorn} className="mr-3" />
                Your bank information is not updated
              </h6>
              <AnnoucementStyled className="d-flex flex-wrap">
                Please update your bank information in order to accept a payment. You can click
                <LyteButton
                  className="mx-1"
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    !user?.registrationCompletedAt
                      ? window.open(`${process.env.REACT_APP_LP_BASE_URL}/account/bank`, "_blank")
                      : push("/bank-verification")
                  }
                >
                  here
                </LyteButton>
                to update your bank account.
              </AnnoucementStyled>
            </>
          )}
        </Alert>
      )}
      <FreelancerInfoCollectionModal show={showModal} setShow={setShowModal} />
      <KycStatus user={user} />
      <Row className="pb-4 step-1">
        <Col md={12} lg={{ span: 6, offset: 3 }}>
          <h5>
            Welcome back, <strong>{user.firstName}!</strong>
          </h5>
        </Col>
        <Col className="py-3" md={12} lg={{ span: 6, offset: 3 }}>
          <LyteMoneyCard advanceListData={AdvanceListData} />
        </Col>
        <AdvanceCard filteredAdvanceList={filteredAdvanceList} />
        <Col className="py-3" lg={{ span: 6, offset: 3 }}>
          <ReferralCard user={user} />
        </Col>
        <Col className="py-3" lg={{ span: 6, offset: 3 }}>
          <PromoCodeCard />
        </Col>
        <Col className="py-3" md={12} lg={{ span: 6, offset: 3 }}>
          <InvoiceSummary />
        </Col>
      </Row>
      {user?.userFeatures.includes(features?.onboarding) && <ProfileCompletionModal />}
    </Container>
  );
};

export default Dashboard;
