import styled from "styled-components";

import lyteWinkBlue from "./lyte-wink-blue.gif";
import lyteWinkWhite from "./lyte-wink-white.gif";

export const LoadingImg = styled.div`
  background: url("${lyteWinkWhite}") center center no-repeat;
  background-size: contain;
  height: 24px;
  width: 0;
  margin-right: 10px;
  transition: all 0.05s ease-in;
`;

// Taken from .global-button styles
export const Button = styled.button`
  display: inline-block;

  ${({ success }) => (success ? "background: #0fd1a3 !important;" : `background: #3363ff;`)}

  color: #fff;
  text-align: center;
  font-size: 15px;
  padding: 8px 27px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  min-height: 36px;
  transition: all 0.1s ease-in;

  &.loading {
    background-color: #8e9ae1;
    cursor: default;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 24px;
    background: url("${lyteWinkWhite}") left center no-repeat;
    background-size: contain;
    vertical-align: middle;
    transition: all 0.1s ease-in;
  }

  &.loading:before {
    width: 34px;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &:disabled {
    background-color: #8c8c8c;
    cursor: inherit;
  }

  &.outline {
    border: 2px solid ${(props) => props.theme.color.borderPrimary};
    background: ${(props) => props.theme.color.backgroundPrimary};
    color: ${(props) => props.theme.color.textPrimary};
    display: block;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
      background: ${(props) => props.theme.color.backgroundPrimary};
      color: ${(props) => props.theme.color.textSecondary};
      border: 2px solid ${(props) => props.theme.color.borderPrimary};
    }

    &:disabled {
      pointer-events: none;
      cursor: inherit;
    }

    &.loading:before {
      background: url("${lyteWinkBlue}") left center no-repeat;
      background-size: contain;
    }
  }

  &.dotted {
    border: 2px dotted #3363ff;
    background: #fff;
    color: #3363ff;
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;

    &.loading:before {
      background: url("${lyteWinkBlue}") left center no-repeat;
      background-size: contain;
    }
  }

  &.link {
    background-color: transparent !important;
    color: ${(props) => props.theme.color.linkPrimary};

    &:hover {
      text-decoration: underline;
    }

    &.loading:before {
      background: url("${lyteWinkBlue}") left center no-repeat;
      background-size: contain;
    }
  }

  &.green {
    background-color: #00dead;
    cursor: default;
  }

  &.red {
    background-color: #ff1f7a;

    &.outline {
      color: #ff1f7a;
      border: 2px solid #ff1f7a;
      background-color: transparent;
    }
  }
`;
