import styled from "styled-components";

import Color from "utils/colours";

export const StyledButton = styled.button`
  width: fit-content;
  border-radius: 32px;
  display: flex;
  padding: 10px 24px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  span {
    position: relative;
  }
  &.primary {
    background-color: ${Color.navi500};
    color: ${Color.neutral0};
  }
  &.secondary {
    background-color: ${Color.neutral0};
    color: ${Color.dark900};
    border-color: ${Color.dark200};
  }
  &.tertiary {
    background-color: ${Color.dark900};
    color: ${Color.neutral0};
  }
  &.link {
    background-color: transparent;
    color: ${Color.navi500};
    text-decoration: underline;
    padding: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${Color.dark900};
    opacity: 0;
    transition: all 0.3s ease-in-out;
    user-select: none;
  }
  &:hover {
    &::before {
      opacity: 0.3;
    }
    &[disabled] {
      cursor: not-allowed;
      &::before {
        opacity: 0;
      }
    }
    &.link {
      &::before {
        display: none;
      }
    }
  }
  &[disabled] {
    opacity: 0.7;
  }
`;
