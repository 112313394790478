/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";

import lyteWink from "images/icons/lyte-wink-white.gif";

/*
|--------------------------------------------------------------------------
| This Loader component is shown when auth0 is redirecting user - can be customised
|--------------------------------------------------------------------------
*/

const loaderContainer = document.querySelector("div#loader");

const LoaderEl = () => {
  // loaderContainer.style.zIndex = "1999";
  useEffect(() => {
    loaderContainer.style.position = "absolute";
    loaderContainer.style.top = "0";
    loaderContainer.style.left = "0";
    loaderContainer.style.width = "100vw";
    loaderContainer.style.height = "100vh";
    loaderContainer.style.zIndex = "1999";

    return () => {
      loaderContainer.style.position = "relative";
      loaderContainer.style.width = "0";
      loaderContainer.style.height = "0";
      loaderContainer.style.zIndex = "0";
    };
  }, []);
  return (
    <div
      className="bg-wink-blue"
      style={{
        width: "100vw",
        height: "100vh",
        // zIndex: "999",
      }}
    >
      <Image className="d-block m-auto" src={lyteWink} />
    </div>
  );
};

export const Loader = () => ReactDOM.createPortal(<LoaderEl />, loaderContainer);

const ProtectedRoute = ({ component, ...rest }) => (
  <Route
    render={(props) => {
      const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />,
        // If using a Hash Router, you need to pass the hash fragment as `returnTo`
        // returnTo: () => window.location.hash.substr(1),
      });
      return <Component {...props} />;
    }}
    {...rest}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
