import styled from "styled-components";
import { down } from "styled-breakpoints";

import { LyteButton } from "components/common/buttons";

export const PwaBannerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  padding: 15px;
  position: relative;
  left: -15px;
  width: calc(100% + 30px);
  p {
    max-width: 70%;
    margin-bottom: 0;
  }
`;

PwaBannerContainer.CloseButton = styled(LyteButton)`
  position: absolute;
  right: 20px;
  color: ${(props) => props.theme.color.textDarkContrast};

  ${down("xs")} {
    top: 15px;
  }
`;
