import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "./useApi";

export const useGetAdvanceList = () => {
  const { Private } = useApi();
  return useQuery(
    "AdvanceList",
    async () => {
      const { data } = await Private({ method: "get", url: "/advances" });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export const useGetAdvanceDetail = (referenceNumber) => {
  const { Private } = useApi();
  return useQuery("AdvanceDetail", async () => {
    const { data } = await Private({ method: "get", url: `/advances/cashout/${referenceNumber}` });
    return data;
  });
};

export const usePostAdvance = (options) => {
  const { Private } = useApi();
  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "advances/cashout",
        data: values,
      });
      toast.promise(promise, {
        loading: "Processing Advance...",
        success: "Advance successfully processed",
        error: ({ message }) => `Error when processing Advance: ${message}`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};
