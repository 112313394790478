import styled from "styled-components";
import { down } from "styled-breakpoints";

import Color from "utils/colours";

export const ProfileContainer = styled.div`
  position: fixed;
  background-color: ${Color.neutral0};
  overflow: hidden;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 428px;
  right: 10px;
  bottom: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  ${down("xs")} {
    right: 0;
    max-width: none;
  }
  .header {
    padding: 20px 25px;
    width: 100%;
    background-color: ${Color.navi500};
    display: flex;
    align-items: center;
    text-align: left;
    border: none;
    gap: 8px;
    .header-left {
      width: calc(100% - 80px);
      color: ${Color.neutral0};
    }
    .header-right {
      position: relative;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .custom-chart {
        position: absolute;
        top: -20px;
        left: -20px;
      }
      .number-counter {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${Color.neutral0};
        font-weight: 700;
      }
    }
  }

  .content {
    padding: 0 25px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    &.visible {
      padding: 0 25px 18px;
      max-height: 100vh;
    }
    .progress-content {
      padding: 18px 0;
      margin-bottom: 18px;
      border-bottom: 1px solid ${Color.dark200};
      display: flex;
      width: 100%;
      gap: 8px;
      .checkmark {
        min-width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px solid ${Color.dark200};
        background-color: ${Color.neutral0};
        color: ${Color.neutral0};
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          opacity: 0;
        }

        &.active {
          border-color: transparent;
          background-color: ${Color.navi500};
          svg {
            opacity: 1;
          }
        }
      }
      p {
        margin: 0;
        font-weight: 700;
        font-size: 18px;
      }
      span {
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
`;
