// TODO: Refactor to use this component instead of the one in the common folder
import { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Button } from "./LyteBtn.styles";

const LyteBtn = ({
  variant,
  className,
  children,
  disabled,
  onClick,
  style,
  hidden,
  title,
  loading,
  type,
  success: successProp,
  successMessage,
  size,
}) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (successProp) {
      setSuccess(true);
    }

    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }, [successProp]);

  return (
    <Button
      className={clsx(
        variant === "white" && "outline m3-auto",
        variant === "link" && "link btn shadow-none",
        variant === "link" &&
          !(
            className &&
            (className.indexOf("m-0") >= 0 ||
              className.indexOf("mr-0") >= 0 ||
              className.indexOf("ml-0") >= 0 ||
              className.indexOf("mt-0") >= 0 ||
              className.indexOf("mb-0") >= 0)
          ) &&
          "mr-4",
        loading && "loading",
        className
      )}
      title={title}
      disabled={disabled || success || loading}
      onClick={onClick}
      style={{ ...style }}
      hidden={hidden}
      type={type}
      success={success}
      size={size}
    >
      {success ? successMessage : children}
    </Button>
  );
};

LyteBtn.defaultProps = {
  variant: "",
  className: "",
  children: "",
  disabled: false,
  onClick: () => {},
  style: {},
  hidden: false,
  title: "",
  loading: false,
  type: "button",
  success: false,
  successMessage: "Done!",
};

LyteBtn.propTypes = {
  variant: PropTypes.oneOf(["", "white", "link"]),
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  hidden: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  success: PropTypes.bool,
  successMessage: PropTypes.string,
};

export default LyteBtn;
