import styled from "styled-components";

export const StyledField = styled.div`
  margin-bottom: 24px;
  position: relative;
  .form-control {
    display: flex;
    font-size: 13px;
    min-height: 84px;
    padding: 8px 12px;
    padding-top: 32px;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: ${(props) => props.theme.color.formInputBackgroundColor};
    border: 2px solid ${(props) => props.theme.color.inputBorderDefault};
    color: ${(props) => props.theme.color.textDarkContrast};
    box-shadow: none !important;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;

    .DraftEditor-root {
      width: 100%;
      .public-DraftStyleDefault-ol,
      .public-DraftStyleDefault-ul {
        margin-top: 0;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorHover};
      border-color: ${(props) => props.theme.color.inputBorderActive};
      color: ${(props) => props.theme.color.textDarkContrast};
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.color.formInputBoxShadowColorHover};
    }

    &:active,
    &:focus {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorActive};
      border-color: ${(props) => props.theme.color.inputBorderActive};
      color: ${(props) => props.theme.color.textDarkContrast};
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.color.formInputBoxShadowColorActive};
    }
    &:disabled {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorDisabled} !important;
      border-color: ${(props) => props.theme.color.inputBorderDisabled} !important;
      color: ${(props) => props.theme.color.textSubtleContrast} !important;
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.color.formInputBoxShadowColorDisabled} !important;
      cursor: default;
    }

    &:-internal-autofill-selected {
      background-color: transparent;
    }
  }

  .description-text,
  .error-text {
    color: ${(props) => props.theme.color.textSubtleContrast};
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 4px;
  }

  &.form-invalid {
    .form-control {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorError};
      border-color: ${(props) => props.theme.color.inputBorderAlert};
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.color.formInputBoxShadowColorError};
    }
    .description-text,
    .error-text {
      color: ${(props) => props.theme.color.textAlertError};
    }
  }

  &.form-success {
    .form-control {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorSuccess};
      border-color: ${(props) => props.theme.color.inputBorderSuccess};
      box-shadow: 0px 0px 0px 2px ${(props) => props.theme.color.formInputBoxShadowColorSuccess};
    }
    .description-text,
    .error-text {
      color: ${(props) => props.theme.color.textAlertSuccess};
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: 13px;
  color: ${(props) => props.theme.color.textDarkContrast};
  margin-bottom: 8px;
  font-weight: 600;
`;

export const StyledPasswordIcon = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: pointer;
  background-color: inherit;
`;

export const StyledToolbar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  background-color: ${(props) => props.theme.color.formInputBackgroundColor};
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 4px;
  button {
    background: transparent;
    color: ${(props) => props.theme.color.textDarkContrast};
    font-size: 13px;
    border: 0;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    cursor: pointer;
    svg {
      fill: ${(props) => props.theme.color.textDarkContrast};
      &:hover,
      &:focus {
        outline: 0;
      }
    }

    ${(props) => `&.${props.toolbarTheme?.active} {
     color: ${props.theme.color.inputBorderActive};
     svg {
      fill : ${props.theme.color.inputBorderActive};
     }
  }`}
  }
`;
