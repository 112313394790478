import { Form } from "react-bootstrap";

import { useContents } from "hooks/contents";

const SelectDiscountType = ({ ...rest }) => {
  const { data, isLoading } = useContents();
  return (
    <Form.Control as="select" {...rest} disabled={isLoading}>
      {data?.discountTypes.map(({ key, value }) => (
        <option label={value} key={key} value={key} />
      ))}
    </Form.Control>
  );
};

export default SelectDiscountType;
