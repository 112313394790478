import PropTypes from "prop-types";
import styled from "styled-components";

import lyteWinkBlue from "./lyte-wink-blue.gif";

const Loading = styled.div`
  background: url("${lyteWinkBlue}") no-repeat center center;
  min-height: ${(props) => props.minHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 32px 32px;
`;

Loading.defaultProps = {
  minHeight: 150,
};

Loading.propTypes = {
  minHeight: PropTypes.number,
};

export default Loading;
