import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Alert, Form } from "react-bootstrap";

import LytePayLogo from "images/svg/lytepay-logo.svg";
import InvoiceTemplate from "components/common/InvoiceTemplate";
import Loader from "components/common/Loader";

const WaterMark = styled.div`
  position: absolute;
  font-size: 7.5rem;
  opacity: 0.2;
  font-weight: 600;
  user-select: none;
  transform: rotate(315deg);
  top: 55%;
  left: 25%;
  z-index: 1000;
`;
const rejectReasons = [
  ["The amount stated is not as discussed", "The amount stated is not as discussed"],
  ["The item listed is not as discussed", "The item listed is not as discussed"],
  ["I did not request this invoice", "I did not request this invoice"],
  ["Other (Please specify)", "Other"],
];

const MockInvoiceAccept = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const queryString = params.get("invoiceQueryString");
  const invoice = JSON.parse(queryString);
  invoice.discount = {
    type: invoice.discountType,
    value: invoice.discountValue,
    description: invoice.discountDescription,
  };

  return !invoice ? (
    <Loader />
  ) : (
    <div className="invoice-reject py-5" style={{ position: "relative" }}>
      <WaterMark>Test Invoice</WaterMark>
      <div className="container">
        <Alert variant="warning" className="mb-5 h5 p-3">
          <p>Note:</p>
          <p>You are viewing a mockup of the invoice REJECTION page which your client will see.</p>
          <p>This page does not serve to be an actual invoice.</p>
        </Alert>
        <div className="form-bg-white py-5">
          <div className="col-md-8 mx-auto text-center">
            <img src={LytePayLogo} className="image mb-5 col-4 col-md-3 mx-auto" alt="" />
            <Form className="my-4 col-lg-8 mx-auto">
              <Form.Control id="rejectReason" name="rejectReason" as="select" required>
                <option value="">Select Reason</option>
                {rejectReasons.map(([label, value]) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Form>
          </div>
        </div>
        <InvoiceTemplate invoice={invoice} disableLogoHint isKycApproved />
      </div>
    </div>
  );
};

export default MockInvoiceAccept;
