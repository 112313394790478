import { Field, Formik } from "formik";
import { Alert, Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import Range from "ui-lib/components/range";
import Loader from "components/common/Loader";
import { useContents } from "hooks/contents";
import { useUpdateNextBoostSettings } from "hooks/boost/useUpdateNextBoostSettings";
import { useGetBoostByRef } from "hooks/boost/useGetBoostByRef";
import { useGetBoostSettings } from "hooks/boost";
import { formatCurrency } from "utils/commonHelpers";
import FormikCheckbox from "components/form/FormikCheckbox";
import Color from "utils/colours";

import { cellUpdateModalSchema } from "./CellUpdateModal.schema";

const PopoverStyled = styled(Popover)`
  width: 300px;
  max-width: none;
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  .arrow {
    display: none;
  }
`;
const BoostSettingContainer = styled.div``;

BoostSettingContainer.SelectContainer = styled.div`
  width: 100%;
  position: relative;
  .icon-container {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: ${Color.dark300};
  }
`;
BoostSettingContainer.FormSwitch = styled.div`
  label {
    display: flex;
    align-items: center;
  }
  &:not(:last-child) {
    padding-bottom: 12px;
  }
  input {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  input:checked + label .switch::before {
    transform: translateX(20px);
  }
  input:checked + label .switch {
    background-color: ${Color.navi600};
  }
`;

BoostSettingContainer.LabelSwitch = styled.label`
  width: 100%;
  gap: 8px;
  .icon {
    color: ${Color.navi500};
  }
  .switch {
    width: 44px;
    height: 22px;
    border-radius: 12px;
    background-color: ${Color.dark300};
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 1px;
      background-color: ${Color.neutral0};
      border-radius: 50%;
      transition: 0.4s;
    }
  }
`;

const CellUpdateModal = () => {
  const { replace } = useHistory();
  const closeModalHandler = () => replace(`/boost/summary`);
  const { boostRefNumber } = useParams();
  const { data: contents } = useContents();
  const { data: boostDetails } = useGetBoostByRef(boostRefNumber);
  const { data: defaultBoostSettings } = useGetBoostSettings();
  const { mutate, isLoading } = useUpdateNextBoostSettings({
    onSuccess: () => closeModalHandler(),
  });

  const DURATION_REWARDS_OPTIONS =
    contents &&
    contents?.boostDurations?.map(
      (e, i) => `${e.key} Days at ${contents.boostRewards[i].key}% Yearly Rewards`
    );

  const handleCheckBoostAmount = (values) => {
    if (
      boostDetails?.validateMinAmount &&
      +boostDetails.boostAmount * +values.boostPercentage <
        contents?.minimumValues?.minimumBoostValue
    )
      return true;
    return false;
  };

  return (
    <Modal show onHide={closeModalHandler}>
      {!boostDetails ? (
        <Loader />
      ) : (
        <Formik
          initialValues={cellUpdateModalSchema.cast(
            {
              boostId: boostDetails.id,
              boostDuration: boostDetails?.nextBoostDays ?? defaultBoostSettings?.duration,
              autoReboost: boostDetails?.autoReboost ?? defaultBoostSettings?.autoReboost,
              boostPercentage: boostDetails?.nextBoostPercentage
                ? boostDetails?.nextBoostPercentage
                : boostDetails?.maxPercentage
                ? Math.ceil(boostDetails?.maxPercentage) / 100
                : defaultBoostSettings?.value,
              agreeReboost: boostDetails?.agreeReboost || false,
            },
            { stripUnknown: true }
          )}
          onSubmit={(values) => mutate(values)}
          validationSchema={cellUpdateModalSchema}
          enableReinitialize
          validateOnMount
        >
          {({ values, setFieldValue, submitForm, isValid }) => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5>Update Next Boost Settings - Boost {boostRefNumber}</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <Form>
                  <Form.Group className="my-2 w-100">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h6>Boost Percentage (Next Cycle)</h6>
                      <h6>{`${formatCurrency(
                        (+boostDetails.boostAmount * +values.boostPercentage).toFixed(2)
                      )}`}</h6>
                    </div>
                    <Range
                      className="w-100"
                      step={1}
                      min={0}
                      max={
                        boostDetails?.maxPercentage <= 100
                          ? 100
                          : Math.ceil(boostDetails?.maxPercentage)
                      }
                      suffix="%"
                      renderPopoverText={(value) => value}
                      value={Number((values?.boostPercentage * 100).toFixed(2))}
                      onChange={(e) =>
                        setFieldValue("boostPercentage", Number((e[0] / 100).toFixed(2)))
                      }
                    />
                  </Form.Group>
                  {handleCheckBoostAmount(values) && (
                    <Alert className="mt-5 mb-0" variant="danger">
                      {`Minimum boost amount is ${formatCurrency(
                        contents?.minimumValues?.minimumBoostValue
                      )}. Please adjust the boost percentage to meet the minimum amount. Your current boost amount is ${formatCurrency(
                        (+boostDetails.boostAmount * +values.boostPercentage).toFixed(2)
                      )}.`}
                    </Alert>
                  )}

                  <Form.Group className="my-2 pb-4">
                    <h6 className="mt-4 pt-2">Boost duration and rate:</h6>
                    <BoostSettingContainer.SelectContainer>
                      <Form.Control
                        as="select"
                        id="boostDuration"
                        className="form-control"
                        onChange={(e) => {
                          const selectedIndex = e.target.value;
                          setFieldValue(
                            "boostDuration",
                            +contents?.boostDurations[selectedIndex].value
                          );
                          setFieldValue(
                            "boostReturn",
                            +contents?.boostRewards[selectedIndex].value
                          );
                        }}
                        value={
                          values.boostDuration &&
                          contents?.boostDurations?.findIndex(
                            (item) => +item.value === values.boostDuration
                          )
                        }
                      >
                        <option value="default" disabled>
                          --Select duration and rate--
                        </option>
                        {DURATION_REWARDS_OPTIONS &&
                          DURATION_REWARDS_OPTIONS?.map((item, index) => (
                            <option key={item} value={index} label={item} />
                          ))}
                      </Form.Control>
                      <div className="icon-container">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </BoostSettingContainer.SelectContainer>
                  </Form.Group>
                  <OverlayTrigger
                    placement="top-start"
                    delay={{ show: 100, hide: 200 }}
                    overlay={
                      <PopoverStyled>
                        <Popover.Content>
                          By selecting this option, I intend to reBoost my invoices upon their
                          maturity date. I acknowledge that any early withdrawal will deactivate
                          this setting, and I will be required to reauthorize Auto ReBoost every 6
                          months.
                        </Popover.Content>
                      </PopoverStyled>
                    }
                  >
                    <BoostSettingContainer.FormSwitch>
                      <Field id="autoReboost" name="autoReboost" type="checkbox" />
                      <BoostSettingContainer.LabelSwitch
                        className="justify-content-between"
                        htmlFor="autoReboost"
                      >
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Auto-reBoost</p>
                          <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
                        </div>
                        <div className="switch" />
                      </BoostSettingContainer.LabelSwitch>
                    </BoostSettingContainer.FormSwitch>
                  </OverlayTrigger>

                  <FormikCheckbox className="m-0" name="agreeReboost">
                    <span>
                      By electing to re-Boost this invoice and/or Rewards, I agree to assign all my
                      rights and benefits under this invoice and/or Rewards to Lytepay Pte Ltd and
                      in return earn further Rewards.
                    </span>
                  </FormikCheckbox>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="lg"
                  className="w-100"
                  disabled={!isValid || isLoading || handleCheckBoostAmount(values)}
                  onClick={() => submitForm()}
                >
                  Update
                </Button>
                {!boostDetails.nextBoostDays ? (
                  <Alert className="mt-4" variant="warning">
                    This boost has not been updated for the next boost cycle.
                  </Alert>
                ) : (
                  <Alert className="mt-4" variant="primary">
                    Last updated:{" "}
                    {dayjs(boostDetails?.updatedDateTime).format("DD MMM YYYY, HH:mm:ss")}
                  </Alert>
                )}
              </Modal.Footer>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default CellUpdateModal;
