export const LYTE_AUDIENCE_SOURCE_TYPES = [
  {
    value: "search-engine",
    label: "Search Engine (Google, Bing!, etc)",
    disabled: false,
    className: "",
  },
  {
    value: "other-freelancer",
    label: "Recommended by other Freelancers",
    disabled: false,
    className: "",
  },
  {
    value: "social-media",
    label: "Social Media",
    disabled: false,
    className: "",
  },
  {
    value: "agency",
    label: "Agency",
    disabled: false,
    className: "",
  },
  {
    value: "others",
    label: "Others",
    disabled: false,
    className: "",
  },
];
