import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import currency from "currency.js";

import { formatCurrency } from "utils/commonHelpers";
import { withCharacterCounter } from "ui-lib/components/input";
import SelectDiscountType from "components/form/SelectDiscountType";
import { useContents } from "hooks/contents";
import { LyteButton } from "components/common/buttons";
import Divider from "components/common/divider";
import Color from "utils/colours";
import { useUserDataState } from "providers/UserDataProvider";

import { AMOUNT, PERCENTAGE, NODISCOUNT } from "../constants";
import InvoiceWithholdingTax from "../InvoiceWithholdingTax";

import { TotalContainer } from "./styles";

const FieldWithCharacterCounter = withCharacterCounter(Field);

const InvoiceTotalCard = ({ values: invoice, errors, readOnly, setFieldValue, setCanNext }) => {
  const [amounts, setAmounts] = useState({
    withholdingTaxAmount: "",
    discountAmount: "",
    subtotal: "",
    total: "",
    vatAmount: "",
  });
  const [show, setShow] = useState(false);
  const discountValueRef = useRef();
  const { data: contents } = useContents();

  const onAddDiscountClick = (e) => {
    e.preventDefault();
    setFieldValue("discount", {
      type: invoice.discount.type === NODISCOUNT ? AMOUNT : invoice.discount.type,
      value: invoice.discount.value,
      description: invoice.discount.description,
    });

    setShow(!show);

    if (!show) {
      setTimeout(() => {
        discountValueRef.current.focus();
      }, 200);
    }
  };

  const calculateAndSetAmounts = (isDelete = false) => {
    const discountAmount =
      (invoice.discount?.type === NODISCOUNT && invoice.discount?.value) ||
      (invoice.discount?.type === AMOUNT && invoice.discount?.value) ||
      (invoice.discount?.type === PERCENTAGE &&
        amounts?.subtotal * (invoice.discount?.value / 100)) ||
      0;
    if (invoice?.withholdingTax?.type === PERCENTAGE) {
      const withholdingTaxAmount =
        ((amounts?.subtotal || 0) - discountAmount) * ((invoice?.withholdingTax?.value || 0) / 100);
      setAmounts((prevVal) => ({
        ...prevVal,
        discountAmount: isDelete ? 0 : discountAmount,
        withholdingTaxAmount,
      }));
    } else {
      setAmounts((prevVal) => ({ ...prevVal, discountAmount: isDelete ? 0 : discountAmount }));
    }
  };

  const onApplyDiscountClick = () => {
    calculateAndSetAmounts();
    setShow(false);
  };

  const onRemoveDiscountClick = () => {
    setFieldValue("discount", {
      type: NODISCOUNT,
      value: "",
      description: "",
    });

    calculateAndSetAmounts(true);

    setShow(false);
  };

  useEffect(() => {
    const subtotal =
      invoice.invoiceItems
        .map(({ quantity, rate }) => +quantity * +rate)
        .reduce((acc, val) => acc + val, 0) || 0;
    setAmounts((prevVal) => ({ ...prevVal, subtotal }));
    calculateAndSetAmounts();
  }, [invoice.invoiceItems]);

  useEffect(() => {
    const calculateTotal = () => {
      const subtotalDiscount = (amounts?.subtotal || 0) - (amounts?.discountAmount || 0);
      const subtotalVat = (subtotalDiscount * (invoice?.vatValue || 0)) / 100;
      const total = subtotalDiscount + subtotalVat - (amounts?.withholdingTaxAmount || 0);
      setAmounts((prevVal) => ({ ...prevVal, vatAmount: subtotalVat, total }));
    };
    calculateAndSetAmounts();
    calculateTotal();
  }, [
    invoice.invoiceItems,
    invoice.vatValue,
    amounts.total,
    amounts.withholdingTaxAmount,
    amounts.discountAmount,
    amounts.subtotal,
  ]);

  useEffect(() => {
    if (!readOnly) {
      if (amounts.total < contents?.minimumValues?.minimumInvoiceValue) {
        setCanNext(false);
      } else setCanNext(true);
    }
  });

  return (
    <TotalContainer>
      <div className="d-flex justify-content-between gap-2 pb-2">
        <small className="fw-600 m-0">Subtotal</small>
        <h6 className="fw-600 m-0">{`${invoice?.currency || ""} ${formatCurrency(
          amounts.subtotal,
          false
        )}`}</h6>
      </div>
      {readOnly ? (
        invoice.discount?.value && (
          <div className="clearfix">
            <div className="d-flex flex-row justify-content-between align-items-start gap-2">
              <p className="d-flex flex-column m-0 text-left align-items-start">
                <small className="fw-600 m-0">
                  Discount{" "}
                  {invoice?.discount?.type.toLowerCase() === PERCENTAGE &&
                    `(${invoice.discount.value}%)`}
                </small>
                {invoice?.discount?.description && (
                  <TotalContainer.Description className="font-italic">
                    {invoice.discount.description}
                  </TotalContainer.Description>
                )}
              </p>

              <h6 className="fw-600 m-0 text-nowrap" style={{ color: Color.integrity800 }}>
                (
                {`${invoice?.currency || ""} ${formatCurrency(
                  amounts.discountAmount || invoice.discountAmount,
                  false
                )}`}
                )
              </h6>
            </div>
          </div>
        )
      ) : (
        <div className="clearfix">
          <OverlayTrigger
            show={show}
            trigger="click"
            placement={window.innerWidth < 768 ? "top" : "left"}
            overlay={
              <Popover id="popover-component">
                <Popover.Content>
                  <div className="form-group row mb-2">
                    <label
                      htmlFor="discountValue"
                      className="col-4 col-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Discount
                    </label>
                    <div className="col-5 pl-0 pr-2">
                      <Field
                        id="discountValue"
                        innerRef={discountValueRef}
                        type="number"
                        name="discount.value"
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        min="0"
                      />
                    </div>
                    <div className="col-3 pl-0">
                      <Field name="discount.type">
                        {({ field }) => (
                          <SelectDiscountType {...field} style={{ fontSize: "14px" }} />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <div className="offset-sm-4">
                      <ErrorMessage name="discount.value">
                        {(msg) => <small className="text-danger">{msg}</small>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <FieldWithCharacterCounter
                      name="discount.description"
                      className="form-control"
                      placeholder="Description (optional)"
                      style={{ fontSize: "14px" }}
                      separator="/"
                      maxLength={100}
                    />
                  </div>
                  <LyteButton
                    className="w-100"
                    size="sm"
                    onClick={onApplyDiscountClick}
                    disabled={!!errors?.discount?.value || !!errors?.discount?.description}
                  >
                    Apply discount
                  </LyteButton>
                </Popover.Content>
              </Popover>
            }
          >
            <div className="w-100">
              {amounts.discountAmount ? (
                <div className="d-flex flex-row justify-content-between align-items-start gap-2">
                  <p className="d-flex flex-column m-0 text-left align-items-start">
                    <LyteButton
                      className="text-left"
                      variant="ghost"
                      size="sm"
                      onClick={onAddDiscountClick}
                    >
                      Discount
                      {invoice.discount.type.toLowerCase() === PERCENTAGE &&
                        ` (${invoice.discount.value}%)`}
                    </LyteButton>
                    {invoice?.discount?.description && (
                      <TotalContainer.Description className="font-italic">
                        {invoice.discount.description}
                      </TotalContainer.Description>
                    )}
                  </p>

                  <div className="d-flex align-items-center text-nowrap">
                    <LyteButton
                      size="sm"
                      variant="ghost"
                      onClick={onRemoveDiscountClick}
                      title="Remove discount"
                      style={{ color: Color.empathy500 }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </LyteButton>
                    ({`${invoice?.currency || ""} ${formatCurrency(amounts.discountAmount, false)}`}
                    )
                  </div>
                </div>
              ) : (
                <LyteButton variant="ghost" onClick={onAddDiscountClick}>
                  Add discount
                </LyteButton>
              )}
            </div>
          </OverlayTrigger>
        </div>
      )}
      {invoice?.currency?.toLowerCase() === "idr" && (
        <>
          <InvoiceWithholdingTax
            amounts={amounts}
            setAmounts={setAmounts}
            readOnly={readOnly}
            values={invoice}
            setFieldValue={setFieldValue}
            errors={errors}
          />
          <div className="clearfix">
            <div className="d-flex flex-row justify-content-between align-items-start gap-2">
              <p className="d-flex flex-column m-0 text-left align-items-start">
                <small className="fw-600 m-0">{`VAT(${invoice?.vatValue || 0}%)`}</small>
              </p>
              <h6 className="fw-600 m-0 text-nowrap">
                {`${invoice?.currency || ""} ${formatCurrency(
                  amounts?.vatAmount || invoice?.vatAmount || 0,
                  false
                )}`}
              </h6>
            </div>
          </div>
        </>
      )}
      <div>
        <Divider className="my-2" />
        <div className="d-flex justify-content-between gap-2">
          <small className="fw-600 m-0">Total</small>
          <h6 className="fw-600 m-0">
            {amounts.total < 0
              ? `${invoice?.currency || ""} ${formatCurrency(0, false)}`
              : `${invoice?.currency || ""} ${formatCurrency(
                  invoice.totalAmount || amounts.total,
                  false
                )}`}
          </h6>
        </div>
        {invoice.totalPaidAmount > 0 && invoice.totalAmountDue > 0 && (
          <>
            <div className="d-flex justify-content-between gap-2 py-2">
              <small className="fw-600 m-0">Paid to Date</small>
              <h6 className="fw-600 m-0" style={{ color: Color.empathy800 }}>
                {`${invoice?.currency || ""} ${formatCurrency(invoice.totalPaidAmount, false)}`}
              </h6>
            </div>
            <div className="d-flex justify-content-between gap-2">
              <small className="fw-600 m-0">Amount Due</small>
              <h6 className="fw-600 m-0">
                {`${invoice?.currency || ""} ${formatCurrency(invoice.totalAmountDue, false)}`}
              </h6>
            </div>
          </>
        )}
      </div>
      <div>
        {!readOnly &&
          amounts.total < contents?.minimumValues?.minimumInvoiceValue &&
          amounts.total !== 0 && (
            <Alert variant="danger">
              {`The minimum total amount is ${currency(
                contents?.minimumValues?.minimumInvoiceValue,
                {
                  symbol: "$",
                  precision: 2,
                }
              ).format()} for invoicing and collecting through LytePay.`}
            </Alert>
          )}
      </div>
    </TotalContainer>
  );
};

InvoiceTotalCard.defaultProps = {
  readOnly: false,
  setFieldValue: () => {},
  errors: {},
};

InvoiceTotalCard.propTypes = {
  values: PropTypes.shape({
    totalAmount: PropTypes.number,
    discountAmount: PropTypes.number,
    discount: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.number,
      description: PropTypes.string,
    }),
    invoiceItems: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        quantity: PropTypes.number,
        unitPrice: PropTypes.number,
      })
    ),
    totalAmountDue: PropTypes.number,
    totalPaidAmount: PropTypes.number,
  }).isRequired,
  readOnly: PropTypes.bool,
  setFieldValue: PropTypes.func,
  errors: PropTypes.shape({
    discount: PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
};

export default InvoiceTotalCard;
