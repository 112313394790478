import { cashout } from "./publicRoutes/cashout";
import { errorPages } from "./publicRoutes/errorPages";
import { homepage } from "./publicRoutes/homepage";
import { invoiceAcceptRejectRoutes } from "./publicRoutes/invoiceAcceptReject";
import { profile } from "./publicRoutes/profile";
import { signup } from "./publicRoutes/signup";

export const publicRoutes = [
  ...errorPages,
  ...invoiceAcceptRejectRoutes,
  ...cashout,
  ...profile,
  ...homepage,
  ...signup,
];
