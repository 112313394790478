import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const CarouselContainer = styled(Carousel)`
  padding: 0;
  .react-multi-carousel-item {
    padding: 10px;
  }
  .card {
    height: 100%;
    box-shadow: none;
    border: 1px solid #000;
    border-radius: 8px;
  }
  .card-body {
    min-height: 150px;
    padding: 16px;
    flex-grow: 1;
    color: #000;
  }
  .star-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      color: #f6ad16;
    }
  }
`;
