import { useMutation } from "react-query";

import useApi from "./useApi";

export const usePromoCode = () => {
  const { Private } = useApi();

  const { mutate, data, isSuccess, isLoading, isError } = useMutation(
    async (value) => {
      const { data: response } = await Private.get(`promocodes/${value}`);
      return response;
    },
    { mutationKey: "promoCode" }
  );

  return {
    submitPromoCode: mutate,
    data,
    isSuccess,
    isLoading,
    isError,
  };
};
