export const DRAFT = "draft";
export const PENDING_SUBMISSION = "pending_submission";
export const PENDING_VERIFICATION = "pending_verification";
export const APPROVED = "approved";
export const REJECTED = "rejected";

export const KYC_STATUSES = {
  [DRAFT]: {
    DRAFT,
    alertVariant: "alert-warning",
  },
  [PENDING_VERIFICATION]: {
    PENDING_VERIFICATION,
    alertVariant: "alert-warning",
  },
  [PENDING_SUBMISSION]: {
    PENDING_SUBMISSION,
    alertVariant: "alert-warning",
  },
  [APPROVED]: {
    APPROVED,
    alertVariant: "alert-success",
  },
  [REJECTED]: {
    REJECTED,
    alertVariant: "alert-danger",
  },
};
