import SignupPage from "views/public/signup";
import OnboardingSteps from "views/public/signup/onboarding-steps";
import RegistrationFinish from "views/public/signup/onboarding-steps/components/registration-finish";
import VerifyEmail from "views/public/signup/verify-email";
import VerifyRegistration from "views/public/signup/verify-email/verify-registration";

export const signup = [
  {
    path: "/signup",
    exact: true,
    component: SignupPage,
  },
  {
    path: "/verify-email",
    exact: true,
    component: VerifyEmail,
  },
  {
    path: "/verify-email/:id",
    exact: true,
    component: VerifyRegistration,
  },
  {
    path: "/signup/steps/:step",
    exact: true,
    component: OnboardingSteps,
  },
  {
    path: "/signup/complete",
    exact: true,
    component: RegistrationFinish,
  },
];
