import styled from "styled-components";

const Title = styled.p`
  margin: 0 0 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
`;

const Ol = styled.ol`
  padding: 0 0 0 25px;
  margin-top: 10px;
  position: relative;
  list-style-type: disc;
  li {
    text-align: justify;
    &:before {
      font-weight: bold;
    }
  }
`;

const Li = styled.li`
  font-size: 15px;
  margin-bottom: 10px;
`;

const TnCContent = () => (
  <div>
    <Ol>
      <Li>
        This referral promotion is limited to the first 100 successful referrals and 100 referees.
      </Li>
      <Li>
        You will receive the following cash reward based on the number of successful referrals you
        qualify for: (i) $10 cash reward for 1 successful referral
      </Li>
      <Li>
        For you to qualify for the referral incentive: you have to ensure that (i) you are an
        existing LytePay user and your bank statement has been uploaded before the campaign end date
      </Li>
      <Li>
        For your friend to qualify as a successful referee: they have to sign up for a LytePay
        account, complete their account verification, and invoice and Collect through LytePay.
      </Li>
      <Li>
        The cash reward for you will be credited into your linked bank account as per the bank
        statement uploaded in LytePay’s records every 30th of the month.
      </Li>
      <Li>
        Failure to fulfill any one of the above terms will result in your and your teammate’s
        ineligibility to receive the cash reward.
      </Li>
    </Ol>
  </div>
);

export default TnCContent;
