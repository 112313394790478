import { useQuery } from "react-query";

import useApi from "hooks/useApi";

export const useGetOnboardingKey = (options) => {
  const { Private } = useApi();

  return useQuery(
    ["onboarding-key"],
    async () => {
      const { data } = await Private.get("public/user/onboarding/key");
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
