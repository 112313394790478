import styled from "styled-components";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { formatCurrency } from "utils/commonHelpers";
import TableBase from "ui-lib/components/table-base";

import InvoiceTotalCard from "../invoice/InvoiceDetailTable/InvoiceTotalCard";

export const DottedBox = styled.div`
  border: 2px dashed grey;
  padding: 0px;
  height: 100px;
  border-radius: 5px;
  background-color: #f2f3f7;
  width: 270px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  padding: 0px;
  height: 100px;
  width: 270px;
  overflow: hidden;
  display: flex;
  justify-content: end;
`;

export const Logo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

/**
   - InvoiceTemplate
  - InvoiceTemplate.Logo
  - InvoiceTemplate.Header
  - InvoiceTemplate.Subheader
  - InvoiceTemplate.Table
  */

//  ===============================================================

const InvoiceTemplate = ({ children }) => (
  <div className="py-5 mt-3 mb-5" style={{ padding: "0 1.25rem" }}>
    {children}
  </div>
);

//  ===============================================================

InvoiceTemplate.Logo = ({ logo }) =>
  logo?.size > 0 && (
    <LogoWrapper>
      <Logo src={(logo && URL.createObjectURL(logo)) || "https://ui-avatars.com/api/?size=80"} />
    </LogoWrapper>
  );

InvoiceTemplate.Logo.defaultProps = {
  invoiceReference: "Z66OGGJQG",
};

//  ===============================================================

InvoiceTemplate.Header = ({
  invoiceNumber,
  issuedDateTime,
  projectTitle,
  logo,
  statusChangeReason,
  statusChangeOtherReason,
  disableLogoHint,
}) => (
  <header className="grid">
    {/* Left */}
    <div className="g-col-6">
      <ul className="list-unstyled">
        <li className="mb-3">
          <strong>INVOICE</strong>
        </li>
        <li data-test="invoiceNumber">
          Invoice Number: <strong>{invoiceNumber}</strong>
        </li>
        <li data-test="dateIssued">
          Date Issued: <strong>{moment(issuedDateTime).format("DD MMM YYYY")}</strong>
        </li>
        {projectTitle && (
          <li data-test="projectTitle">
            Project Title: <strong>{projectTitle}</strong>
          </li>
        )}
        {statusChangeReason && (
          <li>
            Status Reason: <strong>{statusChangeOtherReason || statusChangeReason}</strong>
          </li>
        )}
      </ul>
    </div>
    {/* Right */}
    <div className="g-col-6 d-flex justify-content-end">
      {logo ? <InvoiceTemplate.Logo logo={logo} /> : !disableLogoHint && null}
    </div>
  </header>
);

//  ===============================================================

InvoiceTemplate.Subheader = ({
  invoiceClient,
  addressLine1,
  addressLine2,
  fullName,
  countryCode,
  postalCode,
  mobileCountryCode,
  mobileNumber,
  email,
}) => (
  <div className="row">
    {/* Left */}
    <div className="col-md-6">
      <p className="text-big mb-2">Invoice To</p>
      <ul className="list-unstyled">
        <li className="mb-1" data-test="invoiceClientName">
          <strong>{invoiceClient?.name || ""}</strong>
        </li>
        <li data-test="invoiceClientBusinessName">{invoiceClient?.businessName || ""}</li>
        <li data-test="invoiceClientAddressLine1">{invoiceClient?.addressLine1 || ""}</li>
        <li data-test="invoiceClientAddressLine2">
          {invoiceClient?.addressLine2 || ""}
          {invoiceClient?.addressLine2 && invoiceClient?.country && ", "}
          {invoiceClient?.country || ""} {invoiceClient?.postalCode || ""}
        </li>
        <li className="mt-2" data-test="invoiceClientMobileNumber">
          {invoiceClient?.mobileCountryCode ? `+${invoiceClient?.mobileCountryCode}` : ""}{" "}
          {invoiceClient?.mobileNumber || ""}
        </li>
        <li data-test="invoiceClientEmail">{invoiceClient?.email || ""}</li>
      </ul>
    </div>
    {/* Right */}
    <div className="col-md-6 d-flex flex-column justify-content-end text-md-right">
      <p className="text-big mb-2">Invoice From</p>
      <ul className="list-unstyled">
        <li className="mb-1" data-test="fullName">
          <strong>{fullName || ""}</strong>
        </li>
        <li data-test="addressLine1">{addressLine1 || ""}</li>
        <li data-test="addressLine2">
          {addressLine2 || ""}
          {addressLine2 && countryCode && ", "}
          {countryCode || ""}
        </li>
        <li data-test="country">
          {countryCode || ""} {postalCode || ""}
        </li>

        <li className="mt-2" data-test="mobile">
          {mobileCountryCode && mobileCountryCode === "SG" ? "+65" : `+${mobileCountryCode}`}{" "}
          {mobileNumber || ""}
        </li>
        <li data-test="email">{email || ""}</li>
      </ul>
    </div>
  </div>
);

//  ===============================================================

export const invoiceTableColumns = (invoice) => [
  {
    name: "Item",
    selector: (row) => row.itemName,
    width: "20%",
    maxWidth: "250px",
    wrap: true,
  },
  {
    name: "Description",
    width: "40%",
    selector: (row) => row.description,
    minWidth: "200px",
    wrap: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
    maxWidth: "90px",
    right: true,
  },
  {
    name: "Price",
    selector: (row) => row.rate,
    right: true,
    wrap: true,
    cell: (row) => <div>{`${invoice?.currency || ""} ${formatCurrency(row.rate, false)}`}</div>,
  },
  {
    name: "Amount",
    selector: (row) => row.total,
    right: true,
    wrap: true,
    cell: (row) => <div>{`${invoice?.currency || ""} ${formatCurrency(row.total, false)}`}</div>,
  },
];

export const invoiceTableCustomStyles = {
  header: {
    style: {
      display: "none",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#122ECD",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
    },
  },
  headCells: {
    style: {
      padding: "0 15px",
      fontSize: "14px",
      fontWeight: "400",
      color: "white",
    },
    activeSortStyle: {
      color: "white",
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: "white",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "white",
      },
      "&:hover": {
        color: "white",
      },
    },
  },
  rows: {
    style: {
      "&:not(:last-of-type)": {
        border: "0",
      },
    },
  },
  cells: {
    style: {
      "&:not(:first-of-type)": {
        borderLeft: "2px solid #f8f8f8",
      },
      padding: "8px 10px",
      fontSize: "14px",
      backgroundColor: "#ffffff",
      verticalAlign: "top",
      minWidth: "0",
    },
  },
};

InvoiceTemplate.Table = ({ invoice }) => (
  <>
    <TableBase
      className="mt-5"
      columns={invoiceTableColumns(invoice)}
      data={invoice.invoiceItems}
      responsive
    />
    <div className="row d-flex justify-content-end">
      <div className="my-3 col-sm-4">
        <InvoiceTotalCard values={invoice} readOnly />
      </div>
    </div>
  </>
);

//  ===============================================================

InvoiceTemplate.Footer = ({ issuedDateTime, paymentDue, children }) => (
  <div className="form-bottom-box mt-3">
    <p className="mb-1">
      <strong>Payment Information and Terms</strong>
    </p>
    <p>
      {" "}
      Payment due on{" "}
      <strong>
        {issuedDateTime
          ? moment(issuedDateTime).add(paymentDue, "days").format("DD MMM YYYY")
          : null}
      </strong>
      .
    </p>
    {children}
  </div>
);

InvoiceTemplate.Notes = ({ notes }) => (
  <div className="col-lg-6">
    <p className="mb-1">
      <strong>Notes:</strong>
    </p>
    <p>{notes}</p>
  </div>
);

export default InvoiceTemplate;
