import PropTypes from "prop-types";

import Loading from "../loading";

import { TitleContainer, SubTitleContainer, BodyContainer } from "./styles";

const Widget = ({ title, subtitle, body, containerClassName, loading }) => (
  <div className={containerClassName}>
    {title && <TitleContainer>{title}</TitleContainer>}

    {subtitle && <SubTitleContainer className="text-secondary">{subtitle}</SubTitleContainer>}

    <BodyContainer className="p-3 rounded">{loading ? <Loading /> : body}</BodyContainer>
  </div>
);

Widget.defaultProps = {
  loading: false,
  containerClassName: "",
  title: "",
  subtitle: "",
  body: null,
};

Widget.propTypes = {
  loading: PropTypes.bool,
  containerClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  subtitle: PropTypes.string,
  body: PropTypes.node,
};

export default Widget;
