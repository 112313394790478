import Cashout from "views/private/Cashout";
import CashoutSummary from "views/private/CashoutSummary";

export const cashout = [
  {
    path: "/cashoutsummary",
    exact: true,
    component: CashoutSummary,
  },
  { path: "/cashout/:invoiceReference", component: Cashout, exact: true },
];
