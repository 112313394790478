import { useMutation } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useSubmitManualVerification = (options) => {
  const { Private } = useApi();
  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "/users/kyc/manual/submit",
        data: values,
      });
      toast.promise(promise, {
        loading: "Submitting KYC",
        success: `KyC successfully submitted`,
        error: `Error submitting KYC`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};
