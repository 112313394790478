import { useFormikContext } from "formik";
import styled from "styled-components";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { Image, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import useApi from "hooks/useApi";
import { LyteButton } from "components/common/buttons";
import { useBankVerification } from "hooks/bank-verification";

const StyledUploadProfile = styled.div`
  font-size: 16px;
  color: #3363ff;
  font-weight: 500;
`;

StyledUploadProfile.Dropzone = styled.div`
  width: 100%;
  height: 250px;
  border: 2px dashed #4e4f58;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f1f4fd;

  .progress {
    width: 100%;
  }
`;

StyledUploadProfile.Image = styled(Image)`
  height: 100%;
  max-height: 200px;
`;

const BankVerificationUpload = ({ setStepUpdateBank }) => {
  const { values } = useFormikContext();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileImage, setUploadedFileImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateBankVerification } = useBankVerification();

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileSizeLimit = 30 * 1024 * 1024; // 20MB
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (file?.size > fileSizeLimit) {
      setErrorMessage("File size exceeds the limit (30MB).");
      return;
    }

    if (!acceptedFileTypes.includes(file?.type)) {
      setErrorMessage("Invalid file type. Only PNG, JPG, and JPEG are allowed.");
      return;
    }

    setErrorMessage("");
    setUploadedFile(file);
    setUploadedFileImage(URL.createObjectURL(file));
    setUploadProgress(0);
  };

  const handleSaveDetails = (skip) => {
    const formData = new FormData();
    if (skip) {
      formData.append("BankStatement", uploadedFile);
    }
    Object.keys(values).forEach((value) => {
      formData.append(value, values[value]);
    });

    mutateBankVerification(formData, {
      onSuccess: () => {
        setStepUpdateBank(4);
      },
    });
  };

  return (
    <>
      <h2 className="font-weight-bold">Bank Verification</h2>
      <p className="mt-2">
        As a Financial Institution, we need to verify that your bank account belongs to you. Please
        upload a recent bank statement (or at least the header) showing your:
        <ul className="font-weight-bold">
          <li>Full Name</li>
          <li>Bank Name</li>
          <li>Bank Account Number</li>
          <li>Date of Statement</li>
        </ul>
      </p>
      <p>
        Note: <i>Bank Balance is not required</i>
      </p>
      <div className="bg-white px-5 py-4">
        <StyledUploadProfile>
          <h3 className="text-center mb-5">Upload your Bank Statement</h3>
          <Dropzone
            accept={{
              "image/jpeg": [".jpeg", ".png"],
            }}
            onDrop={handleDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <StyledUploadProfile.Dropzone {...getRootProps()}>
                <input {...getInputProps()} />
                {uploadedFileImage ? (
                  <div className="d-flex align-items-center flex-column justify-content-center w-100 p-3">
                    <StyledUploadProfile.Image
                      src={uploadedFileImage}
                      alt="Uploaded"
                      className="img-fluid pb-2"
                    />
                    {uploadProgress > 0 && (
                      <ProgressBar
                        animated={uploadProgress !== 100}
                        variant={uploadProgress === 100 ? "success" : "info"}
                        now={uploadProgress}
                        label={`${uploadProgress}%`}
                        min={0}
                        max={100}
                      />
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center text-center p-3">
                    <FontAwesomeIcon
                      icon={faCloudUploadAlt}
                      style={{ width: "80px", height: "80px" }}
                    />
                    <p className="pt-2">Drag and drop a file here or click to select a file</p>
                  </div>
                )}
              </StyledUploadProfile.Dropzone>
            )}
          </Dropzone>
          {errorMessage && (
            <p className="mt-2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          )}
        </StyledUploadProfile>
        <LyteButton className="mx-auto mt-4" size="sm" onClick={() => handleSaveDetails(true)}>
          Submit
        </LyteButton>
      </div>
    </>
  );
};

export default BankVerificationUpload;
