import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "./useApi";

export const useUploadFile = (options) => {
  const { Private } = useApi();

  return useMutation(
    async (values) => {
      const promise = Private({
        method: "post",
        url: "files/upload",
        data: values,
      });
      toast.promise(promise, {
        loading: "Verifying data",
        success: `Success Uploading Supporting Document`,
        error: `Error Uploading Supporting Document`,
      });
      const { data } = await promise;
      return data;
    },
    { ...options }
  );
};

export const useGetFile = (pubId, options) => {
  const { Private } = useApi();

  return useQuery(
    pubId,
    async () => {
      const { data } = await Private.get(`files/pubId/${pubId}`, {
        responseType: "blob",
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
