import { useFormik, Field } from "formik";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useQueryClient } from "react-query";

import Card from "ui-lib/components/card/Card";
import { LyteBtn } from "ui-lib/components/buttons";
import Loading from "ui-lib/components/loading";
import { useGetUserRoles, usePostAddUserInfo } from "hooks/user";
import { useContents } from "hooks/contents";

import { FreelancerInfoCollectionSchema } from "./FreelancerInfoCollection.schema";
import { ToggleButtonGroup, ToggleButton, Modal } from "./styles";

const FreelancerInfoCollectionModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const queryClient = useQueryClient();
  const { data: config, isLoading: isContentsLoading } = useContents();
  const { data: userRoles, isLoading: isUserRolesLoading } = useGetUserRoles();
  const { mutateAsync: addUserInfo, isLoading } = usePostAddUserInfo({
    onSuccess: () => {
      queryClient.invalidateQueries("userRoles");
      queryClient.invalidateQueries("userData");
    },
  });

  const activeRoles = userRoles
    ?.filter((item) => item.active === true)
    .map((item) => item.active && item.roleId);

  const formik = useFormik({
    initialValues: {
      roles: [],
      freelancerStatus: "",
      invoicesSentPerMonth: "",
      referrerCode: "",
    },
    onSubmit: (values) => {
      values.referrerCode = values.referrerCode.toUpperCase();

      addUserInfo(values);
    },
    validationSchema: FreelancerInfoCollectionSchema,
    enableReinitialize: true,
  });

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header className="border-bottom-0 pb-0" />
      <Modal.Body>
        {isContentsLoading || isUserRolesLoading ? (
          <Loading />
        ) : (
          <Card>
            <Form>
              <div className="form-row">
                <div className="col">
                  <div className="form-group mb-3">
                    <label htmlFor="mobileCountryCode" className="mb-3 fw-600">
                      What do you do? Select all the roles that apply to you.
                    </label>
                    <ToggleButtonGroup
                      defaultValue={activeRoles}
                      type="checkbox"
                      onChange={(values) => {
                        formik.setFieldValue("roles", values);
                      }}
                    >
                      {userRoles?.map(({ role, roleId }) => (
                        <ToggleButton
                          className="mb-1 mr-1 mb-sm-2 mr-sm-2"
                          value={roleId}
                          key={`${role}-${roleId}`}
                        >
                          {role}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    {formik.touched && formik.errors.roles && (
                      <small className="text-danger">{formik.errors.roles}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="freelancerStatus" className="mb-3 fw-600">
                      Freelancer status
                    </label>
                    <div className="w-auto">
                      {config?.freelancerStatuses?.map(({ key, value }) => (
                        <div
                          className="custom-control custom-radio custom-control-inline"
                          key={key}
                        >
                          <Form.Check
                            name="freelancerStatus"
                            type="radio"
                            id={key}
                            value={key}
                            onChange={(e) => {
                              formik.setFieldValue("freelancerStatus", e.target.value);
                            }}
                            label={value}
                          />
                        </div>
                      ))}
                    </div>
                    {formik.touched.freelancerStatus && formik.errors.freelancerStatus && (
                      <small className="text-danger">{formik.errors.freelancerStatus}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  {" "}
                  <div className="form-group">
                    <label htmlFor="invoicesSentPerMonth" className="mb-3 fw-600">
                      How many invoices do you send every month?
                    </label>
                    <div className="w-auto">
                      {config?.invoicesSentPerMonth?.map(({ key, value }) => (
                        <div
                          className="custom-control custom-radio custom-control-inline"
                          key={key}
                        >
                          <Form.Check
                            id={key}
                            type="radio"
                            value={key}
                            name="invoicesSentPerMonth"
                            onChange={(e) => {
                              formik.setFieldValue("invoicesSentPerMonth", e.target.value);
                            }}
                            label={value}
                          />
                        </div>
                      ))}
                    </div>
                    {formik.touched.invoicesSentPerMonth && formik.errors.invoicesSentPerMonth && (
                      <small className="text-danger">{formik.errors.invoicesSentPerMonth}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="referralCode" className="mb-3 fw-600">
                      Referral / Promotion Code
                    </label>
                    <span className="text-muted font-italic"> (Optional)</span>
                    <div className="w-auto">
                      <input
                        id="referrerCode"
                        type="text"
                        className="form-control"
                        style={{ width: "50%", textTransform: "uppercase" }}
                        {...formik.getFieldProps("referrerCode")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="mb-0 mx-1">
                <small className="text-muted font-italic">
                  *This information helps us to serve you better.
                </small>
              </p>
            </Form>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex border-top-0 justify-content-between">
        <LyteBtn variant="link" loading={isLoading} type="button" onClick={handleClose}>
          Skip
        </LyteBtn>
        <LyteBtn loading={isLoading} className="ml-3" type="submit" onClick={formik.handleSubmit}>
          Submit
        </LyteBtn>
      </Modal.Footer>
    </Modal>
  );
};

FreelancerInfoCollectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default FreelancerInfoCollectionModal;
