import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field } from "formik";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { StyledField, StyledIcon, StyledLabel } from "./styles";

const FormikDatePicker = ({ label, name, className, isRequired, description, ...restProps }) => {
  const handleCustomDatepickerComponent = (props, field) => (
    <div className="position-relative">
      <input
        id={name}
        data-cypress-id={`cypress-daypicker-${name}`}
        {...field}
        {...props}
        className={`form-control date-control ${props.className}`}
      />
      <StyledIcon>
        <FontAwesomeIcon icon={faCalendar} />
      </StyledIcon>
    </div>
  );

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <StyledField
          className={`field-container ${className} ${
            meta?.touched && meta?.error ? "form-invalid" : ""
          } ${meta?.value && meta?.touched && !meta?.error ? "form-success" : ""}`}
        >
          {label && (
            <StyledLabel htmlFor={name}>
              {label}
              {`${isRequired ? "*" : ""}`}
            </StyledLabel>
          )}
          <div className="position-relative">
            <DayPickerInput
              component={(props) => handleCustomDatepickerComponent(props, field)}
              {...restProps}
            />
          </div>
          {description && <p className="description-text">{description}</p>}
          {meta?.touched && (
            <ErrorMessage
              data-cypress-id={`cypress-daypicker-${name}-error-message`}
              name={name}
              component="div"
              className="error-text"
            />
          )}
        </StyledField>
      )}
    </Field>
  );
};

FormikDatePicker.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.node,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikDatePicker.defaultProps = {
  label: "",
  className: "",
  name: "",
  description: "",
  isRequired: false,
};

export default FormikDatePicker;
