import styled from "styled-components";

export const BodyContainer = styled.div`
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

export const SubTitleContainer = styled.div`
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;

  + ${SubTitleContainer} {
    margin-bottom: 1.25rem;
  }
`;
