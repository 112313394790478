const InvoiceFaq = [
  {
    header: "Does my client need to create a LytePay account?",
    description:
      "No, only freelancers need to create an account. We want to make you and your client’s process as seamless as possible.",
  },
  {
    header: "What will my client see when they receive an invoice?",
    description:
      "When you send your clients an invoice via LytePay, they will receive it via email.",
  },
  {
    header: "What payment methods are available for my clients?",
    description: "To make payments, your clients can choose PayNow or bank transfer under LytePay.",
  },
  {
    header: "Why do my clients have to pay to a third party?",
    description:
      "To automate your invoice tracking, LytePay must act as an intermediary collection partner between your clients and you. We use bank account collection to avoid hefty credit card fees.",
  },
  {
    header: "Can I charge international clients using LytePay?",
    description:
      "Currently, we are only able to collect payments in Singapore. We are working on future features that allow freelancers to invoice international clients so stay tuned!",
  },
  {
    header: "What are the fees for using LytePay’s Invoice tool?",
    description:
      "It is free to use LytePay’s invoice and payment collection tool! Ensure that your email address, mobile, and identity (via SingPass) are verified.",
  },
  {
    header: "Can I load money on to LytePay?",
    description:
      "As we are a non-bank financial institutions, freelancers are not able to load money on to LytePay. You are only able to Boost invoices that have been paid and collected through LytePay.",
  },
  {
    header: "Is LytePay licensed by MAS?",
    description: (
      <>
        <p>
          Farewell, paperwork. Hello, automation. LytePay Pte Ltd is a Major Payment Institution
          licensed by the Monetary Authority of Singapore (MAS) under the Payment Services Act to
          provide
        </p>
        <p>(i) account issuance service; </p>
        <p>(ii) domestic money transfer service; </p>
        <p>and (iii) cross-border money transfer service. </p>
        <p>
          We are bound by the rules and regulations of the Republic of Singapore and Payment
          Services Act.
        </p>
      </>
    ),
  },
];

export default InvoiceFaq;
