import * as Sentry from "@sentry/react";
import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Public = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const Private = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  Private.interceptors.request.use(
    useCallback(
      async (config) => {
        config.headers.common.Authorization = `Bearer ${await getAccessTokenSilently()}` || null;
        return config;
      },
      [getAccessTokenSilently]
    )
  );

  Public.interceptors.request.use((config) => {
    if (config.url.includes("lyte-assistant")) {
      config.headers.common.Authorization = process.env.REACT_APP_PUBLIC_PROFILE_TOKEN || null;
    }
    return config;
  });

  Private.interceptors.response.use(
    (response) => {
      if (response?.data?.success === false) {
        Sentry.captureException(response.data);

        // so we will just reject the promise
        return Promise.reject(response);
      }
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  return { Private, Public };
};

export default useApi;
