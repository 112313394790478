import { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import LyteVerifyEmailImage from "images/illust/illust-verify-email.png";

import Header from "../components/header";
import Button from "../components/Button";
import CustomLink from "../components/Link";

import { VerifyEmailContainer } from "./styles";
import { useResendEmail } from "./service";

const VerifyEmail = () => {
  const id = localStorage.getItem("signUpIdentifier");
  const { push, replace } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = decodeURIComponent(queryParams.get("email"));

  const { mutate: mutateResendEmail, isLoading: isLoadingResendEmail } = useResendEmail();

  const handleResendEmail = () => {
    mutateResendEmail(
      {
        key: id,
      },
      {
        onSuccess: (response) => {
          localStorage.setItem("signUpIdentifier", response?.contextObjects[0]);
          push(`/verify-email`);
        },
      }
    );
  };

  useEffect(() => {
    if (!id) replace("/signup");
  }, []);

  return (
    <VerifyEmailContainer>
      <Header />
      <Container className="content-container">
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }} className="text-center">
            <Image src={LyteVerifyEmailImage} className="img-fluid mb-5" />
            <h3>Verify your account</h3>
            <p>
              A verification email has been sent to
              <b> {userEmail || "xxx@xxx.xx"}</b>. Please check your email to verify and continue
              with your sign up.
            </p>
            <Button
              className="mt-4 mx-auto"
              onClick={handleResendEmail}
              disabled={isLoadingResendEmail}
            >
              Didn’t receive anything? Resend email
            </Button>
            <div className="w-100 text-center mt-3">
              Or, <CustomLink onClick={() => push("/signup")}>use another email address</CustomLink>
            </div>
          </Col>
        </Row>
      </Container>
    </VerifyEmailContainer>
  );
};

export default VerifyEmail;
