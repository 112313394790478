import { useMutation } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useRequestOtp = (options) => {
  const { Public } = useApi();
  return useMutation(async (values) => {
    const { data } = await Public.post(`public/user/otp/request`, values);
    return data;
  }, options);
};

export const useValidateOtp = (options) => {
  const { Public } = useApi();

  return useMutation(async (values) => {
    const promise = Public.post(`public/user/otp/validate`, values);
    toast.promise(promise, {
      success: "Mobile phone verification success",
      loading: "Checking your OTP",
      error: ({ response, message }) =>
        `Error verifying your phone number: ${response?.data?.messages?.[0] || message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};
