import { useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { useLockPeriods } from "hooks/contents";
import lytePayLogo from "images/svg/lytepay-logo.svg";
import { formatCurrency } from "utils/commonHelpers";

const CashoutInfo = ({ cashoutAmount, remainingAmount }) => (
  <div>
    <Col className="d-flex justify-content-between border-bottom my-2 p-0">
      <h4>Cashout amount</h4>
      <h4>{formatCurrency(cashoutAmount)}</h4>
    </Col>
    <Col className="d-flex justify-content-between my-2 p-0">
      <p>Remaining amount</p>
      <p>{formatCurrency(remainingAmount)}</p>
    </Col>
  </div>
);

const FinalChecks = ({ setFieldValue, values }) => {
  const LOCK_IN_PERIOD_REWARDS_APY_OPTIONS = useLockPeriods();
  return (
    <>
      <Form.Group controlId="isReboost">
        <Form.Check
          type="checkbox"
          label="I would like to reboost my remaining amount."
          checked={values.boostRemaining}
          onChange={(e) => {
            setFieldValue("boostRemaining", e.currentTarget.checked);
            if (e.currentTarget.checked) {
              setFieldValue("autoReboost", e.currentTarget.checked);
            }
            if (!e.currentTarget.checked) {
              setFieldValue("autoReboost", e.currentTarget.checked);
            }
          }}
        />
      </Form.Group>

      <Form.Group as={Row} controlId="duration">
        <Col sm={6} className="d-flex align-items-center fw-500">
          <Form.Label className="m-0 p-0" style={{ fontSize: "0.85rem" }}>
            Duration / Annual Rewards
          </Form.Label>
        </Col>
        <Col sm={6} className="ps-0">
          <Form.Control
            as="select"
            size="sm"
            disabled={!values.boostRemaining}
            onChange={(e) => {
              const [duration, returns] = e.currentTarget.value.split("/");
              // Beware 'return' is a reserved keyword, so I use 'returns'
              setFieldValue("boostDuration", +duration);
              setFieldValue("boostReturn", +returns);
            }}
          >
            {LOCK_IN_PERIOD_REWARDS_APY_OPTIONS.map(
              ({ value: { lockInPeriod, rewardAPY }, label }) => (
                <option value={`${lockInPeriod}/${rewardAPY}`}>{label}</option>
              )
            )}
          </Form.Control>
        </Col>
      </Form.Group>
      {values.boostDuration !== 365 && (
        <Form.Group controlId="isAcknowledge">
          <Form.Check
            type="checkbox"
            disabled={!values.boostRemaining}
            checked={values.autoReboost}
            onChange={(e) => setFieldValue("autoReboost", e.currentTarget.checked)}
            label="I would to automatically reboost upon maturity. I understand that
        any early withdrawal will disable this setting, and I will need to
              reauthorize this in 6 months."
          />
        </Form.Group>
      )}
    </>
  );
};

const CancelOrConfirm = ({
  setShow,
  submitForm,
  isSubmitting,
  isValid,
  values,
  showReboostOptions,
}) => (
  <div className="d-flex justify-content-center">
    <Button
      variant="link"
      className="text-danger py-3 px-4 w-50"
      onClick={() => setShow(false)}
      disabled={isSubmitting}
    >
      Cancel
    </Button>
    <Button
      className="py-3 px-4 w-50"
      onClick={submitForm}
      disabled={isSubmitting || !isValid || (showReboostOptions && !values.boostRemaining)}
    >
      Confirm
    </Button>
  </div>
);

const CashoutModal = ({ disabled, remainingAmount, showReboostOptions }) => {
  const [show, setShow] = useState(false);
  const { submitForm, setFieldValue, values, isSubmitting, isValid } = useFormikContext();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!showReboostOptions) {
      setFieldValue("boostRemaining", false);
      setFieldValue("autoReboost", false);
    }
  }, [showReboostOptions, setFieldValue]);

  return (
    <>
      <Button
        className="w-100 py-3 my-3"
        onClick={handleShow}
        style={{ borderRadius: "10px" }}
        disabled={disabled}
      >
        CASHOUT
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        style={{ borderRadius: "1.3rem" }}
      >
        <Modal.Body
          className="p-5"
          style={{
            display: "grid",
            gridTemplateRows: "100px 120px auto",
          }}
        >
          <Image src={lytePayLogo} alt="" style={{ width: "150px" }} />
          <CashoutInfo cashoutAmount={values.cashoutAmount} remainingAmount={remainingAmount} />
          {showReboostOptions && <FinalChecks values={values} setFieldValue={setFieldValue} />}
          <CancelOrConfirm
            setShow={setShow}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            isValid={isValid}
            values={values}
            showReboostOptions={showReboostOptions}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CashoutModal;
