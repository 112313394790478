import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import GlobalStyles from "GlobalStyles";
import ProtectedRoute, { Loader } from "auth/ProtectedRoute";
import Navi from "components/common/Navi";
import TopNavi from "components/common/TopNavi";
import Dashboard from "views/private/Dashboard";
import Fallback from "views/public/errors/Fallback";
import { privateRoutes } from "routes/privateRoutes";
import { publicRoutes } from "routes/publicRoutes";
import PwaBanner from "components/pwa-banner";
import { darkColorTheme, lightColorTheme } from "utils/themeColours";
import { GlobalProvider } from "providers/GlobalProvider";
import FloatingHelp from "components/common/floating-help";
import { TourGuideProvider } from "providers/TourGuideProvider";
import { UserDataProvider } from "providers/UserDataProvider";

import { quickpay } from "./routes/quickpay";

const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
`;

const Main = styled.main`
  grid-column: 2 / -1;
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;

  @media only screen and (max-width: 769px) {
    grid-column: 1 / -1;
  }
`;

const SentryRoute = Sentry.withSentryRouting(Route);
const SentryProtectedRoute = Sentry.withSentryRouting(ProtectedRoute);

const App = () => {
  const { user } = useAuth0();
  const [selectedTheme, setSelectedTheme] = useState(
    localStorage.getItem("theme") === "dark" ? "dark" : "light"
  );

  useEffect(() => {
    Sentry.setUser({
      email: user?.email,
    });
    if (window && window.setChatToken) window.setChatToken(user?.sub || "", user?.name || "");
  }, [user]);

  const lightTheme = {
    breakpoints: {
      xs: "0px",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1400px",
    },
    color: {
      ...lightColorTheme,
    },
  };

  const darkTheme = {
    breakpoints: {
      xs: "0px",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1400px",
    },
    color: {
      ...darkColorTheme,
    },
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setSelectedTheme(currentTheme);
    }
  }, []);

  const themes = selectedTheme === "dark" ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={themes}>
      <GlobalProvider>
        <GlobalStyles />
        <Switch>
          {publicRoutes.map(({ exact, path, component: Component }) => (
            <SentryRoute key={path} exact={exact} path={path} component={Component} />
          ))}
          <SentryRoute path="*">
            <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />}>
              <TourGuideProvider>
                <UserDataProvider>
                  <AppContainer>
                    <Navi setSelectedTheme={setSelectedTheme} />
                    <Main>
                      <TopNavi />
                      <PwaBanner />
                      <FloatingHelp />
                      <Switch>
                        {privateRoutes.map(({ exact, path, component }) => (
                          <SentryRoute
                            key={path}
                            exact={exact}
                            path={path}
                            component={withAuthenticationRequired(component, {
                              onRedirecting: () => <Loader />,
                            })}
                          />
                        ))}
                        {quickpay.map(({ path, component }) => (
                          <SentryProtectedRoute
                            exact
                            key={path}
                            path={path}
                            component={component}
                          />
                        ))}
                        <SentryProtectedRoute exact path="/" component={Dashboard} />
                        <SentryRoute path="*">
                          <Redirect to="/" />
                        </SentryRoute>
                      </Switch>
                    </Main>
                  </AppContainer>
                </UserDataProvider>
              </TourGuideProvider>
            </Sentry.ErrorBoundary>
          </SentryRoute>
        </Switch>
      </GlobalProvider>
    </ThemeProvider>
  );
};

export default App;
