import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom/";
import { Formik } from "formik";
import { useQueryClient } from "react-query";

import Loader from "components/common/Loader";
import { useUserData } from "hooks/useUserData";
import { LyteButton } from "components/common/buttons";
import Error401 from "views/public/errors/Error401";

import BankDetailsForm from "./bankDetailsForm";
import { BankDetailSchema } from "./schema";
import BankVerificationUpload from "./bankVerificationUpload";

const BankDetails = () => {
  const queryClient = useQueryClient();
  const { push, replace } = useHistory();
  const [stepUpdateBank, setStepUpdateBank] = useState(1);
  const { data: user } = useUserData();

  if (!user) {
    return <Loader />;
  }

  if (!user.fromOnboarding) {
    return <Error401 />;
  }

  if (user.bankAccount) {
    return <Redirect to="/bank-account" />;
  }

  const initialValues = {
    CountryCode: "SG",
    BankId: "",
    Currency: "SGD",
    AccountHolderName: "",
    AccountNumber: "",
  };

  const handleStepView = () => {
    switch (stepUpdateBank) {
      case 2:
        return <BankDetailsForm setStepUpdateBank={setStepUpdateBank} />;
      case 3:
        return <BankVerificationUpload setStepUpdateBank={setStepUpdateBank} />;
      case 4:
        return (
          <>
            <h2 className="font-weight-bold">Bank Verification</h2>
            <p className="mt-2">Thank you for uploading your bank statement.</p>
            <p>
              Your uploaded bank statement will be reviewed and verified within
              <b> 1-2 business days</b>. Once approved, you are able to receive payments from your
              clients on your invoices!
            </p>
            <div className="mt-3">
              <LyteButton
                className="mx-auto my-3"
                onClick={() => {
                  queryClient.invalidateQueries("userData");
                  replace("/");
                }}
              >
                Return to home
              </LyteButton>
            </div>
          </>
        );
      default:
        return (
          <>
            <h2 className="font-weight-bold">Bank Details</h2>
            <p className="mt-2">
              You will need at least one bank account verified to accept a payment. Please add a
              bank account here.
            </p>
            <p>*Only verified bank account can be used to receive payment. </p>
            <div>
              <LyteButton className="mx-auto my-3" onClick={() => setStepUpdateBank(2)}>
                + New Bank Account
              </LyteButton>
            </div>
          </>
        );
    }
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={BankDetailSchema}
            enableReinitialize
            validateOnMount
          >
            {handleStepView()}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default BankDetails;
