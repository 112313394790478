import clsx from "clsx";
import PropTypes from "prop-types";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import currency from "currency.js";

import { formatCurrency } from "utils/commonHelpers";
import { useUserData } from "hooks/useUserData";
import Loading from "ui-lib/components/loading";

import CashoutModal from "./CashoutModal";

const Breakdown = ({
  boostBalance,
  values,
  setFieldValue,
  promoCodeData,
  isValid,
  submitForm,
  maxAllowedCashout,
}) => {
  const { data: user } = useUserData();

  // const boostBalance = boostBalance
  const { cashoutAmount, cashoutFee: processingFee } = values;

  const discountInDollars = Math.min(
    promoCodeData?.type === "percentage"
      ? promoCodeData?.value * processingFee
      : promoCodeData?.type === "amount"
      ? promoCodeData?.value
      : 0,
    promoCodeData?.maxValue || Infinity
  );

  const totalDeducted = currency(cashoutAmount).add(processingFee).value;

  const remainingAmount = currency(boostBalance)
    .subtract(cashoutAmount)
    .subtract(processingFee).value;

  const canCashout = totalDeducted > 0 && isValid && user?.hasApprovedBankAccount;

  const showReboostOptions = cashoutAmount !== maxAllowedCashout;

  const totalTransferred = currency(cashoutAmount).add(discountInDollars).value;

  const processingFeeToShow = cashoutAmount <= 0 ? 0 : processingFee;
  const totalDeductedToShow = cashoutAmount <= 0 ? 0 : totalDeducted;

  const breakdownItems = [
    {
      key: "cashoutAmount",
      label: "Cashout Amount",
      displayValue: cashoutAmount || 0,
    },
    {
      key: "processingFee",
      label: "Processing Fee*",
      displayValue: processingFeeToShow,
    },
    {
      key: "discount",
      label: "Discount",
      displayValue: discountInDollars,
    },
  ];

  return !user ? (
    <Loading />
  ) : (
    <>
      <p className="fw-600 text-uppercase my-2">Cashout Breakdown</p>
      <Card>
        <Card.Body>
          <ListGroup>
            {breakdownItems.map(({ key, label, displayValue }) => (
              <ListGroup.Item key={key} className="p-2 border-0">
                <Row>
                  <Col xs={6}>{label}</Col>
                  <Col
                    xs={6}
                    className={clsx({
                      "text-right": true,
                      "text-success": key === "discount",
                      "fw-600": key === "discount",
                    })}
                  >
                    {key === "discount" && "+"} {formatCurrency(displayValue)}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
            <ListGroup.Item
              className="p-2 my-2 fw-600"
              style={{
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                borderLeft: 0,
                borderRight: 0,
                borderRadius: 0,
              }}
            >
              <Row>
                <Col xs={6}>Total deducted</Col>
                <Col xs={6} className="text-right">
                  {formatCurrency(totalDeductedToShow)}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className="p-2 border-0 fw-800">
              <Row>
                <Col xs={6}>Total transferred</Col>
                <Col xs={6} className="text-right">
                  {formatCurrency(totalTransferred)}
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          <div>
            <CashoutModal
              disabled={!canCashout}
              invoiceId={0}
              cashoutAmount={cashoutAmount}
              cashoutFee={processingFee}
              remainingAmount={remainingAmount}
              values={values}
              setFieldValue={setFieldValue}
              submitForm={submitForm}
              showReboostOptions={showReboostOptions}
            />
            <p className="text-muted small text-center">
              {!user.hasApprovedBankAccount && (
                <p className="text-danger">You need an approved bank account to cashout.</p>
              )}
              Please review all details above before clicking to cashout. By cashing out, you agree
              to our Terms & Conditions
            </p>
            <small>
              *Each withdrawal from the Boost Rewards program has a 0.25% service fee, subject to a
              minimum of SGD $0.50.
            </small>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Breakdown;

Breakdown.defaultProps = {
  cashoutAmount: 4789.25,
};

Breakdown.propTypes = { cashoutAmount: PropTypes.number };
