/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";

export const quickPaySchema = Yup.object({
  clientName: Yup.string()
    .required("This is required")
    .min(2, "The characters minimum length is ${min}")
    .matches(/^[A-Za-z\s]+$/, {
      message: "Only alphabetic values are allowed",
    }),
  businessName: Yup.string().max(100, "The characters maximum length is ${max}"),
  email: Yup.string().email("Invalid email address format").required("This is required"),
  mobileCountryCode: Yup.string()
    .matches(/^[0-9]*$/, "Invalid mobile country code")
    .when(["mobileNumber"], {
      is: (mobileNumber) => mobileNumber,
      then: Yup.string().required("Mobile Country Code is required"),
    }),
  mobileNumber: Yup.string().matches(/^[0-9]*$/, "Invalid mobile country code"),
  addressLine1: Yup.string().max(100, "The characters maximum length is ${max}").ensure(),
  addressLine2: Yup.string().max(100, "The characters maximum length is ${max}").ensure(),
  country: Yup.string().default("Singapore"),
  postalCode: Yup.string().when("country", {
    is: "Singapore",
    then: Yup.string().matches(/^[0-9]{6}$/g, "Invalid postal code"),
  }),
  invoiceNumber: Yup.string()
    .required()
    .default("QUICKPAY")
    .max(30, "The characters maximum length is ${max}"),
  projectTitle: Yup.string().max(100, "The characters maximum length is ${max}"),
  issuedDateTime: Yup.string()
    .required()
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, "Invalid date format")
    .default(new Date().toISOString().split("T")[0]),
  paymentDueDays: Yup.number().default(0),
  currency: Yup.string().default("SGD"),
  lateFeeRate: Yup.string().oneOf(["0", "2", "3", "4", "5", "6", "7", "8"], "Invalid late fee"),
  invoiceItems: Yup.array()
    .of(
      Yup.object({
        itemName: Yup.string().required("This is required"),
        description: Yup.string(),
        quantity: Yup.number()
          .required("Item quantity is required")
          .min(0, "Minimum 0")
          .default(1)
          .typeError("Number only"),
        rate: Yup.number("Must be number")
          .required("Item price is required")
          .min(0.01, "Minimum $0.01")
          .max(9999999.99, "Rate must not exceed 9999999.99")
          .typeError("Number only"),
        total: Yup.number(),
      })
    )
    .min(1, "Please insert at least one item")
    .default([{ itemName: "", description: "", quantity: 1, rate: 0 }]),
  agreeAuthClause: Yup.boolean().default(true),
  agreeProhibitClause: Yup.boolean().default(true),
  agreeCollectionClause: Yup.boolean().default(true),
  discount: Yup.object().default({
    type: "amount",
    value: 0,
    description: "",
  }),
  notes: Yup.string(),
});
