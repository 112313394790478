import { Col, Container, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { useBoost } from "hooks/useBoost";
import TableBase from "ui-lib/components/table-base";
import { formatCurrency, formatNumber } from "utils/commonHelpers";
import Color from "utils/colours";

import CellBoostAmount from "./cells/CellBoostAmount";
import CellProjectName from "./cells/CellProjectName";
import CellRewardsAccumulated from "./cells/CellRewardsAccumulated";
import CellRewardsUnlocked from "./cells/CellRewardsUnlocked";
import CellStatus from "./cells/CellStatus";
import CellUpdateSettings from "./cells/CellUpdateSettings";
import { FILTER_OPTIONS } from "./constants";
import CellUpdateModal from "./cells/CellUpdateModal";

const PopoverStyled = styled(Popover)`
  width: 300px;
  max-width: none;
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;

  .arrow {
    display: none;
  }
`;

const columns = [
  {
    name: "Project",
    sortable: true,
    wrap: true,
    cell: CellProjectName,
  },
  {
    name: "Amount Boosted",
    selector: (row) => row.boostAmount,
    sortable: true,
    wrap: true,
    cell: CellBoostAmount,
  },
  {
    name: "Boost End Date",
    selector: (row) => row.boostEndDate,
    sortable: true,
    wrap: true,
    cell: CellRewardsAccumulated,
  },
  {
    name: "Rewards Earned",
    selector: (row) => row.unlockedRewards,
    sortable: true,
    wrap: true,
    cell: CellRewardsUnlocked,
  },
  {
    name: "Status",
    selector: (row) => row.invoiceNumber,
    sortable: true,
    cell: CellStatus,
    width: "120px",
    center: true,
  },
  {
    name: "Settings",
    selector: (row) => row.boostRefNumber,
    sortable: true,
    wrap: true,
    cell: CellUpdateSettings,
  },
];

const BoostSummary = () => {
  const { data } = useBoost();
  return (
    <Container className="py-5">
      <h3>
        <em>
          <strong>Boost </strong>
        </em>
        Summary
      </h3>
      <Row className="my-4 text-center">
        <Col xs={12} sm={6} md={3}>
          <p className="m-0 d-flex align-items-center gap-2">
            Boost Rewards Earned
            <OverlayTrigger
              placement="bottom"
              overlay={
                <PopoverStyled>
                  <p className="m-0">Total of rewards earned from a matured Boost cycle.</p>
                </PopoverStyled>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} color={Color.dark300} />
            </OverlayTrigger>
          </p>
          {data && (
            <h4 className="text-left font-weight-bolder">{formatCurrency(data.unlockedRewards)}</h4>
          )}
        </Col>
        <Col xs={12} sm={6} md={3}>
          <p className="m-0 d-flex align-items-center gap-2">
            Incoming Boost Rewards
            <OverlayTrigger
              placement="bottom"
              overlay={
                <PopoverStyled>
                  <p className="m-0">
                    All Boost rewards that are pending from ongoing Boosts (does not include boost
                    principal).
                  </p>
                </PopoverStyled>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} color={Color.dark300} />
            </OverlayTrigger>
          </p>
          {data && (
            <h4 className="text-left font-weight-bolder">{formatCurrency(data.lockedRewards)}</h4>
          )}
        </Col>
        <Col xs={12} sm={6} md={3}>
          <p className="m-0 d-flex align-items-center gap-2">
            Currently Boosting
            <OverlayTrigger
              placement="bottom"
              overlay={
                <PopoverStyled>
                  <p className="m-0">
                    Total of ongoing Boost principal amount and accumulated rewards from Boost.
                  </p>
                </PopoverStyled>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} color={Color.dark300} />
            </OverlayTrigger>
          </p>
          {data && (
            <h4 className="text-left font-weight-bolder">{formatCurrency(data.boostBalance)}</h4>
          )}
        </Col>
      </Row>
      <TableBase
        data={(data && data.boosts) || []}
        className="mt-4"
        columns={columns}
        pagination
        search={{
          searchColumns: ["projectTitle", "invoiceNumber"],
          placeholder: "Search for project name or invoice number...",
          filterOptions: FILTER_OPTIONS,
          filterColumns: ["status"],
        }}
      />
      <Route path="/boost/summary/:boostRefNumber/update" component={CellUpdateModal} />
    </Container>
  );
};

export default BoostSummary;
