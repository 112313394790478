import PropTypes from "prop-types";
import { Range as ReactRange, getTrackBackground } from "react-range";
import clsx from "clsx";

import Color from "utils/colours";

import { RangeContainer, Track, Thumb, TrackingText } from "./Range.styles";

const Range = ({
  type,
  disabled,
  value,
  step,
  min,
  max,
  onChange,
  className,
  renderTooltipText,
  onFinalChange,
  prefix,
  suffix,
}) => {
  const handleColorType = () => {
    const colorType = {
      track: Color.navi200,
      trackBg: Color.dark50,
      thumb: Color.navi600,
    };
    switch (type) {
      case "secondary":
        colorType.track = Color.integrity500;
        colorType.trackBg = Color.integrity200;
        colorType.thumb = Color.integrity700;
        break;
      default:
        colorType.track = Color.navi200;
        colorType.trackBg = Color.dark50;
        colorType.thumb = Color.navi600;
        break;
    }
    return colorType;
  };

  return (
    <RangeContainer className={clsx(disabled && "disabled", className)}>
      <ReactRange
        style={{ position: "relative" }}
        disabled={disabled}
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props: args, children }) => (
          <Track
            onMouseDown={args.onMouseDown}
            onTouchStart={args.onTouchStart}
            ref={args.ref}
            style={{
              background: getTrackBackground({
                values: [value],
                colors: [handleColorType().track, handleColorType().trackBg],
                min,
                max,
              }),
            }}
          >
            {children}
          </Track>
        )}
        renderThumb={({ props }) => (
          <Thumb thumbColor={handleColorType().thumb} {...props}>
            <TrackingText>
              {prefix}
              {renderTooltipText ? renderTooltipText(value) : value}
              {suffix}
            </TrackingText>
          </Thumb>
        )}
        onFinalChange={onFinalChange}
      />
    </RangeContainer>
  );
};

Range.defaultProps = {
  type: "primary",
  disabled: false,
  step: 1,
  min: 0,
  className: "",
  renderTooltipText: null,
  onFinalChange: null,
  prefix: "",
  suffix: "",
};

Range.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  renderTooltipText: PropTypes.func,
  onFinalChange: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default Range;
