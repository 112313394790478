import { Row } from "react-bootstrap";
import { down } from "styled-breakpoints";
import styled from "styled-components";

import Color from "utils/colours";

export const ItemRowContainer = styled.section`
  padding-top: 24px;
  border-top: 1px solid ${(props) => props.theme.color.dividerColor};
`;

export const ItemRows = styled(Row)`
  padding: 24px 0;
  border-bottom: 1px solid ${(props) => props.theme.color.dividerColor};
  margin-left: 0;
  margin-right: 0;

  [class|="col"] {
    padding-left: 0;
    padding-right: 24px;
    &:last-of-type {
      padding-right: 0;
    }
  }
`;

export const IconContainer = styled.div`
  color: ${Color.empathy500};
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

export const TotalContainer = styled.div`
  width: 292px;

  ${down("lg")} {
    width: 100%;
  }
`;

TotalContainer.Description = styled.small`
  color: ${(props) => props.theme.color.textSubtleContrast};
`;
