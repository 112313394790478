import { ErrorMessage, Field, Formik } from "formik";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import { useParams, Redirect, useHistory } from "react-router-dom";
import currency from "currency.js";
import clsx from "clsx";
import styled from "styled-components";
import * as Yup from "yup";

import { formatCurrency } from "utils/commonHelpers";
import Breakdown from "components/cashout/Breakdown";
import { BankAccountDetails } from "components/dashboard/bank-account-details";
import Loader from "components/common/Loader";
import { usePromoCode } from "hooks/usePromoCode";
import { useUserData } from "hooks/useUserData";
import { useGetCashoutInvoice, usePostCashout } from "hooks/cashout";

const NumberInput = styled.input`
  /* Disables the arrows on the right of the input field */
  /* for Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* for Firefox */
  -moz-appearance: textfield;

  background-color: transparent;
  font-size: 1.5rem;
  text-align: right;
  color: #000;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;

  ::before {
    content: "adfasd";
    height: 100%;
    background-color: red;
    width: 200px;
    font-weight: 400;
  }
`;

const Cashout = () => {
  const { data: promoCodeData } = usePromoCode();
  const { data: user } = useUserData();
  const { invoiceReference } = useParams();
  const { data: invoice } = useGetCashoutInvoice(invoiceReference);
  const { replace } = useHistory();
  const { mutate } = usePostCashout({
    onSuccess: () => replace("/cashoutsuccess"),
  });

  const boostBalance = invoice?.boostAmount || 0;
  const processingFeePercent = 0.0025;
  const minProcessingFee = Math.max(
    currency(boostBalance).multiply(processingFeePercent).value,
    0.5
  );
  const maxAllowedCashout = currency(boostBalance).subtract(minProcessingFee).value;

  const validationSchema = Yup.object({
    invoiceId: Yup.number().required().default(0),
    cashoutAmount: Yup.number()
      .required()
      .default(0)
      .min(0.1, "Min cashout amount $0.10")
      .max(maxAllowedCashout, "Amount exceeds maximum allowed cashout"),
    cashoutFee: Yup.number().required().default(0.5),
    // must be true, unless max cashout:
    boostRemaining: Yup.boolean().required().default(true),
    boostDuration: Yup.number().required().default(15),
    boostReturn: Yup.number().required().default(0.07),
    autoReboost: Yup.boolean().required().default(true),
  });

  if (!invoice) {
    return <Loader />;
  }

  if (invoice.boostStatus !== "available" && invoice.boostStatus !== "current") {
    return <Redirect to="/invoice/listing" />;
  }

  return (
    <section id="private-cashout">
      <Formik
        initialValues={validationSchema.cast({ invoiceId: invoice.id }, { stripUnknown: true })}
        onSubmit={(values, { setSubmitting }) =>
          mutate(values, { onSettled: () => setSubmitting(false) })
        }
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, submitForm }) => (
          <Container className="mx-auto p-5">
            <div className="text-center">
              <h5>{invoice.invoiceNumber}</h5>
              <h2 className="fw-700">Cashout</h2>
              <p className="text-muted">
                Make sure you have enough LyteMoney to withdraw the total amount.
              </p>
              <FormGroup className="mx-auto" style={{ width: "300px" }}>
                <p className="fw-600">How much would you like to withdraw?</p>
                <Field name="cashoutAmount">
                  {({ field, meta }) => (
                    <>
                      <NumberInput
                        {...field}
                        type="number"
                        className={clsx({
                          "form-control": true,
                          "is-invalid": meta.error,
                        })}
                        onChange={(e) => {
                          setFieldValue("cashoutAmount", e.currentTarget.value);
                          setFieldValue(
                            "cashoutFee",
                            Math.max(
                              0.5,
                              currency(+e.currentTarget.value).multiply(processingFeePercent).value
                            )
                          );
                        }}
                        placeholder="0.00"
                      />
                      <ErrorMessage name={field.name}>
                        {(msg) => <small className="text-danger">{msg}</small>}
                      </ErrorMessage>
                    </>
                  )}
                </Field>
                <div className="row justify-content-between text-muted">
                  <div className="text-muted p-2">Boost Balance</div>
                  <div className="text-muted p-2">{formatCurrency(boostBalance)}</div>
                </div>
                <div className="row justify-content-between  text-muted">
                  <div className="text-muted p-2">Max Cashout</div>
                  <div className="text-muted p-2">{formatCurrency(maxAllowedCashout)}</div>
                </div>
              </FormGroup>
            </div>
            <Row className="py-5">
              <Col xs={12} md={6}>
                {/* Hidden - not yet ready for production - 07 April 2022 */}
                {/* <Promocode
                  onSubmit={submitPromoCode}
                  isLoading={isLoading}
                  isError={isError || !promoCodeData}
                /> */}
                {user && (
                  <div className="py-3">
                    <BankAccountDetails user={user} />
                  </div>
                )}
              </Col>
              <Col xs={12} md={6}>
                <Breakdown
                  boostBalance={boostBalance}
                  isValid={isValid}
                  values={values}
                  setFieldValue={setFieldValue}
                  promoCodeData={promoCodeData}
                  submitForm={submitForm}
                  maxAllowedCashout={maxAllowedCashout}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Formik>
    </section>
  );
};

export default Cashout;
