import { useState } from "react";
import { Formik } from "formik";
import { Col, Container, FormGroup, Form, Row, Button } from "react-bootstrap";
import { useParams, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import { formatCurrency } from "utils/commonHelpers";
import Breakdown from "components/advance/Breakdown";
import { BankAccountDetails } from "components/dashboard/bank-account-details";
import Loader from "components/common/Loader";
import { useUserData } from "hooks/useUserData";
import { useGetAdvanceDetail, usePostAdvance } from "hooks/advance";
import Color from "utils/colours";
import { useContents } from "hooks/contents";

import { ADVANCE_CASHOUT_VALUES } from "./constant";

const AdvancePercentageContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 15px;

  .form-check {
    padding: 0;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked + .form-check-label {
      background-color: ${Color.navi700};
      color: ${Color.neutral0};
    }
  }
  .form-check-label {
    width: 80px;
    cursor: pointer;
    padding: 5px 15px;
    border: 1px solid ${Color.dark300};
    border-radius: 5px;
    background-color: ${Color.neutral0};
  }
`;

const Advance = () => {
  const { data: user } = useUserData();
  const { data: contents } = useContents();
  const { advanceReference } = useParams();
  const { data: advanceDetailData, isLoading: isLoadingAdvanceDetail } =
    useGetAdvanceDetail(advanceReference);
  const { replace } = useHistory();
  const [selectedPercentage, setSelectedPercentage] = useState(ADVANCE_CASHOUT_VALUES[0].value);
  const { mutate } = usePostAdvance({
    onSuccess: () => replace("/cashoutsuccess"),
  });

  const invoiceBalance = advanceDetailData?.eligibleAdvanceAmount || 0;
  const calculatedAmount = (+invoiceBalance * +selectedPercentage) / 100;
  const advanceFee = calculatedAmount * (advanceDetailData?.advanceFeeRate || 0);
  const minimumAdvanceFee = contents?.minimumValues?.minimumAdvanceFee || 1;

  if (isLoadingAdvanceDetail) {
    return <Loader />;
  }

  if (advanceDetailData.advanceStatus !== "available") {
    return <Redirect to="/" />;
  }

  return (
    <section id="private-cashout">
      <Formik
        initialValues={{
          advanceAmount: calculatedAmount,
          advanceFee: advanceFee < minimumAdvanceFee ? minimumAdvanceFee : advanceFee,
          advanceFeeAddOn: advanceDetailData?.advanceFeeAddOn || 0,
        }}
        onSubmit={(values) => mutate({ advanceReference })}
        validateOnMount
        enableReinitialize
      >
        {({ values }) => (
          <Container className="mx-auto p-5">
            <div className="text-center">
              <h5 className="mb-2">{advanceDetailData.advanceReferenceNumber}</h5>
              <h2 className="fw-700">Advance</h2>
              <FormGroup className="mx-auto">
                <p className="fw-600 d-flex align-items-center justify-content-center">
                  {`You are advancing ${formatCurrency(values.advanceAmount)} from Invoice `}
                  <Button
                    variant="btn btn-block btn-link text-left"
                    size="sm"
                    className="p-0 pl-1 color-blue-link cursor-pointer"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/invoice/${advanceDetailData.invoiceReference}`,
                        "_blank"
                      )
                    }
                    style={{
                      color: Color.navi300,
                      width: "max-content",
                      border: "none",
                      lineHeight: 1.2,
                      outline: "none",
                      boxShadow: "none",
                    }}
                  >
                    {advanceDetailData.invoiceNumber}
                  </Button>
                  .
                </p>
                {/* <AdvancePercentageContainer>
                  {ADVANCE_CASHOUT_VALUES.map((option) => (
                    <Form.Check
                      type="radio"
                      id={`percentage${option.value}`}
                      name="percentage"
                      value={option.value}
                      label={option.label}
                      checked={selectedPercentage.toString() === option.value.toString()}
                      onChange={(e) => setSelectedPercentage(e.target.value)}
                    />
                  ))}
                </AdvancePercentageContainer> */}
              </FormGroup>
            </div>
            <Row className="py-2">
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <Breakdown
                  invoiceBalance={invoiceBalance}
                  values={values}
                  isSponsored={advanceDetailData?.advanceType === "sponsored"}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Formik>
    </section>
  );
};

export default Advance;
