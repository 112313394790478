import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import Range from "ui-lib/components/range";
import { useLockPeriods } from "hooks/contents";
import { formatCurrency } from "utils/commonHelpers";

import { BoostCalculatorContainer } from "./WidgetCalculator.styles";

const WidgetCalculator = () => {
  const [amount, setAmount] = useState("");
  const [amountCounter, setAmountCounter] = useState(0);
  const [selectedRewardDuration, setSelectedRewardDuration] = useState({
    rewardAPY: 0,
    lockInPeriod: 0,
  });
  const symbol = localStorage.getItem("userCurrency") || "SGD";

  const LOCK_IN_PERIOD_REWARDS_APY_OPTIONS = useLockPeriods();

  const estimate = (e) => {
    if (e.type === "blur" || (e.type === "keypress" && e.charCode === 13)) {
      // do nothing
    }
  };

  const handleChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input
      .replace(/[^\d.]/g, "")
      .replace(/(\..*)\./g, "$1")
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      .replace(/(\.\d{2}).*/g, "$1");

    setAmount(sanitizedInput);
  };

  const handleAmountCounter = useCallback(() => {
    const sanitizeAmount = amount.replace(/,/g, "");
    return Math.ceil(sanitizeAmount * amountCounter) / 100;
  }, [amount, amountCounter]);

  const calculateRewardValue = () =>
    ((+handleAmountCounter() * selectedRewardDuration.rewardAPY) / 365) *
    selectedRewardDuration.lockInPeriod;

  return (
    <BoostCalculatorContainer id="boost-settings-calculator-layout">
      <h6 className="font-weight-bold">Boost Calculator</h6>
      <p>Invoice amount</p>
      <BoostCalculatorContainer.InputContainer symbol={symbol}>
        <BoostCalculatorContainer.Input
          type="text"
          name="amount"
          value={amount}
          onChange={handleChange}
          placeholder="1,000.00"
        />
      </BoostCalculatorContainer.InputContainer>
      <h6 className="font-weight-bold">Amount I want to Boost</h6>
      <p className="small-text">You are boosting {formatCurrency(handleAmountCounter())}</p>
      <Range
        value={amountCounter}
        onChange={(rangeValues) => {
          setAmountCounter(rangeValues[0].toFixed(0));
        }}
        type="secondary"
        renderPopoverText={(value) => value}
        className="w-100"
        step={1}
        min={0}
        max={100}
        suffix="%"
      />
      <BoostCalculatorContainer.SelectContainer>
        <h6>Select commitment period:</h6>
        <BoostCalculatorContainer.Select
          defaultValue="-1"
          id="boostDurationReward"
          className="form-control"
          onChange={(e) => {
            setSelectedRewardDuration({
              lockInPeriod: LOCK_IN_PERIOD_REWARDS_APY_OPTIONS[e.target.value].value.lockInPeriod,
              rewardAPY: LOCK_IN_PERIOD_REWARDS_APY_OPTIONS[e.target.value].value.rewardAPY,
            });
          }}
        >
          <option value="-1" disabled>
            Select duration and rate
          </option>
          {LOCK_IN_PERIOD_REWARDS_APY_OPTIONS?.map((item, index) => (
            <option value={index} key={item?.lockInPeriod} label={item?.label} />
          ))}
        </BoostCalculatorContainer.Select>
        <div className="icon-container">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </BoostCalculatorContainer.SelectContainer>
      {selectedRewardDuration.lockInPeriod !== 0 && (
        <BoostCalculatorContainer.RewardContainer>
          <p>Total rewards earned after {selectedRewardDuration.lockInPeriod} days</p>
          <h4>{formatCurrency(calculateRewardValue(), true, { precision: 2 })}</h4>
        </BoostCalculatorContainer.RewardContainer>
      )}
    </BoostCalculatorContainer>
  );
};

export default WidgetCalculator;
