import { useEffect } from "react";
import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Button from "views/public/signup/components/Button";
import FormikInput from "views/public/signup/components/FormikInput";
import FormikCheckbox from "views/public/signup/components/FormikCheckbox";

import { LYTE_USAGE_TYPES } from "./constants";

const LyteUsage = ({ userData }) => {
  const { push, replace } = useHistory();
  const { values, errors } = useFormikContext();

  const isDisabled = "intention" in errors || "intentionOthers" in errors;

  const handleSubmit = () => {
    if (userData) {
      push(`/onboarding/audience`);
      return;
    }
    push(`/signup/steps/5`);
  };

  return (
    <div>
      <form>
        <Row>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <h3 className="font-weight-bold">What are you intending to use Lyte for?</h3>
            <h6 className="pb-4">*You may choose more than 1 option</h6>
            <FormikCheckbox list={LYTE_USAGE_TYPES} name="intention" />
            {values.intention.includes("others") && (
              <FormikInput label="Specify (Others)" name="intentionOthers" />
            )}
            <Button
              type="button"
              className="w-100 mt-4 mb-3"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default LyteUsage;
