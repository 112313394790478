import NoKycWarning from "components/kyc/no-kyc-warning/NoKycWarning";
import { DRAFT, PENDING_VERIFICATION, REJECTED } from "components/kyc/constants";

const InvoiceNoKycWarning = ({ user }) =>
  !user && !user?.isKycApproved ? null : (
    <NoKycWarning kycStatus={user?.kycStatus}>
      {!user?.kycStatus && user?.kycStatus !== DRAFT && (
        <>
          <p className="font-weight-bold">You have not completed your identity verification</p>
          <p>
            LytePay helps you with Invoice Management, Collection of payments and Boost (rewards).
            In order to use our services, you will need to verify your identify.
          </p>
        </>
      )}

      {user?.kycStatus === PENDING_VERIFICATION && (
        <>
          <p className="font-weight-bold">Your identity is being verified.</p>
          <p>
            The process usually takes a few minutes. For follow-ups please contact{" "}
            <span className="text-blue">support@lytepay.co</span>.
          </p>
        </>
      )}

      {user?.kycStatus === REJECTED && (
        <>
          <p className="font-weight-bold">
            Your identity documents has been rejected. Please try again.
          </p>
          <p>
            To enjoy all of LytePay's services, you will need to verify your identity. Complete it
            now.
          </p>
        </>
      )}
    </NoKycWarning>
  );

export default InvoiceNoKycWarning;
