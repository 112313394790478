const LyteBtn = ({ variant, className, children, ...props }) => {
  const classes =
    variant === "white"
      ? "global-button outline m3-auto"
      : variant === "link"
      ? "btn color-blue-link mr-4"
      : "global-button";

  return (
    <button type="button" className={`${classes} ${className}`} {...props}>
      {children}
    </button>
  );
};

export default LyteBtn;
