import { down } from "styled-breakpoints";
import styled from "styled-components";

import Color from "utils/colours";

export const VerifyEmailContainer = styled.div`
  font-family: "Inter";
  color: ${Color.dark700};
  min-height: 100%;
  background-color: ${Color.neutral0};
  position: relative;
  padding: 110px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    color: ${Color.dark900};
  }

  .content-container {
    min-height: 400px;
  }

  ${down("md")} {
    padding: 84px 10px;
  }
  ${down("sm")} {
    .img-fluid {
      max-width: 240px;
    }
  }
`;
