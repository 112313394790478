import { createGlobalStyle } from "styled-components";

import Color from "utils/colours";

const GlobalStyles = createGlobalStyle`
  body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  height: 100vh;
  background-color: ${(props) => props.theme.color.bodyBackground};
  color: ${(props) => props.theme.color.textSecondary};
  }
  .card {
    background-color: ${(props) => props.theme.color.cardBackground};
  }

  p {
    transition-property: none;
    transition-timing-function: none;
  }

  // COLOUR-TEXT
  .color-green {
    color:${Color.integrity500};
  }

  .color-grey {
    color:${Color.dark700};
  }

  .color-darkblue {
    color:${Color.navi800};
  }

  .color-blue-link {
    color:${(props) => props.theme.color.textLinkDefault};
    cursor: pointer;

    &:hover {
      color:${(props) => props.theme.color.textLinkHover};
      text-decoration: none;
    }
  }

  a {
    color: ${(props) => props.theme.color.textLinkDefault};
  }

  .button-link {
    color: ${(props) => props.theme.color.textLinkDefault};
  }

  .navi-con {
    background-color: ${(props) => props.theme.color.cardBackground};
  }

  .navi-title {
    color: ${(props) => props.theme.color.textDarkContrast};
  }

  .navi-each {
    color: ${(props) => props.theme.color.textDarkContrast} !important;

    &:hover {
      color: ${Color.dark100} !important;
    }
  }
  // TOP NAVI
  .tn-con {
    background-color: ${(props) => props.theme.color.cardBackground};
    border-color: ${(props) => props.theme.color.borderSecondary};
  }
  .modal-content {
    background-color: ${(props) => props.theme.color.cardBackground};
    border: 1px solid ${(props) => props.theme.color.borderSecondary};
  }

  .pro-sidebar {
    box-shadow: 0px 0 3px 0px ${(props) => props.theme.color.bodyBackground};
    color: ${(props) => props.theme.color.textDarkContrast};
    & > .pro-sidebar-inner {
      background-color :${(props) => props.theme.color.cardBackground};
    }
  }

  .form-control {
    background-color: ${(props) => props.theme.color.formInputBackgroundColor};
  color: ${(props) => props.theme.color.textDarkContrast};
    border-color: ${(props) => props.theme.color.inputBorderDefault};
    &::placeholder {
      color: ${(props) => props.theme.color.formInputPlaceholderColor};
    }
    &:focus {
      background-color: ${(props) => props.theme.color.formInputBackgroundColorActive};
  color: ${(props) => props.theme.color.textDarkContrast};
    }
  }

  .btn-outline-primary {
    border-color: ${(props) => props.theme.color.borderPrimary};
    color: ${(props) => props.theme.color.textPrimary};
  }

  #kyc-progress-bar {

    &:before {
      border-color: ${(props) => props.theme.color.invoiceBorderLine};
      background: ${(props) => props.theme.color.invoiceBorderLine};
    }
  }

  .steps-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: ${(props) => props.theme.color.textSecondary};

    a {
      color: ${(props) => props.theme.color.textSecondary};
      &.steps{
        &.active,
        &:hover {
          .dot{
            background-color: ${(props) => props.theme.color.textPrimary};
          }
          color: ${(props) => props.theme.color.textPrimary};
        }
      }
    }
  }

  .form-bottom-box {
    background-color: ${(props) => props.theme.color.bodyBackgroundPrimary};
  }
  .text-small-uppercase, .text-big {
    color: ${(props) => props.theme.color.textSmallUppercase};
  }
  .bg-white {
    background-color: ${(props) => props.theme.color.cardBackground} !important;
  }
  .DayPickerInput-OverlayWrapper {
    width: 100%;
    .DayPickerInput-Overlay, bg-light {
      background-color: ${(props) => props.theme.color.formInputBackgroundColor};
      .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
          .DayPicker-Day--outside
        ):hover {
        background-color: 1px solid ${(props) => props.theme.color.inputBorderDefault};
        color: ${(props) => props.theme.color.textSubtleContrast};
      }
    }
  }
  `;

export default GlobalStyles;
