import { formatCurrency } from "utils/commonHelpers";

import { TextDescription } from "./styles";

export const COLUMNS = [
  {
    name: "Item",
    selector: (row) => row.itemName,
    width: "15%",
    maxWidth: "250px",
    wrap: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    width: "30%",
    minWidth: "200px",
    wrap: true,
    cell: (row) => <TextDescription dangerouslySetInnerHTML={{ __html: row.description }} />,
  },
  {
    name: "Quantity",
    selector: (row) => row.quantity,
    center: true,
  },
  {
    name: "Price",
    selector: (row) => row.rate,
    wrap: true,
    cell: (row) => formatCurrency(row.rate),
    width: "120px",
  },
  {
    name: "Amount",
    selector: (row) => row.total,
    right: true,
    wrap: true,
    cell: (row) => formatCurrency(row.quantity * row.rate),
    width: "150px",
  },
];

export const CUSTOM_STYLES = (theme) => ({
  table: {
    style: {
      backgroundColor: "transparent",
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
    },
  },
  tableWrapper: {
    style: {},
  },
  header: {
    style: {
      display: "none",
    },
  },
  subHeader: {
    style: {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
      minHeight: "0",
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
      backgroundColor: "transparent",
      borderBottom: `1px solid ${theme?.color?.invoiceSummaryTableDivider}`,
    },
  },
  headCells: {
    style: {
      padding: "24px 24px 24px 0",
      fontSize: "16px",
      fontWeight: "600",
      color: theme?.color?.textDarkContrast,
      "&:last-of-type": {
        padding: "24px 4px 24px 0",
      },
    },
    activeSortStyle: {
      color: theme?.color?.textDarkContrast,
      "&:focus": {
        outline: "none",
      },
      "&:hover:not(:focus)": {
        color: theme?.color?.textDarkContrast,
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: theme?.color?.textDarkContrast,
      },
      "&:hover": {
        color: theme?.color?.textDarkContrast,
      },
    },
  },
  rows: {
    style: {
      borderBottom: `1px solid ${theme?.color?.invoiceSummaryTableDivider}`,
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme?.color?.invoiceSummaryTableDivider}`,
      },
    },
  },
  cells: {
    style: {
      padding: "24px 24px 24px 0",
      fontSize: "13px",
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      verticalAlign: "top",
      minWidth: "0",
      color: theme?.color?.textDarkContrast,
      alignItems: "flex-start",
      "&:last-of-type": {
        padding: "24px 4px 24px 0",
      },
    },
  },
  pagination: {
    style: {
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      color: theme?.color?.textDarkContrast,
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    pageButtonsStyle: {
      color: theme?.color?.textDarkContrast,
      fill: theme?.color?.textDarkContrast,
      "&:disabled": {
        color: theme?.color?.textDarkContrast,
        fill: theme?.color?.textDarkContrast,
      },
      "&:hover:not(:disabled)": {
        backgroundColor: theme?.color?.buttonBackgroundPrimaryHover,
      },
      "&:focus": {
        backgroundColor: theme?.color?.buttonBackgroundPrimaryHover,
      },
    },
  },
  noData: {
    style: {
      backgroundColor: theme?.color?.tableContentBackgroundPrimary,
      color: theme?.color?.textDarkContrast,
      borderRadius: "8px",
    },
  },
});
