import styled from "styled-components";

import Color from "utils/colours";

export const OtpContainer = styled.div`
  position: relative;

  .phone-number {
    color: ${Color.navi500};
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  div {
    width: 60px;
    .otp-input {
      width: 100%;
      height: 72px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid ${Color.dark200};
      padding: 22px 16px;
      font-size: 33px;
      font-weight: 700;
      color: ${Color.dark900};
      line-height: 28px;
      caret-color: transparent;
      &::selection {
        background-color: transparent;
      }
    }
  }
`;
