import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { LyteButton } from "components/common/buttons";

import { PwaBannerContainer } from "./styles";

const PwaBanner = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const location = useLocation();

  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome !== "accepted") {
          setDeferredPrompt(null);
        }
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  const handleContainer = () => {
    if (location?.pathname === "/")
      return (
        <PwaBannerContainer className={deferredPrompt ? "d-flex" : "d-none"}>
          <p>
            Install our mobile version of LytePay so you are able to create, track and collect your
            invoices and payments seamlessly on the go.
          </p>
          <LyteButton type="button" size="sm" onClick={handleInstallClick}>
            Install
          </LyteButton>

          <PwaBannerContainer.CloseButton
            type="button"
            variant="default"
            size="sm"
            onClick={() => setDeferredPrompt(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </PwaBannerContainer.CloseButton>
        </PwaBannerContainer>
      );
    return false;
  };
  return handleContainer();
};

export default PwaBanner;
