import Error500 from "views/public/errors/Error500";
import Error401 from "views/public/errors/Error401";
import Error404 from "views/public/errors/Error404";

export const errorPages = [
  {
    path: "/500",
    exact: true,
    component: Error500,
  },
  {
    path: "/401",
    exact: true,
    component: Error401,
  },
  {
    path: "/404",
    exact: true,
    component: Error404,
  },
];
