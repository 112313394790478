import styled from "styled-components";

import Color from "utils/colours";

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  label {
    width: fit-content;
    border-radius: 32px;
    display: flex;
    padding: 6px 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid transparent;
    background-color: transparent;
    color: ${Color.dark900};
    border-color: ${Color.dark200};
    transition: none;
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    p {
      transition: none;
      margin: 0;
    }
    &.checked {
      background-color: ${Color.navi500};
      color: ${Color.neutral0};
      border-color: none;
    }
  }
`;

export const SelectContainer = styled.div`
  .lyte-select-container {
    margin-bottom: 16px;
    .lyte-select {
      &__control {
        border-radius: 8px;
        border: 1px solid ${Color.dark200};
      }
      &__indicators {
        opacity: 0;
      }
      &__option {
        &--is-focused {
          background-color: ${Color.navi50};
        }
        &--is-selected {
          background-color: ${Color.navi50};
          color: ${Color.dark900};
        }
      }
    }
  }
  .label-options {
    border: 1px solid ${Color.dark200};
    padding: 6px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 13px;
    font-weight: 600;
    color: ${Color.dark900};
    svg {
      cursor: pointer;
    }
  }
`;
