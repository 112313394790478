import { CustomBadge } from "./styles";

const InvoiceStatuses = ({ status, children, ...restProps }) => {
  const handleCheckVariantStatus = () => {
    switch (status) {
      case "paid":
      case "partially_paid":
      case "advanced":
      case "completed":
      case "accepted":
      case "available":
      case "active":
        return "success";
      case "rejected":
      case "aborted":
      case "cancelled":
      case "expired":
      case "failed":
        return "danger";
      case "current":
      case "pending":
      case "cashout_pending":
      case "pending_payment":
      case "processing":
        return "info";
      default:
        return "light";
    }
  };

  return (
    <CustomBadge variant={handleCheckVariantStatus()} {...restProps}>
      {children || status?.replace("_", " ").replace("-", " ")}
    </CustomBadge>
  );
};
export default InvoiceStatuses;
