import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

import useApi from "hooks/useApi";

export const useSubmitOnboarding = (options) => {
  const { Public } = useApi();

  return useMutation(async (values) => {
    const promise = Public.post(`public/user/onboarding`, values);
    toast.promise(promise, {
      success: "Registration successful.",
      loading: "Checking your registration data",
      error: ({ response, message }) =>
        `Error registering your data: ${response?.data?.messages?.[0] || message}`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const useGetUserRoles = (options) => {
  const { Public } = useApi();
  return useQuery(
    "userRoles",
    async () => {
      const { data } = await Public({
        method: "get",
        url: "public/user/roles",
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
