import styled from "styled-components";
import { down } from "styled-breakpoints";
import { Container, Image } from "react-bootstrap";

import Color from "utils/colours";

export const HeaderContainer = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Color.neutral0};
  padding: 1rem 1.5rem;
  z-index: 1000;
  ${down("md")} {
    padding: 12px 5px;
  }
  ${down("sm")} {
    padding: 20px 5px;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 100%;
  width: 95px;
`;

export const StyledProgress = styled.div`
  width: 100%;

  .progress-bar-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    .each-progress {
      border-radius: 5px;
      width: ${(props) => `calc((100% - (8px * ${+props.steps})) / ${+props.steps})`};
      height: 4px;
      background-color: ${Color.navi50};
      &.active {
        background-color: ${Color.navi500};
      }
    }
  }
`;
