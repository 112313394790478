export const CountryCodes = [
  {
    id: "SG",
    prefix: "+65",
  },
  {
    id: "ID",
    prefix: "+62",
  },
];
