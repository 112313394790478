import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import FormikCheckbox from "components/form/FormikCheckbox";
import Divider from "components/common/divider";

import InvoiceNoKycWarning from "../InvoiceNoKycWarning";

import {
  BankAlertContainer,
  BankInformationContainer,
  TextClauseDescription,
  TextClauseHeader,
} from "./styles";

const InvoicePaymentForm = ({ user }) => {
  const { values } = useFormikContext();

  const handleBankPaymentInvoiceType = () => {
    if (values?.invoiceType === "LyteCollect")
      return (
        <>
          <h6>
            {user?.fullName} authorises LytePay Pte Ltd (UEN: 201827926E) to collect payments on
            their behalf under this invoice and all amounts payable under this Contract shall be
            paid to:
          </h6>
          <Row>
            <Col xs={6}>
              <small>Bank</small>
              <h6 className="m-0 font-weight-bold">DBS Bank Ltd</h6>
            </Col>
            <Col xs={6}>
              <small>Account Holder</small>
              <h6 className="m-0 font-weight-bold">LytePay Pte Ltd</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <small>Swift Code</small>
              <h6 className="m-0 font-weight-bold">DBSSSGSG</h6>
            </Col>
            <Col xs={6}>
              <small>Account Number</small>
              <h6 className="m-0 font-weight-bold">072-5295-300</h6>
            </Col>
          </Row>
        </>
      );
    return (
      <>
        <Row>
          <Col xs={6}>
            <small>Bank</small>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.bankName}</h6>
          </Col>
          <Col xs={6}>
            <small>Account Holder</small>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.accountHolderName}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <small>Account Number</small>
            <h6 className="m-0 font-weight-bold">{user?.bankAccount?.accountNumber}</h6>
          </Col>
        </Row>
      </>
    );
  };

  const handleTextInvoiceType = () => {
    if (values?.invoiceType === "LyteCollect")
      return (
        <>
          <TextClauseHeader>I agree to the Authorisation Clause</TextClauseHeader>
          <TextClauseDescription>
            LytePay is licensed by MAS (Monetary Authority of Singapore) as a Major Payment
            institution.
          </TextClauseDescription>
        </>
      );
    return (
      <p className="mb-0">
        <TextClauseDescription>
          The client will pay to me directly. The invoice will need to be marked as paid manually,
          and will not be eligible for Boost.
        </TextClauseDescription>
      </p>
    );
  };

  return (
    <div>
      {values?.invoiceType === "LyteCollect" && (
        <div className="">
          <h6 className="mb-4 font-weight-bold">Authorise LytePay</h6>
          <h6 className="mb-4">
            By using LyteCollect, you are agreeing with our authorisation clause.
          </h6>
        </div>
      )}
      <BankInformationContainer>{handleBankPaymentInvoiceType()}</BankInformationContainer>

      <form id="invoicePaymentForm" style={{ opacity: user?.isKycApproved ? null : "0.5" }}>
        <div className="my-4">
          <FormikCheckbox name="agreeAuthClause">{handleTextInvoiceType()}</FormikCheckbox>
        </div>
        <Divider />
        <BankAlertContainer className="mt-4">
          To help you get paid on time, LytePay will send out automated reminders to your clients 7
          days and 3 days before invoice due, on invoice due date, and 3 days after invoice is due
          (if unpaid).
        </BankAlertContainer>

        <div className="my-4">
          <FormikCheckbox name="sendReminderEmail">
            <TextClauseHeader>
              LytePay will send system-generated reminder emails to your client.
            </TextClauseHeader>
            <TextClauseDescription>
              Uncheck this box if you want to disable reminder emails except when this invoice is
              sent and when the invoice is due. Disabling system-generated email reminders will
              automatically disable late fee rate.
            </TextClauseDescription>
          </FormikCheckbox>
        </div>
      </form>

      {!user?.isKycApproved && <InvoiceNoKycWarning user={user} />}
    </div>
  );
};

InvoicePaymentForm.propTypes = {
  user: PropTypes.shape({
    bankAccount: PropTypes.shape({
      accountHolderName: PropTypes.string.isRequired,
      accountNumber: PropTypes.string.isRequired,
      bankName: PropTypes.string.isRequired,
    }),
    isKycApproved: PropTypes.bool.isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoicePaymentForm;
