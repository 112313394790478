import { useQuery } from "react-query";

import useApi from "../useApi";

export const useGetBoostByRef = (ref) => {
  const { Private } = useApi();
  return useQuery(
    ["boost", ref],
    async () => {
      const { data } = await Private.get(`boosts/${ref}`);
      return data;
    },
    {
      keepPreviousData: true,
    }
  );
};
