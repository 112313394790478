import Badge from "react-bootstrap/Badge";

import Loader from "components/common/Loader";
import Widget from "ui-lib/components/widget";
import { useUserDataState } from "providers/UserDataProvider";

import { BADGE_VARIANTS, PENDING_SUBMISSION } from "./constants";
import DefaultBankAccountDetails from "./DefaultBankAccountDetails";
import OnboardingBankAccountDetails from "./OnboardingBankAccountDetails";

const BankAccountDetails = () => {
  const { userData: user } = useUserDataState();
  if (!user) {
    return <Loader />;
  }
  const title = (
    <div className="d-flex justify-content-between mb-2">
      <span className="db-subheader">Bank Account</span>
      <span className="d-flex align-items-center ">
        <span className="mr-3 small text-lowercase">status:</span>
        <Badge
          pill
          variant={
            (user?.bankAccount?.status && BADGE_VARIANTS[user.bankAccount.status].variant) ||
            BADGE_VARIANTS[PENDING_SUBMISSION].variant
          }
        >
          {(user?.bankAccount?.status && BADGE_VARIANTS[user.bankAccount.status].label) ||
            BADGE_VARIANTS[PENDING_SUBMISSION].label}
        </Badge>
      </span>
    </div>
  );
  return (
    <Widget
      title={title}
      body={
        !user?.fromOnboarding ? (
          <DefaultBankAccountDetails bankAccount={user?.bankAccount} />
        ) : (
          <OnboardingBankAccountDetails bankAccount={user?.bankAccount} />
        )
      }
    />
  );
};

export default BankAccountDetails;
