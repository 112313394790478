import * as yup from "yup";

export const OnboardingSchema = yup.object().shape({
  key: yup.string().nullable(),
  intention: yup
    .array()
    .of(yup.string())
    .min(1, "Please choose at least one from the following options"),
  intentionOthers: yup.string().when("intention", {
    is: (intention) => intention && intention.includes("others"),
    then: yup
      .string()
      .required("This is a required field for 'Others' What are you intending to use Lyte for"),
  }),

  audienceSource: yup
    .array()
    .of(yup.string())
    .min(1, "Please choose at least one from the following options"),
  audienceSourceOthers: yup.string().when("audienceSource", {
    is: (audienceSource) => audienceSource && audienceSource.includes("others"),
    then: yup
      .string()
      .required("This is a required field for 'Others' where did your hear LytePay"),
  }),

  freelancerType: yup
    .string()
    .oneOf(["full-time", "part-time"])
    .required("Please choose one from the following options"),
  freelancerRoles: yup.array().of(yup.object()).min(1, "Please select at least 1 category"),
  referralCode: yup.string().nullable(),
});
