import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

import useApi from "./useApi";

export const useUpdateUserProfile = () => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private({
      method: "post",
      url: "users/detail",
      data: values,
    });
    toast.promise(promise, {
      loading: "Updating profile",
      success: `Profile updated on ${new Date().toLocaleString()}`,
      error: `Error updating profile`,
    });
    const { data } = await promise;
    return data;
  });
};

export const useGetUserLogo = () => {
  const { Private } = useApi();
  const { user } = useAuth0();
  const lyteIdIdentifier = user?.sub;
  return useQuery(
    ["logo", lyteIdIdentifier],
    async () => {
      const { data } = await Private.get("files/logo", {
        responseType: "blob",
      });
      return data;
    },
    {
      enabled: !!lyteIdIdentifier,
    }
  );
};

export const useUserInvoiceNumberPrefill = () => {
  const { Private } = useApi();
  return useQuery("userInvoiceNumberPrefill", async () => {
    const { data } = await Private.get("users/invoiceNumberPrefill");
    return data;
  });
};

export const useGetUserData = () => {
  const { Private } = useApi();
  return useQuery("userData", async () => {
    const { data } = await Private.get("users");

    return data;
  });
};

export const useGetUserRoles = () => {
  const { Private } = useApi();
  return useQuery("userRoles", async () => {
    const { data } = await Private.get("users/roles");

    return data;
  });
};

export const usePostAddUserInfo = (options) => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("users/addInfo", values);
    toast.promise(promise, {
      loading: "Submitting...",
      success: `Response saved!`,
      error: `Something went wrong while updating your information.`,
    });
    const { data } = await promise;
    return data;
  }, options);
};

export const useUsersPromoCode = () => {
  const { Private } = useApi();

  return useMutation(async (values) => {
    const promise = Private.post("users/promotionCode", values);
    toast.promise(promise, {
      loading: "Submitting...",
      success: `Promo code successfully applied.`,
      error: `Something went wrong while applying your code.`,
    });
    const { data } = await promise;
    return data;
  });
};
