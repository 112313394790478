import image0 from "images/illust/illust-invoice.png";

import image1 from "./images/create-invoice.png";
import image2 from "./images/handshake.png";
import image3 from "./images/invoice-magic.png";
import image4 from "./images/boost-income.png";

export const onboardingFlowContent = [
  {
    id: 0,
    image: image0,
    title: "Welcome to LytePay’s Invoice tool!",
    description: "Invoicing helps all parties involved be clear on their payment obligations.",
  },
  {
    id: 1,
    image: image1,
    title: "1. Create Invoice",
    description:
      "This is where you input your services rendered, and the price charged. You may also select a late payment fee if you wish – we recommend between 2-5%.",
  },
  {
    id: 2,
    image: image2,
    title: "2. Assign invoice to LytePay",
    description:
      "Assign us to collect on your behalf so that we can update the matching invoice as paid and give you rewards for using LytePay",
    links: [{ text: "What is Collect?", href: "" }],
  },
  {
    id: 3,
    image: image3,
    title: "3. Invoice Confirmation",
    description:
      "Before you send your invoice out, you are able to preview how your invoice will look to clients by sending a test invoice to yourself.",
  },
  {
    id: 4,
    image: image4,
    title: "4. Boost your income!",
    description:
      "LytePay rewards you for managing your income with us. Upon sending your first invoice, set up your default Boost preference to start being rewarded, effortlessly.",
    links: [{ text: "Have more question? Read our FAQ.", href: "" }],
  },
];
