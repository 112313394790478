import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import useApi from "./useApi";

export const useUserData = (extraParams) => {
  const { user: userFromAuth0 } = useAuth0();
  const { replace } = useHistory();
  const location = useLocation();

  const { Private } = useApi();

  return useQuery(
    "userData",
    async () => {
      const { data } = await Private.get("users");
      return data;
    },
    {
      keepPreviousData: true,
      enabled: !!userFromAuth0?.sub,
      onSettled: (data) => {
        if (
          !data ||
          !data?.isMobileVerified ||
          !data?.isEmailVerified ||
          data?.userType !== "freelancer"
        ) {
          return window.location.replace(process.env.REACT_APP_LP_BASE_URL);
        }

        localStorage.setItem("userCurrency", data?.currency);

        if (!data?.kycStatus && data?.signUpSource === "centralizedonboarding") {
          replace("/onboarding/lyte-usage");
          return false;
        }

        if (!data?.registrationCompletedAt) return false;

        if (!data?.isKycApproved && !location?.pathname?.includes("/kyc")) {
          replace("/kyc");
        }

        return false;
      },
      ...extraParams,
    }
  );
};

export const useUserDataSingleUse = (extraParams) => {
  const { user: userFromAuth0 } = useAuth0();
  const { Private } = useApi();

  return useQuery(
    "userDataSingleUse",
    async () => {
      const { data } = await Private.get("users");
      return data;
    },
    {
      enabled: !!userFromAuth0?.sub,
      ...extraParams,
    }
  );
};

export const useUpdateUserData = (options) => {
  const { Private } = useApi();
  return useMutation(
    async (values) => {
      const { data } = await Private({
        method: "post",
        url: "users",
        data: values,
      });
      return data;
    },
    { ...options }
  );
};
