import PropTypes from "prop-types";

const Clause = () => <div>Clauses</div>;

const percentLateFee = (lateFeeRate) => (lateFeeRate ? lateFeeRate * 100 : "0");

Clause.LatePaymentClause = ({ lateFeeRate }) =>
  lateFeeRate ? (
    <>
      <h5 className="text-small-uppercase mb-1">LATE PAYMENT CLAUSE</h5>
      <p className="mb-2">
        All payments and other charges which are not paid on the due date shall be subject to a late
        payment fee of {percentLateFee(lateFeeRate)}% per month on the outstanding amount.
      </p>
    </>
  ) : (
    ""
  );

Clause.LatePaymentClause.defaultProps = {
  lateFeeRate: 0,
};

Clause.LatePaymentClause.propTypes = {
  lateFeeRate: PropTypes.number,
};

Clause.Assignment = ({ fullName, invoiceType, user }) => (
  <>
    <h5 className="text-small-uppercase mb-1">ASSIGNMENT CLAUSE</h5>
    <p className="mb-3">
      1.1 &nbsp; {fullName} shall not delegate their obligations under this Contract to a
      third-party, without first receiving the Client’s written permission.
    </p>
    {invoiceType === "LyteCollect" ? (
      <>
        <p className="mb-2">
          1.2 &nbsp; {fullName} shall assign their rights and benefits under this Contract to Lyte
          Ventures Pte Ltd or any of its subsidiaries and amounts payable under this Contract shall
          be paid by either of the following:
        </p>
        <p className="pl-4 mb-2">
          <u>Bank Transfer</u>
        </p>
        <div className="pl-4 mb-4">
          <table className="bank-table mb-3" style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              <tr>
                <th>
                  <strong>Bank</strong>
                </th>
                <td>DBS Bank Ltd</td>
              </tr>
              <tr>
                <th>
                  <strong>Account Holder Name</strong>
                </th>
                <td>LytePay Pte Ltd</td>
              </tr>
              <tr>
                <th>
                  <strong>Swift code</strong>
                </th>
                <td>DBSSSGSG</td>
              </tr>
              <tr>
                <th>
                  <strong>Account number</strong>
                </th>
                <td>072-5295-300</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pl-4 mb-2">
          <u>PayNow QR Code</u>
        </div>
        <div className="pl-4 mb-4">
          <img src={process.env.REACT_APP_PAY_NOW_QR} alt="PayNow QR Code" width={240} />
        </div>
      </>
    ) : (
      <>
        <p className="mb-2">
          1.2 The client will pay to {fullName} directly and amounts payable under this Contract
          shall be paid by to the following:
        </p>
        <p className="pl-4 mb-2">
          <u>Bank Transfer</u>
        </p>
        <div className="pl-4 mb-4">
          <table className="bank-table mb-3" style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              <tr>
                <th>
                  <strong>Bank</strong>
                </th>
                <td>{user?.bankAccount?.bankName}</td>
              </tr>
              <tr>
                <th>
                  <strong>Account Holder Name</strong>
                </th>
                <td>{user?.bankAccount?.accountHolderName}</td>
              </tr>
              <tr>
                <th>
                  <strong>Account number</strong>
                </th>
                <td>{user?.bankAccount?.accountNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )}
    <p className="mb-3">
      1.3 &nbsp; The Client may not assign its rights and delegate its obligations under this
      Contract without permission from {fullName}.
    </p>
  </>
);

export default Clause;
