import { useHistory } from "react-router-dom";

import { LyteButton } from "components/common/buttons";

const CellUpdateSettings = ({ boostRefNumber, status }) => {
  const { push } = useHistory();
  return (
    <div>
      {status === "current" ? (
        <LyteButton
          variant="ghost"
          size="sm"
          onClick={() => push(`/boost/summary/${boostRefNumber}/update`)}
        >
          Update
        </LyteButton>
      ) : (
        <p className="text-align">--</p>
      )}
    </div>
  );
};

export default CellUpdateSettings;
