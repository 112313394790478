import styled from "styled-components";

import Color from "utils/colours";

export const BankInformationContainer = styled.div`
  padding: 16px 24px;
  background-color: ${(props) => props.theme.color.bodyBackground};
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  .row {
    width: 100%;
    div {
      small {
        font-weight: 600;
      }
      &:first-of-type {
        border-right: 1px solid ${(props) => props.theme.color.dividerColor};
      }
    }
  }
`;

export const BankAlertContainer = styled.div`
  padding: 12px 16px;
  background-color: ${Color.navi50};
  color: ${Color.navi700};
  border: 1px solid ${(props) => props.theme.color.borderBlue};
  font-weight: 700;
  font-size: 13px;
`;

export const TextClauseHeader = styled.p`
  margin: 0 !important;
  font-size: 13px;
  color: ${(props) => props.theme.color.textDarkContrast};
`;
export const TextClauseDescription = styled.p`
  margin: 0 !important;
  font-size: 13px;
  color: ${(props) => props.theme.color.textSubtleContrast};
`;
