import { useQuery } from "react-query";

import useApi from "./useApi";

export const useGetAnnoucements = () => {
  const { Private } = useApi();
  return useQuery("annoucements", async () => {
    const { data } = await Private({
      method: "get",
      url: "systemNotification",
    });
    return data;
  });
};
