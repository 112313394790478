import { useParams } from "react-router-dom";

import { useGetProfile, useGetProfilePicture } from "./profile.service";
import ProfilePreview from "./component/preview";

const Profile = () => {
  const { id } = useParams();
  const { data: dataProfile } = useGetProfile(id, {
    onError: () => {
      window.location.replace("/");
    },
  });

  const { data: dataProfilePicture } = useGetProfilePicture(id);
  return <ProfilePreview data={dataProfile} imageData={dataProfilePicture} />;
};

export default Profile;
