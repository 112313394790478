import { Row } from "react-bootstrap";
import { down } from "styled-breakpoints";
import styled from "styled-components";

import Color from "utils/colours";

export const LogoWrapper = styled.div`
  padding: 0px;
  max-height: 135px;
  max-width: 270px;
  overflow: hidden;
`;

LogoWrapper.Logo = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: 100%;
`;

export const ClientDetailsContainer = styled.div`
  margin-top: 24px;
  padding: 24px 0;
  overflow: hidden;
  border-top: 1px solid ${(props) => props.theme.color.dividerColor};
  border-bottom: 1px solid ${(props) => props.theme.color.dividerColor};

  .row {
    margin-left: -24px;
    margin-right: -24px;
    row-gap: 24px;

    div[class|="col"] {
      padding-left: 24px;
      padding-right: 24px;
      &:not(:last-of-type) {
        border-right: 1px solid ${(props) => props.theme.color.dividerColor};
        ${down("md")} {
          border-right: none;
          border-bottom: 1px solid ${(props) => props.theme.color.dividerColor};
          padding-bottom: 24px;
        }
      }
    }
  }
`;

export const TextDescription = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  color: ${(props) => props.theme.color.textDarkContrast};
  margin-bottom: 0;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    min-height: 16px;
  }
`;

export const PaymentDetailsContainer = styled.div`
  background-color: ${(props) => props.theme.color.invoicePaymentDetails};
  padding: 16px;
  border-radius: 8px;
  color: ${(props) => props.theme.color.invoicePaymentDetailsText};
`;

PaymentDetailsContainer.Title = styled.h6`
  margin: 0;
  padding-bottom: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.color.invoicePaymentDetailsText};
`;
PaymentDetailsContainer.Clauses = styled.div`
  margin-bottom: 24px;
  &:not(:last-of-type) {
    padding-bottom: 24px;
    border-bottom: 1px solid ${(props) => props.theme.color.dividerColor};
  }
  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    color: ${(props) => props.theme.color.invoicePaymentDetailsText};
    font-size: 13px;
    &.title {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
`;

export const BankInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  .row {
    width: 100%;
    div {
      small {
        font-weight: 600;
      }
      &:first-of-type {
        border-right: 1px solid ${(props) => props.theme.color.dividerColor};
      }
    }
  }
`;

export const TextClauseHeader = styled.p`
  margin: 0 !important;
  font-size: 13px;
  color: ${(props) => props.theme.color.textDarkContrast};
`;
export const TextClauseDescription = styled.p`
  margin: 0 !important;
  font-size: 13px;
  color: ${(props) => props.theme.color.textSubtleContrast};
`;

export const ItemRows = styled(Row)`
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.color.dividerColor};
  margin-left: 0;
  margin-right: 0;

  div[class|="col"] {
    padding-right: 4px;
    padding-left: 0px;
    &:last-of-type {
      padding-right: 0px;
      padding-left: 4px;
      text-align: right;
    }
  }
`;
