import { useMemo, useState } from "react";

import FormikInput from "components/form/FormikInput";
import FormikDropdown from "components/form/FormikDropdown";

export const useSearchAndFilter = ({
  data = [],
  searchColumns,
  placeholder,
  filterOptions,
  filterColumns,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filterText, setFilterText] = useState("");

  // search data based on searchColumns if provided
  const searchResults =
    data && searchColumns && searchColumns.length > 0
      ? data.filter((item) =>
          searchColumns.some(
            (column) =>
              item[column] && item[column].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      : data;

  // search for name or invoiceNumber from searchResults
  const filterResults =
    filterColumns && filterColumns.length > 0
      ? searchResults.filter((item) =>
          filterColumns.some((column) =>
            item[column].toLowerCase().includes(filterText.toLowerCase())
          )
        )
      : searchResults;

  const searchComponent = useMemo(
    () => (
      <FormikInput
        onChange={(e) => setSearchText(e.target.value)}
        placeholder={placeholder || "Search..."}
        value={searchText}
        name="table-base-searchbar"
        className="mb-0"
      />
    ),
    [searchText, placeholder]
  );

  const filterComponent = useMemo(() => {
    if (!filterOptions) {
      return null;
    }

    return (
      <FormikDropdown
        name="table-base-filter"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
        lists={filterOptions || []}
        withoutDefaultOption
        className="mb-0"
      />
    );
  }, [filterText, filterOptions]);

  const onClearFilters = () => {
    setFilterText("");
    setSearchText("");
  };

  return {
    filterComponent,
    filterText,
    onClearFilters,
    searchComponent,
    searchText,
    results: filterResults,
  };
};
